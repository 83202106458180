import React, { Component } from 'react';
import ReactTable from "react-table";
import { graphql, compose, withApollo } from "react-apollo";
import gql from "graphql-tag";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ClassNames from 'classnames';
import { Link } from "react-router-dom";
import { Icon, Tooltip, Position } from "@blueprintjs/core";

import Query from "../../../graphql/editor/ListUsers";
import LinkIcon from "../../_utils/LinkIcon";
import ResultHeader from "../_layout/ResultHeader";

import {
  setUser,
  setHeader
} from '../../../_actions';

class ListUsers extends Component {
  constructor() {
    super();
    
    this.state = {
      header: {
        title: 'All Users ',
        counter: '',
        action: 'link',
        action_text: 'New User',
        action_icon: 'plus',
        action_route: '/q/users'
      },
      busy: false
      
    }
    
  }

  componentWillMount() {
    //this.registerMe(this.props.db_user, this.props.db_user);
    console.log(this.props);
    
    this.updateState();
    this.registerMe(this.props.db_user.id,this.props.db_user.cognitoId);
    
  }
  
  registerMe(db_user, authuser){
    
      if(db_user === authuser){
        console.log("you are paired")
      }else{
        console.log(`db: ${db_user}`);
        console.log(`auth: ${authuser}`);
        console.log("you are not paired")
        this.props.history.push("/q/welcome");
      }
  }
  

  handleSync = async () => {
    const { client } = this.props;
    const query = Query;

    this.setState({ busy: true });

    await client.query({
      query,
      fetchPolicy: 'network-only',
    });
    
    this.setState({busy: false});
  }

  async updateState() {
    //Getting all GQL payload
    const { client } = this.props;
    let payload = [];
    try {
      const result = await client.query({
        query: gql`
          query {
              listT01S(limit:1000){
                  items{
                      cognitoId
                      id
                      username
                      email
                      locations
                      first
                      last
                      asked
                      answered
                  }
              }
          }
        `
      });
      //console.log(result);
      if(result && result.data.listT01S) {
        payload = result.data.listT01S.items;
      }
    } catch (error) {
      console.log(error);
    }

    //Getting Counter number
    const header = {
      ...this.state.header,
      counter: payload.length
    };

    const { setHeaderData } = this.props;
    setHeaderData(header);

    //Updating State
    this.setState({ table: payload });
  }
  
  renderTable() {
    
    const table = this.state.table;
    //console.log(this.state);

    return (
      <ReactTable
        data={table}
        columns={[{
          columns: [
            {
              Header: 'Name',
              accessor: 'first',
              minWidth: 100,
              Cell: row => (
                <div>
                  <LinkIcon type={'link'}
                            icon={'user'}
                            text={row.value + " " + row.original.last}
                            route={"/q/user/"+ row.original.id+"/profile"}
                  />
                </div>
              )
            },
            {
              Header: 'Email',
              accessor: 'email',
              maxWidth: 100,
              Cell: row => (
                <div>
                  <LinkIcon type={'link'}
                            text={row.value}
                            route={"/q/user/"+ row.original.id+"/profile"}
                  />
                </div>
              )
            },
            {
              Header: 'Locations',
              accessor: 'locations',
              maxWidth: 100,
              Cell: row => (
                <div>
                  <LinkIcon type={'link'}
                            text={row.value}
                            route={"/q/user/"+ row.original.id+"/profile"}
                  />
                </div>
              )
            },
            {
              Header: 'Username',
              accessor: 'username',
              maxWidth: 100,
              Cell: row => (
                <div>
                  <LinkIcon type={'link'}
                            text={row.value}
                            route={"/q/user/"+ row.original.id+"/profile"}
                  />
                </div>
              )
            },
            {
              Header: 'Asked',
              accessor: 'asked',
              maxWidth: 100
            },
            {
              Header: 'Answered',
              accessor: 'answered',
              maxWidth: 100
            },
            {
              Header: 'Actions',
              accessor: 'id',
              maxWidth: 100,
              Cell: row => (
                <div className="actions_column">
                  <Tooltip content="View UserInfo" position={Position.LEFT}>
                    <Link to={"/q/user/" + row.original.id + "/profile"}>
                      <Icon icon="eye-open" iconSize={11}/>
                    </Link>
                  </Tooltip>  
                  <Tooltip content="Edit User" position={Position.LEFT}>
                    <Link to={"/q/user/" + row.original.id + "/edit"}>
                      <Icon icon="edit" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  <Tooltip content="Delete this User" position={Position.LEFT}>
                    <Link to={"/q/user/" + row.original.id + "/delete"}>
                      <Icon icon="cross" iconSize={11}/>
                    </Link>
                  </Tooltip>
                </div>
              )
            }
          
          ]
        }]}
        defaultPageSize={20}
        className=" -highlight"
      />
    )
  }
  
  render() {
    const { busy } = this.state;
    
    return (
      
      <div>
        {this.props.header && <ResultHeader header={this.props.header}/>}
        
        <div className="workarea">
          <button type="button"
                  onClick={this.handleSync}
                  className={ClassNames('bp3-button bp3-icon-add',{'bp3-disabled': busy},{'bp3-icon-swap-horizontal': busy},{'bp3-icon-refresh': !busy})}
          > {busy ? 'Syncing..': 'Sync'}
          </button>
          
          <div>
            {this.renderTable()}
          </div>
        </div>
      </div>
    );
  }
}

ListUsers.propTypes = {
  user: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.dataReducer.user,
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setUserData: value => dispatch(setUser(value)),
  setHeaderData: value => dispatch(setHeader(value)),
});

const ListUserConnected = connect(mapStateToProps, mapDispatchToProps)(ListUsers);

//TODO: Remove this Query, is actually working and pinging the DB
export default withApollo(compose(
  graphql(
    Query,
    {
      options: {
        fetchPolicy: 'network-only',
      },
      props: ({data: {listUsers = {items: []}}}) => ({
        items: listUsers.items
      })
    }
  ),
)(ListUserConnected));
