import React, { Component } from 'react'
import {Auth} from 'aws-amplify'
import { Spring } from 'react-spring'
import { Link } from "react-router-dom"
import { graphql, compose, withApollo} from 'react-apollo'
import { Icon} from "@blueprintjs/core"
import { withRouter } from 'react-router'
import { Row, Col } from 'react-flexbox-grid'
import {Collapse } from "@blueprintjs/core"
import gql from "graphql-tag"
import { getQuestion } from '../../../graphql/queries'
import { createAnswer } from '../../../graphql/mutations'
import { updateQuestion } from '../../../graphql/mutations'
import QuestionHeader from "../_layout/QuestionHeader"
import TaskStepper from "../editor_tasks/components/TaskStepper"

class QuestionAnswer extends Component {
  
  constructor(){
    super();
    this.state = {
      header:{
        breadcrumb:[
          {
            name:'New Answer',
            route:'',
            icon:''
          }
        ],
        qid: '1e994hs294', //dynamically generated
        entity:{
          name:'Loading..',
          route:''
        },
        status: 1, // 1 | 2 | 3 | 4 | 5 | 6 (need to be INT)
        action: 'link', // link | button
        action_text: 'Back', //Text inside button
        action_icon: 'arrow-left', //icon inside button
        action_route: '/q', //Button route
        banner_color: 'new' //new | answer | edit | review | delete
      },
      answer_record: {
        lang:'ENG',
        degree: '3',
        rating: 'G',
        status:'100',
        credit_name:'',
        credit_url:'',
        credit_degree:'',
        creditByEntity:false,
        
      },
      question_record:{
        id:'',
        question:'',
        author:'',
        entity:'',
        entityId:'',
        count:'0',
        d1:'0',
        d2:'0',
        d3:'0',
        d4:'0',
        d1_original:'0',
        d2_original:'0',
        d3_original:'0',
        d4_original:'0',
        task:''
      },
      showDetails:false
    }
  
    this.handleDetailClick = this.handleDetailClick.bind(this);
    this.handleClearAnswer = this.handleClearAnswer.bind(this);
  }
  
  
  componentWillReceiveProps(newProps) {
    
    this.updateState(newProps);
  }
  
  updateState(props){
    
    // GETTING INFO
    const { questionId } =  props.match.params;
    const { question_record, header } = this.state;
    const {question}= props; //AWS QUERY
    
    //UPDATE HEADER
    header.qid = questionId;
    header.action_route = "/q/"+questionId;

    //UPDATE ANSWER
    const {answer_record} =  this.state;
    answer_record.questionId = questionId;
    answer_record.answerQuestionId = questionId;
    answer_record.credit_name = props.db_user.username;
    
    ///UPDATE QUESTION
    if (props.question && props.question.id) {
      
        question_record.question = question.question;
        question_record.id = question.id;
        question_record.entity = question.entity;
        question_record.entityId = question.entityId;
        question_record.lang = question.lang;
        question_record.count = question.count;
        question_record.d1 = question.d1;
        question_record.d2 = question.d2;
        question_record.d3 = question.d3;
        question_record.d4 = question.d4;
        question_record.d1_original = question.d1;
        question_record.d2_original = question.d2;
        question_record.d3_original = question.d3;
        question_record.d4_original = question.d4;
        question_record.task = question.task;
        header.entity.name = question.entity;
        header.entity.route = "/q/entity/"+question.entityId;
        
      
      // Adding 1 to answer count
      question_record.count = question_record.count + 1;
      //Adding 1 to d3 (default)
      question_record.d3 = question_record.d3 + 1;
      
    }
    //UPDATE STATE
    this.setState({ header, answer_record , question_record});
    
  }
  
  async componentDidMount() {
    // get user from auth
    let user = await Auth.currentAuthenticatedUser();
  
    //update state with user info
    //const {header} =  this.state;
    const {answer_record} =  this.state;
    answer_record.author = user.username;
    answer_record.authorId = user.attributes.sub;
  
    const currentDate = Date.now();
    answer_record.createdAt = currentDate.toString();
    answer_record.modifiedAt = currentDate.toString();
    
    this.setState({ answer_record});
    
  }
  
  
  
  handleDetailClick = () => {
    this.setState({ showDetails: !this.state.showDetails });
  }
  
  handleInputValues = field => event => {
    const { answer_record } = this.state
    answer_record[`${field}`] = event.target.value;
    this.setState({ answer_record });
  }
  
  handleClearAnswer = (field) => {
    const { answer_record } = this.state;
    answer_record[`${field}`] = '';
    this.setState({ answer_record });
  }
  
  handleCheckbox = field => event => {
    console.log(this.props.db_user.username)
    const { answer_record } = this.state
    
    if(this.state.answer_record.creditByEntity){
      console.log('second')
      answer_record[`${field}`] = false;
      answer_record[`credit_name`] = this.props.db_user.username;
      answer_record[`degree`] = 3;
    }else{
      console.log('first')
      answer_record[`${field}`] = true;
      answer_record[`credit_name`] = this.state.question_record.entity;
      answer_record[`degree`] = 1;
    }
    
    
    this.setState({ answer_record });
  }
  
  handleDegreeValues = field => event => {
    const { answer_record } = this.state;
    const { question_record } = this.state;
    
    answer_record[`${field}`] = event.target.value;
    
    let d1count=question_record.d1_original;
    let d2count=question_record.d2_original;
    let d3count=question_record.d3_original;
    let d4count=question_record.d4_original;
    switch (event.target.value) {
      case "1":
        d1count++;
        break;
      case "2":
        d2count++;
        break;
      case "3":
        d3count++;
        break;
      case "4":
        d4count++;
        break;
      default: return('no item');
    }
  
    question_record.d1 = d1count;
    question_record.d2 = d2count;
    question_record.d3 = d3count;
    question_record.d4 = d4count;
    
    this.setState({ answer_record, question_record });
  }
  
  handleAnswer = () => {
    
    const { history, createAnswer, updateQuestion } = this.props;
    const { answer_record } = this.state;
    
    if(answer_record.credit_name){
      answer_record.credit = "[("+answer_record.credit_name+"+"+answer_record.degree+"+"+answer_record.credit_url+")]";
    }
  
    if(answer_record.cta_text) {
      answer_record.bounty = "[(" + answer_record.cta_text + "+" + answer_record.cta_url + ")]";
    }
  
    //AWS MUTATION
    createAnswer(answer_record);
    console.log(answer_record);
    
    const { question_record } = this.state;

    updateQuestion(question_record);
  
    //If Question is coming from a Task, send back to task
    if(this.props.match.params.taskId){
      history.push("/q/task/"+this.props.match.params.taskId+"/progress");
    }else{
      history.push("/q/entity/"+this.state.question_record.entityId);
    }
    
  }
  
  handleSaveAndAnswerAgain = () => {
    // aws
    const { history, createAnswer, updateQuestion } = this.props
    const { answer_record, question_record } = this.state;
    
    createAnswer(answer_record);
    updateQuestion(question_record);
    
    
    //If Question is coming from a Task
    if(this.props.match.params.taskId){
      history.push("/q/"+this.state.question_record.id+"/answer/"+this.props.match.params.taskId);
    }else{
      history.push("/q/"+this.state.question_record.id+"/answer");
    }
  }
  
  handleCancel = () => {
    const { history } = this.props
    history.push("/q/entity/"+this.state.question_record.entityId);
  
    //If Question is coming from a Task
    if(this.props.match.params.taskId){
      history.push("/q/task/"+this.props.match.params.taskId+"/progress");
    }else{
      history.push("/q/entity/"+this.state.question_record.entityId);
    }
    
  }
  handleSaveAndNewQuestion = () => {
    const { history, createAnswer, updateQuestion  } = this.props
    const { answer_record, question_record } = this.state;
    
    createAnswer(answer_record);
    updateQuestion(question_record);
  
    //If Question is coming from a Task
    if(this.props.match.params.taskId){
      history.push("/q/entity/"+this.state.question_record.entityId+"/ask/"+this.state.question_record.entity+"/"+this.props.match.params.taskId);
    }else{
      history.push("/q/entity/"+this.state.question_record.entityId+"/ask/"+this.state.question_record.entity);
    }
    
    
  }

  renderAnswer() {
    
    const {
      answer,
      degree,
      rating,
      lang,
      credit_name,
      credit_url,
      cta_text,
      cta_url,
      
    } = this.state.answer_record;
  
    const {
      question,
      id,
      entity
    } = this.state.question_record;
    
    console.log(this.state.question_record.task)
    
    return (
      <div>
        
        { this.props.match.params.taskId ?
          <TaskStepper task={this.state.question_record.task} step={2}>
            <div>Task Instructions</div>
          </TaskStepper>
          :
          <QuestionHeader header={this.state.header} />
        }
        
        <div className="workarea editor_form">
  
          <Row center="xs" style={{textAlign:'left'}} >
            <Col xs={12} sm={11} md={10} lg={9} >
  
              <div>
                <Link to={"/q/"+id}>
                  <h3>{ question }</h3>
                </Link>
              </div>
              <div>
                <span className="mandatory tag tag-alert">Write Answer</span>
                <Row>
                  <Col xs={11} md={11}>
                    <textarea className="bp3-input" style={{width:'100%'}} placeholder="Type answer here" value={answer} onChange={this.handleInputValues('answer')} />
                  </Col>
                  <Col xs={1} md={1}>
                    <button className="bp3-button bp3-fill" type="submit" onClick={() => { this.handleClearAnswer('answer') }}><Icon icon="eraser" iconSize={15} /></button>
                  </Col>
                </Row>
              </div>
            </Col>
  
            <Col xs={12} sm={11} md={10} lg={9} >
              {this.state.answer_record.creditByEntity ?
    
                <div style={{color:'#CCC'}}>{this.state.question_record.entity} (1°)</div>
                :
                <Row>
                  <Col xs={10} md={10} >
                    <div className="bp3-control-group">
                      <span className="bp3-tag bp3-minimal">Author</span>
                      <input className="bp3-input"  placeholder="Name" value={credit_name} onChange={this.handleInputValues('credit_name')} />
                    </div>
        
                    <div className="bp3-control-group">
                      <span className="bp3-tag bp3-minimal">Relation</span>
          
                      <div className="bp3-select " style={{width:'80%'}}>
                        <select className="" value={degree} onChange={this.handleDegreeValues('degree')}>
                          <option value="1">Insider (1°)</option>
                          <option value="2">Expert (2°)</option>
                          <option value="3" selected>Public (3°)</option>
                          <option value="4" >Unknown (4°)</option>
                        </select>
                      </div>
                    </div>
                    <span className="field_details">(Author relation with: {entity})</span>
        
                    <div className="bp3-control-group">
                      <span className="bp3-tag bp3-minimal">Source</span>
                      <input className="bp3-input"  placeholder="URL" value={credit_url} onChange={this.handleInputValues('credit_url')} />
                    </div>
      
                  </Col>
    
                </Row>
              }
  
              <div className="field_details">
               <input type="checkbox" onChange={this.handleCheckbox('creditByEntity')} style={{width:'20px'}}/>
                Entity is the author
              </div>
              <br/><br/>
              <button onClick={this.handleDetailClick}
              >
                {this.state.showDetails ? "Hide Details" : "Details"}
              </button>
            </Col>
          </Row>
          

          <Collapse isOpen={this.state.showDetails}>
            <div style={{backgroundColor:'#f1f1f1', padding:'20px'}}>
              <table>
              <tbody>
              {/*START DETAILS*/}
              
                <tr>
                  <td>
                    <span>Language</span>
                  </td>
                  <td>
                    <div className="bp3-select">
                      <select className="" value={lang} onChange={this.handleInputValues('lang')}>
                        {/*<option selected>Select a status...</option>*/}
                        <option value="ENG">English</option>
                        <option value="SPA">Spanish</option>
                        <option value="POR">Portuguese</option>
                        <option value="MAN">Mandarin</option>
                        <option value="GER">German</option>
                        <option value="JAP">Japanese</option>
                        <option value="FRA">France</option>
                      </select>
                    </div>
                  </td>
                </tr>
              
              {/*<tr>*/}
                {/*<td width="100px">*/}
                  {/*ToDo Status*/}
                {/*</td>*/}
                {/*<td>*/}
                  {/*<div className="bp3-select ">*/}
                    {/*<select className="">*/}
                      {/*<option selected>Select a status...</option>*/}
                      {/*<option value="1">1</option>*/}
                      {/*<option value="2">2</option>*/}
                      {/*<option value="3">3</option>*/}
                      {/*<option value="4">4</option>*/}
                      {/*<option value="5">5</option>*/}
                      {/*<option value="6">6</option>*/}
                    {/*</select>*/}
                  {/*</div>*/}
                {/*</td>*/}
              {/*</tr>*/}
              
              <tr>
                <td>
                  Answer Rating
                </td>
                <td>
                  <div className="bp3-select">
                    <select className="" value={rating} onChange={this.handleInputValues('rating')}>
                      <option value="G" selected>G</option>
                      <option value="PG">PG</option>
                      <option value="PG-13">PG-13</option>
                      <option value="R">R</option>
                      <option value="NC-17">NC-17</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr className="mobile_hide">
                <td >
                  Author
                </td>
                <td>
                  <p>{this.state.answer_record.author} ( {this.state.answer_record.authorId})</p>
                </td>
              </tr>
              
              <tr>
                <td >
                  Call to Action
                </td>
                <td>
                  <Row>
                    <Col xs={12} md={4} >
                      CTA Text
                      <input className="bp3-input" style={{width:'100%'}} placeholder="CTA text (e.g. Book Now)" value={cta_text} onChange={this.handleInputValues('cta_text')} />
                    </Col>
                    <Col xs={12} md={4} >
                      CTA URL
                      <input className="bp3-input" style={{width:'100%'}} placeholder="Absolut path" value={cta_url} onChange={this.handleInputValues('cta_url')} />
                    </Col>
                  </Row>
                </td>
              </tr>
            </tbody>
            </table>
            </div>
          </Collapse>
          
          <br/>
          <div className="editor_footer_wrapper">
            <div className="editor_footer">
              <div>
                <button className="bp3-button bp3-intent-danger bp3-icon-small-cross" onClick={this.handleCancel} >Cancel</button>
              </div>
              <div>
                <button className="bp3-button" onClick={this.handleSaveAndNewQuestion} >Save + New Question</button>
                <button className="bp3-button" onClick={this.handleSaveAndAnswerAgain} >Save + New Answer</button>
                <button className="bp3-button bp3-intent-success" onClick={this.handleAnswer} >Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  render(){
    return(
    <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
      {props => <div style={props}>{this.renderAnswer()}</div>}
    </Spring>
    )
  }
}

export default withRouter(withApollo(compose(
  
  graphql(
    gql(getQuestion),
    {
      options: (props) => ({
        fetchPolicy: 'cache-and-network',
        variables: {id: props.match.params.questionId}
      }),
      props: ({ data: { getQuestion = {}}}) => ({
        question: getQuestion
      })
    }
  ),
  graphql(
    gql(createAnswer),
    {
      props: ({ mutate }) => ({
        createAnswer: ({ answerQuestionId, answer, author, authorId, questionId, lang, degree, rating, credit, createdAt, modifiedAt, bounty, status }) => {
          console.log({ answerQuestionId, answer, author, authorId, questionId, lang, degree, rating, credit, createdAt, modifiedAt, bounty, status });
          mutate({
            variables: {
              input: {
                answerQuestionId,
                answer,
                author,
                authorId,
                questionId,
                lang,
                degree,
                rating,
                credit,
                createdAt,
                modifiedAt,
                bounty,
                status
              }
            },
            refetchQueries:[`getQuestion`]
          })
        }
      })
    }
  ),
  graphql(
    //Updating Question Count
    gql(updateQuestion),
    {
      props: ({ mutate }) => ({
        updateQuestion: ({ id, count, d1, d2, d3, d4 }) => {
          console.log({ id, count, d1, d2, d3, d4 });
          mutate({
            variables: {
              input: {
                id,
                count,
                d1,
                d2,
                d3,
                d4
              }
            }
          })
        }
      })
    }
  )
)(QuestionAnswer)));