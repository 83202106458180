import React, { Component } from 'react';
import ReactTable from "react-table";
import { graphql, compose, withApollo } from "react-apollo";
import gql from "graphql-tag";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {Icon, Tooltip, Position} from "@blueprintjs/core";
import { Row, Col } from 'react-flexbox-grid'
import LinkIcon from "../../_utils/LinkIcon";
import {extractEntities} from '../../_utils/ShowEntities'
import QuestionHeader from "../_layout/QuestionHeader"
import SettingsSidebar from "../Settings/_layout/SettingsSidebar"
import { listEntitys } from '../../../graphql/queries'


import {
  setUser,
  setHeader
} from '../../../_actions';

class ListEntities extends Component {
  constructor(){
    super();

    this.state = {
      header:{
        counter:100,
        action:'link',
        action_text:'Create Channel',
        action_icon:'plus',
        action_route:'/q/newentity',
        banner_color:'expanded',
        breadcrumb:[
          {
            name:'',
            route:'',
            icon:'cog',
            active: false
          },{
            name:'Settings',
            active: true,
            size:'L'
          },{
            name:'User',
            size:'L'
          }
        ],
       
      }
    }
  }

  componentWillMount(){
    this.updateState();
  }

  async updateState() {
    //Getting all GQL payload
    const { client} = this.props;
    let payload = [];
    try {
      const result = await client.query({
        query: gql`
          query {
            listEntities(limit:1000){
              items{
                id
                name
                displayName
                website
                logo
                score
                locations
              }
            }
          }
        `
      });
      if(result && result.data.listEntities) {
        payload = result.data.listEntities.items;
      }
    } catch (error) {
      console.log(error);
    }
    
    const { header} = this.state;
    //If db_user.ID and URL :userId is the same show name, otherwise show only ID
    header.breadcrumb[2].name = (this.props.match.params.userId === this.props.db_user.id ? this.props.db_user.first+' '+this.props.db_user.last : this.props.match.params.userId);
    header.breadcrumb[2].route = (this.props.match.params.userId === this.props.db_user.id ? "/q/user/"+this.props.db_user.id+"/profile" : "/q/user/"+this.props.match.params.userId+"/profile");
    
    //Updating State
    this.setState({ table: payload, header });

  }

  renderTable(){
    
    const selected = extractEntities(this.props.db_user.entities);
    

    return(
      <ReactTable
        data={selected}
        columns={[{
          columns: [
            {
              Header: 'Name',
              accessor: 'label',
              minWidth: 100,
              Cell: row =>(
                <LinkIcon type={'entity_logo'}
                          icon={row.original.logo}
                          text={row.value}
                          route={"/q/entity/"+row.original.id}
                />
              )
            },
            {
              Header: '',
              accessor: 'id',
              maxWidth: 50,
              Cell: row => (
                <div className="actions_column">
                  {/*<Tooltip content="Ask Question" position={Position.LEFT}>*/}
                    {/*<Link to={"/q/entity/"+row.value+"/ask/"+row.original.name}>*/}
                      {/*<Icon icon="plus" iconSize={11}/>*/}
                    {/*</Link>*/}
                  {/*</Tooltip>*/}
                  {/*<Tooltip content="Edit" position={Position.LEFT}>*/}
                    {/*<Link to={"/q/entity/"+row.value+"/edit"}>*/}
                      {/*<Icon icon="edit" iconSize={11}/>*/}
                    {/*</Link>*/}
                  {/*</Tooltip>*/}
                  {/*<Tooltip content="Launch FAQ" position={Position.LEFT}>*/}
                    {/*<Link to={"/e/"+row.value}>*/}
                      {/*<Icon icon="arrow-top-right" iconSize={11}/>*/}
                    {/*</Link>*/}
                  {/*</Tooltip>*/}
  
                  {/*{this.props.db_user &&(*/}
                  
                    {/*this.props.db_user.status === 101 &&(*/}
                      {/*<Tooltip content="Delete" position={Position.LEFT}>*/}
                        {/*<Link to={"/q/entity/"+row.value+"/delete"}>*/}
                          {/*<Icon icon="cross" iconSize={11} />*/}
                        {/*</Link>*/}
                      {/*</Tooltip>*/}
                    {/*)*/}
                  {/*)}*/}
  
                  <Tooltip content="Remove from my Channels" position={Position.LEFT}>
                    <Link to={"/e/"+row.value}>
                      <Icon icon="minus" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  
                </div>
              )
            }

          ]
        }]}
        defaultPageSize={100}
        className=" -highlight"
      />
    )

  }
  
  renderAllChannels(){
    
    const table = this.state.table;
    
    return(
      <ReactTable
        data={table}
        columns={[{
          columns: [
            {
              Header: 'Name',
              accessor: 'displayName',
              minWidth: 100,
              Cell: row =>(
                <LinkIcon type={'entity_logo'}
                          icon={row.original.logo}
                          text={row.value}
                          route={"/q/entity/"+row.original.id}
                />
              )
            },
            {
              Header: '',
              accessor: 'id',
              maxWidth: 50,
              Cell: row => (
                <div className="actions_column">
                  <Tooltip content="Add to my Channels" position={Position.LEFT}>
                    <Link to={"/q/entity/"+row.value+"/ask/"+row.original.name}>
                      <Icon icon="plus" iconSize={11}/>
                    </Link>
                  </Tooltip>
                </div>
              )
            }
          
          ]
        }]}
        defaultPageSize={100}
        className=" -highlight"
      />
    )
    
  }

  render() {
    
    return (

      <div>
        
        <QuestionHeader header={this.state.header} />
  
        <Row>
          <Col xs={3} md={2}>
            <SettingsSidebar userId={this.props.match.params.userId} active="channels"/>
          </Col>
          <Col xs={9} md={10}>
            <Row center={'xs'}>
              <Col xs={11}>
                
                    <div className="bp3-card settings_text">Explore all available channels and add them to your profile. In some cases a channel might be restricted ,
                  if that's the case , submit a request to the the channel admin by clicking on the <Icon icon="add" iconSize={12}/> button.  You can also create new channels in case you can't find the entity or subject you are looking for.</div>
                  <br/><br/>
              </Col>
              
              <Col xs={5} md={5} style={{textAlign:'left'}}>
                <div className="workarea">
                  <h4 style={{color:'#0078ff'}}>My Channels:</h4>
                  <div>
                    {this.renderTable()}
                  </div>
                </div>
              </Col>
              <Col xs={1} md={1}/>
              <Col xs={5} md={5} style={{textAlign:'left'}}>
                <div className="workarea">
                  <h4 style={{color:'#0078ff'}}>All Channels:</h4>
                  <div>
                    {this.renderAllChannels()}
                  </div>
                </div>
              </Col>
              
            </Row>
          </Col>
        </Row>
      </div>

    );
  }
}

ListEntities.propTypes = {
  user: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.dataReducer.user,
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setUserData: value => dispatch(setUser(value)),
  setHeaderData: value => dispatch(setHeader(value)),
});

const ListEntitiesConnected = connect(mapStateToProps, mapDispatchToProps)(ListEntities);


export default withApollo(compose(
  
  graphql(
    //List all tasks
    gql(listEntitys),
    {
      options: {
        //fetchPolicy: 'cache-and-network',
        fetchPolicy: 'network-only',
        variables:{
          limit:1000
        }
      },
      props: ({ data: { listEntitys = { items: [] } } }) => ({
        entities: listEntitys.items
      })
    }
  )
)(ListEntitiesConnected));
