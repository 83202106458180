import React, { Component } from 'react'
import { graphql, compose, withApollo } from "react-apollo"
import { Row, Col } from 'react-flexbox-grid'
import { Icon} from "@blueprintjs/core"
import { Spring } from 'react-spring'
import matchSorter from 'match-sorter'
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet"
import gql from "graphql-tag"
import { getEntity, listQuestions } from '../../graphql/queries'
import Answer from "../Editor/_layout/Answers/Answer"
import EntityHeader from "./_layout/EntityHeader"
import EntityLeftbar from "./_layout/EntityLeftbar"
import EntityRightbar from "./_layout/EntityRightbar"
import CreateEntityQuestion from './CreateQ'

class ListEntity extends Component {
  
  constructor(){
    super();
    
    this.state = {
      header:{
        title:'1',
        counter:'1,092',
        entityLogo:'',
        wall:'',
        theme:'light'
      },
      table:[],
      inputValue:'',
      filtered:'',
      config:'',
      embedConfig:{
        header:true,
        leftbar:true,
        rightbar:true,
        branded:true,
        fluid:true
      },
      embed: false,
      lang_filter:''
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.entity && newProps.entity.id) {
      this.updateState(newProps);
    }
  }
  
  updateState(props){

    // PublicView Results from AWS DynamoDB using graphql
    const { entity, listQuestions } = props;
    
    // PublicView Header Info change
    const { header} = this.state;
    header.title = entity.name;
    header.displayName = entity.displayName;
    header.entityLogo = entity.logo;
    header.id = entity.id;
    header.wall = entity.wall;
    header.theme = (entity.theme? entity.theme: 'light');
    header.status = entity.status;
    

    let payload = [];
    if(listQuestions.length) {
      payload = listQuestions.map(item => {
        return {
          entity: entity.name,
          entityId: entity.id,
          question: item.question,
          questionId: item.id,
          entityLogo: item.entityLogo,
          lang: item.lang,
          answerCount: '',
          newCount: '',
          author: item.author,
          authorId: item.authorId,
          actions: '',
          count: item.count,
          d1: item.d1,
          d2: item.d2,
          d3: item.d3,
          d4: item.d4
        };
      });
      header.counter = listQuestions.length;
    }
    
  
    //Organize Payload by languages
    payload.sort(function(a, b){
      let langA=a.lang.toLowerCase(), langB=b.lang.toLowerCase();
      if (langA < langB) //sort string ascending
        return -1;
      if (langA > langB)
        return 1;
      return 0 //default return value (no sorting)
    });

    this.setState({table: payload, header, embed:(this.props.match.params.config === 'embed')});
  }
  
  renderTag(text){
    return(
      <span data-tag-index="0" className="bp3-tag bp3-minimal">
        <span className="bp3-text-overflow-ellipsis bp3-fill">
          {text}
        </span>
        <button disabled type="button" className="bp3-tag-remove">
          <span className="bp3-icon" icon="">
            <svg data-icon="small-cross" width="16" height="16" viewBox="0 0 16 16">
              <desc>small-cross</desc>
              <path d="M9.41 8l2.29-2.29c.19-.18.3-.43.3-.71a1.003 1.003 0 0 0-1.71-.71L8 6.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42L6.59 8 4.3 10.29c-.19.18-.3.43-.3.71a1.003 1.003 0 0 0 1.71.71L8 9.41l2.29 2.29c.18.19.43.3.71.3a1.003 1.003 0 0 0 .71-1.71L9.41 8z">
              
              </path>
            </svg>
          </span>
        </button>
      </span>
    
    );
    
  }
  
  updateInputValue(evt) {
  
    //all the results
    let allQuestions = this.state.table;
    
    //The user search input
    let inputValue= evt.target.value;
    
    //Search Input vs. AllQuestions
    const filterResult = matchSorter(allQuestions, inputValue, {
      keys: ['question', {minRanking: matchSorter.rankings.EQUAL, key: 'questionId'}],
    });
    
    //Update state with filtered results
    this.setState({
      filtered: filterResult,
      inputValue: inputValue
    });
  }
  
  
  handleLangFilter = lang => event => {
    const allQuestions = this.state.table;
    const filterResult = matchSorter(allQuestions, lang, {
      keys: ['lang', {minRanking: matchSorter.rankings.EQUAL, key: 'questionId'}],
    });
  
    this.setState({
      filtered: filterResult, lang_filter: lang
    });
  }
  
  renderHelmet(){
  
    const {displayName, entityLogo, title}= this.state.header;
    
    return(
      <Helmet>
        <meta charSet="utf-8" />
        <title>{` ${displayName} - FAQ `}</title>
        
        <meta name="description" content={`${displayName} Regularly asked questions`}/>
        <meta property="og:description" content={`Questions about ${displayName} ,  ${displayName} Preguntas Frequentes`}/>
        <meta property="og:image" content={`${entityLogo}`} />
        <meta property="og:title" content={`${displayName} FAQ`} />
        <meta property="og:type" content="website" />
  
      </Helmet>
    )
  }
  
  
  renderItems() {
  
    let mapThis = [];
    
    if(this.state.filtered){
      mapThis = this.state.filtered
    }else(
      mapThis = this.state.table
    );
    
    return (
      <div style={{paddingTop:'40px'}}>
        <Row center="xs">
          <Col xs={this.state.embed? 12 : 11} md={this.state.embed? 12 : 8}>
            <Row>
              <Col xs={12} md={12}>
                
                <div className="bp3-input-group">
                  <span className="bp3-icon bp3-icon-search" style={{padding:"6px 6px"}} />
                  <input
                    value={this.state.inputValue}
                    onChange={evt => this.updateInputValue(evt)}
                    type="text"
                    className="bp3-inputx"
                    placeholder={"Search"}
                  />
                </div>
                
                <div className="search_filters">
                  <div>
                    <h4 style={{fontSize:'1.6em', margin:'0'}}>{mapThis.length} Questions :</h4>
                  </div>
  
                  {!this.state.embed?
                  <div>
                    <button onClick={this.handleLangFilter('')} className={this.state.lang_filter===''? 'selected' : ''}> ALL </button>|
                    <button onClick={this.handleLangFilter('eng')} className={this.state.lang_filter==='eng'? 'selected' : ''}> ENG </button> |
                    <button onClick={this.handleLangFilter('spa')} className={this.state.lang_filter==='spa'? 'selected' : ''}> SPA </button>
                    {/*<a onClick={this.handleLangFilter('jap')} className={this.state.lang_filter==='jap'? 'selected' : ''}> 日本人</a>*/}
                  </div>
                  : ''}
                  
                  
                </div>
              </Col>
              <Col xs={12} md={12} style={{paddingTop:'30px', paddingBottom:'100px', textAlign:'left'}}>
                
                { mapThis.map(e => <Answer embed={this.state.embed} data={e} key={e.questionId} />) }
                
                {!mapThis.length && (
                
                    <Row>
                      <Col xs={12} md={6}>
                        <h4>We don’t have that information. Create new question and we’ll start looking for it.</h4>
                      </Col>
                      <Col xs={12} md={6}>
                        <CreateEntityQuestion question={this.state.inputValue} id={9714}/>
                      </Col>
                    </Row>
                
                )}
  
                {mapThis.length && (
                <Row style={{margin:'50px'}}>
                  <Col xs={12} md={12}>
                    <Link to={"/questions/"+this.props.match.params.slug+"/"+this.state.header.id+"/ask" }>
                      <Icon icon="plus" iconSize={20} /> Ask New Question.
                    </Link>
                  </Col>
                </Row>
                )}
  
                
                
              </Col>
              
              
            </Row>
          </Col>
          {/*<Col xs={12} md={2}>*/}
            {/*<Switch checked={true} label={<span style={{padding:'0 5px'}}>ENG</span>} />*/}
            {/*<Switch checked={true} label={<span style={{padding:'0 5px'}}>SPA</span>} />*/}
          {/*</Col>*/}
          
          {/*<Col xs={12} md={2}>*/}
            {/*<div className="filter_box">*/}
              
              {/*<Switch checked={true} disabled label={<span className="degree degree1" style={{padding:'0 5px'}}>ENG</span>} />*/}
              {/*<Switch checked={true} disabled label={<span className="degree degree2" style={{padding:'0 5px'}}>Expert Source</span>} />*/}
              {/*<Switch checked={true} disabled label={<span className="degree degree3" style={{padding:'0 5px'}}>Public Source</span>} />*/}
              {/*<Switch checked={false} disabled label={<span className="degree degree4" style={{padding:'0 5px'}}>Unknown</span>} />*/}
              
              {/*<h4>Data Age <span style={{fontWeight:"100", color:"#c1c1c1", fontSize:"0.9em", marginLeft:"10px"}}>(years)</span></h4>*/}
              
              {/*<div style={{marginLeft:'10px'}}>*/}
                {/*<RangeSlider*/}
                {/*min={0}*/}
                {/*max={100}*/}
                {/*stepSize={2}*/}
                {/*labelStepSize={20}*/}
                {/*value={[0, 65]}*/}
                {/*vertical={false}*/}
                {/*showTrackFill={true}*/}
                {/*/>*/}
              {/*</div>*/}
              
              {/*<h4>Licenses</h4>*/}
              {/*<div className="bp3-input bp3-tag-input bp3-multi-select disabled">*/}
                {/*<div className="bp3-tag-input-values">*/}
                {/*{this.renderTag('PDDL')}*/}
                {/*{this.renderTag('ODC-By')}*/}
                {/*{this.renderTag('ODC-ODbL')}*/}
                {/*{this.renderTag('COM')}*/}
                {/*<input disabled value="" className="bp3-input-ghost"/>*/}
                {/*</div>*/}
              {/*</div>*/}
              
              {/*<h4>Audience</h4>*/}
              {/*<Switch checked={true} disabled label="> PG13" />*/}
              
              {/*<div className="bp3-input bp3-tag-input bp3-multi-select disabled">*/}
                {/*<div className="bp3-tag-input-values">*/}
                {/*{this.renderTag('ENG')}*/}
                {/*{this.renderTag('SPA')}*/}
                {/*{this.renderTag('FRA')}*/}
                {/*<input disabled value="" className="bp3-input-ghost"/>*/}
                {/*</div>*/}
              {/*</div>*/}
            
            {/*</div>*/}
          {/*</Col>*/}
          
        </Row>
      </div>
    
    );
  }
  
  render(){
    
    const bg = this.state.header.wall;
    
    return (
    
      <div className={"theme_wrapper "+ this.state.header.theme}>
        
        {this.renderHelmet()}
        
        <div  className="entity_background"
              style={{ backgroundImage:`url(${bg})` }}
        />
  
        {!this.state.embed &&(
          <EntityHeader header={this.state.header} match={this.props.match} />
        )}
  
        
          <EntityLeftbar header={this.state.header} match={this.props.match} status={this.props.entity.status} />
       
          
          <EntityRightbar header={this.state.header} match={this.props.match} />
       
        
        
        <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
          {props => <div style={props}>{this.renderItems()}</div>}
        </Spring>
        
      </div>
    );
  
  }
}

export default withApollo(compose(

  // graphql(
  //   Query,
  //   {
  //     options: (props) => ({
  //       fetchPolicy: 'network-only',
  //       variables: {id: props.match.params.entityId, entityId: props.match.params.entityId}
  //     }),
  //     props: ({ data: { getEntities = {}}}) => ({
  //       entity: getEntities
  //     })
  //   }
  // ),
  graphql(
    gql(getEntity),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.entityId, entityId: props.match.params.entityId}
      }),
      props: ({ data: { getEntity = {}}}) => ({
        entity: getEntity
      })
    }
  ),
  graphql(
    gql(listQuestions),
    {
      options: (props) => ({
        variables: {
          limit:1000,
          filter:{entityId:{eq: props.match.params.entityId}}
        }
      }),
      props: ({ data: { listQuestions = { items: [] } } }) => ({
        listQuestions: listQuestions.items
      })
    }
  )
)(ListEntity));
