import {
  SET_DATA,
  SET_USER,
  SET_HEADER,
} from '../_constants';

const initialState = {
  data: [],
  user: {},
  header: {},
};

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case SET_HEADER:
      return {
        ...state,
        header: action.payload,
      };

    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
}
