import React from "react"
import { Link } from "react-router-dom"
import { Row, Col } from 'react-flexbox-grid'
import { Bar } from '@nivo/bar'
import TaskHead from './TaskHead'
import TaskInfo from './TaskInfo'

const TaskWrapper =({task, questions, children, section, user})=>{
  
  //DEFINING USER ROLE ::::::::::::::::::
  let role=null;  // owner | assignee | *
  
  if(task && user){
    if(task.createdBy === user.id && task.assignedTo === user.id ){
      role = 'owner_assignee';
    }else if(task.assignedTo === user.id ){
      role = 'assignee';
    }else if(task.createdBy === user.id){
      role = 'owner';
    }else{ role='other'}
  }
  
  const renderBar =(t)=>{
  
    
    let total = null;
    
    // The total Questions
    if(task.goal){
      total = task.goal
    }else{
      total = questions.length ;
    }
    
    let tasks={
      label:'count',
      Returned: 0,
      ReturnedColor: "#ff8c00",
      WorkInProgress: 0,
      WorkInProgressColor: "#e6c735",
      ForReview: 0,
      ForReviewColor: "#009ee0",
      Accepted: 0,
      AcceptedColor: "#00c532",
      Missing: 0,
      MissingColor: "#CCCCCC"
    };
    
    
    //OPTION A: This will count all the Questions
    //let questionCounter = questions.length;
    
    //Option B: This will count only the Questions WITH ANSWER
  
    function getTotalAnsweredQuestions(){
      let generalCount = 0;
    
      questions.map(item => {
      
        if(item.count >= 1){
          generalCount ++
        }
      })
    
      return generalCount;
    }
    let questionCounter = getTotalAnsweredQuestions();
    
    
    tasks.Missing = total - questionCounter;
    tasks.Accepted = questionCounter;
    
    tasks.WorkInProgress = 0;
    tasks.ForReview = 0;
    tasks.Returned = 0;
    let completed = Math.floor(questionCounter * 100 / total);
    
    return(
      <div className='task_progress'>
        
        <h2>{questionCounter} <span style={{fontWeight:'200'}}>/{total}</span></h2>
        <br/>
        
        <div style={{fontSize:'0.8em', color:'#CCC'}}>
          Progress: {completed}%
        </div>
        
        <Bar
          width={130}
          height={60}
          margin={{
            top: 0,
            right: 20,
            bottom: 40,
            left: 5
          }}
          style={{textAlign:'right'}}
          data={[tasks]}
          //colors={this.state.tasks}
          //colorBy={d => d.data.color}
          colorBy={({id, data})=> data[`${id}Color`]}
          //indexedBy={d => d.data.id}
          keys={[
            "WorkInProgress",
            "Returned",
            "ForReview",
            "Accepted",
            "Missing"
          ]}
          axisBottom={null}
          axisLeft={null}
          indexBy="label"
          groupMode="stacked"
          borderWidth={0}
          borderColor="inherit:darker(0.2)"
          padding={0.2}
          labelTextColor="inherit:darker(1.4)"
          labelSkipWidth={16}
          labelSkipHeight={16}
          layout="horizontal"
          enableGridY={false}
          enableGridX={false}
        />
      </div>
    )
  }
  
  const renderActions =(task)=>{
    
      if(task.status === '101'){
        return(
          <Link to={"/q/task/" + task.id + "/assign"}
                className="bp3-button bp3-icon-plus bp3-intent-success"
          >
            Sign up for Job
          </Link>
        )
      }else if(task.status === '102'){
        
        if(role === 'assignee' || role === 'owner_assignee'){
          return (
            <React.Fragment>
              <Link to={`/q/entity/${task.entityId}/ask/${task.entity}/${task.id}`}
                    className="bp3-button bp3-intent-success bp3-icon-add"
                    style={{marginRight: '10px'}}>
                Add Question</Link>
    
            </React.Fragment>
          )
        }
      }
  }
    
  return (
    <React.Fragment>
      <TaskHead task={task} userRole={role} status={task.status} >{renderActions(task)}</TaskHead>
      <div className="workarea editor_form">
        <Row>
          <Col xs={12} sm={12} md={2} lg={2}>
            
            <div className="bp3-tabs bp3-vertical">
              <ul className="bp3-tab-list" role="tablist">
                <Link to={`/q/task/${task.id}`}>
                  <li className="bp3-tab task-tab" role="tab" aria-selected={section ==='details'}>Instructions</li>
                </Link>
                <Link to={`/q/task/${task.id}/progress`}>
                  <li className="bp3-tab task-tab" role="tab" aria-selected={section ==='progress'}>Progress</li>
                </Link>
                <Link to={`/q/task/${task.id}/payment`}>
                  <li className="bp3-tab task-tab" role="tab" aria-selected={section ==='payment'}>Payment</li>
                </Link>
              </ul>
            </div>
            
            
            {questions && renderBar()}
            
            {/*{ role === 'owner' &&*/}
              {/*renderSubmit()*/}
            {/*}*/}
            
            
          </Col>
          <Col xs={12} sm={12} md={10} lg={10} >
            
              <Row center="xs">
                <Col xs={12} md={11} style={{textAlign:'left'}}>
                  <TaskInfo task={task} role={role} />
                  {children}
                </Col>
              </Row>
           
          </Col>
        </Row>
      </div>
    </React.Fragment>
  
  );
  
  
}


export default TaskWrapper;