import React, { Component } from "react"
import ClassNames from 'classnames'
import { Link } from "react-router-dom"
import { Icon, Position, Tooltip } from "@blueprintjs/core";

class EntityTabs extends Component {
  
  constructor(props){
    super(props);
    this.goBack = this.goBack.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
  }
  
  
  goBack(){
    this.props.history.goBack();
  }
  
  renderOptions(){
  
    let url = this.props.match.url;
    let pathArray = url.split('/');
    let activeTab = pathArray[3];
  
  
    let tab1 = (!activeTab);
    let tab2 = (activeTab === 'ask');
    let tab3 = (activeTab === 'analytics');
    let tab4 = (activeTab === 'i');
  
    let route0 = '/questions/explore';
    let route1 = '/questions/'+pathArray[2]+'/'+pathArray[3];
    let route2 = route1+'/ask';
    let route3 = route1+'/analytics';
    //let route4 = route1+'/i/share';
    let route5 = '/q/entity/'+pathArray[3]+'/info';
    let joinRoute = '/join/'+pathArray[3]+'/'+pathArray[2];
    
    return(
      <div className="entity_tabs">
      
        <div>
          <div>
            <Link to={route1}>
              <Tooltip content={"Search"} position={Position.RIGHT}>
                <div className={ClassNames('tabs',{active: tab1 })} ><Icon icon="search" /></div>
              </Tooltip>
            </Link>
          </div>
        
          <div>
            <Link to={route2}>
              <Tooltip content={"Ask"} position={Position.RIGHT}>
                <div className={ClassNames('tabs',{active: tab2 })} ><Icon icon="plus" /></div>
              </Tooltip>
            </Link>
          </div>
  
          
          <div>
            <Link to={route3}>
              <Tooltip content={"Score"} position={Position.RIGHT}>
                <div className={ClassNames('tabs',{active: tab3 })} ><Icon icon="timeline-bar-chart" /></div>
              </Tooltip>
            </Link>
          </div>
          
        
          <div>
            <Link to={joinRoute} >
              <Tooltip content={"Join"} position={Position.RIGHT}>
                <div className={ClassNames('tabs',{active: tab4 })} ><Icon icon="follower" /></div>
              </Tooltip>
            </Link>
          </div>
        </div>
        
        <div>
          
          <div>
            <Tooltip content={"Explore"} position={Position.RIGHT}>
              <Link to={route0}>
                <div className={ClassNames('tabs')}><Icon icon="grid-view" /></div>
              </Link>
            </Tooltip>
          </div>
            
          <div>
            <Tooltip content={"Settings"} position={Position.RIGHT}>
              <Link to={route5}>
                <div className={ClassNames('tabs')} ><Icon icon="cog" /></div>
              </Link>
            </Tooltip>
          </div>
        </div>
  
    </div>
    );
  }
  
  renderNeutralOptions(){
    return(
      <div className="entity_tabs">
        <button onClick={this.goBack}>
          <div className={ClassNames('tabs')} >
            <Icon icon="arrow-left" />
            <div> &nbsp; Back</div>
          </div>
          
        </button>
      </div>
    );
  }
  
  
  render() {
    const pathname = this.props.location.pathname;
    
    return (
      <React.Fragment>
        
        { pathname !== '/questions/explore' ? this.renderOptions() : this.renderNeutralOptions()}
        
      </React.Fragment>
    
    );
  }
  
}


export default EntityTabs;
