import React, {Component} from "react";
import {Link} from "react-router-dom";
import {v4 as uuid} from "uuid";
import {graphql, compose, withApollo} from "react-apollo";
import { withRouter } from 'react-router';
import { Row, Col } from 'react-flexbox-grid';
import gql from "graphql-tag"
import { createEntity } from '../../../graphql/mutations'
import { listEntitys } from '../../../graphql/queries'
import { getEntity } from '../../../graphql/queries'
import QuestionHeader from "../_layout/QuestionHeader";

class ChannelCreate extends Component {
  
  static defaultProps = {
    createTask: () => null,
  }
  
  state = {
    header: {
      title: '',
      qid: '',
      breadcrumb:[
        {
          icon:'globe',
        },
        {
          name:'Channels',
        },
        {
          name:'Create Channel',
          route:'',
          icon:'',
          active:true
        }
      ],
      type: 'new', //new: green | answer: blue | edit: yellow  |  review: orange  |  delete : red
      status: 115, // Need to be INT
      action: 'link',
      action_text: 'Back to Channels',
      action_icon: 'arrow-left',
      action_route: '/q/channels',
      banner_color: 'new' //new | answer | edit | review | delete
      
    },newItem: {
      id:'9714',
      name:'',
      displayName:'',
      score:'100',
      locations:'',
      website:''
    }
  };
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.entities) {
      this.updateState(newProps);
    }
  }
  
  componentDidMount(){
    this.updateState(this.props);
  }
  
  
  updateState(props) {
    const {header,newItem} = this.state;
    //newItem.entityId= props.match.params.entityId;
    newItem.createdBy= props.db_user.id;
    
    this.setState({header,newItem});
    
  }
  
  handleChange(field, {target: {value}}) {
    const {newItem} = this.state;
    
    newItem[field] = value;
    
    this.setState({newItem});
  }
  
  handleEntityChange = (selectedOption) => {
    
    const { temp,newItem } = this.state;
    newItem['entityId'] = selectedOption.value;
    newItem['entity'] = selectedOption.label;
    newItem['entityLogo'] = selectedOption.logo;
    
    this.setState({ temp,newItem });
  };
  
  handleSave = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    const {createEntity, history} = this.props;
    const {newItem} = this.state;
    
    // const now= moment().format();
    // newItem.createdAt = now;
    // newItem.modifiedAt = now;
    
    await createEntity({...newItem});
    
    console.log(this.props);
    history.goBack();
  }
  
  render() {
    const {newItem} = this.state;
    
    return (
      <div>
        <QuestionHeader header={this.state.header}/>
  
        <Row center="xs">
          <Col xs={12} md={11} lg={10}>
            <div className="workarea editor_form">
  
              <table>
                <tbody>
                  <tr>
                    <td style={{width: '150px'}}>
                     Short Name
                    </td>
                    <td>
                      <input className="bp3-input bp3-fill"
                             type="text"
                             id="name"
                             value={newItem.name}
                             placeholder="Short name e.g.(Yankees)"
                             onChange={this.handleChange.bind(this, 'name')}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Full Name
                    </td>
                    <td>
                      <input className="bp3-input bp3-fill"
                             type="text"
                             id="bounty"
                             value={newItem.displayName}
                             placeholder="Full Name (New York Yankees)"
                             onChange={this.handleChange.bind(this, 'displayName')}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      City / Location
                    </td>
                    <td>
                      <input className="bp3-input bp3-fill"
                             type="text"
                             id="locations"
                             value={newItem.locations}
                             placeholder="City (e.g. New York, NY)"
                             onChange={this.handleChange.bind(this, 'locations')}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Website
                    </td>
                    <td>
                      <input className="bp3-input bp3-fill"
                             type="text"
                             id="website"
                             value={newItem.website}
                             placeholder="URL"
                             onChange={this.handleChange.bind(this, 'website')}/>
                    </td>
                  </tr>
                  {/*<tr>*/}
                    {/*<td>*/}
                      {/*Entity*/}
                    {/*</td>*/}
                    {/*<td>*/}
                      {/*{selectedOption &&(<h4>{selectedOption.label}</h4>)}*/}
                  
                      {/*{!selectedOption &&(*/}
                        {/*<Select*/}
                          {/*options={options}*/}
                          {/*onChange={this.handleEntityChange}*/}
                        {/*/>*/}
                      {/*)}*/}
                      {/**/}
                      {/**/}
                    {/*</td>*/}
                  {/*</tr>*/}
                
                </tbody>
              </table>
  
              <div className="editor_footer" style={{ justifyContent:'flex-end'}}>
                <Link to="/q/tasks" className="bp3-button">Cancel</Link>
                <button className="bp3-button bp3-intent-primary" onClick={this.handleSave}>Save</button>
              </div>
              
            </div>
          </Col>
        </Row>
      </div>
    
    );
  }
  
}


export default withRouter(withApollo(compose(
  // graphql(
  //   gql(createEntity),
  //   {
  //     props: ({ mutate }) => ({
  //       createEntity: ({ createdBy, name, displayName, locations, score, website}) => {
  //         console.log({ createdBy, name, displayName, locations, score, website});
  //         mutate({
  //           variables: {
  //             input: {
  //               createdBy, name, displayName, locations, score, website
  //             }
  //           },
  //           refetchQueries:[`listEntitys`]
  //         })
  //       }
  //     })
  //   }
  // ),
  
  graphql(
    gql(createEntity),
    {
      props: (props) => ({
        createEntity: (entity) => {

          return props.mutate({
            update: (proxy, {data: {createEntity}}) => {
              // Update ListEntities
              const query = gql(listEntitys);

              const data = proxy.readQuery({query});

              data.listEntitys.items = [...data.listEntitys.items.filter(e => e.id !== createEntity.id), createEntity];

              proxy.writeQuery({query, data});

              // Create cache entry for GetEntity
              const query2 = gql(getEntity);
              const variables = {id: createEntity.id};
              const data2 = {getEntity: {...createEntity}};

              proxy.writeQuery({query: query2, variables, data: data2});
            },
            variables:{input: entity},
            refetchQueries:[`listEntitys`],
            optimisticResponse: () => ({
              createEntity: {
                ...entity,
                id: uuid(),
                __typename: 'Entity'
              }
            }),
          })
        }
      })
    }
  ),
  // graphql(
  //   gql(listEntitys),
  //   {
  //     options: {
  //       //fetchPolicy: 'network-only',
  //     },
  //     props: ({ data: { listEntitys = { items: [] } } }) => ({
  //       entities: listEntitys.items
  //     })
  //   }
  // )
)(ChannelCreate)));
