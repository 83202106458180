import React, { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import {Link} from "react-router-dom"
import Tabs from './Tabs'

class Join extends Component {
  
  constructor() {
    super();
    this.state = {
      copySuccess: ''
    }
  }
  
  renderIcon(image){
    return(
      <img style={{width:'20px', height:'auto', opacity:'1'}} src={image} alt=""/>
    );
  }
  
  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    
    e.target.focus();
    this.setState({ copySuccess: 'Copied!' });
  };
  
  renderOptions(){
  
    const entityId = this.props.record.entityId;
    const slug = this.props.slug;
    
    return(
      <Row center="xs" style={{textAlign:'inherit'}}>
        <Col xs={11} md={8}>
          <Tabs record={this.props.record} type={this.props.type}/>
          
          <div style={{padding:'50px 0'}}>
            <Link to={"/join/"+entityId+"/"+slug} className="button primary">
              Join {slug} FAQ
            </Link>
          </div>
          
  
          {/*<p>*/}
            {/*Or follow this link:*/}
          {/*</p>*/}
          
          {/*<form>*/}
            {/*<textarea*/}
              {/*className="embed_box bp3-fill"*/}
              {/*ref={(textarea) => this.textArea = textarea}*/}
              {/*value={"/join/"+entityId+"/"+slug}*/}
              {/*readOnly={true}*/}
            {/*/>*/}
            {/*<a className="button" onClick={this.copyToClipboard}>Copy</a>*/}
            {/*<span style={{margin:'0 10px'}}>{this.state.copySuccess}</span>*/}
          {/*</form>*/}
          
          
        </Col>
      </Row>
    )
    
  }
  
  render(){
    return(
      this.renderOptions()
    );
  }
}

export default Join;

