import React from 'react';
import {
  Icon
} from "@blueprintjs/core";
import ClassNames from "classnames";
import { Link } from "react-router-dom";
import ShowStatus from './ShowStatus'
import './LinkIcon.css';

const LinkIcon = ({type, icon, text, route, status, primary}) => {
  
  switch (type) {
    
    case 'button':
      return (
        <Link to={route}>
          <button className={ClassNames({primary: primary})}>
            <Icon icon={icon} iconSize={13} style={{color:'grey'}} />
            {text}
          </button>
        </Link>
      );
      
    case 'button_status':
      return (
        <Link to={route}>
          <button className={ClassNames({primary: primary})}>
            {ShowStatus(status)}
            <span style={{padding:'0 10px'}}>{text}</span>
          </button>
        </Link>
      );
  
    case 'entity_logo':
      return (
        <Link to={route}>
          <div className="avatar_logo">
            <div className={ClassNames('avatar_icon',{primary: primary})} style={{ backgroundImage:`url(${icon})` }}/>
            {text &&(<span style={{padding:'0 10px'}}>{text}</span>) }
          </div>
        </Link>
      );
      
    default:
      return (
        <React.Fragment>
          {text && (
            <Link to={route}>
                <span>
                  <Icon icon={icon}/>
                  <span style={{padding: '0 11px'}}>{text}</span>
                </span>
            </Link>
            )
          }
        </React.Fragment>
        
      );
    
    
  }
  
};

export default LinkIcon;