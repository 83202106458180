import React, { Component } from "react";
import { Link } from "react-router-dom"

class EntityInfo extends Component {
  
  state = {
    name: "EntityInfo",
  };
  
  render() {
    
    return (
      <div className="entity_info">
        <Link to={this.props.link}>
          <img src={this.props.logo} alt={this.props.alt} />
        </Link>
        <div style={{display:'flex', flexFlow:'column'}}>
          {this.props.children}
        </div>
      </div>
    
    );
  }
  
}


export default EntityInfo;
