import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom"
import {Popover, Icon} from "@blueprintjs/core";
import kebabCase from "lodash/kebabCase"
import truncate from "lodash/truncate"


class EntityHeader extends Component {
  
  constructor(){
    super();
    this.state = {
    
    }
  }
  
  renderNew() {
    const path = this.props.match ? this.props.match.path : '';
    const entityId = this.props.entityId;
    const logo = this.props.logo;
    const name = this.props.name;
    const slug = kebabCase(truncate(name));
   
    
    const createMenu= (
      <ul className="bp3-menu bp3-elevation-1">
        <Link className="bp3-menu-item bp3-icon-dashboard" to={'/q/entity/'+entityId+'/info'}>
          <li>
            <div>
              Dashboard
            </div>
          </li>
        </Link>
        <Link className="bp3-menu-item bp3-icon-menu-open" to={'/q/entity/'+entityId}>
          <li>
            Questions
          </li>
        </Link>
        <Link className="bp3-menu-item bp3-icon-plus" to={'/q/entity/'+entityId+'/ask/'+name}>
          <li >
            Create
          </li>
        </Link>
        <Link className="bp3-menu-item bp3-icon-send-to" to={'/questions/'+slug+'/'+entityId}>
          <li >
            Launch Profile
          </li>
        </Link>
      
      </ul>
    )
    
    return (
      <div className="breadcrumb_header">
  
        <ul className="bp3-breadcrumbs">
          <li>
          <span className="bp3-breadcrumb">
            <span style={{opacity:'0.5'}}>
              <Icon icon="globe" iconSize={18} />
            </span>
          </span>
          </li>
          
          
  
          <li>
            <span className="bp3-breadcrumb">
              
              <Link to={'/q/entity/'+entityId} className="mobile_hide">
                <div style={{display: 'flex', alignItems:'center'}}>
                  {logo &&(
                    <span className="icon1" style={{ backgroundImage:`url(${logo})` }}/>
                  )}
                  {name}
                </div>
              </Link>
              
              <div className="mobile_show">
                <Popover content={createMenu}>
                  <div className="spaced">
                    <h3 style={{margin: '10px 0'}}>{name}</h3>
                    <Icon icon="chevron-down" iconSize={12}/>
                  </div>
                </Popover>
              </div>
              
            </span>
          </li>
          
        </ul>
            
        <div className="spaced">
          
          {!path &&(
            
            <Link className='bp3-button bp3-activex bp3-intent-success bp3-small' to={'/q/entity/'+entityId+'/ask/'+name} >
              <Icon icon="plus" iconSize={15}/> <span className="mobile_hide"> New Question</span>
            </Link>
          )}
          
          &nbsp; &nbsp;
          <Link to={'/questions/'+slug+'/'+entityId} className="bp3-button bp3-small bp3-icon-send-to mobile_hide">
            Launch Profile
          </Link>
        
        </div>
          
      </div>
      
    
    );
  }
  
  render(){
    return(
      <Fragment>
      {this.renderNew()}
      </Fragment>
    )
  }
}

export default EntityHeader;