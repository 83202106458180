import React, {Component} from "react"
import { graphql, compose, withApollo } from "react-apollo"
import { Link } from "react-router-dom";
import matchSorter from 'match-sorter'
import ReactTable from "react-table"
import {Icon, Tooltip, Position} from "@blueprintjs/core"
import gql from "graphql-tag"
import { getTask, listQuestions } from '../../../graphql/queries'
import LinkIcon from "../../_utils/LinkIcon"
import TaskWrapper from "./components/TaskWrapper"


class TaskProgress extends Component {
  
  constructor() {
    super();
    this.state = {
      header: {
        title: '',
        qid: '9714',
        type: 'answer', //new: green | answer: blue | edit: yellow  |  review: orange  |  delete : red
        status: 2, // Need to be INT
        banner_color: 'answer' //new | answer | edit | review | delete
      },
      table:[],
      tasks:{
        label: "count",
        Returned: 0,
        ReturnedColor: "#ff8c00",
        WorkInProgress: 0,
        WorkInProgressColor: "#e6c735",
        ForReview: 0,
        ForReviewColor: "#009ee0",
        Accepted: 0,
        AcceptedColor: "#00c532",
        Missing: 0,
        MissingColor: "#CCCCCC"
      }
    }
  }
  
  componentWillReceiveProps(newProps) {
    
    this.updateState(newProps);
  }
  
  
  updateState(props) {
    const {header,tasks} = this.state;
    
    let questionCounter = 0;
    let zeroCounter = 0;
    let payload = [];

    //Extract basic info from all questions
    if ( props && props.listQuestions) {
      
        payload = props.listQuestions.map(item => {
          
          if(item.count === '0' || item.count === 0 || item.count === null){zeroCounter++}

          return {
            entity: item.entity,
            question: item.question,
            questionId: item.id,
            entityLogo: item.entityLogo,
            author: item.author,
            authorId: item.authorId,
            count: item.count,
            lang: item.lang,
            d1: item.d1,
            d2: item.d2,
            d3: item.d3,
            d4: item.d4
          };
        });
  
        const total = 120;
        questionCounter = props.listQuestions.length;
        //updating Count
        header.counter = questionCounter;
        
        tasks.Missing = total - questionCounter;
        tasks.Accepted = questionCounter;
        
        tasks.WorkInProgress = 0;
        tasks.ForReview = 0;
        tasks.Returned = 0;
        
      
    }
    
    this.setState({header, table: payload, zero_counter:zeroCounter, tasks});
    
  }
  
  renderTable(){
  
    const tasks = this.state.table;
    const tableLength = this.state.table ? this.state.table.length : '0';
    
    return(
      <ReactTable
        data={tasks}
        noDataText="No Tasks here!"
        filterable
        defaultFilterMethod={(filter, row) =>
        String(row[filter.id]) === filter.value}
        columns={[{
          columns: [
            {
              Header: "Questions: "+tableLength+" ( Unanswered : "+this.state.zero_counter+" )",
              id: "question",
              accessor: d => d.question,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {keys: ["question"]}),
              filterAll: true,
              minWidth: 300,
              Cell: row =>(
                <div style={{
                  backgroundColor: (row.original.count === null || row.original.count === 0 ? '#fff1ed' : 'inherit'),
                  borderRadius:'4px',
                  padding:'3px'
                }}>
                  <LinkIcon type={'link'}
                            text={row.value}
                            route={`/q/${row.original.questionId}?m=t`}
                  />
                </div>
    
              )
            },
            {
              Header: 'Status',
              accessor: 'status',
              maxWidth: 50,
              Filter: '-',
              Cell: row =>(
                
                row.original.count === null || row.original.count === 0 ?
                  <Tooltip content="Answer Missing" position={Position.LEFT}>
                    <Link className='bp3-button bp3-minimal' to={`/q/${row.original.questionId}?m=t`}>
                      <Icon icon="issue" iconSize={13} style={{color:'red'}}/>
                    </Link>
                  </Tooltip>
                  :
                  ''
  
              )
            },
            {
              accessor: 'id',
              maxWidth: 70,
              Filter: '-',
              Cell: row => (
                
                  <Tooltip content="Answer this" position={Position.LEFT}>
                    <Link to={"/q/"+row.original.questionId+"/answer/"+this.props.task.id} className="bp3-button action_text bp3-small" style={{fontSize:'0.9em', backgroundColor: 'white'}}>
                      Answer
                    </Link>
                  </Tooltip>
                
              )
            },
            {
              accessor: 'id',
              maxWidth: 60,
              Filter: '-',
              Cell: row => (
                <div className="actions_column">
                  
                  <Tooltip content="Edit" position={Position.LEFT}>
                    <Link to={"/q/"+row.original.questionId+"/edit"}>
                      <Icon icon="edit" iconSize={11}/>
                    </Link>
                  </Tooltip>
        
                  <Tooltip content="Delete" position={Position.LEFT}>
                    <Link to={"/q/"+row.original.questionId+"/delete"}>
                      <Icon icon="cross" iconSize={11} />
                    </Link>
                  </Tooltip>
                  
                </div>
              )
            }
          ]
        }]}
        defaultPageSize={100}
        className=" -highlight"
      />
    )
    
  }
  
  render() {
    
    const {task, listQuestions, loading, db_user} = this.props;
    
    return (
      <div className={`${loading ? 'loading' : ''}`}>
         {task &&
           <TaskWrapper
             task={task}
             questions={listQuestions}
             section="progress"
             user={db_user}
           >
             {this.renderTable()}
  
             {task.assignedTo === db_user.id &&
             <Link to={`/q/entity/${task.entityId}/ask/${task.entity}/${task.id}`}
                   className="bp3-button bp3-icon-add bp3-large bp3-intent-success">
               Add
             </Link>
             }
             
           </TaskWrapper>
         }
    
      </div>
    );
  }
  
}

export default withApollo(compose(
  graphql(
    gql(getTask),
    {
      options: ({match: {params: {id}}}) => ({
        variables: {id, limit:1000,},
        fetchPolicy: 'cache-and-network',
      }),
      props: ({data: {getTask: task, loading}}) => ({
        task,
        loading,
      }),
    },
   ),
  graphql(
    gql(listQuestions),
    {
      options: (props) => ({
        variables: {
          limit:1000,
          filter:{taskId:{eq:props.match.params.id}}
        }
      }),
      props: ({ data: { listQuestions = { items: [] } } }) => ({
        listQuestions: listQuestions.items
      })
    }
  )
)(TaskProgress));