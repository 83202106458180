import React, {Component} from "react";
import { Link } from "react-router-dom";
import kebabCase from "lodash/kebabCase"

class Mosaic extends Component {
  
  render() {
    
    const slug = kebabCase(this.props.data.name);

    return (
      
        <div className="entity_mosaic">
          <Link to={"/questions/"+slug+"/"+this.props.data.id}>
          <img src={this.props.data.logo} alt=""/>
      
          <p>{this.props.data.displayName}</p>
          </Link>
         
        </div>
    
    );
  }
  
}


export default Mosaic;
