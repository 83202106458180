import React, { Component } from 'react';
import QuestionHeader from "../_layout/QuestionHeader";
import { getQuestion } from '../../../graphql/queries';
import { deleteQuestion } from '../../../graphql/mutations';
import gql from "graphql-tag";
import { graphql, compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setHeader
} from '../../../_actions';

class QuestionDelete extends Component {
  
  constructor(){
    super();
    this.state = {
      header:{
        title: 'Delete Question', //Optional
        qid: '1e99ej3827', //dynamically generated // Empty will show TBD
        entity:{
          name:'Loading..',
          route:''
        },
        status: 1, // 1 | 2 | 3 | 4 | 5 | 6 (need to be INT)
        action: 'link', // link | button
        action_text: 'Back', //Text inside button
        action_icon: '', //icon inside button
        action_route: '/q', //Button route
        banner_color: 'delete' //new | answer | edit | review | delete
      },
      record: {
        id: '12345'
      }
    }
  }

  handleDelete = () => {
    const { history, deleteQuestion } = this.props
    const { record } = this.state;
    const { questionId } =  this.props.match.params;
    record.id = questionId;

    deleteQuestion(record);

    history.push("/q/entity/"+this.props.question.entityId);
  }

  handleCancel = () => {
    const {history} = this.props
    history.goBack();
  }

  componentWillMount(){
    this.updateState();
  }

  updateState(){
    const { header } =  this.state;
    const { questionId } =  this.props.match.params;
    header.qid =  questionId;

    const { setHeaderData } = this.props;
    setHeaderData(header);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.question && newProps.question.id) {
      this.updateQuestionState(newProps);
    }
  }

  updateQuestionState(props) {
    console.log(props);
    const question = props.question? props.question.question:'';
    const entity = props.question? props.question.entity: '';
  
    const { header } =  this.state;
    header.entity.name = props.question.entity;
    header.entity.route = "/q/entity/"+props.question.entityId;
    
    
    this.setState({ question, entity, header });
  }

  render() {
    console.log(this.props)
    
    return (
      <div>
        <QuestionHeader header={this.state.header} />
        <div className="workarea editor_form">
          <table>
            <tbody>
              <tr>
                <td>
                  <span className="tag tag-warning">To be Deleted</span>
                </td>
                <td>
                  <h3>{ this.state.question }</h3>
                </td>
              </tr>
              <tr>
                <td>
                  Entity
                </td>
                <td>
                  { this.state.entity }
                </td>
              </tr>
            </tbody>
          </table>
          <div className="editor_footer">
            <div/>
            <div>
              <button className="bp3-button" onClick={this.handleCancel}>Cancel</button>
              <button className="bp3-button bp3-intent-danger" onClick={this.handleDelete}>Delete Question</button>
            </div>
          </div>
        </div>
      </div>
    
    );
  }
  
}

QuestionDelete.propTypes = {
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setHeaderData: value => dispatch(setHeader(value)),
});

const QuestionDeleteConnected = connect(mapStateToProps, mapDispatchToProps)(QuestionDelete);

export default withRouter(withApollo(compose(

  graphql(
    gql(deleteQuestion),
    {
      props: ({ mutate }) => ({
        deleteQuestion: ({ id }) => {
          console.log({ id });
          mutate({
            variables: {
              input: {
                id
              }
            },
            refetchQueries:[`getEntities`]
          })
        }
      })
    }
  ),
  graphql(
    gql(getQuestion),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.questionId, questionId: props.match.params.questionId}
      }),
      props: ({ data: { getQuestion = {}}}) => ({
        question: getQuestion
      })
    }
  ),
)(QuestionDeleteConnected)));