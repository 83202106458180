import React, { Component } from 'react';
import QuestionHeader from "../_layout/QuestionHeader";
import Query from "../../../graphql/editor/UpdateCognitoUser";
import { graphql, compose, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import { Storage } from 'aws-amplify';
import myconfig from '../../../custom-config';
import amplifyconfig from '../../../aws-exports'


import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setHeader
} from '../../../_actions';

Storage.configure({
  bucket: myconfig.Storage.bucket,
  region: myconfig.Storage.region,
  identityPoolId: amplifyconfig.aws_cognito_identity_pool_id
});

class UserEdit extends Component {
  
  constructor(){
    super();
    this.state = {
      header:{
        title:'Delete UserSettings',
        qid:'1e99ej3827', //dynamically generated // Empty will show TBD
        action:'link', // link | button
        action_text:'Back', //Text inside button
        action_icon:'', //icon inside button
        action_route:'/q/users', //Button route
        banner_color:'delete' //new | answer | edit | review | delete
      },
      record: {
        status: '404',
        id:''
      }
      ,
      entities_temp:{},
      prevSrc: null,
      file: null
    }
  }

  handleSave = () => {
    const { history, updateT01 } = this.props
    const { record } = this.state;
    const { userId } =  this.props.match.params;
    record.id = userId;
    
    updateT01(record);
    
    history.push("/signout");
  }

  handleCancel = () => {
    const { history} = this.props;
    history.push( "/q/user/"+this.state.record.id+"/profile");
  }
  
  handleEdit = () => {
    const { history} = this.props;
    history.push( "/q/user/"+this.state.record.id+"/edit");
  }
  
  componentWillMount() {
    this.updateState();
  }

  async updateState() {
    const { header } = this.state;
    header.qid = this.props.match.params.userId;
    header.action_route = "/q/user/"+this.props.match.params.userId+"/profile";
    
    const { setHeaderData } = this.props;
    setHeaderData(header);

    const { record } = this.state;
    record.cognitoId = this.props.db_user.id;
    
    let prevSrc = this.state.prevSrc;
    const {client} = this.props;

    try {
      const result = await client.query({
        query: gql`
          query getT01($id: ID!){
            getT01(cognitoId: $id) {
              cognitoId
              id
              username
              first
              last
              email
              about
              avatar
              entities
              status
            }
          }
        `,
        fetchPolicy: 'network-only',
        variables: {
          id: this.props.match.params.userId,
        },
      });
      
      if ( result && result.data.getT01) {
        record.username = result.data.getT01.username;
        record.first = result.data.getT01.first;
        record.last = result.data.getT01.last;
        record.email = result.data.getT01.email;
        record.about = result.data.getT01.about? result.data.getT01.about:'';
        record.avatar = result.data.getT01.avatar;
        record.entities = result.data.getT01.entities;
        //record.status = result.data.getT01.status;
        prevSrc = result.data.getT01.avatar;
      }

    } catch (error) {
      console.log(error);
    }

    this.setState({ record, prevSrc });
  }
  

  render() {
    const {
      username,
      email,
      first,
      last,
      about
    } = this.state.record;
    
    console.log(this.props);
    
    return (
      <div>
        <QuestionHeader header={this.state.header} />
        <div className="workarea editor_form">
          <table>
            <tbody>
              <tr>
                <td style={{width:'150px'}}>
                  Username
                </td>
                <td>
                  {username}
                </td>
              </tr>
              <tr>
                <td>
                  First Name
                </td>
                <td>
                  {first}
                </td>
              </tr>
              <tr>
                <td>
                  Last Name
                </td>
                <td>
                  {last}
                </td>
              </tr>
              <tr>
                <td>
                  Email
                </td>
                <td>
                  {email}
                </td>
              </tr>
              <tr>
                <td>
                  About
                </td>
                <td>
                  { about }
                </td>
              </tr>
            </tbody>
          </table>
          <div className="editor_footer">
            <div>
              <button className="bp3-button" onClick={this.handleEdit} >Edit Instead</button>
            </div>
            <div>
              <button className="bp3-button" onClick={this.handleCancel} >Cancel</button>
              <button className="bp3-button bp3-intent-danger" onClick={this.handleSave} >Delete</button>
            </div>
          </div>
        </div>
      </div>
    
    );
  }
  
}

UserEdit.propTypes = {
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setHeaderData: value => dispatch(setHeader(value)),
});

const UserEditConnected = connect(mapStateToProps, mapDispatchToProps)(UserEdit);

export default withRouter(withApollo(compose(

  graphql(
    Query,
    {
      props: ({ mutate }) => ({
        updateT01: ({ cognitoId, status }) => {
          console.log({ cognitoId, status});
          mutate({
            variables: {
              input: {
                cognitoId,
                status
              }
            }
          })
        }
      })
    }
  )
)(UserEditConnected)));

