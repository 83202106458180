import React, { Component } from 'react'
import {Link} from "react-router-dom";
import kebabCase from "lodash/kebabCase"
import truncate from "lodash/truncate"

class Tabs extends Component {
  
  constructor() {
    super();
    this.state = {
      activeTab:'join'
    
    }
  }
  
  render(){
  
    const slug = kebabCase(truncate(this.props.record.entity));
    
    const entityId = this.props.record.entityId;
    const type = this.props.type;
    
    
    return(
      <div className="bp3-tabs">
        <ul className="bp3-tab-list" role="tablist">
          <li className="bp3-tab" role="tab" aria-selected={type === 'share'}>
            <Link to={"/questions/"+slug+"/"+entityId+"/i/share"}> Invite</Link>
          </li>
          <li className="bp3-tab" role="tab" aria-selected={type === 'join'}>
            <Link to={"/questions/"+slug+"/"+entityId+"/i/join"}>Join</Link>
          </li>
          <li className="bp3-tab" role="tab" aria-selected={type === 'web'}>
            <Link to={"/questions/"+slug+"/"+entityId+"/i/web"}>Embed</Link>
          </li>
          <li className="bp3-tab" role="tab" aria-selected={type === 'bots'}>
            <Link to={"/questions/"+slug+"/"+entityId+"/i/bots"}>Export</Link>
          </li>
        </ul>
      </div>
    )
    
  }
  
}

export default Tabs;

