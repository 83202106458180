//THIS ARE CUSTOMIZED CONFIGURATIONS, independent from AWS,Amplify
//1. AWS S3
//2. Stripe

const myconfig =  {
  Storage: {
    bucket: 'f400-avatar-hosting-09272018',
    region: 'us-east-1'
  },
  Stripe: {
    stripe_secret_key:'sk_test_dqnXMXBtu4Kq2R5XTkiy0kOC',
    stripe_public_key:'pk_test_zXwYYJoWJ3KE2mwrCBrSC3Z7',
    client_id: 'ca_ELPkW7YRA07cW9R4JKDJitjgLUbw4bRG',
    auth_url: "https://connect.stripe.com/oauth/token",
    grant_type: 'authorization_code',
    currency: 'USD',
    apiUrl: 'https://6fnbbh45vk.execute-api.us-west-2.amazonaws.com/dev',
    testPaymentAcct_ID: 'acct_1EhRbJEmutovN5BE'
  }
  
};


export default myconfig;
