import React, { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import {Icon} from "@blueprintjs/core"
import { Dialog} from "@blueprintjs/core"
import { Link } from "react-router-dom";
import logo1 from '../../_layout/assets/alexa.png'
import logo2 from '../../_layout/assets/assistant.png'
import logo3 from '../../_layout/assets/messenger.png'
import logo4 from '../../_layout/assets/amelia.png'
import logo5 from '../../_layout/assets/twilio.png'
import talk from '../../_layout/assets/talk.png'
import Tabs from './Tabs'



class Chatbots extends Component {
  
  constructor() {
    super();
    this.state = {
      modals:{
        first:false
      }
    
    }
  }
  
  renderIcon(image){
    return(
      <img style={{width:'20px', height:'auto', opacity:'1'}} src={image} alt=""/>
    );
  }
  
  handleCancel = () => {
    const { history} = this.props;
    history.push("/q/entity/"+this.props.slug);
  };
  
  handleToggleModal = () => {
    const { modals} = this.state;
    modals.first = !modals.first;
    this.setState({ modals });
  };
  
  renderModal(){
    
    return(
      <Dialog
        icon="info-sign"
        onClose={this.handleToggleModal}
        title="Integration to Voice Assistants"
        isOpen={this.state.modals.first}
      >
        <div style={{padding:'10px 30px'}}>
          <p style={{padding:'20px 0'}}>
            Export Answers for chatbots and AI assistant.
          </p>
  
  
          <img style={{width:'150px', height:'auto', opacity:'1'}} src={talk} alt="devices"/>
          
          <div class="bp3-dialog-footer">
            <div class="bp3-dialog-footer-actions">
              <button className="bp3-button bp3-intent-success" onClick={this.handleToggleModal}>Gotcha, close this</button>
            
            </div>
          </div>
        </div>
      </Dialog>
    
    )
  }
  
  renderExportButton(entityId){
    return(
      <Link className="button" to={"/join/"+entityId+"/"+this.props.slug}>
         Export
      </Link>
    )
  }
  
  renderOptions(){
    
    const entityId = this.props.record.entityId;
    
    return(
  
      <Row center="xs" style={{textAlign:'inherit'}}>
          <Col xs={11} md={8}>
  
            <Tabs record={this.props.record} type={this.props.type}/>
            
            <div className="settings_header">
              <p>
                 Export {this.props.record.displayName} Answers for Chatbots and AI assistant.
              </p>
              <Icon className="mobile_hide" icon="chat" iconSize={30} style={{color:'#CCC', margin:'0 30px'}} />

            </div>
            
            <table className="bp3-html-table bp3-html" style={{width:'100%', paddingBottom:'40%'}}>
              <tbody>
              <tr>
                <td>
                  {this.renderIcon(logo1)}
                </td>
                <td>Alexa</td>
                <td>
                  {this.renderExportButton(entityId)}
                  
                </td>
              </tr>
              <tr>
                <td>
                  {this.renderIcon(logo2)}
                </td>
                <td>Google Assistant</td>
                <td>
                  {this.renderExportButton(entityId)}
                </td>
              </tr>
              <tr>
                <td>
                  {this.renderIcon(logo3)}
                </td>
                <td>Messenger</td>
                <td>
                  {this.renderExportButton(entityId)}
                </td>
              </tr>
              <tr>
                <td>
                  {this.renderIcon(logo4)}
                </td>
                <td>Amelia</td>
                <td>
                  {this.renderExportButton(entityId)}
                </td>
              </tr>
              <tr>
                <td>
                  {this.renderIcon(logo5)}
                </td>
                <td>Twilio</td>
                <td>
                  {this.renderExportButton(entityId)}
                </td>
              </tr>
              
              {/*<tr>*/}
              {/*<td><Switch checked={false}  /></td>*/}
              {/*<td>*/}
              {/*{this.renderIcon(logo4)}*/}
              {/*</td>*/}
              {/*<td>Amelia</td>*/}
              {/*<td>*/}
              {/*Automatic*/}
              {/*<Popover content={menuAutomatic} position={Position.RIGHT_TOP}>*/}
              {/*<Icon icon="chevron-down" iconSize={15} color="#017FE7" />*/}
              {/*</Popover>*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*Manual*/}
              {/*<Popover content={menuManual} position={Position.RIGHT_TOP}>*/}
              {/*<Icon icon="chevron-down" iconSize={15} color="#017FE7" />*/}
              {/*</Popover>*/}
              {/*</td>*/}
              {/*</tr>*/}
              
              </tbody>
            </table>
          </Col>
        </Row>
    )
    
  }
  
  render(){
    return(
      this.renderOptions()
    );
  }
}

export default Chatbots;

