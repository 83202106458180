import gql from "graphql-tag";

export default gql(`
mutation ($input: CreateUsersInput!){
    createUsers(input: $input) {
        id
        username
        first
        last
        email
        about
        answered
        asked
        lang
        locations
    }
}
`)
