import React, { Component } from 'react'
import { graphql, compose, withApollo } from 'react-apollo'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import QuestionHeader from "../_layout/QuestionHeader"
import gql from "graphql-tag"
import { getQuestion } from '../../../graphql/queries'
import { updateQuestion } from '../../../graphql/mutations'

import {
  setHeader
} from '../../../_actions';

class QuestionEdit extends Component {
  
  constructor(){
    super();
    this.state = {
      header:{
        title: 'Edit Question', //Optional
        qid: '0', //dynamically generated // Empty will show TBD
        entity:{
          name:'Loading..',
          route:''
        },
        status: 1, // 1 | 2 | 3 | 4 | 5 | 6 (need to be INT)
        action: '', // link | button
        action_text: '', //Text inside button
        action_icon: '', //icon inside button
        action_route: '', //Button route
        banner_color: 'edit' //new | answer | edit | review | delete
      },
      record: {
        id: '0',
        question: '',
        entityId: '01',
        count:'0',
        lang:'',
        d1:'0',
        d2:'0',
        d3:'0',
        d4:'0'
        
      }
    }
  }
  
  componentWillMount(){
    this.updateState();
  }
  
  updateState(){
    const { header } =  this.state;
    const { questionId } =  this.props.match.params;
    header.qid =  questionId;
    
    const { setHeaderData } = this.props;
    setHeaderData(header);
  }
  
  handleValues = field => event => {
    const { record } = this.state
    record[`${field}`] = event.target.value;
    this.setState({ record });
  }

  handleSave = () => {
    const { history, updateQuestion } = this.props
    const { record } = this.state;
    const { questionId } =  this.props.match.params;
    record.id = questionId;
    console.log(record);

    updateQuestion(record);
    //history.push("/q/entity/"+this.state.record.entityId);
    history.goBack();
  }

  handleCancel = () => {
    const {history} = this.props
    history.goBack();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.question && newProps.question.id) {
      this.updateQuestionState(newProps);
    }
  }

  updateQuestionState(props) {
    console.log(props);
  
    //counting Answers by Degrees
    console.log("counting....");
  
    let d1count=0;
    let d2count=0;
    let d3count=0;
    let d4count=0;
    
    if(props.question.answers){
      console.log(props.question.answers.items);
      const answers = props.question.answers;
      
      answers.items.map(item => {
        switch (item.degree) {
          case "1":
            d1count++;
            break;
          case "2":
            d2count++;
            break;
          case "3":
            d3count++;
            break;
          case "4":
            d4count++;
            break;
          default: return('no item');
        }
        return('');
      });
      
    }else{
      console.log('no Answers available')
    }
    
    const { question } = props;
    const { record, header } = this.state;
    record.question = question.question;
    record.entity = question.entity;
    record.entityId = question.entityId;
    record.lang = question.lang;
    record.count = question.answers ? question.answers.items.length : 0;
    record.d1 = d1count;
    record.d2 = d2count;
    record.d3 = d3count;
    record.d4 = d4count;
    header.entity.name = question.entity;
    header.entity.route = "/q/entity/"+question.entityId;
    
    this.setState({ record });
  }

  render() {
    
    console.log(this.props);
    const {
      question,
      lang
    } = this.state.record
    return (
      <div>
        <QuestionHeader header={this.state.header} />
        <div className="workarea editor_form">
          <table>
            <tbody>
              <tr>
                <td style={{width:'150px'}}>
                  Question
                </td>
                <td>
                  <input className="bp3-input" placeholder="you Question here" style={{width:'100%'}} value={question} onChange={this.handleValues('question')} />
                </td>
              </tr>
              <tr>
                <td>
                  Entity
                </td>
                <td>
                    {this.state.record.entity}
                </td>
              </tr>
              <tr>
                <td>
                  Language
                </td>
                <td>
                  <select className="bp3-select" value={lang} onChange={this.handleValues('lang')}>
                    <option value="">Select one</option>
                    <option value="ENG">English</option>
                    <option value="SPA">Spanish</option>
                    <option value="FRA">French</option>
                    <option value="GER">German</option>
                    <option value="MAN">Mandarin</option>
                    <option value="JAP">Japanese</option>
                  </select>
                
                </td>
              </tr>
              <tr>
                <td>
                  Answer Count
                </td>
                <td>
                  {this.state.record.count}
                  [{this.state.record.d1} | {this.state.record.d2} |  {this.state.record.d3} | {this.state.record.d4} ]
                
                </td>
              </tr>
            </tbody>
          </table>
          <div className="editor_footer">
            <div>
              <button className="bp3-button" onClick={this.handleCancel} >Cancel</button>
              <button className="bp3-button bp3-intent-primary" onClick={this.handleSave} >Save</button>
            </div>
          </div>
        </div>
      </div>
    
    );
  }
}

QuestionEdit.propTypes = {
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setHeaderData: value => dispatch(setHeader(value)),
});

const QuestionEditConnected = connect(mapStateToProps, mapDispatchToProps)(QuestionEdit);

export default withRouter(withApollo(compose(
  graphql(
    gql(updateQuestion),
    {
      props: ({ mutate }) => ({
        updateQuestion: ({ id, question, author, lang, entity, entityId, count, d1, d2, d3, d4 }) => {
          console.log({ id, question, author, lang, entity, entityId, count, d1, d2, d3, d4 });
          mutate({
            variables: {
              input: {
                id,
                question,
                author,
                lang,
                entity,
                entityId,
                count,
                d1,
                d2,
                d3,
                d4
              }
            }
          })
        }
      })
    }
  ),
  graphql(
    gql(getQuestion),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.questionId, questionId: props.match.params.questionId}
      }),
      props: ({ data: { getQuestion = {}}}) => ({
        question: getQuestion
      })
    }
  )
)(QuestionEditConnected)));
