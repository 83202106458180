import gql from "graphql-tag";

export default gql(`
mutation ($input: UpdateT01Input!){
    updateT01(input: $input) {
        cognitoId
        username
        first
        last
        email
        about
        avatar
        status
        entities
    }
}`);
