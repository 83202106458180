import React from 'react';
import Query from "../../../graphql/editor/UpdateCognitoUser";
import Query2 from "../../../graphql/editor/ListUserQuestionsCount";
import Query3 from "../../../graphql/editor/ListUserAnswersCount";
import { graphql, compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setHeader
} from '../../../_actions';

const UserCount = (props) => {
  
    const { history, updateT01 } = props
    const record = [];
    
    
    record.cognitoId = props.match.params.userId;
    record.id = props.match.params.userId;
    record.asked = props.userQuestions.length;
    record.answered = props.userAnswers.length
  
    updateT01(record);
  
    console.log('Q:'+record.asked+' | A:'+record.answered)
    history.push("/q/user/"+record.id+"/profile");
    
    return (
      <div/>
    );
  
}

UserCount.propTypes = {
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setHeaderData: value => dispatch(setHeader(value)),
});

const UserEditConnected = connect(mapStateToProps, mapDispatchToProps)(UserCount);

export default withRouter(withApollo(compose(

  graphql(
    Query,
    {
      props: ({ mutate }) => ({
        updateT01: ({ cognitoId, answered, asked }) => {
          console.log({ cognitoId, answered, asked});
          mutate({
            variables: {
              input: {
                cognitoId,
                answered,
                asked
              }
            }
          })
        }
      })
    }
  ),
  graphql(
    Query2,
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.userId}
      }),
      props: ({ data: { listQuestions = { items: [] } } }) => ({
        userQuestions: listQuestions.items
      })
    }
  ),
  graphql(
    Query3,
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.userId}
      }),
      props: ({ data: { listAnswers = { items: [] } } }) => ({
        userAnswers: listAnswers.items
      })
    }
  )
)(UserEditConnected)));

