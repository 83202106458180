import React from "react"
import moment from 'moment';
import {Icon} from "@blueprintjs/core"
import { Link } from "react-router-dom"
import { Row, Col } from 'react-flexbox-grid'

const TaskInfo =({task, role})=>{
  
    const {where, when, name, id, bounty, entity, entityLogo} = task;
    
    return (
      <Row>
        <Col xs={12} md={12}>
            <h3 style={{textAmign:'left', margin:'0' }}>
              
              {role === 'owner' || role ==='owner_assignee' ?
                <Link to={"/q/task/" + id + "/edit"}>
                  {name}
                  <Icon icon="edit" iconSize={11} style={{marginLeft: '20px'}}/>
                </Link>
                :
                <span>
                   {where &&(
                     "["+where+"] "
                   )}
                   
                  {name}
                </span>
              }
            </h3>
        </Col>
        <Col xs={12} md={12}>
            <div className="task_details">
              <div className="dt">
                Bounty: ${bounty}
              </div>
              
              <div className="dt">
                <Icon icon="calendar" iconSize={14} style={{marginRight:'10px'}}/>
                Due: {moment(when).format('L')}
              </div>
  
              {entity ?
                <div className="dt">
      
                  {entityLogo &&
                  <div className='icon1' style={{ backgroundImage:`url(${entityLogo})`, width:'20px', height:'20px', margin:'0 10px 0 0' }}/>
                  }
      
                  {entity}
    
                </div>
                : null
              }
              
            </div>
        </Col>
      </Row>
    );
}


export default TaskInfo;