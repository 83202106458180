import React, { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import QuestionHeader from "../../_layout/QuestionHeader"
import SettingsSidebar from "./SettingsSidebar"
import "./SettingsStyle.css"


class SettingsWrapper extends Component {
  
  constructor(){
    super();
    this.state = {
      header: {
        counter: '',
        action: 'button',
        action_text: 'Go to user Profile',
        action_icon: 'arrow-left',
        action_route: '/q/users',
        banner_color:'expanded',
        breadcrumb:[
          {
            name:'',
            route:'',
            icon:'cog',
            active: false
          },{
            name:'Settings',
            active: true,
            size:'L'
          },{
            name:'',
            size:'L'
          }
        ],
      },
      
    }
  }
  
  render() {
    
    return (
      <div>
        
        <QuestionHeader
          header={this.props.header}
        />
        
        <Row>
          <Col xs={12} sm={12} md={2} lg={2}>
            <SettingsSidebar userId={this.props.user} userRole={this.props.userRole} activeTab={this.props.activeTab} />
          </Col>
          <Col xs={12} sm={12} md={10} lg={10} >
            <div className="workarea editor_form">
              
              {this.props.children}
              
            </div>
          </Col>
        </Row>
      </div>
    
    );
  }
  
}


export default SettingsWrapper;