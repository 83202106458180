import React, { Component } from 'react'
import ReactTable from "react-table"
import { Link } from "react-router-dom"
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Spring } from 'react-spring'
import ClassNames from 'classnames'
import kebabCase from "lodash/kebabCase"
import truncate from "lodash/truncate"
import { graphql, compose, withApollo } from "react-apollo"
import { Icon} from "@blueprintjs/core"
import {Helmet} from "react-helmet";
import gql from "graphql-tag";
import { getEntity, getQuestion } from '../../graphql/queries';
import {renderCredit, friendlyDate, renderTag, renderCTA} from '../_utils/helpers'
import EntityHeader from "./_layout/EntityHeader"
import EntityLeftbar from "./_layout/EntityLeftbar"
import EntityRightbar from "./_layout/EntityRightbar"

class EntityQuestionDetail extends Component {

  constructor(){
    super();
    this.state = {
      header:{
        title: '',
        displayName: '',
        counter:'',
        entityLogo:'',
        wall:'',
        theme:'light'
      },
      question_record:{
        questionName:'',
        questionId:''
      },
      embed:false
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.question && newProps.question.id) {
      this.updateState(newProps);
    }
  }
  
  
  updateState(props){
    
    // Question result from AWS DynamoDB using graphql
    const { question } = props;
    const { entity } = props;
    
    // PublicView Header Info
    const { header } = this.state;
    header.title = entity.name;
    header.displayName = entity.displayName;
    header.name = entity.name;
    header.entityId = entity.id;
    header.entityLogo = entity.logo;
    header.wall = entity.wall;
    header.theme = (entity.theme? entity.theme: 'light');
    header.id = (entity? entity.id: '');
  
    const { question_record } = this.state;
    question_record.questionName = (question? question.question : '');
    question_record.questionId = (question? question.id : '');
    
    let payload = [];

    if(question.answers) {
      payload = question.answers.items.map(item => {
        //console.log(item);
        return {
          answer: item.answer,
          author: item.author,
          degree: item.degree,
          answerId: item.id,
          rating: item.rating,
          credit: item.credit,
          modifiedAt: item.modifiedAt,
          createdAt: item.createdAt,
          bounty: item.bounty,
          lang: item.lang,
          actions: '12345'
        };
      });
    }

    this.setState({table: payload, question_record, entity: (question ? props.question.entity:'No publicFAQ'), header, embed:(this.props.match.params.config === 'embed')});
  }
  
  renderAuthor(e){
    return(
      <div>
        {e.author}
        {renderTag(e.degree)}
      </div>
    )
    
  }
  
  renderAsItems(){
   
    const { table } = this.state;
    const {title, id}= this.state.header;
    
    return(
      <div>
        { table &&(table.map(e =>
          <div className="faq_answer">
            <div className="faq_answer_row1">
              { e.credit ? renderCredit(e) : this.renderAuthor(e) }
              
              <div style={{opacity:'0.5', fontWeight:'400', fontSize:'0.9em'}}>
                {e.lang} &nbsp;| &nbsp; {e.rating} &nbsp; |&nbsp;
                { friendlyDate(e.modifiedAt)}
              </div>
              
            </div>
            
            <div className="faq_answer_row2">
              <Link to={`/questions/${kebabCase(truncate(title))}/${id}/a/${e.answerId}/${kebabCase(truncate(e.answer))}${(this.state.embed === true ? '/embed':'')}`} >
                {e.answer}
              </Link>
              <br/>
              
              {e.bounty &&
                <div style={{margin:'20px 0'}}>
                  { renderCTA(e.bounty,'button') }
                </div>
              }
              
              
            </div>
          </div>
        )) }
      </div>
    )
  }
  
  renderAsTable(){
    const { table } = this.state;
    
    return(
    <ReactTable
      data={table}
      columns={[{
        columns: [{
          Header: 'Answer',
          accessor: 'answer',
          minWidth: 300
        },
          {
            Header: 'Degree',
            accessor: 'degree',
            width: 70,
            Cell: row =>(
              <div style={{textAlign:'center'}}>
                  <span
                    className={ClassNames('degree',
                      {degree1 : row.value === '1'},
                      {degree2 : row.value === '2'},
                      {degree3 : row.value === '3'},
                      {degree4 : row.value === '4'}
                    )} >
                  {row.value}°
                  </span>
              </div>
            )
          },
          {
            Header: 'Author',
            accessor: 'author',
            width: 50,
          },
          {
            Header: 'Rating',
            accessor: 'rating',
            width: 50,
          }
        ]
      }]}
      defaultPageSize={25}
      className=" -highlight"
    />
    )
    
  }
  
  renderHelmet(){
    
    const { questionName} = this.state.question_record;
    const {displayName, entityLogo, title}= this.state.header;
    
    return(
      <Helmet>
        <meta charSet="utf-8" />
        <title>{` ${displayName} Question : ${questionName}`}</title>
        
        <meta name="description" content={`Answers to different ${title} questions: ${questionName}`} />
        <meta property="og:description" content={`${displayName} Frequent Asked Question:  ${questionName}`} />
        <meta property="og:image" content={`${entityLogo}`} />
        <meta property="og:title" content={`${questionName}`} />
        <meta property="og:type" content="website" />
        
      </Helmet>
    )
  }

  renderResults() {
    
    const { questionName, questionId, entitySlug} = this.state.question_record;
    
    return (
      <Row center="xs">
        
        <Col xs={this.state.embed? 12 : 11} md={this.state.embed? 12 : 8}>
          <Grid style={{textAlign:'left'}}>
            <Row>
              <Col xs={12} md={12}>
                <div style={{fontSize:'0.8em', opacity:'0.7'}}>
                  <Link to={"/questions/"+entitySlug+"/"+this.props.match.params.entityId+(this.state.embed === true ? '/embed':'')}>
                    <Icon icon="arrow-left" iconSize={15} /> Back
                  </Link>
                </div>
              </Col>
            </Row>
            <Row style={{padding:'20px 0'}}>
              <Col xs={12} md={9} >
                <h1 style={{fontWeight: '400', fontSize: '2em'}}>
                  {questionName}
                </h1>
              </Col>
              <Col xs={12} md={3} style={{padding:'20px 0'}}>
                  {/*<Link className='button' to={"/join/"+this.state.header.id}>*/}
                  {/*Answer this*/}
                  {/*</Link>*/}
                
                  <Link className='primary entity_action_right' to={"/q/"+questionId+"/answer"} >
                    Answer this
                  </Link>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                {this.renderAsItems()}
              </Col>
              
            </Row>
          </Grid>
        </Col>
      </Row>
    );
  }
  
  
  render(){
    
    const bg = this.state.header.wall;
    console.log(this.props, this.state)
    
    return(
  
      <div className={"theme_wrapper "+ this.state.header.theme}>
        
        {this.renderHelmet()}
        
        <div  className="entity_background" style={{ backgroundImage:`url(${bg})` }} />
  
        {!this.state.embed &&(
          <EntityHeader header={this.state.header} match={this.props.match} />
        )}
        
        <EntityLeftbar header={this.state.header} match={this.props.match} />
        <EntityRightbar header={this.state.header} match={this.props.match} />
        
        <div style={{paddingTop:'20px'}}>
  
          <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
            {props => <div style={props}>{this.renderResults()}</div>}
          </Spring>
          
        </div>
      </div>
 
    );
  }
  
}

export default withApollo(compose(

  graphql(
    gql(getQuestion),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.questionId, questionId: props.match.params.questionId}
      }),
      props: ({ data: { getQuestion = {}}}) => ({
         question: getQuestion
      })
    }
  ),
  graphql(
    gql(getEntity),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.entityId, entityId: props.match.params.entityId}
      }),
      props: ({ data: { getEntity = {}}}) => ({
        entity: getEntity
      })
    }
  )
)(EntityQuestionDetail));