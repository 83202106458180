import React, { Component } from "react"
import { Link } from "react-router-dom"
import { graphql, compose, withApollo } from "react-apollo"
import {Icon, Tooltip, Position, Popover} from "@blueprintjs/core"
import { Row, Col } from 'react-flexbox-grid'
import { pullAllBy, cloneDeep} from 'lodash';
import matchSorter from 'match-sorter'
import ReactTable from "react-table"
import gql from "graphql-tag"
import { listEntitys } from '../../../graphql/queries'
import { deleteEntity } from '../../../graphql/mutations'
import { updateUser } from '../../../graphql/mutations'
import { getUser } from '../../../graphql/queries'
import LinkIcon from "../../_utils/LinkIcon"
import {extractEntities} from '../../_utils/ShowEntities'
import SettingsWrapper from "../Settings/_layout/SettingsWrapper"


class ListUserChannels extends Component {
  
  constructor() {
    super();
    this.state = {
      header: {
        counter: 100,
        action: 'link',
        action_text: 'Create Channel',
        action_icon: 'plus',
        action_route: '/q/newentity',
        banner_color: 'expanded',
        breadcrumb: [
          {
            name: '',
            route: '',
            icon: 'cog',
            active: false
          }, {
            name: 'Settings',
            active: true,
            size: 'L'
          }, {
            name: 'User',
            size: 'L'
          }
        ]
      },
      record: {
        entities: 'entitieshere'
      },
      temp: {
        user_entities: [],
        all_entities: []
      },
      busy: false,
      showAll: true
    }
  }
  
  static defaultProps = {
    events: [],
    deleteEvent: () => null,
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.thisUser) {
      this.updateState(newProps);
    }
  }
  
  updateState(props){
    const { header, temp } =  this.state;
    
    //If db_user.ID and URL :userId is the same show name, otherwise show only ID
    header.breadcrumb[2].name = (props.match.params.userId === props.db_user.id ? props.db_user.username : props.match.params.userId);
    header.breadcrumb[2].route = (props.match.params.userId === props.db_user.id ? "/q/user/"+props.db_user.id+"/profile" : "/q/user/"+props.match.params.userId+"/profile");
    
    temp.user_entities = props.thisUser.entities ? extractEntities(props.thisUser.entities, 'keys') : [];
    
    //Removing all Entities user with lodash 'pullAllBy'
    let clone= cloneDeep(props.entities);
    temp.all_entities = pullAllBy(clone, temp.user_entities, 'id');
    
    this.setState({header, temp});
    
  }
  
  async handleDeleteClick(item, e) {
    e.preventDefault();
    
    if (window.confirm(`Are you sure you want to delete event ${item.id}`)) {
      const { deleteEntity } = this.props;
      
      await deleteEntity(item);
    }
  }
  
  async handleAddChannel(item, e) {
    e.preventDefault();
  
    //Turn Loader On
    this.setState({ busy: true });
    
    //The previous record
    const record = this.state.temp.user_entities;
    //adding the new item
    record.push(item);
  
    const format2save = record.map(e => ("("+e.id +"+"+ e.displayName +"+"+ e.logo+"+3)"));
    
    if (window.confirm(`You are adding ("${item.name}") to your Channels`)) {
  
      const { updateUser} = this.props;
      
      const newEntitiesString = {id: this.props.match.params.userId, cognitoId: this.props.match.params.userId, entities: format2save};
      await updateUser(newEntitiesString);
  
      //Turn loader Off
      this.setState({ busy: false });
    }
  }
  
  async handleRemoveChannel(item, e){
    e.preventDefault();
  
    //Turn Loader On
    this.setState({ busy: true });
  
    //The previous record
    const existing = this.state.temp.user_entities;
    const toRemove = [item];
  
    //Removing Entity with lodash 'pullAllBy'
    let updated_user_entities = pullAllBy(existing, toRemove, 'id');
    
    //Preparing to save as a String
    const format2save = updated_user_entities.map(e => ("("+e.id +"+"+ e.displayName +"+"+ e.logo+"+3)"));
    
    
    if (window.confirm(`You are removing ("${item.name}") from your channels`)) {
      
      //Mutation
      const { updateUser} = this.props;
      
      const newEntitiesString = {id: this.props.match.params.userId, cognitoId: this.props.match.params.userId, entities: format2save};
      await updateUser(newEntitiesString);
  
      //Turn loader Off
      this.setState({ busy: false });
      
    }
    
  }
  
  handleEntityChange = (selectedOption) => {
    
    console.log(selectedOption);
    const { record } = this.state;
    
    const format2save = selectedOption.map(e => ("("+e.value +"+"+ e.label +"+"+ e.logo+"+3)"));
    
    record['entities'] = format2save.join();
    
    this.setState({record, entities_temp: format2save });
    
    
  };
  
  
  handleSync = async () => {
    const { client } = this.props;
    const query = listEntitys;
    
    this.setState({ busy: true });
    
    await client.query({
      query,
      fetchPolicy: 'network-only',
    });
    
    this.setState({ busy: false });
  }
  
  ToggleNewChannels = async () => {
    
    this.setState({ showAll: !this.state.showAll });
  }
  
  
  renderUserChannels(){
    
    const selected = this.state.temp.user_entities;
    
    return(
      <ReactTable
        data={selected}
        columns={[{
          columns: [
            {
              Header: 'Name',
              accessor: 'displayName',
              minWidth: 100,
              Cell: row =>(
                <div style={{display:'flex'}}>
                  <LinkIcon type={'entity_logo'}
                            icon={row.original.logo}
                            text={row.value}
                            route={"/q/entity/"+row.original.id}
                  />
                </div>
                
              )
            },
            {
              Header: '',
              accessor: 'id',
              maxWidth: 80,
              minWidth: 80,
              Cell: row => (
                <div className="actions_column">
                  <Tooltip content="Remove from my channels" position={Position.LEFT}>
                    <div onClick={this.handleRemoveChannel.bind(this, row.original)}>
                      <Icon icon="remove" iconSize={12} />
                    </div>
                  </Tooltip>
  
                  {this.props.db_user &&(
                    this.props.db_user.status === 101 &&(
                      <Popover content={
                        <ul className="bp3-menu bp3-elevation-1">
                          <li>
                            <Link to={"/q/entity/" + row.value + "/ask/" + row.original.name}>
                              Ask Question
                            </Link>
                          </li>
                          <li>
                            <Link to={"/q/entity/" + row.value + "/edit"}>
                              Edit
                            </Link>
                          </li>
                          <li>
                            {/*TODO: Replace 'channel' for real entity Name*/}
                            <Link to={"/questions/channel/" + row.value}>
                              Launch FAQ
                            </Link>
                          </li>
        
                          <li>
                            <Link to={"/q/entity/" + row.value + "/delete"}>
                              Delete
                            </Link>
                          </li>
                        </ul>
                      }>
                        <Icon icon="edit" iconSize={12} />
                      </Popover>
                    )
                  )}
  
                  {this.props.db_user.status === 101 &&(
                    <Tooltip content="Delete" position={Position.LEFT}>
                      <div onClick={this.handleDeleteClick.bind(this, row.original)}>
                        <Icon icon="trash" iconSize={12} />
                      </div>
                    </Tooltip>
                  )}
                  
                
                </div>
              )
            }
          
          ]
        }]}
        defaultPageSize={100}
        className=" -highlight"
      />
    )
    
  }
  
  renderAllChannels(){
    
    
    const entities = this.state.temp.all_entities;
    
    return(
      <ReactTable
        data={entities}
        filterable
        columns={[{
          columns: [
            {
              Header: 'Name',
              id: "displayName",
              accessor: d => d.displayName,
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ["displayName"]}),
              filterAll: true,
              minWidth: 120,
              Cell: row =>(
                <LinkIcon type={'entity_logo'}
                          icon={row.original.logo}
                          text={row.value}
                          route={"/q/entity/"+row.original.id}
                />
              )
            },
            {
              Header: '',
              accessor: 'id',
              Filter: <Icon icon="search" iconSize={14} style={{color:'#dcdcdc'}} />,
              maxWidth: 60,
              Cell: row => (
                <div className="actions_column">
                  <Tooltip content="Add to my Channels" position={Position.LEFT}>
                    <div onClick={this.handleAddChannel.bind(this, row.original)}>
                      <Icon icon="add" iconSize={12} />
                    </div>
                  </Tooltip>
  
                  {this.props.db_user.status === 101 &&(
                  <div content="Delete" position={Position.LEFT}>
                    <div onClick={this.handleDeleteClick.bind(this, row.original)}>
                      <Icon icon="trash" iconSize={12} />
                    </div>
                  </div>
                  )}
                  
                  
                </div>
              )
            }
          ]
        }]}
        defaultPageSize={100}
        className=" -highlight"
      />
    )
  }
  
  render() {
    
    const userRole = this.props.db_user ? parseInt(this.props.db_user.status) : 0;
    
    return (
      <SettingsWrapper user={this.props.match.params.userId} userRole={userRole} header={this.state.header} activeTab="mychannels">
  
        <h2>Channels</h2>
        { this.state.busy && <div className="loading_overlay">Loading..</div>}
        <Row>
          <Col xs={12}>

            <div style={{display:'flex', justifyContent:'space-between', textAlign:'left'}}>
              
              <div className="workarea">
                <div style={{display:'flex', justifyContent:'space-between', padding:'10px 0'}}>
                  <h4 className="settings-title">Joined ({this.state.temp.user_entities.length})</h4>
                  <div className="bp3-button bp3-icon-plus" onClick={this.ToggleNewChannels} style={{display: !this.state.showAll ? 'block' : 'none'}}>
                    Join more channels
                  </div>
                </div>
                <div>
                  {this.renderUserChannels()}
                </div>
              </div>

              <div className="workarea" style={{display: this.state.showAll ? 'block' : 'none'}}>
                <div style={{display:'flex', justifyContent:'space-between', padding:'10px 0'}}>
                  <h4 style={{color:'#0078ff'}}>Available ({this.state.temp.all_entities.length})</h4>
                  <div className="bp3-button" onClick={this.ToggleNewChannels} style={{display: this.state.showAll ? 'block' : 'none'}}>Hide</div>
                </div>
                <div>
                  {this.renderAllChannels()}
                </div>
              </div>
            </div>
            
          </Col>
        </Row>
        
      </SettingsWrapper>
    );
  }
  
}

export default withApollo(compose(
  graphql(
    //List all tasks
    gql(listEntitys),
    {
      options: {
        //fetchPolicy: 'cache-and-network',
        fetchPolicy: 'network-only',
        variables:{
          limit:1000
        }
      },
      props: ({ data: { listEntitys = { items: [] } } }) => ({
        entities: listEntitys.items
      })
    }
  ),
  graphql(
    //DeleteEntity
    gql(deleteEntity),
    {
      options: {
        update: (proxy, { data: { deleteEntity } }) => {
          const query = listEntitys;
          const data = proxy.readQuery({ query });
          
          data.listEntitys.items = data.listEntitys.items.filter(entity => entity.id !== deleteEntity.id);
          
          proxy.writeQuery({ query, data });
        }
      },
      props: (props) => ({
        deleteEntity: (entity) => {
          return props.mutate({
            variables: { input:{id: entity.id} },
            optimisticResponse: () => ({
              deleteEntity: {
                ...entity, __typename: 'Task', comments: { __typename: 'CommentConnection', items: [] }
              }
            }),
          });
        }
      })
    }
  ),
  graphql(
    //Update User
    gql(updateUser),
    {
      props: (props) => ({
        updateUser: ({id, cognitoId, entities}) => {
          return props.mutate({
            variables: { input:{id, cognitoId, entities} },
            //fetchPolicy: 'no-cache',
            refetchQueries:[`getUser`]
          });
        }
      })
    }
  ),
  graphql(
    gql(getUser),
    {
      options: (props) => ({
        //fetchPolicy: 'no-cache',
        variables: {id: props.match.params.userId}
      }),
      props: ({ data: { getUser = {}}}) => ({
        thisUser: getUser
      })
    }
  )
)(ListUserChannels));
