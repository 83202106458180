import React, { Component } from 'react'
import ReactTable from "react-table"
import { graphql, compose, withApollo } from "react-apollo"
import gql from "graphql-tag"
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import {Icon, Tooltip, Position} from "@blueprintjs/core"
import ResultHeader from "../_layout/ResultHeader"
import LinkIcon from "../../_utils/LinkIcon"
import { listEntitys } from '../../../graphql/queries'


import {
  setUser,
  setHeader
} from '../../../_actions';

class ListEntities extends Component {
  constructor(){
    super();
    
    this.state = {
      header:{
        title:'All Channels',
        counter:100,
        action:'link',
        action_text:'Create Channel',
        action_icon:'plus',
        action_route:'/q/newentity'
      }
      
    }
  }
  
  componentWillMount(){
    this.updateState();
  }
  
  async updateState() {
    //Getting all GQL payload
    const { client } = this.props;
    let payload = [];
    try {
      const result = await client.query({
        query: gql(listEntitys),
        //fetchPolicy: 'cache-and-network',
        variables:{
          limit:1000
        }
       
      });
      console.log(result);
      
      if(result && result.data.listEntitys) {
        payload = result.data.listEntitys.items;
      }
    } catch (error) {
      console.log(error);
    }
    
    
    //Getting Counter number
    
    const header = {
      ...this.state.header,
      counter: payload.length
    };
    const { setHeaderData } = this.props;
    setHeaderData(header);
    
    //Updating State
    this.setState({ table: payload });
    
  }
  
  renderTable(){
    
    const table = this.state.table;
    
    return(
      <ReactTable
        data={table}
        columns={[{
          columns: [
            {
              Header: 'Name',
              accessor: 'displayName',
              minWidth: 300,
              Cell: row =>(
                <LinkIcon type={'entity_logo'}
                          icon={row.original.logo}
                          text={row.value}
                          route={"/q/entity/"+row.original.id}
                />
              )
            },
            {
              Header: 'Score',
              accessor: 'score'
            },
            {
              Header: 'Website',
              accessor: 'website'
            },
            {
              Header: 'Locations',
              accessor: 'locations'
            },
            {
              Header: 'Actions',
              accessor: 'id',
              maxWidth: 100,
              Cell: row => (
                <div className="actions_column">
                  <Tooltip content="Ask Question" position={Position.LEFT}>
                    <Link to={"/q/entity/"+row.value+"/ask/"+row.original.name}>
                      <Icon icon="plus" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  <Tooltip content="Edit" position={Position.LEFT}>
                    <Link to={"/q/entity/"+row.value+"/edit"}>
                      <Icon icon="edit" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  <Tooltip content="Launch FAQ" position={Position.LEFT}>
                    <Link to={"/e/"+row.value}>
                      <Icon icon="arrow-top-right" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  
                  {this.props.db_user &&(
                    
                    this.props.db_user.status === 101 &&(
                      <Tooltip content="Delete" position={Position.LEFT}>
                        <Link to={"/q/entity/"+row.value+"/delete"}>
                          <Icon icon="cross" iconSize={11} />
                        </Link>
                      </Tooltip>
                    )
                  )}
                
                </div>
              )
            }
          
          ]
        }]}
        defaultPageSize={20}
        className=" -highlight"
      />
    )
    
  }
  
  render() {
    
    return (
      
      <div>
        {this.props.header && <ResultHeader header={this.props.header} />}
        
        <div className="workarea">
          <div>
            {this.renderTable()}
          
          </div>
        
        </div>
      </div>
    
    );
  }
}

ListEntities.propTypes = {
  user: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.dataReducer.user,
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setUserData: value => dispatch(setUser(value)),
  setHeaderData: value => dispatch(setHeader(value)),
});

const ListEntitiesConnected = connect(mapStateToProps, mapDispatchToProps)(ListEntities);

export default withApollo(compose(
  
  graphql(
    //List all tasks
    gql(listEntitys),
    {
      options: {
        //fetchPolicy: 'cache-and-network',
        fetchPolicy: 'network-only',
        variables:{
          limit:1000
        }
      },
      props: ({ data: { listEntitys = { items: [] } } }) => ({
        entities: listEntitys.items
      })
    }
  )
)(ListEntitiesConnected));
