import React from 'react';
import { Colors, Icon } from "@blueprintjs/core";

const ShowStatus = (statusIn, statusText) => {
  
  const status = parseInt(statusIn, 0);
  
  return(
  <span style={{fontSize:'1em'}}>
    <span style={{
      color: status === 1 ? Colors.FOREST4
        : status === 2 ? Colors.GOLD3
        : status === 3 ? Colors.BLUE3
        : status === 4 ? Colors.TURQUOISE1
        : status === 5 ? Colors.RED3
        : status === 6 ? Colors.INDIGO3
        : status === 10 ? Colors.GRAY1
        
        //TASK STATUSES
        : status === 101 ? Colors.FOREST1
        : status === 102 ? Colors.GOLD3
        : status === 103 ? Colors.BLUE3
        : status === 104 ? Colors.TURQUOISE1
        : status === 105 ? Colors.RED3
        : status === 106 ? Colors.INDIGO3
        : status === 110 ? Colors.GRAY1
        : Colors.WHITE,
      transition: 'all .3s ease'
      
    }}>
      {/*&#x25cf;*/}
      <Icon icon={'symbol-circle'} iconSize={13}/>
      
    </span>
    {statusText && (
      
      status === 1 ? 'Open'
        : status === 2 ? `Draft`
        : status === 3 ? `On Review`
        : status === 4 ? `Reviewed`
        : status === 5 ? `Approved`
        : status === 6 ? `Paid`
        : status === 7 ? `Closed`
        //TASK STATUSES
        : status === 101 ? `Available`
        : status === 102 ? `Assigned`
        : status === 103 ? `On-review`
        : status === 104 ? ``
        : status === 105 ? `Completed`
        : status === 106 ? `Paid`
        : status === 110 ? `Closed`
        : '-'
    
    )}
    
    
    
  </span>)
  
};

export default ShowStatus;