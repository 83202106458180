import React, { Component } from 'react'
import ReactTable from "react-table"
import ClassNames from "classnames"
import { Link } from "react-router-dom"
import {Icon, Tooltip, Position, NonIdealState} from "@blueprintjs/core"
import { graphql, compose, withApollo} from "react-apollo"
import gql from "graphql-tag"
import { listAnswers } from '../../../graphql/queries'
import ResultHeader from "../_layout/ResultHeader"
import LinkIcon from "../../_utils/LinkIcon"

class ListUserAnswers extends Component {
  constructor(){
    super();
    
    this.state = {
      header:{
        title:'loading..',
        counter:''
      },
      table:[]
      
    }
  }
  
  componentWillReceiveProps(newProps){
    this.updateState(newProps);
  }
  
  
  updateState(props){
   
    //Getting all GQL payload
    const payload = props.items;
    
    //Getting Counter number
    const header = {...this.state.header};
    header.counter = payload.length;
    header.title = (props.db_user ? props.db_user.username : " User ");
    
    //Updating State
    this.setState({table: payload, header});
    
  }
  
  renderTable(){
    
    const table = this.state.table;
    
    return(
      <ReactTable
        data={table}
        noDataText="No Answers here!. Go to Questions and answer some"
        columns={[{
          columns: [
            {
              Header: 'Answer',
              accessor: 'answer',
              minWidth: 300,
              Cell: row =>(
                <LinkIcon type={'link'}
                          text={row.value}
                          route={"/q/answer/" + row.original.id + "/detail"}
                />
              )
            },
            {
              Header: 'Degree',
              accessor: 'degree',
              maxWidth: 50,
              Cell: row =>(
                <Link to={'/q/entity'} className="entity_column">
                            <span
                              className={ClassNames('degree',
                                {degree1 : row.value === '1'},
                                {degree2 : row.value === '2'},
                                {degree3 : row.value === '3'},
                                {degree4 : row.value === '4'}
                              )} >
                            {row.value}°
                            </span>
      
                </Link>
              )
            },
            {
              Header: 'Author',
              accessor: 'author',
              minWidth: 150,
              Cell: row =>(
                <LinkIcon type={'link'}
                          icon={'user'}
                          text={row.value}
                          route={"/q/home/"+row.original.id}
                />
              )
            },
            {
              accessor: 'id',
              maxWidth: 100,
              Cell: row => (
                <div className="actions_column">
                  <Tooltip content="Go to Answer" position={Position.LEFT}>
                    <Link to={"/q/answer/"+row.value+"/detail"}>
                      <Icon icon="arrow-left" iconSize={11} />
                    </Link>
                  </Tooltip>
                  <Tooltip content="Edit" position={Position.LEFT}>
                    <Link to={"/q/answer/"+row.value+"/edit"}>
                      <Icon icon="edit" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  <Tooltip content="Delete" position={Position.LEFT}>
                    <Link to={"/q/answer/"+row.value+"/delete"}>
                      <Icon icon="cross" iconSize={11}/>
                    </Link>
                  </Tooltip>
                </div>
              )
            }
          
          ]
        }]}
        defaultPageSize={20}
        className=" -highlight"
      />
    )
    
  }
  
  renderNoResults(){
    
    const action =(
      <Link to="/q" className="bp3-button">
        Find a question
      </Link>
    )
    return(
      <div style={{display:'flex', justifyContent:'center', height:'50vh'}}>
        <NonIdealState
          icon="issue"
          title="No Answers here"
          description="You haven't answered any question yet"
          action={action}
        />
      </div>
    )
  }
  
  
  render() {
    
    console.log(this.props);
    console.log(this.state);
    
    return (
      
      <div>
        {this.state.header && <ResultHeader category="Answers" header={this.state.header} />}
  
        <div className="workarea">
          { !this.state.table.length ? this.renderNoResults() : this.renderTable() }
        </div>
      </div>
    
    );
  }
}


export default withApollo(compose(
  graphql(
  
    gql(listAnswers),
    {
      options: (props) => ({
        variables: {
          //id: props.match.params.userId,
          limit:1000,
          filter:{authorId:{eq: props.match.params.userId}}
        }
      }),
      props: ({ data: { listAnswers = { items: [] } } }) => ({
        items: listAnswers.items
      })
    }
  ),
)(ListUserAnswers));
