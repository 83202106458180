import React, {Fragment} from 'react';
import ClassNames from 'classnames'
import moment from "moment"
import { Link } from "react-router-dom";
import {Icon} from "@blueprintjs/core"

//This function separate values separated by '+', and return an array
export function separateValues(arr) {
  
  //Unpacking Entities from String
  let ent = JSON.stringify(arr);
  ent = String(ent);
  
  let regex = /\(([^)]+)\)/;
  let e = ent.split(regex);
  //Cleaning up array
  let res = e.filter(function(v, i) {
    // check the index is even
    return i % 2 !== 0;
  });
  
  let separated =[];
  res.map(z => {
    let x = z.split('+');
    separated.push(x);
    return x;
  });
  
  return separated;
  
}

//This function Render Credit in the form of a Link when it's saved in DB in the following format:
//"[(Credit Name+https://www.credit.org/faq)+1]"
export function renderCredit(e) {
  
  const values = separateValues(e.credit);
 
  if(values[0]){
    return (
      <div>
        <a href={values[0][1]} target="_blank" rel="noopener noreferrer">
          {values[0][0]}
        </a>
        {renderTag(e.degree)}
      </div>
    );
  }else{
    return 'NA';
  }

}

//This function Render Credit in the form of a Link when it's saved in DB in the following format:
//"[(Credit Name+https://www.credit.org/faq)+1]"
export function renderCTA(string, type) {
  
  const values = separateValues(string);
  
  //If exist at all
  if(values[0]){
  
    //If exist but empty
    if(values[0][0]){
      return (
    
        <button className={type + " entity_action_left"} href={values[0][1]} target="_blank">
          {values[0][0]}
        </button>
  
      );
    }
  }else{
    return 'NA';
  }
  
}


//This function returns a 'degree tag' based on the input 1-4
export function renderTag(degree){
  
  return(
    <span
      style={{margin:'0 10px'}}
      className={ClassNames('degree',
        {degree1 : degree === '1'},
        {degree2 : degree === '2'},
        {degree3 : degree === '3'},
        {degree4 : degree === '4'}
      )} >
                  {degree}°
    </span>
  );
}


//This function return date in a friendly format:

export function friendlyDate(date){
  
  const friendly = moment(parseInt(date, 0)).fromNow();
  
  return friendly;
  
}

//Create one crumb in the general Breadcrumb
export function renderCrumb(crumb){
  
  let size= '1em';
  switch(crumb.size){
    case 'L' : size='1.2em';
    break;
    case 'M' : size='1em';
    break;
    case 'S' : size='0.8em';
    break;
    default: size='1em';
  }
  
  
  return(
    <li>
          <span className={ClassNames('bp3-breadcrumb',{'bp3-breadcrumb-current':crumb.active})} >
            <span style={{opacity:'0.5'}}>
              {crumb.icon ?
                <Icon icon={crumb.icon} iconSize={18}/>
                :null
              }
            </span>
  
            {crumb.route ?
              <Link to={crumb.route} >
                <span style={{fontSize:size}}>{crumb.name}</span>
              </Link>
              :
              <Fragment><span style={{fontSize:size}}>{crumb.name}</span></Fragment>
            }
          </span>
    </li>
  );
}

