import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Popover, Icon} from "@blueprintjs/core";
import { Link } from "react-router-dom";
import './Layout.css';
import logo from './assets/logo.png'

import {
  setUser
} from '../../_actions';

class Header extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      embedConfig:{
        header:true,
        branded:true,
      },
      modals:{
        first:false
      }
    }
  
    this.loggedInMenu = this.loggedInMenu.bind(this);
    this.loggedOutMenu = this.loggedOutMenu.bind(this);
    this.updateState = this.updateState.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
  }
  
  componentDidMount() {
    if (this.props.match.params) {
      this.updateState(this.props);
    }
  }
  
  updateState(props){
    // PublicView Header Info change
    const { embedConfig} = this.state;
    
    if(props.match.params.config === 'embed'){
      embedConfig.header = false;
      embedConfig.branded = true;
    }
    
    this.setState({embedConfig});
  }
  
  loggedInMenu(){
    const createMenu = (
      <ul className="bp3-menu bp3-elevation-1">
        {/*<li>*/}
          {/*<Link className="bp3-menu-item bp3-icon-arrow-left" to={'/q'}>*/}
            {/*Explore All Questions*/}
          {/*</Link>*/}
        {/*</li>*/}
        <li>
          <Link className="bp3-menu-item bp3-icon-search" to={'/q/settings/'+this.props.user.cognitoId+'/channels/join'}>
            Explore Channels
          </Link>
        </li>
        <li>
          <Link className="bp3-menu-item bp3-icon-plus" to={'/q/newentity'}>
            Create Channels
          </Link>
        </li>
        <li>
          <Link className="bp3-menu-item bp3-icon-new-person bp3-disabled" to={'/q/entities'}>
            Invite Users
          </Link>
        </li>
      </ul>
    );
    
    const userMenu=(
      <ul className="bp3-menu  bp3-elevation-1">
        <li className="bp3-menu-divider"/>
        <li>
          <Link className="bp3-menu-item bp3-icon-user" to={'/q/user/'+this.props.user.cognitoId+'/profile'}>
            <span style={{fontWeight:'800'}}>{this.props.user.username}{this.props.user.status}</span>
          </Link>
        </li>
        <li className="bp3-menu-divider"/>
        <li>
          <Link className="bp3-menu-item " to={'/q/settings/'+this.props.user.cognitoId+'/edit'}>
            My Settings
          </Link>
        </li>
        {/*<li>*/}
          {/*<Link className="bp3-menu-item " to={'/q/settings/'+this.props.user.cognitoId+'/channels'}>*/}
            {/*My Channels*/}
          {/*</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
          {/*<Link className="bp3-menu-item " to={'/q/usertasks/'+this.props.user.cognitoId }>*/}
            {/*My Tasks*/}
          {/*</Link>*/}
        {/*</li>*/}
        <li className="bp3-menu-divider"/>
        <li>
          <Link className="bp3-menu-item bp3-icon-log-out bp3-intent-danger" to={'/signout'}>
            Log Out
          </Link>
        </li>
      </ul>
    )
    
    return(
      <div className="header-right">
        
        <Popover content={createMenu}>
          <button className="mobile_hide">
            <Icon icon="offline" iconSize={15} color="#CCC" />
          </button>
        </Popover>
      
        <Link to={'/q'}>
          <span className='mobile_hide' >{this.props.user.username}</span>
        </Link>
      
        <Popover content={userMenu}>
          <div>
            <Icon className='mobile_hide' icon="chevron-down" iconSize={12}/>
            <Icon className='mobile_show' icon="menu" iconSize={20} color="#b3b3b3" />
          </div>
        </Popover>
      </div>
    );
  }
  
  loggedOutMenu(){
    
    return(
      <div className="header-right">
        <Link to={'/q/entities'}>
             Sign In &nbsp;
            <Icon icon="log-in" iconSize={12} color="#fff" />
        </Link>
  
        {/*<div style={{backgroundColor:'yellow', position:'fixed', top:'100px', right:'30px', color:'black', padding:'20px'}}>*/}
          {/*<h5>username: test</h5>*/}
          {/*<h5>password: Happy123</h5>*/}
        {/*</div>*/}
      </div>
    );
  }
  
  
  renderMenu() {
    return (
      
      <div className="general_header">
        <div>
          { this.props.user.cognitoId ?
            
            <Link to="/q/entities" style={{color:'white'}}>
              <img style={{width:'75px', height:'auto', margin:'5px 20px 0px 20px', opacity:'0.5'}} src={logo} alt=""/>
            </Link>
            :
  
            <a href="/" style={{color:'white'}} >
              <img style={{width:'75px', height:'auto', margin:'5px 20px 0px 20px', opacity:'0.5'}} src={logo} alt=""/>
            </a>
          }
          
          
        </div>
        <div>
          
          { this.props.user.cognitoId ? this.loggedInMenu() : this.loggedOutMenu() }
        </div>
      </div>
      
    );
  }
  
  render(){
    return(
    <div>
    {this.state.embedConfig.header &&(
      this.renderMenu()
    )}
    </div>
    
    );
  }
  
}

Header.propTypes = {
  user: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.dataReducer.user
});

const mapDispatchToProps = dispatch => ({
  setUserData: value => dispatch(setUser(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
