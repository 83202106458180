import React, { Component } from 'react'
import { graphql, compose, withApollo } from "react-apollo"
import { Link } from "react-router-dom"
import { Row, Col } from 'react-flexbox-grid'
import { Icon, Intent, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core"
import {Pie } from '@nivo/pie'
import sortBy from "lodash/sortBy"
import gql from "graphql-tag"
import { Spring } from 'react-spring'
import { getEntity } from '../../../graphql/queries'
import { listQuestions } from '../../../graphql/queries';
import EntityHeader from "../_layout/EntityHeader"

class EntityDashboard extends Component {
  
  constructor(){
    super();
    
    this.state = {
      header:{
        title:'',
        displayName:'',
        counter:'1,092',
        action:'link',
        action_text:'Ask a question',
        action_icon:'add',
        action_route:'/q/create',
        entityLogo:''
      },
      payload:[],
      languages:[],
      pieLoad:[{
        "id": "loading",
        "label": "loading",
        "value": 50,
        "color": "hsl(20, 70%, 50%)"
      }],
      firstLang:0,
      secondLang:0,
      copySuccess: ''
    }
    
    
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps.entities && newProps.entities.id) {
      this.updateState(newProps);
    }
  }
  
  updateState(props){
    
    // Entity Results from AWS DynamoDB using graphql
    const { entities, listQuestions } = props;
    
    // Entity Header Info change
    const { header } = this.state;
    if(entities){
      header.title = entities.name;
      header.displayName = entities.displayName;
      header.entityId = entities.id;
      header.entityLogo = entities.logo;
    }
    
    let totalCount = 0;
    
    let payload = [];
    if(listQuestions) {
      payload = listQuestions.map(item => {
        
        return {
          entity: entities.name,
          entityId: entities.id,
          question: item.question,
          questionId: item.id,
          entityLogo: item.entityLogo,
          lang: item.lang,
          answerCount: '',
          newCount: '',
          author: item.author,
          authorId: item.authorId
        };
        
      });
      header.counter = listQuestions.length;
    }
    
    //LANGUAGE COUNT:::::::::::::
    
    function usedLanguages(array) {
      let arr = array;
      let a = [], b = [], prev;
      
      arr = sortBy(arr, 'lang');
      for ( let i = 0; i < arr.length; i++ ) {
        if ( arr[i].lang !== prev ) {
          a.push(arr[i].lang);
          b.push(1);
        } else {
          b[b.length-1]++;
        }
        prev = arr[i].lang;
      }
      return [a, b];
      
    }
  
    let langs=null;
    let langCount=null;
    
    if(listQuestions) {
      langs = usedLanguages(listQuestions);
    }
    
    //TODO: This need to be improved, like this we can only handle 2 langs (ENG, SPA)
    
    if(langs){
      langCount =[
        {
          "language": "LAN",
          "eng" : langs[1][0],
          "spa" : langs[1][1]
        }];
    }
    
    
    //DEGREE COUNT:::::::
    
    let d1Count = 0;
    let d2Count = 0;
    let d3Count = 0;
    let d4Count = 0;
    
    if(listQuestions) {
      listQuestions.map(item => {
        
        totalCount = totalCount + item.count;
        d1Count = d1Count + item.d1;
        d2Count = d2Count + item.d2;
        d3Count = d3Count + item.d3;
        d4Count = d4Count + item.d4;
        return('');
        
      });
      
    }
    
    const pieLoad = [
      {
        id:'owners',
        label:'1st Degree',
        value: d1Count,
        "color": "#0285f2"
      },
      {
        id:'experts',
        label:'2nd Degree',
        value: d2Count,
        "color": "#9057a3"
      },
      {
        id:'public',
        label:'3rd Degree',
        value: d3Count,
        "color": "#ef7d02"
      },
      {
        id:'unknown',
        label:'4th Degree',
        value: d4Count,
        color:"#edcb02"
      },
    ];
    
    
    //STATE UPDATE :::::::::::::
  
    if(langs){
      this.setState({
        payload: payload,
        header,
        totalCount: totalCount,
        d1Count: d1Count,
        d2Count: d2Count,
        d3Count: d3Count,
        d4Count: d4Count,
        pieLoad: pieLoad,
        languages: langCount,
        firstLang:langs[1][0],
        secondLang:langs[1][1]
      });
    }
    
  }
  
  renderScore(){
    
    //A.Questions
    let scoreA = (this.state.payload.length >= 100 ? 100 : this.state.payload.length);
    //B.Answers
    let scoreB = (this.state.totalCount >=300 ? 100 : this.state.totalCount /3);
    //C.Languages
    let L1= (this.state.firstLang ? this.state.firstLang : 0);
    let L2= (this.state.secondLang ? this.state.secondLang : 0);
    let s= (L2 * 200) / L1;
    let scoreC = (s >=100 ? 100 : s );
    //let scoreC=0;
    //D.Usage
    let scoreD = 10;
    //E.Community
    let scoreE = 10;
    
    let score = Math.round((scoreA + scoreB + scoreC + scoreD + scoreE )/5)*10 ;
    
    // console.log('A:'+scoreA);
    // console.log('B:'+scoreB);
    // console.log('C:'+scoreC);
    // console.log('D:'+scoreD);
    // console.log('E:'+scoreE);
    // console.log('SCORE:'+score);
    
    return (String(score));
    
  }
  
  renderPie(){
    
    return(
      <Pie
        width={300}
        height={190}
        data={this.state.pieLoad}
        margin={{
          "top": 20,
          "right": 70,
          "bottom": 0,
          "left": 70
        }}
        innerRadius={0.8}
        padAngle={0.7}
        cornerRadius={3}
        colors={this.state.pieLoad}
        colorBy={d => d.color}
        borderWidth={0}
        borderColor="inherit:darker(0.2)"
        radialLabel="value"
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="inherit"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor="inherit"
        enableSlicesLabels={false}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    );
  }
  
  
  renderLangPercent(){
    
    const total = this.state.payload.length;
    const first = this.state.firstLang;
    const second = total - first;
    
    const firstPercent = Math.round((first*100) / total);
    const secondPercent = Math.round((second*100) / total);
    
    
    return(
      <div>
        <p style={{textAlign:'left', color:'#868686'}}>{firstPercent}% english</p>
        <p style={{textAlign:'left', color:'#868686'}}>{secondPercent}% other</p>
      </div>
    
    );
  }
  
  renderTag(text){
    return(
      <span data-tag-index="0" className="bp3-tag bp3-minimal">
        <span className="bp3-text-overflow-ellipsis bp3-fill">
          {text}
        </span>
        <button disabled type="button" className="bp3-tag-remove">
          <span className="bp3-icon" icon="">
            <svg data-icon="small-cross" width="16" height="16" viewBox="0 0 16 16">
              <desc>small-cross</desc>
              <path d="M9.41 8l2.29-2.29c.19-.18.3-.43.3-.71a1.003 1.003 0 0 0-1.71-.71L8 6.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42L6.59 8 4.3 10.29c-.19.18-.3.43-.3.71a1.003 1.003 0 0 0 1.71.71L8 9.41l2.29 2.29c.18.19.43.3.71.3a1.003 1.003 0 0 0 .71-1.71L9.41 8z">
              
              </path>
            </svg>
          </span>
        </button>
      </span>
    
    );
    
  }
  
  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    
    e.target.focus();
    this.setState({ copySuccess: 'Copied!' });
  };
  
  renderItems() {
    
    const entityId = this.state.header.entityId;
    const logo = this.state.header.entityLogo;
    const name = this.state.header.title;
    const displayName = this.state.header.displayName;
    
    return (
      <div style={{minHeight:'600px'}}>
        
        <EntityHeader entityId={entityId} logo={logo} name={displayName} type="dashboard"/>
        
        <Row>
          <Col xs={12} md={8}>
            
            <Row>
              <Col xs={6}>
                <Link to={'/q/entity/'+entityId}>
                  <div className="dashboard_card margin_right">
                    
                    <h4>Questions</h4>
                    <Spring
                      from={{ number: 0 }}
                      to={{ number: this.state.payload.length }}>
                      {props => <h3 className="kpi_number">{Math.round(props.number)}</h3>}
                    </Spring>
                  </div>
                </Link>
                
              </Col>
              <Col xs={6}>
                <div className="dashboard_card margin_no">
                  <h4>Answers</h4>
                  <Spring
                    from={{ number: 0 }}
                    to={{ number: this.state.totalCount }}
                  >
                    {props => <h3 className="kpi_number">{Math.round(props.number)}</h3>}
                  </Spring>
                </div>
              </Col>
            </Row>
              
            <Row>
              <Col xs={12} md={12}>
                <div className="dashboard_card margin_top" style={{ minHeight:'401px'}}>
                  <h4>
                    <div>VEO Score</div>
                    <Popover
                      interactionKind={PopoverInteractionKind.CLICK}
                      popoverClassName="bp3-popover-content-sizing"
                      position={Position.RIGHT}
                    >
                      <Icon icon="issue" iconSize={12} intent={Intent.PRIMARY}/>
                      <div>
                        <p>
                          Data scores will vary for several reasons,
                          including the amount of Questions and Answers,
                          the percent of Owners and Experts,
                          and the translations available.
                        </p>
                        <button className="bp3-popover-dismiss">
                          <Icon icon="cross" iconSize={12} intent={Intent.PRIMARY}/>
                        </button>
                      </div>
                    </Popover>
                  </h4>
                  {/*<Link to={'/'} className="addmore">*/}
                  {/*<Icon icon="issue" iconSize={12} />*/}
                  {/*</Link>*/}
  
                  <div className="float_box">
                    <div className="float_score">
                      <h5 style={{color:'#848484'}}>{name}</h5>
                      <h2>{this.renderScore()}</h2>
                      <h5>Data Score</h5>
    
                    </div>
    
                    <div className="float_graph">
                      {this.renderPie()}
                    </div>
                  </div>
                  <div className="keys">
                    <span>Owner</span>
                    <span className="degree degree1">
                    {this.state.d1Count}
                    </span>
                    <span>Expert</span>
                    <span className="degree degree2">
                    {this.state.d2Count}
                    </span>
                    <span>Public</span>
                    <span className="degree degree3">
                    {this.state.d3Count}
                    </span>
                    <span>Unknown</span>
                    <span className="degree degree4">
                    {this.state.d4Count}
                    </span>
                  </div>
                </div>
                
              </Col>
            </Row>
          </Col>
  
          <Col xs={12} md={4} className="dashboard_card margin_left">
  
            <div className="spaced_vertical">
              <ul className="bp3-menu">
                <li>
                  <Link to={"/questions/"+name+"/"+entityId } className="bp3-menu-item bp3-icon-arrow-left">
                    Go to Public FAQ
                  </Link>
                </li>
                <li>
                  <Link to={'/q/entity/'+entityId } className="bp3-menu-item bp3-icon-property">
                    See all questions
                  </Link>
                </li>
                <li>
                  <Link to={'/q/entity/'+entityId+'/ask/'+name } className="bp3-menu-item bp3-icon-plus">
                    New Question
                  </Link>
                </li>
                <li>
                  <Link to={'/q/entity/'+entityId+'/newtask' } className="bp3-menu-item bp3-icon-insert">
                    New Task
                  </Link>
                </li>
                <li>
                  <Link to={'/q/entity/'+entityId+'/edit' } className="bp3-menu-item bp3-icon-edit">
                    Edit info
                  </Link>
                </li>
                {
                  document.queryCommandSupported('copy') &&
                  <li>
                    <button className="bp3-menu-item bp3-icon-clipboard" onClick={this.copyToClipboard}>
                      Copy invite link
                    </button>
                    {this.state.copySuccess}
                  </li>
                }
                
              </ul>
              
              {/*<div className="dashboard_card margin_no">*/}
                {/*<h4>Invite Link</h4>*/}
                {/*<form>*/}
                    {/*<textarea*/}
                      {/*className="embed_box"*/}
                      {/*ref={(textarea) => this.textArea = textarea}*/}
                      {/*value={ "/join/"+entityId }*/}
                      {/*style={{width:'100%'}}*/}
                      {/*readOnly={true}*/}
                    {/*/>*/}
                {/*</form>*/}
                {/**/}
              {/*</div>*/}
            </div>
          </Col>
        </Row>
      
      </div>
    
    );
  }
  
  
  render(){
    
    return (
      <div>
        <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
          {props => <div style={props}>{this.renderItems()}</div>}
        </Spring>
      
      </div>
    );
    
  }
}

export default withApollo(compose(
  
  graphql(
    gql(getEntity),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.entityId, entityId: props.match.params.entityId}
      }),
      props: ({ data: { getEntity = {}}}) => ({
        entities: getEntity
      })
    }
  ),
  graphql(
    gql(listQuestions),
    {
      options: (props) => ({
        variables: {
          limit:1000,
          filter:{entityId:{eq: props.match.params.entityId}}
        }
      }),
      props: ({ data: { listQuestions = { items: [] } } }) => ({
        listQuestions: listQuestions.items
      })
    }
  )
)(EntityDashboard));
