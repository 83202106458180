import React, {Component} from 'react'
import gql from "graphql-tag";
import {graphql, compose, withApollo} from 'react-apollo'
import {withRouter} from 'react-router';
import {Grid, Row, Col} from 'react-flexbox-grid'
import {Checkbox} from "@blueprintjs/core"
import {Spring} from 'react-spring'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import { createUser } from '../../../graphql/mutations';
import { getEntity } from '../../../graphql/queries';
import logo from '../../_layout/assets/logo.png'
import visual from '../../_layout/assets/question.png'
import sidebar from '../../_layout/assets/example_sidebar.png'

import {
  setHeader
} from '../../../_actions';

class NewUserRegistration extends Component {
  
  constructor() {
    super();
    this.state = {
      header: {
        title: '', //Optional
        qid: '', //dynamically generated // Empty will show TBD
        status: '', // 1 | 2 | 3 | 4 | 5 | 6 (need to be INT)
        action: 'link', // link | button
        action_text: 'Back', //Text inside button
        action_icon: 'arrow-left', //icon inside button
        action_route: '/q', //Button route
        banner_color: 'new' //new | answer | edit | review | delete
      },
      record: {
        cognitoId: 'abc',
        id: 'xyz',
        username: '',
        email: '-',
        entities: "[(7e9a254a-b98d-4e32-8a53-2423ab58008f+Greenpeace+https://s3.amazonaws.com/f400-avatar-hosting-09272018/public/greenpeace.png+3)]",
        about: 'text about me',
        answered: '0',
        asked: '0',
        lang: 'ENG',
        status:'110',
        usingDefault:false,
      },
      showDetails: false,
      isEnabled: true,
      ready:false
      
    }
    
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }
  
  // componentWillMount = () => {
  //   const { setHeaderData } = this.props;
  //   setHeaderData(this.state.header);
  //
  //   this.updateState();
  // }
  
  componentWillReceiveProps(newProps) {
    const {setHeaderData} = this.props;
    setHeaderData(this.state.header);
    
    if (newProps.entity && newProps.entity.id) {
      this.updateState(newProps);
    }
  }
  componentDidMount(){
    this.updateState(this.props);
  }
  
  async updateState(props) {
    const {header, record} = this.state;
    header.qid = this.props.authUser.id;
    record.cognitoId = this.props.authUser.id;
    record.id = this.props.authUser.id;
    record.email = this.props.authUser.email;
    record.username = this.props.authUser.username;
    if (props.entity) {
      if(props.entity.id){
        record.entities = "[(" + props.entity.id + "+" + props.entity.name + "+" + props.entity.logo + "+3)]";
      }else{
        record.usingDefault= true;
      }
    }
  
  }
  
  handleValues = field => event => {
    const {record} = this.state;
    record[`${field}`] = event.target.value;
    this.setState({record});
  }
  
  // handleCreateOriginal = () => {
  //   // aws
  //   const {history, createUser} = this.props;
  //   const {record} = this.state;
  //
  //   createUser(record);
  //
  //   console.log('handleCreate')
  //
  //   window.location.reload();
  //   history.push("/q/hard");
  // }
  
  handleCreate = () => {
    // aws
    const {createUser } = this.props;
    const { record } = this.state;
    
    createUser(record);
    console.log(record);
  
    this.setState({ready:true});
    
  }
  
  handleRedirect = () => {
    // aws
    const { history} = this.props;
  
    window.location.reload();
    history.push("/q/users");
    
  }
  
  
  handleCancel = () => {
    const {history} = this.props;
    history.goBack();
  }
  
  
  handleCheckboxChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    
    
    this.setState({
      isEnabled: value
    });
  }
  
  
  renderWelcome() {
    const {
      username
    } = this.state.record;
    
    return (
      <div className="editor_form welcome_screen" style={{justifyContent:'center'}}>
        <img style={{width: '100px', height: 'auto', position: 'fixed', top: '20px', right: '20px'}}
             src={logo} alt="F400"/>
        
        <div style={{zIndex: "10", width: '100%'}}>
          
          <Grid fluid>
            <Row center="xs">
              <Col xs={8}>
                <div>
                  <h1>
                    <span>w</span>
                    <span>e</span>
                    <span>l</span>
                    <span>c</span>
                    <span>o</span>
                    <span>m</span>
                    <span>e</span>
                  </h1>
                  
                  <h1 style={{marginBottom: '40px', color: '#694f85'}}>{username}</h1>
                  
                  <Spring from={{opacity: 0}}
                          to={{opacity: 1}}
                          config={{delay: 2000}}
                  >
                    {props => <div style={props}>
                      <h4 style={{color: '#adadad'}}>Your page is ready</h4>
                    </div>
                    }
                  </Spring>
                </div>
                <div>
                  <Spring from={{opacity: 0}}
                          to={{opacity: 1}}
                          config={{delay: 1500}}
                  >
                    {props =>
                      <div style={props}>
                        {this.state.isEnabled && (
                          <button onClick={this.handleCreate}>Next</button>)}
                        
                        <Checkbox checked={this.state.isEnabled}
                                  label={<span>Accept TOC</span>}
                                  onChange={this.handleCheckboxChange}
                                  style={{paddingTop: '20px'}}/>
                        
                      </div>
                    }
                  </Spring>
                </div>
              
              </Col>
            
            </Row>
          
          </Grid>
        
        
        </div>
        
        <img style={{width: '600px', height: 'auto', position: 'fixed', right: '-200px',}}
             src={visual} alt=""/>
      
      </div>
    
    );
  }
  
  renderGo(){
    
    return(
      <div className="editor_form welcome_screen" style={{display:'inherit'}}>
        <img style={{width: '100px', height: 'auto', position: 'fixed', top: '20px', right: '20px'}}
             src={logo} alt="F400"/>
    
        <div style={{zIndex: "10", width: '100%'}}>
      
          <Grid>
            <Row>
              <Col xs={12}>
                <img style={{width: '370px'}}
                     src={sidebar} alt=""/>
              </Col>
            </Row>
          </Grid>
          <Grid fluid>
            <Row center="xs">
              <Col xs={8}>
  
                {this.state.record.usingDefault &&
                <p style={{color:'#bcbcbc', marginBottom:'20px'}}>(We've added 'Greenpeace' as a sample channel)</p>
                }
                
                <a className="bp3-button bp3-intent-success bp3-large bp3-fill" href={`/q/settings/${this.props.authUser.id}/channels`}>
                  Ok Gotcha
                </a>
                
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    )
  }
  
  render(){
    
    if(!this.state.ready) {
      return (
        this.renderWelcome()
      )
    }else{
      return(
        this.renderGo()
      );
    }
    
  }
  
}

NewUserRegistration.propTypes = {
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setHeaderData: value => dispatch(setHeader(value)),
});

const UserCreateConnected = connect(mapStateToProps, mapDispatchToProps)(NewUserRegistration);

export default withRouter(withApollo(compose(
  graphql(
    gql(createUser),
    {
      props: ({mutate}) => ({
        createUser: ({cognitoId, id, username, email, about, asked, answered, lang, entities, status}) => {
          mutate({
            variables: {
              input: {
                cognitoId,
                id,
                username,
                email,
                about,
                asked,
                answered,
                lang,
                entities,
                status
              }
            },
            refetchQueries:[`getUser`]
          })
        }
      })
    }
  ),
  graphql(
    gql(getEntity),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.entityId, entityId: props.match.params.entityId}
      }),
      props: ({data: {getEntity = {}}}) => ({
        entity: getEntity
      })
    }
  ),
)(UserCreateConnected)));



