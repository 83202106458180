import gql from "graphql-tag";

//todo: add question, entity, entityId to Schema
export default gql(`
query getAnswer($id:ID!){
    getAnswer(id:$id) {
        id
        answer
        author
        authorId
        questionId
        lang
        degree
        rating
        credit
        createdAt
        modifiedAt
        bounty
    }
}`);