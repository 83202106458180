import React, { Component } from 'react'
import SettingsWrapper from "../_layout/SettingsWrapper"

class PaymentConfirm extends Component {
  constructor(){
    super();
    this.state = {
      header: {
        counter: '',
        action: 'button',
        action_text: 'Settings',
        action_icon: 'arrow-left',
        action_route: '/q/settings',
        banner_color:'expanded',
        breadcrumb:[
          {
            name:'',
            route:'',
            icon:'cog',
            active: false
          },{
            name:'Settings',
            active: true,
            size:'L'
          },{
            name:'',
            size:'L'
          }
        ],
      }
    }
  }

  componentDidMount(){
    const url_string = window.location.href
    const url = new URL(url_string)
    const code = url.searchParams.get('code')
    const userId = url.searchParams.get('state')
    const host = window.location.host
    const endpoint = this.state.header.action_route
    window.location.href = `https://${host}${endpoint}/${userId}/connect/?code=${code}`;
  }

  render(){
    return(
      <SettingsWrapper user={this.props.db_user.id} header={this.state.header} activeTab="start">
        <p>This will redirect you to payment page soon. Wait a minute</p>
      </SettingsWrapper>
    )
  }
}

export default PaymentConfirm