/*
 * Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

export const Container = {};
export const FormContainer = {
  backgroundColor:'transparent',
  boxShadow:'none',
  width: '100%',
  fontFamily:'Nunito',
  padding:'0 20px',
  minWidth:'300px',
  
};
export const FormSection = {
  backgroundColor:'transparent',
  boxShadow: 'none',
  padding:'40px 0'

};
export const FormField = {
  
};
export const SectionHeader = {
  color:'#CCC',
  fontSize:'2em',
  fontWeight: '600',
  //display:'none',
  // marginBottom: '24px',
  // fontSize: '15px',
  // padding: '45px 20px 20px 20px',
  // position: 'fixed',
  // backgroundColor: '#1b1c1d',
  // width: '100%',
  // top: '0',
  // left: '0',
};
export const SectionBody = {};
export const SectionFooter = {};
export const SectionFooterPrimaryContent = {};
export const SectionFooterSecondaryContent = {};
export const Input = {
  backgroundColor:'transparent',
  borderWidth:0,
  boxShadow: '-1px 1px #CCC',
  borderRadius:'0',
  color:'#5900ff',
  marginBottom:'20px'

};
export const Button = {
  backgroundColor:'#5900ff',
  boxShadow:'0 0 25px #b3b3b3',
  padding: '14px 20px'
};
export const PhotoPickerButton = {};
export const PhotoPlaceholder = {};
export const SignInButton = {};
export const SignInButtonIcon = {};
export const SignInButtonContent = {};
export const Strike = {};
export const StrikeContent = {};
export const ActionRow = {};
export const FormRow = {};
export const A = {
  color:'#629eff'
};
export const Hint = {};
export const Radio = {};
export const InputLabel = {
  fontSize: '0.8em',
  color: '#CCC',
  fontWeight: '600',
  paddingTop: '10px'
};
export const AmazonSignInButton = {};
export const FacebookSignInButton = {};
export const GoogleSignInButton = {};
export const OAuthSignInButton = {};
export const Toast = {};
export const NavBar = {display:'none'};
export const NavRight = {};
export const Nav = {};
export const NavItem = {};
export const NavButton = {};

const AmplifyTheme = {
  container: Container,
  formContainer: FormContainer,
  formSection: FormSection,
  formField: FormField,
  
  sectionHeader: SectionHeader,
  sectionBody: SectionBody,
  sectionFooter: SectionFooter,
  sectionFooterPrimaryContent: SectionFooterPrimaryContent,
  sectionFooterSecondaryContent: SectionFooterSecondaryContent,
  
  input: Input,
  button: Button,
  photoPickerButton: PhotoPickerButton,
  photoPlaceholder: PhotoPlaceholder,
  signInButton: SignInButton,
  signInButtonIcon: SignInButtonIcon,
  signInButtonContent: SignInButtonContent,
  amazonSignInButton: AmazonSignInButton,
  facebookSignInButton: FacebookSignInButton,
  googleSignInButton: GoogleSignInButton,
  oAuthSignInButton: OAuthSignInButton,
  
  formRow: FormRow,
  strike: Strike,
  strikeContent: StrikeContent,
  actionRow: ActionRow,
  a: A,
  
  hint: Hint,
  radio: Radio,
  inputLabel: InputLabel,
  toast: Toast,
  
  navBar: NavBar,
  nav: Nav,
  navRight: NavRight,
  navItem: NavItem,
  navButton: NavButton
};

export default AmplifyTheme;