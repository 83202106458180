import React, { Component } from "react"
import { graphql, compose, withApollo } from "react-apollo"
import gql from "graphql-tag"
import ResultHeader from "../_layout/ResultHeader"
import TaskTable from '../editor_tasks/components/TaskTable'


const userTasks = `
query listTasks($id:String!, $limit:Int){
  listTasks(filter: { createdBy: {eq: $id}}, limit:$limit ){
    items{
      id
      name
      where
      when
      description
      entity
      bounty
      createdAt
      modifiedAt
      status
      count
      createdBy
      assignedTo
      transaction
    }
  }
}`;

class ListTasks extends Component {
  
  state = {
    header:{
      title:'Posted Jobs',
      counter:'0',
      action:'link',
      action_text:'See all tasks',
      action_icon:'arrow-left',
      action_route:'/q/tasks',
      entityLogo:'',
      entityId:'',
      filter:''
    },
    tasks:[],
    busy: false,
    
  }
  
  static defaultProps = {
    events: [],
    deleteEvent: () => null,
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.tasks) {
      this.updateState(newProps);
    }
  }
  
  updateState(props){
    const { header} =  this.state
    const tasks = (props.tasks ? props.tasks : '')
    header.counter =  (props.tasks ? props.tasks.length: '');
    header.title = (props.db_user ? props.db_user.username :'user');
    header.filter = (props.match ? props.match.params.filter :'');
    
    this.setState({ header, tasks:tasks });
  }
  
  render() {

    return (
      <div>
        <div>
          {this.state.header && <ResultHeader category="Posted" header={this.state.header} />}
          <div style={{backgroundColor:'yellow'}}>{this.state.header.filter}</div>
        </div>
        <div>
          <TaskTable tasks={this.state.tasks} busy={this.state.busy} userId={this.props.db_user.id}> Something</TaskTable>
        </div>
      </div>
    );
  }
  
}

export default withApollo(compose(
  graphql(
    gql(userTasks),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.userId, limit:1000}
      }),
      props: ({ data: { listTasks = { items: [] } } }) => ({
        tasks: listTasks.items
      })
    }
  ),
)(ListTasks));
