import React, { Component } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Collapse, Card} from "@blueprintjs/core";
import { withRouter } from 'react-router';
import kebabCase from "lodash/kebabCase"
import {Auth} from 'aws-amplify';
import { Spring } from 'react-spring'
import gql from "graphql-tag";
import { getEntity} from '../../graphql/queries';
import { createQuestion} from '../../graphql/mutations';

class QuestionCreate extends Component {
  
  constructor() {
    super();
    this.state = {
      header: {
        title: '',
        displayName: '',
        counter:'',
        entityLogo:''
      },
      record: {
        question: '',
        author: 'Unknown user',
        authorId: '100',
        entity: 'Entity',
        entityId: 'entityId',
        entityLogo: null,
        lang: 'ENG',
        status: 1,
        rated: 'G',
        createdAt: ''
      },
      entities:'',
      selectedOption: null,
      showDetails:false
      
    }
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps.entity && newProps.entity.id) {
      this.updateState(newProps);
    }
  }
  
  
  updateState(props){
    
    // PublicView Results from AWS DynamoDB using graphql
    const { entity } = props;
    
    // PublicView Header Info change
    const { header } = this.state;
    header.title = (entity? entity.name: 'No PublicView');
    header.entityLogo = (entity? entity.logo: 'No_Logo');
    header.displayName = (entity? entity.displayName: 'No Name');
    header.id = (entity? entity.id: '');
  
    const { record } = this.state;
    record.entity = (entity? entity.name: 'No PublicView');
    record.entityId = (entity? entity.id: 'No EntityId');
    
    //updated from FAQ page
    record.question = props.question;
    
    this.setState({header, record});
  }
  
  
  async componentDidMount() {

    // get user
    let user = await Auth.currentAuthenticatedUser();
    
    //updating  user info
    const { record } = this.state;
    record['author']= user.username;
    record['authorId']= user.attributes.sub;
    
    this.setState({ record });
    
  }
  
  handleValues = field => event => {
    const { record } = this.state;
    record[`${field}`] = event.target.value;
    this.setState({ record });

  };
  
  
  handleCreate = () => {
    // aws
    const { history, createQuestion } = this.props
    const { record } = this.state;
    const currentDate = Date.now();
    const slug = kebabCase(this.state.record.entity);
    record.createdAt = currentDate.toString();
    console.log(record);
    createQuestion(record);
    history.push("/questions/"+slug+"/"+this.state.record.entityId+"/analytics");
  };
  
  handleDetailClick = () => {
    this.setState({ showDetails: !this.state.showDetails });
  }
  
  renderForm() {
    const { author, lang, status, rated } = this.state.record;
    
    return (
        <Grid>
          <Row>
            <Col xs={12} md={12} style={{color:'grey'}}>
              <Card>
          
                <div className="bp3-input-group" style={{padding:'30px'}}>
                  <span>Your question:</span>
                  {/*<input type="text" className="bp3-input bp3-round bp3-fill" placeholder={"Your question here "} value={question} onChange={this.handleValues('question')} style={{ padding: "20px 40px"}} />*/}
                  <h2 style={{margin: '20px'}}>{this.props.question}</h2>
                </div>
  
                {/*TODO: ONCE USER IS RECOGNIZED HERE, ADD QUESTION DETAILS TO REGISTERED USERS*/}
                {/*<button onClick={this.handleDetailClick}*/}
                        {/*type="button"*/}
                        {/*//className="bp3-button bp3-icon-small-plus bp3-minimal"*/}
                        {/*className={ classNames('bp3-button bp3-minimal',*/}
                          {/*{ 'bp3-icon-small-plus' : !this.state.showDetails },*/}
                          {/*{ 'bp3-icon-small-minus' : this.state.showDetails }*/}
                          {/*) }*/}
                        {/*style={{ opacity: '1', fontSize: '0.8em'}}*/}
                {/*>*/}
                  {/*{this.state.showDetails ? "Hide" : "Show"} Details*/}
                {/*</button>*/}
                
                <button type="button"
                        onClick={this.handleCreate}
                        className="bp3-button bp3-icon-arrow-right "
                >Submit question
                </button>
                

                
              </Card>
              
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} style={{paddingTop:'40px', textAlign:'left'}}>
  
              <Collapse isOpen={this.state.showDetails}>
                <div className="workarea editor_form">
                  <table>
                    <tbody>
                    <tr>
                      <td width="100px">
                        Author
                      </td>
                      <td>
                        {author}
                      </td>
                    </tr>
                    <tr>
                      <td width="100px">
                        Language
                      </td>
                      <td>
                        <div className="bp3-select">
                          <select className="" value={lang} onChange={this.handleValues('lang')}>
                            <option selected>Choose a language...</option>
                            <option value="ENG">English</option>
                            <option value="SPA">Spanish</option>
                            <option value="POR">Portuguese</option>
                            <option value="MAN">Mandarin</option>
                            <option value="GER">German</option>
                            <option value="JAP">Japanese</option>
                            <option value="FRA">France</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="100px">
                        Status
                      </td>
                      <td>
                        <div className="bp3-select .modifier">
                          <select className="" value={status} onChange={this.handleValues('status')}>
                            <option selected>Choose a status...</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="100px">
                        Rated
                      </td>
                      <td>
                        <div className="bp3-select .modifier">
                          <select className="" value={rated} onChange={this.handleValues('rated')}>
                            <option selected>Choose a Rate...</option>
                            <option value="G">G</option>
                            <option value="PG">PG</option>
                            <option value="PG-13">PG-13</option>
                            <option value="R">R</option>
                            <option value="NC-17">NC-17</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
    
                </div>
              </Collapse>
              
            </Col>
          </Row>
        </Grid>
      
    );
  }
  
  render(){
    
    return(
      <div>
        <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
          {props => <div style={props}>{this.renderForm()}</div>}
        </Spring>
      </div>
    );
  }
  
}

export default withRouter(withApollo(compose(
  
  graphql(
    gql(createQuestion),
    {
      props: ({ mutate }) => ({
        createQuestion: ({ question, author, authorId, entity, entityId, entityLogo, lang, status, rated, createdAt }) => {
          console.log({ question, author, authorId, entity, entityId, entityLogo, lang, status, rated, createdAt });
          mutate({
            variables: {
              input: {
                question,
                author,
                authorId,
                entity,
                entityId,
                entityLogo,
                lang,
                status,
                rated,
                createdAt
              }
            }
          })
        }
      })
    }
  ),
  graphql(
    gql(getEntity),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.entityId, entityId: props.match.params.entityId}
      }),
      props: ({ data: { getEntity = {}}}) => ({
        entity: getEntity
      })
    }
  )
)(QuestionCreate)));
