import React, { Component } from "react"
import { Link } from "react-router-dom"
import { graphql, compose, withApollo } from "react-apollo"
import {Icon, Tooltip, Position} from "@blueprintjs/core"
import matchSorter from 'match-sorter'
import ReactTable from "react-table"
import gql from "graphql-tag"
import moment from "moment"
import LinkIcon from "../../../_utils/LinkIcon"
import ShowStatus from "../../../_utils/ShowStatus"
import { deleteTask } from '../../../../graphql/mutations'
import { listTasks as userTasks } from '../../../../graphql/queries'

class ListTasks extends Component {
  
  state = {
    header:{
      title:'Posted Jobs',
      counter:'0',
      action:'link',
      action_text:'See all tasks',
      action_icon:'arrow-left',
      action_route:'/q/tasks',
      entityLogo:'',
      entityId:'',
      filter:''
    },
    tasks:[],
    busy: false,
    
  }
  
  static defaultProps = {
    events: [],
    deleteEvent: () => null,
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.tasks) {
      this.updateState(newProps);
    }
  }
  
  updateState(props){
    const { header} =  this.state
    const tasks = (props.tasks ? props.tasks : '')
    header.counter =  (props.tasks ? props.tasks.length: '');
    header.title = (props.db_user ? props.db_user.username :'user');
    header.filter = (props.match ? props.match.params.filter :'');
    
    this.setState({ header, tasks:tasks });
  }
  
  async handleDeleteClick(task, e) {
    e.preventDefault();
    
    if (window.confirm(`Are you sure you want to delete event ${task.id}`)) {
      const { deleteTask } = this.props;
      
      await deleteTask(task);
    }
  }
  
  render() {
    
    const { tasks } = this.state;
    
    return(
      <div>
        <ReactTable
          data={tasks}
          noDataText="No Tasks here!"
          filterable
          defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value}
          columns={[{
            columns: [
              {
                Header: 'editor_tasks',
                id: "name",
                accessor: d => d.name,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {keys: ["name"]}),
                filterAll: true,
                Cell: row =>(
                  <div>
                    <LinkIcon type={'link'}
                              text={row.value}
                              route={"/q/task/"+row.original.id}
                    />
                    {row.original.createdBy === this.props.userId ? <span className="bp3-tag bp3-round bp3-intent-danger">Created by you</span> :null }
                  </div>
                )
              },{
                Header:'Assigned',
                accessor:'createdBy',
                Filter: '-',
                maxWidth: 120,
                Cell: row => {
                  if(row.original.assignedTo === this.props.userId){
                    return(
                      <span className="bp3-tag bp3-round bp3-intent-success"> Assigned to you </span>
                    )
                  }else if(row.original.assignedTo){
                    return(
                      <span className="bp3-tag bp3-round"> Assigned </span>
                    )
                  }else{
                    return(<div>Open</div>)
                  }
                }
              },{
                Header:'Entity',
                accessor:'entity',
                Filter: '-',
                maxWidth: 75
              },
              {
                Header:'Bounty',
                accessor:'bounty',
                maxWidth: 50,
                Filter: '-',
                Cell: row =>(
                  <div>
                    ${row.value}
                  </div>
                )
              },
              {
                Header:'Funded',
                accessor:'transaction',
                maxWidth: 50,
                Filter: '-',
                Cell: row =>(
                  <div>
                    {row.original.transaction ?
                      <Link to={"/q/task/"+row.original.id+"/payment"}>
                        <Icon icon="endorsed" style={{color:'#8bc34a'}} iconSize={13}/>
                      </Link>
                      :
                      <Link to={"/q/task/"+row.original.id+"/payment"}>
                        <Icon icon="circle" style={{color:'#CCC'}} iconSize={13}/>
                      </Link>
                    }
                  </div>
                )
              },
              {
                Header:'Due',
                accessor:'when',
                Filter: '-',
                maxWidth: 50,
                Cell: row =>(
                  <div>
                    {moment(row.value).format('MM-DD')}
                  </div>
                )
              },
              {
                Header:'Status',
                accessor:'status',
                maxWidth:70,
                Filter: '-',
                Cell: row =>(
                  <div>
                    {
                      ShowStatus(row.value, true)
                    }
                  </div>
                )
              },
              {
                Header:'Actions',
                accessor:'id',
                maxWidth: 60,
                Cell: row => (
                  <React.Fragment>
                    {row.original.createdBy === this.props.userId &&
                      <div className="actions_column">
                        <Tooltip content="Edit Task" position={Position.LEFT}>
                          <Link to={"/q/task/"+row.original.id+"/edit"}>
                            <Icon icon="edit" iconSize={11}/>
                          </Link>
                        </Tooltip>
                        <Tooltip content="Delete Task" position={Position.LEFT}>
                          <button onClick={this.handleDeleteClick.bind(this, row.original)}>
                            <Icon icon="cross" iconSize={11} />
                          </button>
                        </Tooltip>
                      </div>
                    }
                  </React.Fragment>
                ),
              }
            ]
          }]}
          defaultPageSize={20}
          className=" -highlight"
        />
      </div>
    );
    
  }
  
}

export default withApollo(compose(
  graphql(
    gql(deleteTask),
    {
      options: {
        update: (proxy, { data: { deleteTask } }) => {
          const query = gql(userTasks);
          const data = proxy.readQuery({ query });
          
          data.listTasks.items = data.listTasks.items.filter(task => task.id !== deleteTask.id);
          
          proxy.writeQuery({ query, data });
        }
      },
      props: (props) => ({
        deleteTask: (task) => {
          return props.mutate({
            variables: { input:{id: task.id} },
            optimisticResponse: () => ({
              deleteTask: {
                ...task, __typename: 'Task', comments: { __typename: 'CommentConnection', items: [] }
              }
            }),
          });
        }
      })
    }
  )
)(ListTasks));
