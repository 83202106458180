import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Redirect } from 'react-router'
import { Authenticator} from 'aws-amplify-react';
import { Grid, Row, Col } from 'react-flexbox-grid'
import Amplify from 'aws-amplify';
import awsmobile from '../aws-exports';
import myconfig from '../custom-config';
import MyTheme from './_layout/AmplifyTheme/f400';
import Footer from './_layout/Footer';
import Header from './_layout/Header';
import SignOut from './_layout/SignOut';
import SignUp from './_layout/SignUp';
import PublicView from './PublicView';
import Editor from './Editor/Editor';

import 'react-datepicker/dist/react-datepicker.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import 'react-table/react-table.css';

import './_layout/Themes/light.css'
import './_layout/Themes/light_embed.css'
import './_layout/Themes/light_admin.css'
import './_layout/Themes/dark.css'
import './_layout/Themes/dark_orange.css'
import './_layout/Themes/dark_green.css'
import './_layout/Themes/dark_red.css'
import './_layout/Themes/dark_blue.css'
import './_layout/Themes/dark_rosa.css'
import './_layout/Themes/dark_yellow.css'
import './_layout/Themes/dark_emerald.css'

import {
  setUser
} from '../_actions';


Amplify.configure(awsmobile);
Amplify.configure(myconfig);


// Auth.currentAuthenticatedUser()
// .then(user => saveUserInfo(user))
// .catch(err => console.log(err));
//
// function saveUserInfo(user){
//   let authenticated = user.attributes;
//   console.log(authenticated);
//
// }


class Home extends Component {
  constructor() {
    super();
    this.state={
      authStep:'',
      defaultEntity:'123abc',
      hideUi:false
    };
    
    this.handleAuthStateChange = this.handleAuthStateChange.bind(this);
    
  }
  
  handleAuthStateChange(state, data) {
    
    if (state === 'signedIn') {
      const { setUserData } = this.props;
      const { email, sub } = data && data.signInUserSession && data.signInUserSession.idToken.payload;
      const { username } = data;

      const user = {
        username: username,
        email: email,
        cognitoId: sub
      };

      setUserData(user);
      
      this.setState({authStep:state, hideUi: true});
    }
  }
  renderAuthenticator(){
    
    const authenticator = (
      <div className="xyz">
        <Authenticator
          theme={MyTheme}
          authState='signIn'
          hideDefault={this.state.hideUi}
          onStateChange={this.handleAuthStateChange}
        >
          <Switch>
            <Route exact={true} path="/" render={routeProps => <Redirect to="/q/tasks"/>} />
            {/*<Route path="/questions" render={routeProps => <PublicView {...routeProps}/>} />*/}
            <Route path="/q" render={routeProps => <Editor {...routeProps}/>}/>
          </Switch>
        </Authenticator>
      </div>
    );
    
    if(!this.state.authStep){
      return(
        <div style={{paddingTop:'60px'}}>
          <Grid>
            <Row center="xs">
              {/*<Col xs={12} md={3} style={{textAlign:'left'}}>*/}
                {/*<Spring from={{ opacity: 0 }} to={{ opacity: 1 }} delay="500">*/}
                  {/*{props => <div style={props}>*/}
                    {/*<h2 style={{marginTop:'50px'}}>Login</h2>*/}
                    {/*<h4 style={{color:'#adadad'}}>into your account</h4>*/}
                    {/**/}
                  {/*</div>}*/}
                {/*</Spring>*/}
              {/*</Col>*/}
              <Col xs={12} md={8} style={{maxWidth:'400px'}}>
                    {authenticator}
              </Col>
            </Row>
          </Grid>
        </div>
      )
      
    }else{
      return(
        <React.Fragment>
          {authenticator}
        </React.Fragment>
      )
      
    }
  }
  
  render() {
    
    return (
      <Router>
        <div>
          <Switch>
            
            <Route path="/questions/:slug/:entityId/:config" render={routeProps => <Header {...routeProps}/>}/>
            <Route render={routeProps => <Header {...routeProps}/>}/>
          </Switch>
          
          <Switch>
            {/*<Route exact={true} path="/" render={routeProps => <PublicView {...routeProps}/>} />*/}
            <Route path="/questions" render={routeProps => <PublicView {...routeProps}/>} />
            
            <Route path="/signout" component={SignOut}/>
            <Route path="/join/:id"
                     render={routeProps => <SignUp {...routeProps}/>}/>
            
            <React.Fragment>
              {this.renderAuthenticator()}
            </React.Fragment>
  
            

          </Switch>

          <Footer/>
        </div>
      </Router>
    );
  }
}


Home.propTypes = {
  setUserData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  setUserData: value => dispatch(setUser(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);


// render() {
//   const { user } = this.props;
//
//   return(
//     <React.Fragment>
//       {user.cognitoId ? this.renderExplore() : ''}
//     </React.Fragment>
//   );
// }