import React, { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { graphql, compose, withApollo } from 'react-apollo'
import { withRouter } from 'react-router'
import { Link } from "react-router-dom"
import gql from "graphql-tag"
import queryString from "query-string"
import TaskHead from '../editor_tasks/components/TaskHead'
import { updateAnswer } from '../../../graphql/mutations'
import { getAnswer } from '../../../graphql/queries'
import QuestionHeader from "../_layout/QuestionHeader"

class AnswerEdit extends Component {
  
  constructor(){
    super();
    this.state = {
      header:{
        qid: '1e99ej3827', //dynamically generated // Empty will show TBD
        breadcrumb:[
          {
            icon:'globe',
          },
            {
              name:'loading..',
            },
           {
              name:'Edit Answer',
              route:'',
              icon:'',
              active:true
            }
        ],
        status: 1, // 1 | 2 | 3 | 4 | 5 | 6 (need to be INT)
        action: '', // link | button
        action_text: '', //Text inside button
        action_icon: '', //icon inside button
        action_route: '', //Button route
        banner_color: 'edit' //new | answer | edit | review | delete
      },
      answer: {
        id: '1234567',
        answer: 'No Answer',
        author:'',
        authorId:'',
        credit: null,
        credit_name:'',
        credit_url:'',
        cta_text:'',
        cta_url:'',
        creditByEntity:false,
      },
      question: {
        id: '',
        question: 'No Question',
        entity: 'No PublicView'
      },
      urlQuery:''
    }
  }
  
  handleInputFields = field => event => {
    const { answer } = this.state
    answer[`${field}`] = event.target.value;
    this.setState({ answer });
  }
  
  handleClearCredits = field => event => {
    const { answer } = this.state
    answer['credit_name'] = '';
    answer['credit_url'] = '';
    answer['credit'] = '';
    this.setState({ answer });
  }
  
  handleSave = () => {
    const { history, updateAnswer } = this.props;
    const { answer } = this.state;
    
    //Creating "Credit" String before saving
    if(answer.credit_name===''){
      answer.credit = null;
    }else{
      answer.credit = "[("+answer.credit_name+"+"+answer.degree+"+"+answer.credit_url+")]";
    }
  
    //Creating "Bounty" String before saving
    if(answer.bounty===''){
      answer.credit = null;
    }else{
      answer.bounty = "[("+answer.cta_text+"+"+answer.cta_url+")]";
    }
    
    
    console.log(answer);
    
    updateAnswer(answer);
    history.push(`/q/${this.state.question.id}${this.props.location.search}`);
  }

  handleCancel = () => {
    const { history } = this.props
    history.push(`/q/${this.state.question.id}${this.props.location.search}`);
  }

  componentWillReceiveProps(newProps) {
    if (newProps && newProps.answer) {
      this.updateState(newProps);
    }
  }

  async updateState(props) {
    
    const { header, answer, question } = this.state;
    header.qid = props.match.params.answerId;

    
    answer.id = props.match.params.answerId;
    answer.answerId = props.answer.id;
    answer.answer = props.answer.answer;
    answer.degree = props.answer.degree;
    answer.rating = props.answer.rating;
    answer.lang = props.answer.lang;
    answer.author = props.answer.author;
    answer.authorId = props.answer.authorId;
    answer.createdAt = props.answer.createdAt;
   
  
    //Updating Credit Values
    answer.credit = props.answer.credit;
    const credit = props.answer.credit;
    const credit_values = (credit ? this.separateValues(credit): '');
  
    if(credit_values[0]) {
      answer.credit_name = credit_values[0][0];
      answer.credit_url = credit_values[0][2];
    }
  
    //Updating CTA Values
    answer.bounty = props.answer.bounty;
    const bounty = props.answer.bounty;
    const bounty_values = (bounty ? this.separateValues(bounty): '');
    
    if(bounty_values[0]) {
      answer.cta_text = bounty_values[0][0];
      answer.cta_url = bounty_values[0][1];
    }
    
    const currentDate = Date.now();
    answer.modifiedAt = currentDate.toString();
    
  
    //Question Info
    if(props.answer.question) {
      question.question = props.answer.question.question;
      question.entity = props.answer.question.entity;
      question.entityId = props.answer.question.entityId;
      question.taskId = props.answer.question.taskId;
      question.id = props.answer.question.id;
      header.breadcrumb[1].name = props.answer.question.entity;
      header.breadcrumb[1].route = '/q/entity/'+props.answer.question.entityId;
    }
    
    //URL Query
    const parsed = queryString.parse(this.props.location.search);
    

    this.setState({ header, answer, question, urlQuery: parsed });
  }
  
  separateValues(arr){
    //Unpacking Entities from String
    const ent = JSON.stringify(arr);
    let regex = /\(([^)]+)\)/;
    let e = ent.split(regex);
    //Cleaning up array
    let res = e.filter(function(v, i) {
      // check the index is even
      return i % 2 !== 0;
    });
    
    let separated =[];
    res.map(z => {
      let x = z.split('+');
      separated.push(x);
      return x;
    });
    
    return separated;
  }
  
  renderHeader(){
    
    const task = this.state.question.taskId;
    
    if(this.state.urlQuery.m === 't'){
      return <TaskHead taskId={task} />
      
    }else{
      return <QuestionHeader header={this.state.header} />
    }
  }
  
  handleCheckbox = field => event => {
    console.log(this.props.db_user.username)
    const { answer } = this.state
    
    if(this.state.answer.creditByEntity){
      console.log('second')
      answer[`${field}`] = false;
      answer[`credit_name`] = this.props.db_user.username;
      answer[`degree`] = 3;
    }else{
      console.log('first')
      answer[`${field}`] = true;
      answer[`credit_name`] = this.state.question.entity;
      answer[`degree`] = 1;
    }
    
    
    this.setState({ answer });
  }
  

  render() {
    
    const { answer, degree, rating, lang, credit_url, credit_name, cta_text, cta_url  } = this.state.answer;
    const { question, id, entity} = this.state.question;
    
    console.log(this.props, this.state)
    return (
      <div>
        {this.renderHeader()}
        
        <div className="workarea editor_form">
          <table>
            <tbody>
              <tr>
                <td>
                  Question
                </td>
                <td>
                  <Link to={`/q/${id}${this.props.location.search}`}>
                  { question }
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  Entity
                </td>
                <td>
                    { entity }
                </td>
              </tr>
              <tr>
                <td style={{width:'150px'}}>
                  Answer
                </td>
                <td>
                  <textarea className="bp3-input bp3-fill" value={answer} onChange={this.handleInputFields('answer')}>
                  </textarea>
                </td>
              </tr>
              {/*<tr>*/}
                {/*<td>*/}
                  {/*Author*/}
                {/*</td>*/}
                {/*<td>*/}
                  {/*{author} ({authorId})*/}
                {/*</td>*/}
              {/*</tr>*/}

              <tr>
                <td style={{width:'150px'}}>
                Credit
                </td>
                <td>
  
                  {this.state.answer.creditByEntity ?
    
                    <div style={{color:'#CCC'}}>{entity} (1°)</div>
                    :
                    <Row>
                      <Col xs={10} md={10} >
                        <div className="bp3-control-group">
                          <span className="bp3-tag bp3-minimal">Author</span>
                          <input className="bp3-input"  placeholder="Name" value={credit_name} onChange={this.handleInputFields('credit_name')} />
                        </div>
        
                        <div className="bp3-control-group">
                          <span className="bp3-tag bp3-minimal">Relation</span>
                          {/*TODO: Implememt handleDegreeValues to affect general count*/}
                          <div className="bp3-select " style={{width:'80%'}}>
                            <select className="" value={degree} onChange={this.handleInputFields('degree')}>
                              <option value="1">Works for {entity}  (1°)</option>
                              <option value="2">Domain Expert (2°)</option>
                              <option value="3" selected>General Public (3°)</option>
                              <option value="4" >Unknown (4°)</option>
                            </select>
                          </div>
                        </div>
                        <span className="field_details">(Author relation with: {entity})</span>
        
                        <div className="bp3-control-group">
                          <span className="bp3-tag bp3-minimal">Source</span>
                          <input className="bp3-input"  placeholder="URL" value={credit_url} onChange={this.handleInputFields('credit_url')} />
                        </div>
      
                      </Col>
    
                    </Row>
                  }
                  <div className="field_details">
                    <input type="checkbox" onChange={this.handleCheckbox('creditByEntity')} style={{width:'20px'}}/>
                    Entity is the author
                  </div>
                  
                </td>
              </tr>
              <tr>
                <td style={{width:'150px'}}>
                  Details
                </td>
                <td>
                  <div className="bp3-control-group">
                    <span className="bp3-tag bp3-minimal">Language</span>
                    <div className="bp3-select">
                      <select className="" value={lang} onChange={this.handleInputFields('lang')}>
                        <option value="">Select one</option>
                        <option value="ENG">English</option>
                        <option value="SPA">Spanish</option>
                        <option value="FRA">French</option>
                        <option value="GER">German</option>
                        <option value="MAN">Mandarin</option>
                        <option value="JAP">Japanese</option>
                      </select>
                    </div>
                  </div>
                  <div className="bp3-control-group">
                    <span className="bp3-tag bp3-minimal">Rating</span>
                    <div className="bp3-select">
                      <select className="" value={rating} onChange={this.handleInputFields('rating')}>
                        <option value="G">G</option>
                        <option value="PG">PG</option>
                        <option value="PG-13">PG-13</option>
                        <option value="R">R</option>
                        <option value="NC-17">NC-17</option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
              
              <tr>
                <td >
                  Call to Action
                </td>
                <td>
                  <Row>
                    <Col xs={12} md={5} >
                      <div className="bp3-control-group">
                        <span className="bp3-tag bp3-minimal">CTA Text</span>
                        <input className="bp3-input" placeholder="CTA text (e.g. Book Now)" style={{width:'80%'}} value={cta_text} onChange={this.handleInputFields('cta_text')} />
                      </div>
                    </Col>
                    <Col xs={12} md={5} >
                      <div className="bp3-control-group">
                        <span className="bp3-tag bp3-minimal">CTA URL</span>
                        <input className="bp3-input" placeholder="Absolut path" value={cta_url} style={{width:'80%'}} onChange={this.handleInputFields('cta_url')} />

                      </div>
                     
                    </Col>
                  </Row>
                </td>
              </tr>
            
            </tbody>
          </table>
          <br/><br/>
          <div className="editor_footer_wrapper">
            <div className="editor_footer">
                <button className="bp3-button"  onClick={this.handleCancel}>Cancel</button>
                <button className="bp3-button bp3-intent-primary" onClick={this.handleSave} >Save</button>
              
            </div>
          </div>
        </div>
        
      </div>
    
    );
  }
  
}

export default withRouter(withApollo(compose(

  graphql(
    gql(updateAnswer),
    {
      props: ({ mutate }) => ({
        updateAnswer: ({ id, answer, degree, rating, author, lang, authorId, credit, modifiedAt, bounty }) => {
          console.log(id, answer, degree, rating, author, lang, authorId, credit, modifiedAt, bounty)
          mutate({
            variables: {
              input: {
                id,
                answer,
                degree,
                rating,
                author,
                lang,
                authorId,
                credit,
                modifiedAt,
                bounty
                
              }
            },
            refetchQueries:[`listUsers`]
          })
        }
      })
    }
  ),
  graphql(
    gql(getAnswer),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.answerId}
      }),
      props: ({ data: { getAnswer = {}}}) => ({
        answer: getAnswer
      })
    }
  )
)(AnswerEdit)));
