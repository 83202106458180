import React, { Component } from "react"
import { withApollo, compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from "react-router-dom"
import { Elements } from 'react-stripe-elements'
import AddCard from './payments/AddCard'
import {renderCardLogo} from '../../_utils/paymentHelpers'
import SettingsWrapper from "./_layout/SettingsWrapper"
import { createStripeCustomer } from '../../../graphql/mutations'
import { listStripeCustomers } from '../../../graphql/queries'

class ConnectMethod extends Component {

  constructor() {
    super();
    this.state = {
      header: {
        counter: '',
        action: 'button',
        action_text: 'Settings',
        action_icon: 'arrow-left',
        action_route: '/q/settings',
        banner_color: 'expanded',
        breadcrumb: [
          {
            name: '',
            route: '',
            icon: 'cog',
            active: false
          }, {
            name: 'Settings',
            active: true,
            size: 'L'
          }, {
            name: '',
            size: 'L'
          }
        ],
      },
      formVisible:true
    }
  }
  
  renderCards(data){
    console.log(this.props);
    
    const items = data.map((item, key) =>
      <li key={item.id}>
        <img src={renderCardLogo(item.brand)} className="cc_icon" style={{width:'20px'}} alt="cardlogo"/>
        <span style={{margin:'0 10px', fontSize:'0.8em'}}>{item.brand} ({item.last4})</span>
      </li>
    );
    
    return (
      <div style={{opacity:'0.8'}}>
        <h4>Existing Card{data.length === 1 ? '':'s'} ({data.length}):</h4>
        <ul>
          {items}
        </ul>
        <br/>
        <Link to={`/q/settings/${this.props.db_user.id}/transactions`} className="bp3-button bp3-minimal bp3-icon-arrow-left"> Go to My Cards</Link>
      </div>
    );
    
  }

  setCustomer = (customer) => {
    const { createStripeCustomer } = this.props

    const stripeCustomer = {
      cognitoId: this.props.db_user.id,
      cust_id: customer.id,
      cust_email: customer.email,
      brand: customer.sources.data[0].brand,
      last4: customer.sources.data[0].last4,
      created: customer.created
    }
    createStripeCustomer(stripeCustomer)
    
    //Also Show confirmation and hide form:
    this.setState({
      formVisible: false,
      lastAdded: `${customer.sources.data[0].brand} (${customer.sources.data[0].last4})`,
    })
    
  }

  render() {
    console.log(this.props.listStripeCustomers)
    const userId = this.props.match.params.userId ? this.props.match.params.userId : this.props.db_user.id;
    const userRole = this.props.db_user ? parseInt(this.props.db_user.status) : 0;

    return (
      <SettingsWrapper user={userId} header={this.state.header} userRole={userRole} activeTab="transactions">
        <h2>My Cards <span style={{opacity:'0.5', fontWeight:'100'}}> / add new</span></h2>
  
        
          <div className="bp3-card" style={{margin: '40px 0', padding: '40px'}}>
  
            {this.state.formVisible ?
              <div>
                Please add <span style={{fontWeight: '400'}}>New Card</span> information
                <br/>
                <Elements>
                  <AddCard setCustomer={this.setCustomer}/>
                </Elements>
              </div>
              :
              <h4>Your {this.state.lastAdded} card was added</h4>
            }
            
          </div>
        
        <div >
          {this.renderCards(this.props.listStripeCustomers)}
        </div>
        
      </SettingsWrapper>
    );
  }

}
export default withApollo(compose(
  graphql(
    gql(listStripeCustomers),
    {
      options: (props) => ({
        variables: {
          limit: 1000,
          filter: { cognitoId: { eq: props.match.params.userId } }
        }
      }),
      props: ({ data: { listStripeCustomers = { items: [] } } }) => ({
        listStripeCustomers: listStripeCustomers.items
      })
    }
  ),
  graphql(
    gql(createStripeCustomer),
    {
      props: ({ mutate }) => ({
        createStripeCustomer: ({ cognitoId, cust_id, cust_email, brand, last4, created }) => {
          mutate({
            variables: {
              input: {
                cognitoId,
                cust_id,
                cust_email,
                brand,
                last4,
                created
              }
            },
            refetchQueries: [{
              query: gql(listStripeCustomers),
              variables: {
                limit: 1000,
                filter: { cognitoId: { eq: cognitoId } }
              },
            }]
          })
        }
      })
    },
  )
)(ConnectMethod));
