import React, { Component } from "react"
import {Row, Col} from 'react-flexbox-grid'
import ReactTable from "react-table"
import { Link } from "react-router-dom"
import { withApollo, compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import moment from 'moment'
import {renderSingleCard} from '../../_utils/paymentHelpers'
import SettingsWrapper from "./_layout/SettingsWrapper"
import { updateUser } from '../../../graphql/mutations'
import { listStripeCustomers } from '../../../graphql/queries'
import myconfig from '../../../custom-config';

class Transactions extends Component {
  
  constructor(){
    super();
    this.state = {
      header: {
        counter: '',
        action: 'button',
        action_text: 'Settings',
        action_icon: 'arrow-left',
        action_route: '/q/settings',
        banner_color:'expanded',
        breadcrumb:[
          {
            name:'',
            route:'',
            icon:'cog',
            active: false
          },{
            name:'Settings',
            active: true,
            size:'L'
          },{
            name:'',
            size:'L'
          }
        ],
      },
      message: "",
      charges: {},
      transfers: {},
      activeCard:'',
      cardsFull:true
    }
  }
  
  componentDidMount(){
    
    const userRole = parseInt(this.props.db_user.status);
    
    const { db_user } = this.props;
    if (userRole === 110) { // tasker
      if (db_user.accountNumber) {
        this.listTransfer(db_user.accountNumber)
      } else {
        this.setState({
          message: 'You need to connect your stripe account on the platform.'
        })
      }
    }
  }

  listCharge = async (custId) => {
    const res = await fetch(`${myconfig.Stripe.apiUrl}/charges/${custId}`, { // POST to our backend server with the token and charge detgails
      method: 'GET'
    });
    const data = await res.json();
    
      if (data.message && data.message.includes('success')) {
        
        this.setState({
          charges: data.charges,
          cardsFull:false,
          activeCard: data.charges.data[0] ? data.charges.data[0].payment_method_details.card.last4: ''
        }, () => {
          // console.log(data)
          console.log('ok')
        })
      } else {
        alert ('failed')
      }
    
  }

  listTransfer = async (destination) => {
    const res = await fetch(`${myconfig.Stripe.apiUrl}/transfer/${destination}`, { // POST to our backend server with the token and charge detgails
      method: 'GET'
    });
    const data = await res.json();

    if (data.message && data.message.includes('success')) {
      this.setState({
        charges: data.transfers
      }, () => {
        console.log(data)  
      })
    } else {
      alert ('failed')
    }  
  }
  
  
  handleToggleSize = () => {
    const cardsFull = this.state.cardsFull;
    this.setState({ cardsFull:!cardsFull });
  };

  convertTableDataFormat(data){
    data.map( item => {
      item.amount = (item.amount / 100).toFixed(2)
      item.created = moment(item.created * 1000).format('YYYY/MM/DD, hh:mm:ss a')
      return null;
    })
  }


  renderTable(){
    
    let tableData = this.state.charges.data;
    if (tableData){
      this.convertTableDataFormat(tableData)
  
      return(
        <Row>
          <Col xs={12}>
            <ReactTable
              data={tableData}
              columns={[{
                columns: [
                  {
                    Header:'Type',
                    accessor: 'object',
                  },
                  {
                    Header:'Amount',
                    accessor: 'amount',
                  },
                  {
                    Header:'Currency',
                    accessor: 'currency',
                  },
                  {
                    Header:'created',
                    accessor: 'created',
                  },
                  {
                    Header:'id',
                    accessor: 'id',
                  },
            
                ]
              }]}
              defaultPageSize={40}
              className=" -highlight"
            />
          </Col>
        </Row>
      )
    }
    
  }
  
  renderCards(customers){
  
    const items = customers.map((item, key) =>
      <span key={item.id}>
        {renderSingleCard(item.cust_id, item.last4, this.state.activeCard===item.last4, item.brand, this.listCharge.bind(this, item.cust_id ) )}
      </span>
    );
    
    
    let userId = this.props.db_user.id;

    return(
      
        <div className={`ccard_wrapper ${this.state.cardsFull ? 'full' : ''}`}>
          <div style={{display:'flex', flexWrap:'wrap'}}>
            
            {items}
  
            <div style={{ display: this.state.cardsFull ? 'none' : 'inline-flex', margin: '0 20px', opacity:'0.5' }}>
              <div className="bp3-button bp3-minimal bp3-small bp3-icon-eye-off" onClick={this.handleToggleSize}>hide</div>
            </div>
          </div>
          
          <Link to={"/q/settings/" + userId + "/paymentmethods"} className="bp3-button bp3-small bp3-minimal bp3-icon-add" style={{minWidth:'130px'}}>
            Add New Card
          </Link>
        </div>
      
      );
      
  }
  
  
  render() {
  
    const customers = this.props.listStripeCustomers;
  
    //if not provided by URL, use current user
    const userId = this.props.match.params.userId ? this.props.match.params.userId : this.props.db_user.id ;
    const userRole = this.props.db_user ? parseInt(this.props.db_user.status) : [];
    
    return (
      <SettingsWrapper user={userId} header={this.state.header} userRole={userRole} activeTab="transactions">
        <h2>My Cards</h2>
        
        <p>Please select card to see transactions</p>
        
        {this.renderCards(customers)}
        
        <div style={{ display: this.state.cardsFull ? 'none' : 'inherit' }}>
        {this.renderTable()}
        </div>
        
        
        {
          this.state.message &&
          <p>{this.state.message}</p>
        }
      </SettingsWrapper>
    );
  }
  
}

export default withApollo(compose(
  graphql(
    gql(listStripeCustomers),
    {
      options: (props) => ({
        variables: {
          limit: 1000,
          filter: { cognitoId: { eq: props.db_user.id } }
        }
      }),
      props: ({ data: { listStripeCustomers = { items: [] } } }) => ({
        listStripeCustomers: listStripeCustomers.items
      })
    }
  ),
  graphql(
    gql(updateUser),
    {
      props: ({mutate}) => ({
        updateUser: ({id, stripeCustomerId}) => {
          console.log({id, stripeCustomerId});
          mutate({
            variables: {
              input: {
                id,
                stripeCustomerId
              }
            }
          })
        }
      })
    }
  )
)(Transactions));