import React, {Component} from "react"
import { Link } from "react-router-dom"
import ClassNames from "classnames"
import {Icon} from "@blueprintjs/core"
import kebabCase from "lodash/kebabCase"
import truncate from "lodash/truncate"

class Answer extends Component {
  
  state = {
    name: "Answer",
  };
  
  render() {
    let slugEntity =kebabCase(truncate(this.props.data.entity));
    let slugQuestion =kebabCase(truncate(this.props.data.question));
    
    return (
        <div className="q_wrapper">
          
          <div className="q_row_wrapper" style={{borderWidth:'0 0 1px 0', borderStyle:'dotted', borderColor:'#92929245' }}>
            <div className="q_row q_row1">
              <span className="element element1_1">
                <Link to={`/questions/${slugEntity}/${this.props.data.entityId}/q/${this.props.data.questionId}/${slugQuestion}${(this.props.embed === true ? '/embed':'')}`}>
                  <h3 className="question_list">
                    <Icon icon="small-plus" />&nbsp;
                    {this.props.data.question}
                    
                  </h3>
                </Link>
              </span>
            </div>
            <div className="q_row q_row5 mobile_hide">
              
              {!this.props.embed &&(
                
                <div className="element element5_1">
                  <span className={ClassNames('degree degree1',{hidden: !this.props.data.d1 })}>{this.props.data.d1}</span>
    
                  <span className={ClassNames('degree degree2',{hidden: !this.props.data.d2 })}>{this.props.data.d2}</span>
    
                  <span className={ClassNames('degree degree3',{hidden: !this.props.data.d3 })}>{this.props.data.d3}</span>
    
                  <span className={ClassNames('degree degree4',{hidden: !this.props.data.d4 })}>{this.props.data.d4}</span>
                </div>
                
              )}
              
              <div className={ClassNames('element element5_1',{hidden: !this.props.data.count })}>
                <span>{this.props.data.count} answers</span>
  
              </div>
              
            </div>
            
          </div>
        </div>
    
    
    
    );
  }
  
}


export default Answer;
