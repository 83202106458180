import React, { Component } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';
import gql from "graphql-tag";
import { withRouter } from 'react-router';
import { Storage } from 'aws-amplify';
import { PhotoPicker } from 'aws-amplify-react';
import QuestionHeader from "../_layout/QuestionHeader";
import { updateEntity } from '../../../graphql/mutations';
import { getEntity } from '../../../graphql/queries';
import myconfig from '../../../custom-config';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setHeader
} from '../../../_actions';

const previewStyle = {
  width: 70,
  height: 70,
  objectFit: 'cover',
  borderRadius: '50%',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'darkgray'
}

class EntityEdit extends Component {
  
  constructor(){
    super();
    this.state = {
      header:{
        title:'Edit PublicView',
        qid:'123', //dynamically generated // Empty will show TBD
        action:'', // link | button
        action_text:'', //Text inside button
        action_icon:'', //icon inside button
        action_route:'', //Button route
        banner_color:'edit' //new | answer | edit | review | delete
      },
      record: {
        id: '',
        name: '',
        displayName: '',
        website: '',
        logo: '',
        wall:'',
        theme:'',
        status:''
      },
      prevSrc: null,
      file: []
      
    }
  }

  componentWillMount() {
    this.updateState();
  }

  async updateState() {
    const entityId = this.props.match.params.entityId;
    const { header } = this.state;
    header.qid = entityId;
    const { setHeaderData } = this.props;
    setHeaderData(header);
    setHeaderData(header);

    const { record } = this.state;
    record.id = this.props.match.params.entityId;
    const { client } = this.props;
    let prevSrc = this.state.prevSrc;
    try {
      const result = await client.query({
        query: gql(getEntity),
        fetchPolicy: 'network-only',
        variables: {
          id: entityId,
        },
      });
  
      //Todo: if we remove this console.log, update stop working, why?
      console.log(result);
      
      if ( result && result.data.getEntity) {
        record.name = result.data.getEntity.name;
        record.displayName = result.data.getEntity.displayName;
        record.website = result.data.getEntity.website;
        record.logo = result.data.getEntity.logo;
        record.wall = result.data.getEntity.wall;
        record.theme = result.data.getEntity.theme;
        record.status = result.data.getEntity.status;
        prevSrc = result.data.getEntity.logo;
      }
  
    } catch (error) {
      console.log(error);
    }
    
    this.setState({ record, prevSrc });
  }

  onPickInfo (data) {
    const { record } = this.state;
    record.logo = "https://s3.amazonaws.com/"+myconfig.Storage.bucket+"/public/" + data.name;
    //const { file } = this.state;
    this.setState({ record, file: data });
    
  }

  onChangeImage (data) {
    this.setState({prevSrc: data});
  }

  handleValues = field => event => {
    const { record } = this.state
    record[`${field}`] = event.target.value;
    this.setState({ record });
  }

  handleSave = () => {
    const { history, updateEntity } = this.props
    const { record } = this.state;
    const { entityId } =  this.props.match.params;
    record.id = entityId;

    updateEntity(record);
    const { file } = this.state;
    if(file) {
      Storage.put( file.name, file.file, {
        Bucket: myconfig.Storage.bucket,
        key: record.logo,
        ACL: "public-read"
      })
      .then (result => {
        console.log("Logo is added into the S3 bucket correctly.");
      })
      .catch(err => {
        console.log("logo is not added. error is ", err);
      });
    }
    history.push(`/q/entity/${entityId}/info`);
  }

  handleCancel = () => {
    const { history } = this.props
    history.goBack();
  }

  render() {
    const {
      name,
      displayName,
      website,
      wall,
      theme,
      status
    } = this.state.record
    return (
      <div>
        <QuestionHeader header={this.state.header} />
        <div className="workarea editor_form">
          <table>
            <tbody>
              <tr>
                <td style={{width:'150px'}}>
                  Entity
                </td>
                <td>
                  <input className="bp3-input" placeholder="display name" style={{width:'100%'}} value={name} onChange={this.handleValues('name')} />
                </td>
              </tr>
              <tr>
                <td style={{width:'150px'}}>
                  Display Name
                </td>
                <td>
                  <input className="bp3-input" placeholder="display name" style={{width:'100%'}} value={displayName} onChange={this.handleValues('displayName')} />
                </td>
              </tr>
              <tr>
                <td>
                  Website
                </td>
                <td>
                  <input className="bp3-input" placeholder="website" style={{width:'100%'}} value={website} onChange={this.handleValues('website')} />
                </td>
              </tr>
              <tr>
                <td>
                  Wallpaper
                </td>
                <td>
                  <input className="bp3-input" placeholder="absolut path to s3 image" style={{width:'100%'}} value={wall} onChange={this.handleValues('wall')} />
                </td>
              </tr>
              <tr>
                <td>
                  Theme
                </td>
                <td>
                  {/*<input className="bp3-input" placeholder="light | dark " style={{width:'100%'}} value={theme} onChange={this.handleValues('theme')} />*/}
                  
                  <div className="bp3-select">
                    <select value={theme} onChange={this.handleValues('theme')}>
                      <option value="light">Light</option>
                      <option value="light_embed">Light Embed</option>
                      <option value="dark">Dark</option>
                      <option value="dark_blue">Dark / Blue</option>
                      <option value="dark_orange">Dark / Orange</option>
                      <option value="dark_green">Dark / Green</option>
                      <option value="dark_red">Dark / Red</option>
                      <option value="dark_rosa">Dark / Pink</option>
                      <option value="dark_yellow">Dark / Yellow</option>
                      <option value="dark_emerald">Dark / Emerald</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Logo
                </td>
                <td>
                  <img src={this.state.prevSrc} style={previewStyle} alt=""/>
                  <PhotoPicker
                    title="select a logo"
                    preview
                    onPick={ data=> this.onPickInfo(data)}
                    onLoad={dataURL => this.onChangeImage(dataURL)}
                  />
                  
                </td>
              </tr>
              <tr>
                <td>
                  Status
                </td>
                <td>
                  <select value={status} onChange={this.handleValues('status')}>
                    <option value="">Public</option>
                    <option value="201">Showcase</option>
                    <option value="202">Private</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="editor_footer">
            <div>
      
            </div>
            <div>
              <button className="bp3-button" onClick={this.handleCancel} >Cancel</button>
              <button className="bp3-button bp3-intent-primary"  onClick={this.handleSave} >Save</button>
            </div>
          </div>
        </div>
      </div>
    
    );
  }
  
}

EntityEdit.propTypes = {
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setHeaderData: value => dispatch(setHeader(value)),
});

const EntityEditConnected = connect(mapStateToProps, mapDispatchToProps)(EntityEdit);

export default withRouter(withApollo(compose(

  graphql(
    gql(updateEntity),
    {
      props: ({ mutate }) => ({
        updateEntity: ({ id, name, displayName, website, logo, wall, theme, status }) => {
          mutate({
            variables: {
              input: {
                id,
                name,
                displayName,
                website,
                logo,
                wall,
                theme,
                status
              }
            }
          })
        }
      })
    }
  ),
)(EntityEditConnected)));