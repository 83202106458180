import React, { Component } from "react"
import { Link } from "react-router-dom"
import { graphql, compose, withApollo } from "react-apollo"
import Query1 from "../../../graphql/editor/ListUserCreatedTasks"
import Query2 from "../../../graphql/editor/DeleteTask"
import { Button} from "@blueprintjs/core"
import {Icon, Tooltip, Position} from "@blueprintjs/core"
import { Row, Col } from 'react-flexbox-grid'
import matchSorter from 'match-sorter'
import ReactTable from "react-table"
import LinkIcon from "../../_utils/LinkIcon"
import ResultHeader from "../_layout/ResultHeader"
import ShowStatus from "../../_utils/ShowStatus"

import moment from "moment";

class ListCreatedTasks extends Component {
  
  state = {
    header:{
      title:'User Tasks',
      counter:'0',
      action:'link',
      action_text:'See all tasks',
      action_icon:'arrow-left',
      action_route:'/q/tasks',
      entityLogo:'',
      entityId:''
    },
    busy: false,
    
  }
  
  static defaultProps = {
    events: [],
    deleteEvent: () => null,
  }
  
  componentDidMount(){
    this.updateState();
  }
  
  updateState(){
    const { header } =  this.state;
    
    header.counter =  (this.props.tasks ? this.props.tasks.length: '');
    header.title = (this.props.db_user ? "Created by you" :'User Task ');
    this.setState({ header });
    console.log(this.props);
  }
  
  async handleDeleteClick(task, e) {
    e.preventDefault();
    
    if (window.confirm(`Are you sure you want to delete event ${task.id}`)) {
      const { deleteTask } = this.props;
      
      await deleteTask(task);
    }
  }
  
  
  handleSync = async () => {
    const { client } = this.props;
    const query = Query1;
    
    this.setState({ busy: true });
    
    await client.query({
      query,
      fetchPolicy: 'network-only',
    });
    
    this.setState({ busy: false });
  }
  
  renderEvent = (event) => (
    
    <Link to={`/q/task/${event.id}`} key={event.id}>
      <Row>
        <Col xs={12} md={6} >
          <h3>{event.name}</h3>
          <div>{event.description}</div>
        </Col>
        
        <Col xs={12} md={6} >
          
          <span>{moment(event.when).format('LL')}</span> |
          <span>{moment(event.when).format('LT')}</span> |
          <span>{event.where}</span>
          
          {event.comments.items.length} comments
          <Button className="button" onClick={this.handleDeleteClick.bind(this, event)}>
            Delete
          </Button>
        </Col>
      </Row>
    </Link>
  
  );
  
  renderTable(){
    
    const { tasks } = this.props;
    const { busy } = this.state;
    
    return(
      <ReactTable
        data={tasks}
        noDataText="No Tasks here!"
        filterable
        defaultFilterMethod={(filter, row) =>
        String(row[filter.id]) === filter.value}
        columns={[{
          columns: [
            {
              Header: 'editor_tasks',
              id: "name",
              accessor: d => d.name,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {keys: ["name"]}),
              filterAll: true,
              Cell: row =>(
                <LinkIcon type={'link'}
                          text={row.value}
                          route={"/q/task/"+row.original.id}
                />
              )
            },{
              Header:'Entity',
              accessor:'entity',
              Filter: '-',
              maxWidth: 75
            },
            {
              Header:'Bounty',
              accessor:'bounty',
              maxWidth: 50,
              Filter: '-',
            },
            {
              Header:'Due',
              accessor:'when',
              Filter: '-',
              maxWidth: 50,
              Cell: row =>(
                <div>
                  {moment(row.value).format('MM-DD')}
                </div>
              )
            },
            {
              Header:'Status',
              accessor:'status',
              maxWidth:70,
              Filter: '-',
              Cell: row =>(
                <div>
                  {
                    ShowStatus(row.value, true)
                  }
                
                </div>
              )
            },
            {
              Header:'Actions',
              accessor:'id',
              maxWidth: 60,
              Cell: row => (
                <div className="actions_column">
                  {/*<Tooltip content="Add to my Queue" position={Position.LEFT}>*/}
                  {/*<a onClick={this.handleAddToMyTasks.bind(this, row.original)} className="action_text">*/}
                  {/*<Icon icon="plus" iconSize={11}/>&nbsp; Queue*/}
                  {/*</a>*/}
                  {/*</Tooltip>*/}
                  <Tooltip content="Edit Task" position={Position.LEFT}>
                    <Link to={"/q/task/"+row.original.id+"/edit"}>
                      <Icon icon="edit" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  <Tooltip content="Delete Task" position={Position.LEFT}>
                    <button onClick={this.handleDeleteClick.bind(this, row.original)}>
                      <Icon icon="cross" iconSize={11} />
                    </button>
                  </Tooltip>
                </div>
              ),
              Filter: row =>(
                <Button className="bp3-button bp3-minimal" onClick={this.handleSync} disabled={busy}>
                  <Icon icon={busy ? 'time' : 'refresh' } iconSize={11} />
                </Button>
              )
            }
          ]
        }]}
        defaultPageSize={20}
        className=" -highlight"
      />
    )
    
  }
  
  
  render() {
    console.log('ListUserTasks::');
    console.log(this.props);
    
    return (
      <div>
        <div>
          {this.state.header && <ResultHeader category="Tasks" header={this.state.header} />}
        
        </div>
        <div>
          
          {this.renderTable()}
          
          {/*{[].concat(events).sort((a, b) => a.when.localeCompare(b.when)).map(this.renderEvent)}*/}
        </div>
      </div>
    );
  }
  
}

export default withApollo(compose(
  graphql(
    //List this user tasks
    Query1,
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.userId, limit:1000}
      }),
      props: ({ data: { listTasks = { items: [] } } }) => ({
        tasks: listTasks.items
      })
    }
  ),
  graphql(
    //DeleteTask
    Query2,
    {
      options: {
        update: (proxy, { data: { deleteTask } }) => {
          const query = Query1;
          const data = proxy.readQuery({ query });
          
          data.listTasks.items = data.listTasks.items.filter(task => task.id !== deleteTask.id);
          
          proxy.writeQuery({ query, data });
        }
      },
      props: (props) => ({
        deleteTask: (task) => {
          return props.mutate({
            variables: { input:{id: task.id} },
            optimisticResponse: () => ({
              deleteTask: {
                ...task, __typename: 'Task', comments: { __typename: 'CommentConnection', items: [] }
              }
            }),
          });
        }
      })
    }
  )
)(ListCreatedTasks));
