import React, { Component } from 'react';
import ReactTable from "react-table";
import ResultHeader from "../_layout/ResultHeader";
import ClassNames from "classnames";
import { Link } from "react-router-dom";
import {Icon, Tooltip, Position} from "@blueprintjs/core";
import { graphql, compose, withApollo } from "react-apollo";
import gql from "graphql-tag";
import { listAnswers } from '../../../graphql/queries'
import LinkIcon from "../../_utils/LinkIcon";


class ListAnswers extends Component {
  constructor(){
    super();
    
    this.state = {
      header:{
        title:'All editor_answers.',
        counter:''
      }
      
    }
  }
  
  componentWillMount(){
    this.updateState();
  }
  
  async updateState(){
    
    const { client } = this.props;
    let payload = [];
    try {
      const result = await client.query({
        query: gql(listAnswers)
      });
      if(result && result.data.listAnswers) {
        payload = result.data.listAnswers.items;
        console.log(payload);
      }
    } catch (error) {
      console.log(error);
    }
    
    //Getting Counter number
    const header = {...this.state.header};
    header.counter = payload.length;
    
    //Updating State
    this.setState({table: payload, header});
    
  }
  
  renderTable(){
    
    const table = this.state.table;
    
    return(
      <ReactTable
        data={table}
        columns={[{
          columns: [
            {
              Header: 'Answer',
              accessor: 'answer',
              minWidth: 300,
              Cell: row =>(
                <LinkIcon type={'link'}
                          text={row.value}
                          route={"/q/answer/"+row.original.id+"/detail"}
                />
              )
            },
            {
              Header: 'Degree',
              accessor: 'degree',
              maxWidth: 50,
              Cell: row =>(
                <div className="entity_column">
                    <span
                      className={ClassNames('degree',
                        {degree1 : row.value === '1'},
                        {degree2 : row.value === '2'},
                        {degree3 : row.value === '3'},
                        {degree4 : row.value === '4'}
                      )} >
                    {row.value}°
                    </span>
                </div>
              )
            },
            {
              Header: 'Author',
              accessor: 'author',
              minWidth: 150,
              Cell: row =>(
                <LinkIcon type={'link'}
                          icon={'user'}
                          text={row.value}
                          route={"/q/user/"+row.original.id+"/profile"}
                />
              )
            },
            {
              accessor: 'id',
              maxWidth: 100,
              Cell: row => (
                <div className="actions_column">
                  <Tooltip content="Go to Question" position={Position.LEFT}>
                    <Link to={"/q/answer/"+row.value+"/detail"}>
                      <Icon icon="arrow-left" iconSize={11} />
                    </Link>
                  </Tooltip>
                  <Tooltip content="Edit" position={Position.LEFT}>
                    <Link to={"/q/answer/"+row.value+"/edit"}>
                      <Icon icon="edit" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  <Tooltip content="Delete" position={Position.LEFT}>
                    <Link to={"/q/answer/"+row.value+"/delete"}>
                      <Icon icon="cross" iconSize={11}/>
                    </Link>
                  </Tooltip>
                </div>
              )
            }
          
          ]
        }]}
        defaultPageSize={20}
        className=" -highlight"
      />
    )
    
  }
  
  
  render() {
    
    return (
      
      <div>
        {this.state.header && <ResultHeader header={this.state.header} />}
        
        <div className="workarea">
          <div>
            {this.renderTable()}
          
          </div>
        
        </div>
      
      </div>
    
    
    
    );
  }
}


export default withApollo(compose(
  
  graphql(
    gql(listAnswers),
    {
      options: {
        fetchPolicy: 'network-only',
      },
      props: ({ data: { listAnswers = { items: [] } } }) => ({
        items: listAnswers.items
      })
    }
  ),
)(ListAnswers));
