import React, { Component } from 'react';
import { Link } from "react-router-dom";
import QuestionHeader from "../_layout/QuestionHeader";
import Query from "../../../graphql/editor/DeleteAnswer";
import Query1 from "../../../graphql/editor/GetAnswer";
import Query2 from "../../../graphql/editor/GetQuestionOnly"
import Query3 from "../../../graphql/editor/UpdateQuestion";
import { graphql, compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';

class AnswerDelete extends Component {
  
  constructor(){
    super();
    this.state = {
      header:{
        qid: '1e99ej3827', //dynamically generated // Empty will show TBD
        breadcrumb:[
          {
            name:'Delete Answer',
            route:'',
            icon:''
          }
        ],
        action: 'link', // link | button
        action_text: 'Back', //Text inside button
        action_icon: '', //icon inside button
        action_route: '/q', //Button route
        banner_color: 'delete' //new | answer | edit | review | delete
      },
      record: {
        id: ''
      },
      answer: {
        answer: '',
        author: '',
        degree:''
      },
      question_record:{
        id:'',
        question:'',
        author:'',
        entity:'',
        entityId:'',
        count:'0',
        d1:'0',
        d2:'0',
        d3:'0',
        d4:'0',
      }
      
    }
  }
  
  handleDelete = () => {
    const { history, deleteAnswer, updateQuestion } = this.props
    const { record, question_record } = this.state;
    deleteAnswer(record);
    updateQuestion(question_record);
    
    history.push("/q/"+this.state.question_record.id);
  }

  handleCancel = () => {
    const { history } = this.props
    history.goBack();
  }

  componentWillReceiveProps(newProps) {
    
    //receiving Answers
    if (newProps && newProps.answer ) {
      this.updateState(newProps);
    }
    console.log(newProps);
  
    //receiving questions
    if (newProps.question && newProps.question.id) {
      //this.updateQuestionState(newProps);
      //console.log(newProps);
    
      const { question_record, header } = this.state;
      
        question_record.question = newProps.question.question;
        question_record.id = newProps.question.id;
        question_record.entity = newProps.question.entity;
        question_record.entityId = newProps.question.entityId;
        question_record.count = newProps.question.count;
        question_record.d1 = newProps.question.d1;
        question_record.d2 = newProps.question.d2;
        question_record.d3 = newProps.question.d3;
        question_record.d4 = newProps.question.d4;
        question_record.d1_original = newProps.question.d1;
        question_record.d2_original = newProps.question.d2;
        question_record.d3_original = newProps.question.d3;
        question_record.d4_original = newProps.question.d4;
      
        header.breadcrumb[0].route = "/q/entity/"+newProps.question.entityId;
      
        // Adding 1 to answer count
        question_record.count = question_record.count - 1;
        //Adding 1 to d3 (default)
      
        const degree = this.state.answer.degree;
        switch(degree){
          case '1': question_record.d1 = question_record.d1 - 1; console.log("D1 -1");
            break;
          case '2': question_record.d2 = question_record.d2 - 1; console.log("D2 -1");
            break;
          case '3': question_record.d3 = question_record.d3 - 1; console.log("D3 -1");
            break;
          case '4': question_record.d4 = question_record.d4 - 1; console.log("D4 -1");
            break;
          default: console.log('no change');
        }
        
    
      this.setState({ question_record, header});
      
    }
    
    //console.log(this.state);
    
  }

  updateState(props){
    const { record } = this.state;
    record.id = props.match.params.answerId;
    
    const { header } = this.state;
    header.action_route = "/q/"+props.question.id;
    header.qid = props.match.params.answerId;
    
    const { answer } = this.state;
    answer.answer = props.answer.answer;
    answer.author = props.answer.author;
    answer.degree = props.answer.degree;
  
    // const { question_record } = this.state;
    // question_record.count = props.question.count;
    // question_record.d1 = props.question.d1;
    // question_record.d2 = newProps.question.d2;
    // question_record.d3 = newProps.question.d3;
    // question_record.d4 = newProps.question.d4;

    this.setState({ record, answer });
  }

  render() {
    
    const { answer, author, degree } = this.state.answer;
    const {
      question, id, count, entity, d1, d2, d3, d4
    } = this.state.question_record;

    return (
      <div>
        <QuestionHeader header={this.state.header} />
        <div className="workarea editor_form">
          <table>
            <tbody>
              <tr>
                <td>
                  Question
                </td>
                <td>
                  <Link to={"/q/"+id}>
                  { question }
                  </Link>
                  <span style={{color:'#ccc', float:'right', fontSize:'0.6em'}}>
                    {count}
                    ( {d1} | {d2} | {d3} | {d4})
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  Entity
                </td>
                <td>
                  { entity }
                </td>
              </tr>
              <tr>
                <td>
                  <span className="tag tag-warning">To be Deleted</span>
                </td>
                <td>
                  <h3> { answer } </h3>
                </td>
              </tr>
              <tr>
                <td>
                  Author
                </td>
                <td>
                  { author } ({degree})
                </td>
              </tr>
            </tbody>
          </table>
          <div className="editor_footer">
            <div/>
            <div>
              <button className="bp3-button" onClick={this.handleCancel}>Cancel</button>
              <button className="bp3-button bp3-intent-danger" onClick={this.handleDelete}>Delete Answer</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default withRouter(withApollo(compose(

  graphql(
    Query,
    {
      props: ({ mutate }) => ({
        deleteAnswer: ({ id }) => {
          mutate({
            variables: {
              input: {
                id
              }
            }
          })
        }
      })
    }
  ),
  graphql(
    Query1,
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.answerId}
      }),
      props: ({ data: { getAnswer = {}}}) => ({
        answer: getAnswer
      })
    }
  ),
  graphql(
    Query2,
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.answer.questionId, questionId: props.answer.questionId}
      }),
      props: ({ data: { getQuestion = {}}}) => ({
        question: getQuestion
      })
    }
  ),
  graphql(
    Query3,
    {
      props: ({ mutate }) => ({
        updateQuestion: ({ id, count, d1, d2, d3, d4 }) => {
          console.log({ id, count, d1, d2, d3, d4 });
          mutate({
            variables: {
              input: {
                id,
                count,
                d1,
                d2,
                d3,
                d4
              }
            }
          })
        }
      })
    }
  )
)(AnswerDelete)));