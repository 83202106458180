import gql from "graphql-tag";

export default gql(`
query listTasks($id:String!, $limit:Int){
  listTasks(filter: { createdBy: {eq: $id}}, limit:$limit ){
    items{
      id
      name
      where
      when
      description
      entity
      bounty
      createdAt
      modifiedAt
      status
      count
      createdBy
      assignedTo
    }
  }
}`);
