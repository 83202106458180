import gql from "graphql-tag";

export default gql(`
query getQuestion($questionId:ID!){
    getQuestion(id:$questionId) {
        id
        question
        author
        lang
        entity
        entityId
        count
        d1
        d2
        d3
        d4
      
    }
}`);
