import React, {Component} from 'react'
import { graphql, compose, withApollo } from "react-apollo"
import { Switch, Route } from 'react-router-dom'
import { Grid, Row, Col } from 'react-flexbox-grid'
import ClassNames from "classnames"
import Sidebar from "./_layout/Sidebar"
import ListUserQuestions from "./editor_users/ListUserQuestions"
import ListAnswers from "./editor_answers/ListAnswers"
import ListUserAnswers from "./editor_users/ListUserAnswers"
import ListEntity from "./editor_entities/ListEntity"
import ListEntities from "./editor_entities/ListEntities"
import ListUserEntities from "./editor_entities/ListUserEntities"
import ListUserChannels from "./editor_channels/ListUserChannels"
import ListUsers from "./editor_users/ListUsers"
import ListTasks from "./editor_tasks/ListTasks"
import ListChannels from "./editor_channels/ListChannels"
import ListCreatedTasks from "./editor_tasks/ListCreatedTasks"
import ListUserTasks from "./editor_tasks/ListUserTasks"
import ListUserPosted from "./editor_tasks/ListUserPosted"
import UserCreate from "./editor_users/UserCreate"
import NewUserRegistration from "./editor_users/NewUserRegistration"
import UserDelete from "./editor_users/UserDelete"
import UserEdit from "./editor_users/UserEdit"
import UserCount from "./editor_users/UserCount"
import UserCountManual from "./editor_users/UserCountManual"
import ChannelCreate from "./editor_channels/ChannelCreate"
import QuestionCreateByEntity from "./editor_questions/QuestionCreateByEntity"
import QuestionDetail from "./editor_questions/QuestionDetail"
import QuestionEdit from "./editor_questions/QuestionEdit"
import QuestionDelete from "./editor_questions/QuestionDelete"
import AnswerCreate from "./editor_answers/AnswerCreate"
import AnswerDelete from "./editor_answers/AnswerDelete"
import AnswerEdit from "./editor_answers/AnswerEdit"
import AnswerDetail from "./editor_answers/AnswerDetail"
import EntityCreate from "./editor_entities/EntityCreate"
import EntityDelete from "./editor_entities/EntityDelete"
import EntityEdit from "./editor_entities/EntityEdit"
import EditorHome from "./editor_users/UserHome"
import UserTaskerHome from "./editor_users/UserTaskerHome"
import EntityHome from "./editor_entities/EntityHome"
import TaskCreate from "./editor_tasks/TaskCreate"
import TaskDetail from "./editor_tasks/TaskDetail"
import TaskProgress from "./editor_tasks/TaskProgress"
import TaskPayment from "./editor_tasks/TaskPayment"
import TaskEdit from "./editor_tasks/TaskEdit"
import TaskAssign from "./editor_tasks/TaskAssign"
import ConnectAccount from "./Settings/ConnectAccount"
import PaymentMethod from "./Settings/PaymentMethod"
import PaymentConfirm from './Settings/payments/PaymentConfirm'
import TestPayments from "./Settings/TestPayments"
import Transactions from "./Settings/Transactions"
import gql from "graphql-tag";
import { getUser } from '../../graphql/queries';

import HardReload from "../_utils/HardReload"


class EditorWrapper extends Component {
  
  constructor() {
    super();
    this.state = {
      db_user:{
        id:'',
        username:'',
        about:'',
        answered:'',
        asked:'',
        entities:{},
        status:'100',
        avatar:'',
        accountNumber: '',
        stripeCustomerId: '',
      },
      auth_user:{
        id:'',
        username:'',
        email:''
        
      },
      paired: false
      
    }
  }
  
  componentWillReceiveProps(newProps) {
      this.updateState(newProps);
      
      
  }
  
  componentWillMount() {
    this.updateState(this.props);
  }
  
  componentDidUpdate() {
    //sendToRegister(this.props);
  }
  
  updateState(props){
    const {db_user, auth_user} = this.state;
    
    if (props.users) {
      db_user.id= props.users.id;
      db_user.username= props.users.username;
      db_user.first= props.users.first;
      db_user.last= props.users.last;
      db_user.status= props.users.status;
      db_user.about= props.users.about;
      db_user.entities= props.users.entities;
      db_user.asked= props.users.asked;
      db_user.answered= props.users.answered;
      db_user.lang= props.users.lang;
      db_user.avatar= props.users.avatar;
      db_user.accountNumber = props.users.accountNumber;
      db_user.stripeCustomerId = props.users.stripeCustomerId;
    }
    
    if (props.loggedUser){
      auth_user.id= props.loggedUser.cognitoId;
      auth_user.username= props.loggedUser.username;
      auth_user.email= props.loggedUser.email;
    }
    
    this.setState({db_user, auth_user, paired:(db_user.id === auth_user.id)});
    
  }

  renderWelcome(db_user,auth_user){
    if(!this.state.paired){
      return(
      
        <Switch>
            <Route
              path={`${this.props.match.url}/entity/:entityId`}
              render={routeProps => <NewUserRegistration  {...routeProps} db_user={db_user} authUser={auth_user}/>}
            />
            <Route
              path={`${this.props.match.url}`}
              render={routeProps => <NewUserRegistration  {...routeProps} db_user={db_user} authUser={auth_user}/>}
          />
        </Switch>
      
      )
    }
  }
  
  
  render() {
    const {
      db_user,
      auth_user
    } = this.state;
    
    return (
      <div>
        {this.renderWelcome(db_user,auth_user)}
        
        <Grid fluid className={ClassNames('editor',{hidden: !this.state.paired })} >
          <Row style={{height:'100%'}}>
            <Col xs={12} md={1} style={{ paddingRight:'1.5px'}} className="mobile_hide">
              
                <Switch>
                  <Route path={`${this.props.match.url}/user/:id/profile`}
                         render={routeProps => <Sidebar {...routeProps}
                                                        db_user={db_user}
                                                        auth_user={auth_user}
                                                        select={'u'}/>}/>
  
                  <Route exact path={`${this.props.match.url}/user/:id`}
                         render={routeProps => <Sidebar {...routeProps}
                                                        db_user={db_user}
                                                        auth_user={auth_user}
                                                        select={'q'}/>}/>
                  
                  <Route path={`${this.props.match.url}/users`}
                         render={routeProps => <Sidebar {...routeProps}
                                                        db_user={db_user}
                                                        auth_user={auth_user}
                                                        select={''}/>}/>
  
  
                  <Route path={`${this.props.match.url}/answers/:userId`}
                         render={routeProps => <Sidebar {...routeProps}
                                                        db_user={db_user}
                                                        auth_user={auth_user}
                                                        select={'a'}/>}/>
                  
                  <Route exact path={`${this.props.match.url}/answers`}
                         render={routeProps => <Sidebar {...routeProps}
                                                        db_user={db_user}
                                                        auth_user={auth_user}
                                                        select={''}/>}/>
  
                  <Route path={`${this.props.match.url}/entity/:entityId`}
                         render={routeProps => <Sidebar {...routeProps}
                                                        db_user={db_user}
                                                        auth_user={auth_user}
                         />
                         }/>
  
                  <Route path={`${this.props.match.url}/tasks`}
                         render={routeProps => <Sidebar {...routeProps}
                                                        db_user={db_user}
                                                        auth_user={auth_user}
                                                        select={'t'}
                         />
                         }/>
                  <Route path={`${this.props.match.url}/usertasks/:userId`}
                         render={routeProps => <Sidebar {...routeProps}
                                                        db_user={db_user}
                                                        auth_user={auth_user}
                                                        select={'mt'}
                         />
                         }/>
  
                  <Route path={`${this.props.match.url}/user/:userId/edit`}
                         render={routeProps => <Sidebar {...routeProps}
                                                        db_user={db_user}
                                                        auth_user={auth_user}
                                                        select={'s'}
                         />}/>
    
                  <Route path={`${this.props.match.url}`}
                         render={routeProps => <Sidebar {...routeProps}
                                                        db_user={db_user}
                                                        auth_user={auth_user}
                                                        select={''}
                         />}/>
  
                </Switch>
              
            </Col>
            <Col xs={12} md={11}>
              
              <Row center="xs">
                <Col xs={12} md={12}>
                
        
                 <div className="result_wrapper" >
                
                    <div className="workarea_wrapper">
            
                  <Switch key={this.props.location.key}>
  
                    {/*HARD RELOAD*/}
                    {/*todo: This should not exist, all the data should update correctly after fetching*/}
                    <Route exact path={`${this.props.match.url}/hard`}
                           render={routeProps => <HardReload {...routeProps} authUser={auth_user} db_user={db_user}/>}/>
              
                    {/*LIST ALL QUESTIONS*/}
                    {/*<Route exact path={this.props.match.url}*/}
                           {/*render={routeProps => <ListQuestions {...routeProps} db_user={db_user}/>}/>*/}
                    <Route exact path={this.props.match.url}
                           render={routeProps => <ListEntities {...routeProps} db_user={db_user}/>}/>
                    
                    {/*LIST ALL ENTITIES*/}
                    <Route path={`${this.props.match.url}/entities`}
                           render={routeProps => <ListEntities {...routeProps} db_user={db_user}/>}/>
                    {/*CREATE NEW ENTITY */}
                    <Route path={`${this.props.match.url}/newentity`}
                           render={routeProps => <EntityCreate {...routeProps} db_user={db_user}/>}/>
  
                    {/*ASK ENTITY A QUESTION + TASKID*/}
                    <Route path={`${this.props.match.url}/entity/:entityId/ask/:entityName/:taskId`}
                           render={routeProps => <QuestionCreateByEntity {...routeProps} db_user={db_user}/>}/>
  
                    {/*ASK ENTITY A QUESTION*/}
                    <Route path={`${this.props.match.url}/entity/:entityId/ask/:entityName`}
                           render={routeProps => <QuestionCreateByEntity {...routeProps} db_user={db_user}/>}/>
  
                    {/*CREATE NEW ENTITY TASK*/}
                    <Route path={`${this.props.match.url}/entity/:entityId/newtask`}
                           render={routeProps => <TaskCreate {...routeProps} db_user={db_user}/>}/>
                    
                    
                    {/*UPDATE ENTITY BY ID*/}
                    <Route path={`${this.props.match.url}/entity/:entityId/edit`}
                           render={routeProps => <EntityEdit {...routeProps} db_user={db_user}/>}/>
                    {/*DELETE ENTITY BY ID*/}
                    <Route path={`${this.props.match.url}/entity/:entityId/delete`}
                           render={routeProps => <EntityDelete {...routeProps} db_user={db_user}/>}/>
                    {/*GET ENTITY PROFILE */}
                    <Route path={`${this.props.match.url}/entity/:entityId/info`}
                           render={routeProps => <EntityHome {...routeProps} db_user={db_user}/>}/>
                    {/*GET ENTITY QUESTIONS */}
                    <Route exact path={`${this.props.match.url}/entity/:entityId`}
                           render={routeProps => <ListEntity {...routeProps} db_user={db_user}/>}/>
                    
                    
                    
                    {/*LIST ALL USERS*/}
                    <Route path={`${this.props.match.url}/users`}
                           render={routeProps => <ListUsers {...routeProps} db_user={db_user} authUser={auth_user} />}/>
                    {/*CREATE NEW USER */}
                    <Route path={`${this.props.match.url}/newuser`}
                           render={routeProps => <UserCreate {...routeProps} db_user={db_user}/>}/>
                    {/*LIST ALL QUESTIONS BY USER*/}
                    <Route exact path={`${this.props.match.url}/user/:userId`}
                           render={routeProps => <ListUserQuestions {...routeProps} db_user={db_user}/>}/>
                    {/*COUNT USER BY ID*/}
                    <Route path={`${this.props.match.url}/user/:userId/count`}
                           render={routeProps => <UserCount {...routeProps} db_user={db_user}/>}/>
                    {/*MANUAL COUNT USER BY ID*/}
                    <Route path={`${this.props.match.url}/user/:userId/mcount`}
                           render={routeProps => <UserCountManual {...routeProps} db_user={db_user}/>}/>
                    {/*DELETE USER BY ID*/}
                    <Route path={`${this.props.match.url}/user/:userId/delete`}
                           render={routeProps => <UserDelete {...routeProps} db_user={db_user}/>}/>
                    {/*GET USER PROFILE*/}
                    <Route path={`${this.props.match.url}/user/:userId/oldprofile`}
                           render={routeProps => <EditorHome {...routeProps} db_user={db_user} authUser={auth_user}/>}/>
                    {/*GET USERTASKER PROFILE*/}
                    <Route path={`${this.props.match.url}/user/:userId/profile`}
                           render={routeProps => <UserTaskerHome {...routeProps} db_user={db_user} authUser={auth_user}/>}/>
                    {/*LIST USER ENTITIES*/}
                    <Route path={`${this.props.match.url}/user/:userId/entities`}
                           render={routeProps => <ListUserEntities {...routeProps} db_user={db_user}/>}/>
                    
  
                    {/*LIST ALL ANSWERS*/}
                    <Route exact path={`${this.props.match.url}/answers`}
                           render={routeProps => <ListAnswers {...routeProps} db_user={db_user}/>}/>
                    {/*LIST ALL ANSWERS BY USER */}
                    <Route exact path={`${this.props.match.url}/answers/:userId`}
                           render={routeProps => <ListUserAnswers {...routeProps} db_user={db_user}/>}/>
                    {/*UPDATE ANSWER BY ID*/}
                    <Route path={`${this.props.match.url}/answer/:answerId/edit`}
                           render={routeProps => <AnswerEdit {...routeProps} db_user={db_user}/>}/>
                    {/*DELETE ANSWER BY ID*/}
                    <Route path={`${this.props.match.url}/answer/:answerId/delete`}
                           render={routeProps => <AnswerDelete {...routeProps} db_user={db_user}/>}/>
                    {/*GET ANSWER BY ID*/}
                    <Route path={`${this.props.match.url}/answer/:answerId`}
                           render={routeProps => <AnswerDetail {...routeProps} db_user={db_user}/>}/>
                    {/*CREATE NEW ANSWER - RELATED TO TASK */}
                    <Route path={`${this.props.match.url}/:questionId/answer/:taskId`}
                           render={routeProps => <AnswerCreate {...routeProps} db_user={db_user}/>}/>
                    {/*CREATE NEW ANSWER (FOR SPECIFIC QUESTION) */}
                    <Route path={`${this.props.match.url}/:questionId/answer`}
                           render={routeProps => <AnswerCreate {...routeProps} db_user={db_user}/>}/>
  
  
                    {/*LIST ALL TASKS*/}
                    <Route exact path={`${this.props.match.url}/tasks`}
                           render={routeProps => <ListTasks {...routeProps} db_user={db_user}/>}/>
                    <Route exact path={`${this.props.match.url}/usertasks/:userId`}
                           render={routeProps => <ListUserTasks {...routeProps} db_user={db_user}/>}/>
                    {/*<Route exact path={`${this.props.match.url}/usertasks/:userId/:filter`}*/}
                           {/*render={routeProps => <ListUserTasks {...routeProps} db_user={db_user}/>}/>*/}
                    <Route exact path={`${this.props.match.url}/userposted/:userId`}
                           render={routeProps => <ListUserPosted {...routeProps} db_user={db_user}/>}/>
                    <Route exact path={`${this.props.match.url}/tasksby/:userId`}
                           render={routeProps => <ListCreatedTasks {...routeProps} db_user={db_user}/>}/>
                    <Route exact path={`${this.props.match.url}/newtask`}
                           render={routeProps => <TaskCreate {...routeProps} db_user={db_user}/>}/>
                    <Route exact path={`${this.props.match.url}/task/:id/progress`}
                           render={routeProps => <TaskProgress {...routeProps} db_user={db_user}/>}/>
                    
                    <Route exact path={`${this.props.match.url}/task/:id/edit`}
                           render={routeProps => <TaskEdit {...routeProps} db_user={db_user}/>}/>
                    <Route exact path={`${this.props.match.url}/task/:id/assign`}
                           render={routeProps => <TaskAssign {...routeProps} db_user={db_user}/>}/>
                    <Route exact path={`${this.props.match.url}/task/:id/payment`}
                           render={routeProps => <TaskPayment {...routeProps} db_user={db_user}/>}/>
                    <Route exact path={`${this.props.match.url}/task/:id`}
                           render={routeProps => <TaskDetail {...routeProps} db_user={db_user}/>}/>
                    
  
                    {/*CHANNELS*/}
                    <Route exact path={`${this.props.match.url}/settings/channels`}
                           render={routeProps => <ListChannels {...routeProps} db_user={db_user}/>}/>
                    <Route exact path={`${this.props.match.url}/settings/newchannel`}
                           render={routeProps => <ChannelCreate {...routeProps} db_user={db_user}/>}/>
                    <Route path={`${this.props.match.url}/settings/:userId/channels`}
                           render={routeProps => <ListUserChannels {...routeProps} db_user={db_user}/>}/>
                    
                    {/*SETTINGS*/}
                    
                    <Route path={`${this.props.match.url}/settings/:userId/edit`}
                           render={routeProps => <UserEdit {...routeProps} db_user={db_user} auth_user={auth_user}/>}/>
                    <Route path={`${this.props.match.url}/settings/:userId/connect`}
                           render={routeProps => <ConnectAccount {...routeProps} db_user={db_user}/>}/>
                    <Route path={`${this.props.match.url}/settings/:userId/paymentmethods`}
                           render={routeProps => <PaymentMethod {...routeProps} db_user={db_user} />} />
                    <Route path={`${this.props.match.url}/settings/:userId/testpayments`}
                           render={routeProps => <TestPayments {...routeProps} db_user={db_user}/>}/>
                    <Route path={`${this.props.match.url}/settings/:userId/transactions`}
                           render={routeProps => <Transactions {...routeProps} db_user={db_user}/>}/>
                    <Route path={`${this.props.match.url}/settings/payment_confirm`}
                           render={routeProps =><PaymentConfirm {...routeProps} db_user={db_user}/>} />
                    
                    
                    {/*UPDATE QUESTION*/}
                    <Route path={`${this.props.match.url}/:questionId/edit`}
                           render={routeProps => <QuestionEdit {...routeProps} db_user={db_user}/>}/>
                    {/*DELETE QUESTION*/}
                    <Route path={`${this.props.match.url}/:questionId/delete`}
                           render={routeProps => <QuestionDelete {...routeProps} db_user={db_user}/>}/>
                    {/*GET QUESTION BY ID*/}
                    <Route path={`${this.props.match.url}/:questionId`}
                           render={routeProps => <QuestionDetail {...routeProps} db_user={db_user}/>}/>
                    
                  </Switch>
          
                </div>
                
              </div>

                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      
      </div>
    
    );
  }
}


export default withApollo(compose(
  graphql(
    gql(getUser),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.loggedUser.cognitoId}
      }),
      props: ({ data: { getUser = {}}}) => ({
        users: getUser
      })
    }
  )
)(EditorWrapper));
