import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import myconfig from '../../../../custom-config';

import './AddCard.css';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    // User clicked submit
    let { token } = await this.props.stripe.createToken({ name: "Name" });
    console.log(token)
    const res = await fetch(`${myconfig.Stripe.apiUrl}/customer`, {
      method: 'POST',
      body: JSON.stringify({
        token,
        description: 'F400 customer',
        email:'fake@mail.com'
      })
    });
    const data = await res.json()
    console.log(data)
    if (data.message && data.message.includes('success')) {
      this.props.setCustomer(data.customer)
    } else {
      return null
    }
  }

  render() {
    return (
      <div className="checkout">
        <CardElement />
        <button className="stripe_button" onClick={this.submit}>Add</button>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);