import React, { Component } from "react";
import { Route, Switch } from 'react-router-dom';
import EntityTabs from "./EntityTabs";

class EntityLeftbar extends Component {
  
  render() {
    const match = this.props.match;
    const embed = this.props.match.params.config;
    
    return (
      <div className="entity_leftbar">
        {/*HIDE MENU IF EMBED IS TRUE*/}
        {!embed &&(
          <Switch>
            <Route exact path={match.url} render={routeProps => <EntityTabs {...routeProps} name={this.props.header.title} status={this.props.status} />} />
            <Route exact path={`${match.url}/:entityId/q/:questionId`} render={routeProps => <EntityTabs {...routeProps} name={this.props.header.title} status={this.props.status} />} />
            <Route exact path={`${match.url}/i/share`} render={routeProps => <EntityTabs {...routeProps} name={this.props.header.title} status={this.props.status} />} />
            <Route exact path={`${match.url}/ask`} render={routeProps => <EntityTabs {...routeProps} name={this.props.header.title} status={this.props.status} />} />
            <Route exact path={`${match.url}/analytics`} render={routeProps => <EntityTabs {...routeProps} name={this.props.header.title} status={this.props.status} />} />
          </Switch>
        )}
        
      </div>
    
    );
  }
  
}


export default EntityLeftbar;
