import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Authenticator} from 'aws-amplify-react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Amplify from 'aws-amplify'
import awsmobile from '../../aws-exports'
import myconfig from '../../custom-config'
import MyTheme from './AmplifyTheme/f400'
import { Icon } from "@blueprintjs/core";


import {
  setUser
} from '../../_actions';

Amplify.configure(awsmobile);
Amplify.configure(myconfig);


class SignUpWrapper extends Component {
  
  constructor() {
    super();
    this.state = {
      showDetails:true,
      entity:{
          id:'x'
      },
      authStep:''
    }
  
    this.handleAuthStateChange = this.handleAuthStateChange.bind(this);
  }
  
  componentWillMount() {
    this.updateState();
  }
  
  
  handleAuthStateChange(authState){
    
    this.setState({ authStep: authState });
    const {history} = this.props;
    
    if(authState==='signedUp'){
      //history.push("/q/welcome/"+this.state.entity.id);
      history.push("/q/entity/"+this.state.entity.id+"/info");
    }

    if(authState==='signedIn'){
      history.push("/q/entity/"+this.state.entity.id+"/info");
    }
  }
  
  
  async updateState() {
    
    const { entity } = this.state;
    
    entity.id= this.props.match.params.id;
    
    this.setState({ entity });
  }
  
  progress(){
    
    let authStep = this.state.authStep;
    let progress = '10%';
    
    switch(authStep){
      case 'signUp': progress = '50%';
      break;
      case 'confirmSignUp': progress = '75%';
        break;
      case 'signedUp': progress = '90%';
        break;
      case 'signedIn': progress = '100%';
        break;
      default: progress= '10%';
    }
    
    return progress;
  }
  
  renderStepInstructions(step){
    
    let header1='';
    let icon='';
    
    switch(step){
      case 'signUp': header1='Phone Number is optional';
        break;
      case 'confirmSignUp': header1='Check your email for a 6 digit code'; icon='envelope';
        break;
      case 'signedUp': header1='Login to your new account';
        break;
      case 'signedIn': header1='';
        break;
      default: header1='';
    }
    
    
    return (
      <div style={{display:'flex'}}>
        <div className="speech-bubble">
          <div>
            {icon &&
              <div style={{marginBottom:'10px'}}>
                <Icon icon={icon} iconSize={25} color="#a998ca" />
                
              </div>
            }
            
            {header1}
          </div>
        </div>
      </div>
    );
  }
  
  
  render() {
    
    return (
      <div className="welcome_screen">
  
        <Grid>
  
          <Row center="xs">
            <Col xs={12} sm={2} md={2} >
              {this.renderStepInstructions(this.state.authStep)}
            </Col>
            <Col xs={12} sm={9} md={8} >
              <Authenticator
                theme={MyTheme}
                //forgotPassword | signIn | signUp |
                authState='signUp'
                onStateChange={(authState) => this.handleAuthStateChange(authState)}
              >
  
              </Authenticator>
            </Col>
            
          </Row>
          
          
        </Grid>
        
      </div>
    
    );
  }
  
}

SignUpWrapper.propTypes = {
  setUserData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  setUserData: value => dispatch(setUser(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpWrapper);