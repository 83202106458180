import React, { Component } from "react";
import {Auth} from 'aws-amplify';
import './Layout.css';
import bye from '../_layout/assets/waving.png'

class SignOut extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }
  
  componentDidMount(){
    this.signOut()
  }
  signOut(){
    Auth.signOut();
  }
  
  
  render() {
    
    console.log('signout here');
    //this.props.history.push('/q');
  
    return (
      
      <div className="signup_screen">
  
        <img style={{width: '100px'}}
             src={bye} alt="bye"/>
        <br/>
        
        <h1>See you next time</h1>
        <a href="/q/tasks">
          Sign up again
        </a>
        
      </div>
      
    );
  }
  
}


export default SignOut;
