import React, { Component } from "react";
import {Link} from "react-router-dom";

class EntityRightbar extends Component {
  
  render() {
    const {entityId, slug} = this.props.match.params;
    const embed = this.props.match.params.config;
    
    return (
      <React.Fragment>
      {!embed &&(
         <div className="entity_rightbar">
           <div className="corner">
           </div>
  
           <Link to={`/questions/${slug}/${entityId}/ask`} className="button join">
             Ask any question
           </Link>
           
         </div>
       )}
      </React.Fragment>
    
    );
  }
  
}


export default EntityRightbar;
