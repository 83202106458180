import React, { Component } from 'react'
import { graphql, compose, withApollo } from "react-apollo"
import { Link } from "react-router-dom"
import kebabCase from "lodash/kebabCase"
import truncate from "lodash/truncate"
import { Icon} from "@blueprintjs/core"
import { Spring } from 'react-spring'
import {Helmet} from "react-helmet"
import gql from "graphql-tag"
import { Row, Col } from 'react-flexbox-grid'
import { getEntity, getAnswer } from '../../graphql/queries';
import {renderCredit, friendlyDate, renderTag, renderCTA} from '../_utils/helpers'
import EntityHeader from "./_layout/EntityHeader"
import EntityLeftbar from "./_layout/EntityLeftbar"
import EntityRightbar from "./_layout/EntityRightbar"

class EntityAnswerDetail extends Component {
  
  constructor(){
    super();
    this.state = {
      header:{
        title: '',
        displayName: '',
        counter:'',
        entityLogo:'',
        wall:'',
        theme:'light'
      },
      answer: {
        questionId: '12345',
        question: 'No Question',
        answer: '',
        author: '',
        entity: 'No PublicView'
      },
      embed:false
    }
  }
  
  renderHelmet(){
    
    const { answer, author, lang, degree, question} = this.state.answer;
    const {displayName, entityLogo, title}= this.state.header;
    
    return(
      <Helmet>
        <title>{`${displayName} Answer : ${truncate(answer, {length:50})}, (${author})`}</title>
        
        <meta name="description" content={`This is the answer to the ${displayName} question: ${question}`}/>
        <meta property="og:description" content={`${displayName} Answer: ${answer}, Language: ${lang} , degree: ${degree} `} />
        <meta property="og:image" content={`${entityLogo}`} />
        <meta property="og:title" content={`${displayName} answer by ${author}`} />
        <meta property="og:type" content="website" />
      
      </Helmet>
    )
  }
  
  componentWillReceiveProps(newProps) {
    if(newProps && newProps.answer) {
      this.updateState(newProps)
    }
  }
  
  async updateState(props){
  
    const { entity } = props;
    
    // PublicView Header Info
    const { header } = this.state;
    header.title = entity.name;
    header.displayName = entity.displayName;
    header.name = entity.name;
    header.entityId = entity.id;
    header.entityLogo = entity.logo;
    header.wall = entity.wall;
    header.theme = (entity.theme? entity.theme: 'light');
    header.id = (entity? entity.id: '');
    
    const {answer} = this.state;
    
    console.log(props)
    answer.answer = props.answer.answer;
    answer.author = props.answer.author;
    answer.degree = props.answer.degree;
    answer.rating = props.answer.rating;
    answer.createdAt = props.answer.createdAt;
    answer.modifiedAt = props.answer.modifiedAt;
    answer.credit = props.answer.credit;
    answer.lang = props.answer.lang;
    answer.bounty = props.answer.bounty;
    
    let result = {}
    const {client} = props;
    try {
      result = await client.query({
        query: gql`
            query getQuestion($id: ID!){
                getQuestion(id: $id) {
                    id
                    question
                    author
                    lang
                    entity
                }
            }
        `,
        variables: {
          id: props.answer.questionId,
        },
      });
    } catch (error) {
      console.log(error);
    }
    
    if ( result && result.data) {
      answer.question = result.data.getQuestion.question;
      answer.questionId = result.data.getQuestion.id;
      answer.entity =  result.data.getQuestion.entity;
    }
    this.setState({header, answer, embed:(this.props.match.params.config === 'embed')});
  }
  
  handleBack = () => {
    const { history } = this.props
    history.goBack();
  }
  
  
  renderResults() {
    
    const { question, questionId, answer, author, entity, lang, degree, rating, createdAt, modifiedAt, credit, bounty  } = this.state.answer;
    
    return (
      <Row center="xs">
        <Col xs={this.state.embed? 12 : 11} md={this.state.embed? 12 : 8}>
         <div style={{textAlign:'left'}}>
        <div style={{fontSize:'0.8em', opacity:'0.7'}}>
          <Link to={`/questions/${(kebabCase(entity))}/${this.props.match.params.entityId}/q/${questionId}/${(kebabCase(truncate(question)))}${(this.state.embed === true ? '/embed':'')}`}>
            <Icon icon="arrow-left" iconSize={15} /> Back
          </Link>
        </div>
        <table className="answer_table">
          <tbody>
          <tr>
            <td>Question:</td>
            <td>
              <Link to={`/questions/${(kebabCase(entity))}/${this.props.match.params.entityId}/q/${questionId}/${(kebabCase(truncate(question)))}${(this.state.embed === true ? '/embed':'')}`}>
                  <h2>{question}</h2>
              </Link>
              
            </td>
          </tr>
  
          {!entity ? null :(
            <tr>
              <td>Entity:</td>
              <td>
                <Link to={`/questions/${(kebabCase(entity))}/${this.props.match.params.entityId}${(this.state.embed === true ? '/embed':'')}`}>
                  { entity }
                </Link>
              </td>
            </tr>
          )}
          
          <tr>
            <td>Reply:</td>
            <td>
              <h1>{ answer }</h1>
            </td>
          </tr>
          
          {bounty &&(
            <tr>
              <td>CTA:</td>
              <td>
                <div style={{margin:'20px 0'}}>
                  { renderCTA(bounty,'button') }
                </div>
              </td>
            </tr>
          )}
  
          {credit ? (
            <tr>
              <td>Credit:</td>
              <td>
                <div style={{margin:'10px 0'}}>
                  { renderCredit(this.state.answer)}
                </div>
              </td>
            </tr>
          ):(
            <tr>
              <td>Credit:</td>
              <td>
                <div style={{margin:'10px 0'}}>
                  { author } {renderTag(degree)}
                </div>
              </td>
            </tr>
          )}
  
          {!lang ? null :(
            <tr>
              <td>Language:</td>
              <td>{ lang }</td>
            </tr>
          )}
  
          {!rating ? null :(
            <tr>
              <td>Rating:</td>
              <td>{ rating }</td>
            </tr>
          )}
  
          {!createdAt ? null :(
            <tr>
              <td>Created </td>
              <td>{ friendlyDate(createdAt) }</td>
            </tr>
          )}
  
          {!modifiedAt ? null :(
            <tr>
              <td>Modified </td>
              <td>{ friendlyDate(modifiedAt) }</td>
            </tr>
          )}

          <tr>
            <td></td>
            <td>
              <div style={{margin:'40px 0 200px 0'}}>
                {/*<Link className='button' to={"/join/"+this.state.header.id}>*/}
                {/*Answer this*/}
                {/*</Link>*/}
                <Link className='primary entity_action_right' to={"/q/"+questionId+"/answer"}>
                  Provide your own answer
                </Link>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        
      </div>
        </Col>
      </Row>
    );
  }
  
  render(){
    
    const bg = this.state.header.wall;
    
    console.log(this.props);
    
    return(
      
      <div className={"theme_wrapper "+ this.state.header.theme}>
        
        {this.renderHelmet()}
        
        <div  className="entity_background" style={{ backgroundImage:`url(${bg})` }} />
        
        {!this.state.embed &&(
          <EntityHeader header={this.state.header} match={this.props.match} />
        )}
        
        <EntityLeftbar header={this.state.header} match={this.props.match} />
        <EntityRightbar header={this.state.header} match={this.props.match} />
        
        <div style={{paddingTop:'20px'}}>
          
          <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
            {props => <div style={props}>{this.renderResults()}</div>}
          </Spring>
        
        
        </div>
      </div>
    
    );
  }
}

export default withApollo(compose(
  
  graphql(
    gql(getAnswer),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.answerId}
      }),
      props: ({ data: { getAnswer = {}}}) => ({
        answer: getAnswer
      })
    }
  ),
  graphql(
    gql(getEntity),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.entityId, entityId: props.match.params.entityId}
      }),
      props: ({ data: { getEntity = {}}}) => ({
        entity: getEntity
      })
    }
  )
)(EntityAnswerDetail));