import React from 'react';
import amex from 'payment-icons/min/flat/amex.svg'
import generic from 'payment-icons/min/flat/default.svg'
import diners from 'payment-icons/min/flat/diners.svg'
import discover from 'payment-icons/min/flat/discover.svg'
import mastercard from 'payment-icons/min/flat/mastercard.svg'
import unionpay from 'payment-icons/min/flat/unionpay.svg'
import jcb from 'payment-icons/min/flat/jcb.svg'
import visa from 'payment-icons/min/flat/visa.svg'



export function renderSingleCard(cust, last, active, brand, onclick) {
  
  return(
    <button className={active ? 'ccard active' : 'ccard' } onClick={onclick}>
      <img src={renderCardLogo(brand)} className="cc_icon" alt=""/>
      <span className="last">
          {last}
        </span>
    </button>
  )

}

export function renderCardLogo(code) {
  
  let logo =null;
  
  switch(code){
    case 'American Express': logo = amex;
      break;
    case 'Diners Club': logo = diners;
      break;
    case 'Discover': logo = discover;
      break;
    case 'MasterCard': logo = mastercard
      break;
    case 'UnionPay': logo = unionpay;
      break;
    case 'Visa': logo = visa;
      break;
    case 'JCB': logo = jcb;
      break;
    default: logo= generic;
  }
  
  return logo;
  
}


