import React, { Component } from 'react'
import { graphql, compose, withApollo } from 'react-apollo'
import { withRouter } from 'react-router'
import {Auth} from 'aws-amplify'
import { Spring } from 'react-spring'
import kebabCase from "lodash/kebabCase"
import truncate from "lodash/truncate"
import gql from "graphql-tag"
import {Helmet} from "react-helmet"
import { getEntity} from '../../graphql/queries'
import Invite from './Share/Share'
import Chatbots from './Share/Chatbots'
import WebEmbed from './Share/WebEmbed'
import Join from './Share/Join'
import EntityHeader from "./_layout/EntityHeader"
import EntityLeftbar from "./_layout/EntityLeftbar"
import EntityRightbar from "./_layout/EntityRightbar"

class EntityIntegrations extends Component {
  
  constructor() {
    super();
    this.state = {
      header: {
        title: '',
        displayName: '',
        counter:'',
        entityLogo:'',
        wall:'',
        theme:'light'
      },
      record: {
        question: '',
        author: 'unregistered',
        authorId: '104',
        entity: 'Entity',
        entityId: 'entityId',
        entityLogo: 'entityLogo',
        lang: 'ENG',
        status: 1,
        rated: 'G',
        createdAt: ''
      },
      entities:'',
      selectedOption: null,
      showDetails:false
      
    }
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps.entity && newProps.entity.id) {
      this.updateState(newProps);
    }
  }
  
  renderHelmet(){
    
    return(
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex"/>
      </Helmet>
    )
  }
  
  updateState(props){
    
    // PublicView Results from AWS DynamoDB using graphql
    const { entity } = props;
    
    // PublicView Header Info change
    const { header } = this.state;
    header.title = entity.name;
    header.displayName = entity.displayName;
    header.entityLogo = entity.logo;
    header.name = entity.name;
    header.wall = entity.wall;
    header.theme = (entity.theme ? entity.theme: 'light');
    header.id = entity.id;
    
    const { record } = this.state;
    record.entity = (entity? entity.name: 'No PublicView');
    record.displayName = (entity? entity.displayName: '');
    record.entityId = (entity? entity.id: 'No EntityId');
    
    this.setState({header, record});
  }
  
  
  async componentDidMount() {
    
    // get user
    let user = await Auth.currentAuthenticatedUser();
    
    //updating  user info
    const { record } = this.state;
    record['author']= user.username;
    record['authorId']= user.attributes.sub;
    
    //Getting the entities List from GQL
    const entities = this.props.entities;
    
    this.setState({ record, entities });
    
  }
  
  handleEntityChange = (selectedOption) => {
    
    const { record } = this.state;
    record['entityId'] = selectedOption.value;
    record['entity'] = selectedOption.label;
    record['entityLogo'] = selectedOption.logo;
    
    this.setState({ record });
    
    
    console.log(`Option selected:`, selectedOption);
  };
  
  
  handleValues = field => event => {
    const { record } = this.state;
    record[`${field}`] = event.target.value;
    this.setState({ record });
    
  };
  
  
  renderTabs(){
    
    const slug = kebabCase(truncate(this.state.record.entity));
    let selected = '';
    
    switch(this.props.match.params.type){
      case 'share': selected = <Invite type={this.props.match.params.type} slug={slug} record={this.state.record} />;
      break;
      case 'join': selected = <Join type={this.props.match.params.type} slug={slug} record={this.state.record} />;
        break;
      case 'web': selected = <WebEmbed type={this.props.match.params.type} slug={slug} record={this.state.record} />;
      break;
      case 'bots': selected = <Chatbots type={this.props.match.params.type} slug={slug} record={this.state.record} />;
      break;
      default: selected = <Invite type={this.props.match.params.type} slug={slug} record={this.state.record} />;
    }
    
    return selected;
    
  }
  
  
  
  render(){
    const bg = this.state.header.wall;
    
    return(
      <div className={"theme_wrapper "+ this.state.header.theme}>
        {this.renderHelmet()}
        <div  className="entity_background" style={{ backgroundImage:`url(${bg})` }} />
  
        <EntityLeftbar header={this.state.header} match={this.props.match} />
        <EntityHeader header={this.state.header} match={this.props.match} />
        <EntityRightbar header={this.state.header} match={this.props.match} />
        
        <div className="entity_settings">
            <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
              {props => <div style={props}>{this.renderTabs()}</div>}
            </Spring>
        </div>
        
      </div>
    );
  }
  
}

export default withRouter(withApollo(compose(
  
  graphql(
    gql(getEntity),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.entityId, entityId: props.match.params.entityId}
      }),
      props: ({ data: { getEntity = {}}}) => ({
        entity: getEntity
      })
    }
  )
)(EntityIntegrations)));
