import React, {Component} from 'react';
import QuestionHeader from "../_layout/QuestionHeader";
import Query from "../../../graphql/editor/CreateUser";
import { graphql, compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setHeader
} from '../../../_actions';

class UserCreate extends Component {

  constructor(){
    super();
    this.state = {
      header:{
        title:'Add User', //Optional
        qid:'', //dynamically generated // Empty will show TBD
        status:'', // 1 | 2 | 3 | 4 | 5 | 6 (need to be INT)
        action:'link', // link | button
        action_text:'Back', //Text inside button
        action_icon:'arrow-left', //icon inside button
        action_route:'/q', //Button route
        banner_color:'new' //new | answer | edit | review | delete
      },
      record: {
        username: '',
        first:'',
        last:'',
        email: '-',
        about: '-',
        answered: '100',
        asked: '150',
        locations: '-',
        lang: 'ENG'
      }
    }
  }

  componentWillMount = () => {
    const { setHeaderData } = this.props;
    setHeaderData(this.state.header);
  }

  handleValues = field => event => {
    const { record } = this.state
    record[`${field}`] = event.target.value;
    this.setState({ record });
  }

  handleCreate = () => {
    // aws
    const { history, createUsers } = this.props
    const { record } = this.state;
    console.log(record);
    createUsers(record);

    history.push("/q/users");
  }

  handleCancel = () => {
    const { history } = this.props
    history.goBack();
  }

  render() {
    const {
      username,
      first,
      last,
      email,
      about,
      locations,
      lang
    } = this.state.record
    return (
      <div>
        <QuestionHeader header={this.state.header} />
        
        <div className="workarea editor_form">
          <table>
            <tbody>
              <tr>
                <td width="150px">
                  <span className="tag">
                    Username
                  </span>
                </td>
                <td>
                  <input className="bp3-input" placeholder="e.g. MickeyMouse" style={{width:'100%'}} value={username} onChange={this.handleValues('username')}/>
                </td>
              </tr>
              <tr>
                <td>
                  First
                </td>
                <td>
                  <input className="bp3-input" placeholder="e.g. Mickey" style={{width:'100%'}} value={first} onChange={this.handleValues('first')}/>
                </td>
              </tr>
              <tr>
                <td>
                  Last
                </td>
                <td>
                  <input className="bp3-input" placeholder="e.g. Mouse" style={{width:'100%'}} value={last} onChange={this.handleValues('last')}/>
                </td>
              </tr>
              <tr>
                <td>
                  email
                </td>
                <td>
                  <input className="bp3-input" placeholder="e.g. mickey@gmali.com" style={{width:'100%'}} value={email} onChange={this.handleValues('email')}/>
                </td>
              </tr>
              <tr>
                <td>
                  About
                </td>
                <td>
                  <textarea className="bp3-input" style={{width:'100%'}} placeholder="A very famous mouse" value={about} onChange={this.handleValues('about')}>
                  </textarea>
                </td>
              </tr>
              <tr>
                <td>
                  Locations
                </td>
                <td>
                  <input className="bp3-input" placeholder="United States" style={{width:'100%'}} value={locations} onChange={this.handleValues('locations')}/>
                </td>
              </tr>
              <tr>
                <td width="150px">
                  Primary Language
                </td>
                <td>
                  <div className="bp3-select .modifier">
                    <select className="" value={lang} onChange={this.handleValues('lang')}>
                      <option >Choose a language...</option>
                      <option selected value="ENG">English</option>
                      <option value="SPA">Spanish</option>
                      <option value="POR">Portuguese</option>
                      <option value="MAN">Mandarin</option>
                      <option value="GER">German</option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <br/>
          <div className="editor_footer">
            <div>
            </div>
            <div>
              <button className="bp3-button" onClick={this.handleCancel}>Cancel</button>
              <button className="bp3-button bp3-intent-primary"  onClick={this.handleCreate}>Create</button>
            </div>
          </div>
        
        </div>
      </div>
    
    );
  }
  
}

UserCreate.propTypes = {
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setHeaderData: value => dispatch(setHeader(value)),
});

const UserCreateConnected = connect(mapStateToProps, mapDispatchToProps)(UserCreate);

export default withRouter(withApollo(compose(

  graphql(
    Query,
    {
      props: ({ mutate }) => ({
        createUsers: ({ username, first, last, email, about, asked, answered, locations, lang }) => {
          mutate({
            variables: {
              input: {
                username,
                first,
                last,
                email,
                about,
                asked,
                answered,
                locations,
                lang
              }
            }
          })
        }
      })
    }
  ),
)(UserCreateConnected)));