import React, { Component } from 'react'
import ReactTable from "react-table"
import { graphql, compose, withApollo } from "react-apollo"
import { connect } from 'react-redux'
import { Link} from "react-router-dom"
import matchSorter from 'match-sorter'
import PropTypes from 'prop-types'
import gql from "graphql-tag";
import LinkIcon from "../../_utils/LinkIcon"
import EntityHeader from "../_layout/EntityHeader"
import { getEntity } from '../../../graphql/queries';
import { listQuestions } from '../../../graphql/queries';
import {Icon, Tooltip, Position} from "@blueprintjs/core"

import {
  setUser,
  setHeader
} from '../../../_actions';


class ListEntity extends Component {

  constructor(){
    super();

    this.state = {
      header:{
        title:'Loading..',
        counter:'123',
        action:'link',
        action_text:'See all questions',
        action_icon:'arrow-left',
        action_route:'/q/create',
        entityLogo:'',
        entityId:'123'
      },
      zero_counter:0

    }
  }
  
  componentDidMount() {
    this.addFilterPlaceholder();
  }
  
  componentWillMount(){
    this.updateState(this.props);
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.entities2) {
      this.updateState(newProps);
    }
  }
  
  updateState(props){
    const {header} = this.state;
    
    let zeroCounter = 0;
    let payload =[];
  
    if(props.listQuestions) {
      payload = props.listQuestions.map(item => {
  
        if(item.count === '0' || item.count === 0 || item.count === null){zeroCounter++}
        
        return {
          entity: item.name,
          question: item.question,
          questionId: item.id,
          entityLogo: item.entityLogo,
          author: item.author,
          authorId: item.authorId,
          count: item.count,
          lang: item.lang,
          d1: item.d1,
          d2: item.d2,
          d3: item.d3,
          d4: item.d4
        };
      });
      
      header.counter= props.listQuestions.length;
      header.title= props.listQuestions.displayName;
      header.entityLogo= props.listQuestions.logo;
      header.entityId= props.listQuestions.id;
      header.action_route= "/q/entity/"+props.listQuestions.id;
      header.action_text= "See all "+props.listQuestions.name+" questions";
    }
    
    this.setState({ table : payload, zero_counter:zeroCounter, header});
  }
  
  addFilterPlaceholder = () => {
    const filters = document.querySelectorAll("div.rt-th > input");
    for (let filter of filters) {
      filter.placeholder = "Search..";
    }
  }
  
  render() {
    
    console.log(this.props)

    const table = this.state.table;
    const tableLength = this.state.table ? this.state.table.length : '0';
    
    const entityId = this.props.entities2.id;
    const logo = this.props.entities2.logo;
    const displayName = this.props.entities2.displayName;
    
    return (
      <div>
        <EntityHeader entityId={entityId} logo={logo} name={displayName} type="questions"/>
        
        <div className="workarea">
          <div>
            <ReactTable
              data={table}
              noDataText="No Questions here!"
              filterable
              defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value}
              defaultSorted={[
                {
                  id: "count"
                }
              ]}
              columns={[{
                columns: [{
                  Header: "Questions: "+tableLength+" ( Unanswered : "+this.state.zero_counter+" )",
                  id: "question",
                  accessor: d => d.question,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {keys: ["question"]}),
                  filterAll: true,
                  minWidth: 300,
                  Cell: row =>(
                    <div className={ row.original.count === null || row.original.count === 0 ? 'marked_highlight' : ''}>
                      <LinkIcon type={'link'}
                                text={row.value}
                                route={"/q/"+row.original.questionId}
                      />
                    </div>
  
                  )
                },{
                  Header: 'Answers',
                  accessor: 'count',
                  maxWidth: 50,
                  Filter: '-',
                  Cell: row =>(
                    <div className={ row.original.count === null || row.original.count === 0 ? 'marked_highlight' : ''}>
                      <Link to={"/q/"+ row.original.questionId }>
                        {row.value === null ? '0' : row.value}
                      </Link>
                    </div>
                  )
                },
                {
                  Header: 'Language',
                  accessor: 'lang',
                  maxWidth: 50,
                  Filter: '-',
                },
                //   {
                //   Header: 'Sources',
                //   accessor: 'count',
                //   maxWidth: 200,
                //   Filter: '-',
                //   Cell: row =>(
                //     <Link to={"/q/"+ row.original.questionId }>
                //       <span className={ClassNames('degree degree1',{hidden: !row.original.d1 })}>{row.original.d1}</span>
                //
                //       <span className={ClassNames('degree degree2',{hidden: !row.original.d2 })}>{row.original.d2}</span>
                //
                //       <span className={ClassNames('degree degree3',{hidden: !row.original.d3 })}>{row.original.d3}</span>
                //
                //       <span className={ClassNames('degree degree4',{hidden: !row.original.d4 })}>{row.original.d4}</span>
                //
                //     </Link>
                //   )
                // },
                  {
                    Header: 'Actions',
                    accessor: 'questionId',
                    maxWidth: 120,
                    Filter: '-',
                    Cell: row => (
                      <div className="actions_column">
                        <Tooltip content="Answer this" position={Position.LEFT}>
                          <Link to={"/q/"+row.value+"/answer"} className="action_text">
                            answer
                            {/*<Icon icon="plus" iconSize={11}/>*/}
                          </Link>
                        </Tooltip>
                        <Tooltip content="Edit Question" position={Position.LEFT}>
                          <Link to={"/q/"+row.value+"/edit"}>
                            <Icon icon="edit" iconSize={11}/>
                          </Link>
                        </Tooltip>
                        <Tooltip content="Delete Question" position={Position.LEFT}>
                          <Link to={"/q/"+row.value+"/delete"}>
                            <Icon icon="cross" iconSize={11} />
                          </Link>
                        </Tooltip>
                      </div>
                    )
                  }

                ]
              }]}
              defaultPageSize={20}
              className=" -highlight"
            />
          </div>
        </div>

      </div>

    );
  }
}

ListEntity.propTypes = {
  user: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.dataReducer.user,
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setUserData: value => dispatch(setUser(value)),
  setHeaderData: value => dispatch(setHeader(value)),
});

const ListEntityConnected = connect(mapStateToProps, mapDispatchToProps)(ListEntity);

export default withApollo(compose(

  graphql(
    gql(getEntity),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.entityId, entityId: props.match.params.entityId}
      }),
      props: ({ data: { getEntity = {}}}) => ({
        entities2: getEntity
      })
    }
  ),
  graphql(
    gql(listQuestions),
    {
      options: (props) => ({
        variables: {
          limit:1000,
          filter:{entityId:{eq: props.match.params.entityId}}
        }
      }),
      props: ({ data: { listQuestions = { items: [] } } }) => ({
        listQuestions: listQuestions.items
      })
    }
  )
)(ListEntityConnected));
