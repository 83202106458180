import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditorRouter from "./EditorRouter"
import { Auth } from 'aws-amplify';
import bg from "../_layout/assets/blueprint-6.png"

import './_layout/Layout.css';

import {
  setUser
} from '../../_actions';

class Editor extends Component {
  
  constructor() {
    super();
    this.state = {
      showDetails:true,
      logged_user:{
        cognitoId:'',
        username:'',
        email:''
      }
    }
  }
  
  async componentDidMount() {
    //get Logged in user
    let user = await Auth.currentAuthenticatedUser();
    this.updateState(user);
    
  }
  async updateState(user) {
    
    //console.log(user);
    const { logged_user } = this.state;
    
    logged_user.cognitoId = (user.attributes ? user.attributes.sub : '');
    logged_user.username = user.username;
    logged_user.email = (user.attributes ? user.attributes.email : '');
    
    this.setState({ logged_user });
    
  }
  
  renderRouter() {
    return (
      <Route render={routeProps => <EditorRouter {...routeProps} loggedUser={this.props.user}/>} />
    )
  }
  
  renderLoggedOut(){
    return(
      <div className="loggedOut">
        <div  className="screen" style={{ backgroundImage:`url(${bg})` }} />
      </div>
    )
  }


  render() {
    const { user } = this.props;
    
    return(
      <React.Fragment>
        {user.cognitoId ? this.renderRouter() : this.renderLoggedOut()}
      </React.Fragment>
    );
  }
};


Editor.propTypes = {
  user: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.dataReducer.user
});

const mapDispatchToProps = dispatch => ({
  setUserData: value => dispatch(setUser(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
