import React, {Component} from "react"
import { graphql, compose, withApollo } from 'react-apollo'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Select from 'react-select'
import gql from "graphql-tag"
import { getTask, listEntitys } from '../../../graphql/queries'
import { updateTask } from '../../../graphql/mutations'
import { withRouter } from 'react-router'
import { Row, Col } from 'react-flexbox-grid'
import QuestionHeader from "../_layout/QuestionHeader"
import ResultHeader from "../_layout/ResultHeader"
import {nearest15min} from "../../../Utils"
import DateTimePickerCustomInput from "./components/DateTimePickerCustomInput"
import ShowStatus from "../../_utils/ShowStatus"
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';


class TaskEdit extends Component {
  
  constructor() {
    super();
    this.state = {
      header: {
        qid: '',
        breadcrumb:[
          {
            icon:'globe',
          },
          {
            name:'Edit Task',
          }
        ],
        type: 'answer', //new: green | answer: blue | edit: yellow  |  review: orange  |  delete : red
        status: 1, // Need to be INT
        action: 'link', // link | button
        action_text: 'Back to Tasks', //Text inside button
        action_icon: 'arrow-left', //icon inside button
        action_route: '/q/tasks', //Button route
        banner_color: 'edit' //new | answer | edit | review | delete
      },
      task: {
        id:'',
        name: '',
        when: nearest15min().format(),
        where: '',
        description:'',
        entity:'',
        entityId:'eid',
        entityLogo:'logo',
        bounty:'',
        createdAt:'',
        modifiedAt:'',
        count:'',
        createdBy:'',
        completedBy:'',
        status:'1',
        assignedTo:'1',
        goal:''
      },
      textEditor:''
    }
  
    this.handleModelChange = this.handleModelChange.bind(this);
    this.goBack = this.goBack.bind(this);
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.task) {
      this.updateState(newProps);
    }
  }
  
  updateState(props) {
    const {header,task} = this.state;
    
    header.qid = props.task.id;
    header.status = props.task.status;
  
    task.id= props.task.id;
    task.name= props.task.name;
    task.when= props.task.when;
    task.where= props.task.where;
    task.description= props.task.description;
    task.entity= props.task.entity;
    task.entityId= props.task.entityId;
    task.entityLogo= props.task.entityLogo;
    task.bounty= props.task.bounty;
    task.assignedTo= props.task.assignedTo;
    task.status= props.task.status;
    task.goal= props.task.goal;
    
    this.setState({header,task, textEditor:props.task.description});
    
  }
  
  handleEntityChange = (selectedOption) => {
    
    const { task } = this.state;
    task['entityId'] = selectedOption.value;
    task['entity'] = selectedOption.label;
    task['entityLogo'] = selectedOption.logo;
    
    this.setState({ task });
    console.log(`Option selected:`, selectedOption);
  };
  
  handleValues = field => event => {
    const { task } = this.state
    task[`${field}`] = event.target.value;
    this.setState({ task });
  }
  
  //RICH TEXT EDITOR
  handleModelChange =(text)=> {
    console.log(text)
    this.setState({
      textEditor: text
    });
  }

  handleDateChange(field, value) {
    const { task } = this.state
    task[`${field}`] = value;
    this.setState({ task });
  }
  
  
  handleSave = () => {
    const { history, updateTask} = this.props;
    const { task, textEditor } = this.state;
    
    task.modifiedAt = moment().format();
    task.description = textEditor ? textEditor : task.description;
    
    updateTask(task);
    
    history.push(`/q/task/${task.id}`);
 
  }
  
  goBack =()=>{
    this.props.history.goBack();
  }
  
  render() {
    const {loading, entities} = this.props;
  
    const {
      name,
      when,
      bounty,
      where,
      assignedTo,
      status,
      goal
    } = this.state.task;
  
  
    let selectedOption = [];
    
    const options = entities.map(entity => {
  
      //isolating Selected Option
      if(entity.id === this.state.task.entityId){
        selectedOption ={value: entity.id, label: entity.name, logo: entity.logo}
      }
      //Returning all options
      return ({value: entity.id, label: entity.name, logo: entity.logo })
      
    });
    
    
    return (
      <div className={`someclasshere ${loading ? 'loading' : ''}`}>
        
        <QuestionHeader header={this.state.header} />
        <ResultHeader header={this.state.header} />
  
        <Row center="xs">
          <Col xs={12} >
             <div className="workarea editor_form">
  
               <table>
                 <tbody>
                 <tr>
                   <td style={{width: '150px'}}>
                     Task Title
                   </td>
                   <td>
                     <input className="bp3-input bp3-fill"
                            type="text"
                            placeholder="Task Name (e.g. Complete 50 Translations)"
                            value={name}
                            onChange={this.handleValues('name')}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td>
                     Channel
                   </td>
                   <td>
                     <div className='icon1' style={{ backgroundImage:`url(${selectedOption.logo})`, float:'right', marginLeft:'5px' }}/>
                     <Select
                             value={selectedOption}
                             options={options}
                             onChange={this.handleEntityChange}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td>
                     Bounty
                   </td>
                   <td>
                     {/*TODO: Modify Bounty*/}
                     ${bounty}
                   </td>
                 </tr>
                 <tr>
                   <td>
                     Due
                   </td>
                   <td>
                     <DatePicker
                       className="ui container"
                       customInput={<DateTimePickerCustomInput />}
                       id="when"
                       selected={moment(when)}
                       onChange={this.handleDateChange.bind(this, 'when')}
                       peekNextMonth
                       showMonthDropdown
                       showYearDropdown
                       dropdownMode="select"
                       showTimeSelect
                       timeFormat="hh:mm a"
                       timeIntervals={15}
                       dateFormat="LL LT"
                     />
                   </td>
                 </tr>
                 <tr>
                   <td>
                     Location / City
                   </td>
                   <td>
                     <input className="bp3-input bp3-fill"
                            type="text"
                            placeholder="Location (e.g. New York)"
                            value={where}
                            onChange={this.handleValues('where')}
                     />
                   </td>
                 </tr>
                 
                 
                 <tr>
                   <td>Description</td>
                   <td>
                     <FroalaEditor
                       model={this.state.textEditor}
                       onModelChange={this.handleModelChange}
                       tag='textarea'
                       config={{
                         placeholderText: 'Add Instructions here',
                       }}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td>Goal</td>
                   <td>
                     <input className="bp3-input bp3-fill"
                            type="text"
                            placeholder="The amount of questions to be completed"
                            value={goal}
                            onChange={this.handleValues('goal')}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td>
                     Actions
                   </td>
                   <td>
  
                     <span style={{marginRight:'10px'}}>{ShowStatus(status, false)}</span>
                     
                     <select className="bp3-select" value={status} onChange={this.handleValues('status')}>
                       <option value="">Select one</option>
                       <option value="101">Available Job</option>
                       <option value="102">Assigned Job</option>
                       <option value="103">Job on-Review</option>
                       <option value="105">Completed Job</option>
                       <option value="106">Paid</option>
                       <option value="110">Closed Job</option>
                     </select>
                     
                   </td>
                 </tr>
                 <tr>
                   <td>
                     Assigned To:
                   </td>
                   <td>
                     {assignedTo}
                   </td>
                 </tr>
    
                 </tbody>
               </table>
  
               <div className="editor_footer" style={{ justifyContent:'flex-end'}}>
                 <button className="bp3-button" onClick={this.goBack} >Cancel</button>
                 <button className="bp3-button bp3-intent-primary" onClick={this.handleSave} >Save</button>
               </div>
               
              </div>
          </Col>
        </Row>
      </div>
    );
  }
  
}


export default withRouter(withApollo(compose(
  
  graphql(
    gql(getTask),
    {
      options: ({match: {params: {id}}}) => ({
        variables: {id},
        fetchPolicy: 'cache-and-network',
      }),
      props: ({data: {getTask: task, loading}}) => ({
        task,
        loading,
      }),
    },
  ),
  graphql(
    gql(listEntitys),
    {
      options: {
        //fetchPolicy: 'network-only',
      },
      props: ({ data: { listEntitys = { items: [] } } }) => ({
        entities: listEntitys.items
      })
    }
  ),
  graphql(
    gql(updateTask),
    {
      props: ({ mutate }) => ({
        updateTask: ({ id, name, when, where, description, entity, bounty, entityId, entityLogo, assignedTo, status, goal }) => {
          //console.log(id, name, when, where, description, entity, bounty,  entityId, entityLogo, assignedTo, status, goal);
          mutate({
            variables: {
              input: {
                id,
                name,
                when,
                where,
                bounty,
                description,
                entity,
                entityId,
                entityLogo,
                assignedTo,
                status,
                goal
              }
            }
          })
        }
      })
    }
  ),
)(TaskEdit)));

