import React, {Component} from 'react';
import gql from "graphql-tag";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { graphql, compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import QuestionHeader from "../_layout/QuestionHeader";
import { createEntity } from '../../../graphql/mutations';

import {
  setHeader
} from '../../../_actions';

class EntityCreate extends Component {
  
  constructor(){
    super();
    this.state = {
      header:{
        title:'New Channel', //Optional
        qid:'', //dynamically generated // Empty will show TBD
        status:'', // 1 | 2 | 3 | 4 | 5 | 6 (need to be INT)
        action:'link', // link | button
        action_text:'Back', //Text inside button
        action_icon:'arrow-left', //icon inside button
        action_route:'/q', //Button route
        banner_color:'new' //new | answer | edit | review | delete
      },
      entity_record: {
        name:'',
        displayName: '',
        website: '',
        locations: '',
        theme:'light',
        score: 0
      }
    }
  }
  
  componentWillMount = () => {
    const { setHeaderData } = this.props;
    setHeaderData(this.state.header);
  }

  handleInputValues = field => event => {
    const { entity_record } = this.state
    entity_record[`${field}`] = event.target.value;
    this.setState({ entity_record });
  }

  handleEntityCreate = () => {
    const { history, createEntity } = this.props
    const { entity_record } = this.state;
    console.log(entity_record);
    createEntity(entity_record);
    history.push("/q/entities");
  }

  render() {
    const {
      name,
      displayName,
      website,
      locations
    } = this.state.entity_record

    return (
      <div>
        <QuestionHeader header={this.state.header} />
        
        <div className="workarea editor_form">
          <table>
            <tbody>
              <tr>
                <td >
                  <span className="tag mandatory">
                    Name
                  </span>
                </td>
                <td>
                  <input className="bp3-input" placeholder="Short Name (e.g. MoMA)" style={{width:'100%'}} value={name} onChange={this.handleInputValues('name')}/>
                </td>
              </tr>
              <tr>
                <td >
                  Display Name
                </td>
                <td>
                  <input className="bp3-input" placeholder="Entity Full Name (e.g. Museum of Modern Art)" style={{width:'100%'}} value={displayName} onChange={this.handleInputValues('displayName')}/>
                </td>
              </tr>
              <tr>
                <td>
                  Website
                </td>
                <td>
                  <input className="bp3-input" placeholder="website" value={website} onChange={this.handleInputValues('website')} style={{width:'100%'}} />
                </td>
              </tr>
              <tr>
                <td>
                  <span className="mandatory">
                    Location
                  </span>
                </td>
                <td>
                  <input className="bp3-input" placeholder="City or Region (e.g. New York)" value={locations} onChange={this.handleInputValues('locations')} style={{width:'100%'}}/>
                </td>
              </tr>
              {/* <tr>
                <td>
                  Entity Logo
                </td>
                <td>
                  <input className="bp3-input" placeholder="Logo URL" style={{width:'100%'}}  value={logo} onChange={this.handleInputValues('logo')}/>
                </td>
              </tr> */}
              {/*<tr>*/}
                {/*<td>*/}
                  {/*Entity wall*/}
                {/*</td>*/}
                {/*<td>*/}
                  {/*<input className="bp3-input" placeholder="Entity Wall" style={{width:'100%'}} disabled/>*/}
                {/*</td>*/}
              {/*</tr>*/}
              {/* <tr>
                <td>
                  Entity Status
                </td>
                <td>
                  <div className="bp3-select .modifier">
                    <select className="" value={status} onChange={this.handleInputValues('status')}>
                      <option selected>Choose an Status</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="4">5</option>
                      <option value="4">6</option>
                    </select>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
          <br/>
          <div className="editor_footer">
            <div>
            </div>
            <div>
              <button className="bp3-button">Cancel</button>
              <button className="bp3-button bp3-intent-primary" onClick={this.handleEntityCreate}>Create Channel</button>
            </div>
          </div>
        
        </div>
      </div>
    
    );
  }
}

EntityCreate.propTypes = {
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setHeaderData: value => dispatch(setHeader(value)),
});

const EntityCreateConnected = connect(mapStateToProps, mapDispatchToProps)(EntityCreate);

export default withRouter(withApollo(compose(

  graphql(
    gql(createEntity),
    {
      props: ({ mutate }) => ({
        createEntity: ({ name, displayName, website, score, locations }) => {
          mutate({
            variables: {
              input: {
                name,
                displayName,
                website,
                score,
                locations
              }
            },
            refetchQueries:[`listEntitys`]
          })
        }
      })
    }
  ),
)(EntityCreateConnected)));