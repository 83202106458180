import React from 'react';
import { Link } from "react-router-dom";

const HardReload = (props) => {
  
  console.log(props);
  const { history} = props;
  
  console.log("Hard Reload");
  window.location.reload();
  
  if(props.match.params.entity){
    history.push("/q/entity/"+props.match.params.entity);
  }else{
    history.push("/q/user/"+props.authUser.id+"/profile");
  }
  
  return (
    <div>
      Go to: <Link to={"/q/user/"+props.authUser.id+"/profile"}>{props.authUser.username}</Link>
    </div>
  );
  
}

export default HardReload

