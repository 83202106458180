import React from 'react';
import ShowStatus from "../../_utils/ShowStatus"
import LinkIcon from "../../_utils/LinkIcon"
import ClassNames from "classnames"
import {renderCrumb} from '../../_utils/helpers'

const QuestionHeader = ({header, children}) => (
  
      <div className={ClassNames('result_header',
        {new_header : header.banner_color === 'new' },
        {answer_header : header.banner_color === 'answer' },
        {edit_header : header.banner_color === 'edit' },
        {review_header : header.banner_color === 'review' },
        {delete_header : header.banner_color === 'delete' },
        {neutral_header : header.banner_color === 'neutral' },
        {expanded_header : header.banner_color === 'expanded' }
        
        )} >
  
          <ul className="bp3-breadcrumbs">
  
            
            {header.breadcrumb &&
              header.breadcrumb[0] &&(
                renderCrumb(header.breadcrumb[0])
              )
            }
            
            {header.breadcrumb &&
              header.breadcrumb[1] &&(
                renderCrumb(header.breadcrumb[1])
              )
            }
  
            {header.breadcrumb &&
              header.breadcrumb[2] && (
                renderCrumb(header.breadcrumb[2])
              )
            }
  
            {header.breadcrumb &&
            header.breadcrumb[3] && (
                renderCrumb(header.breadcrumb[3])
              )
            }
            
            
            {header.title &&(
              <li>
                <span className="bp3-breadcrumb bp3-breadcrumb-current">
                      {header.title}
                </span>
              </li>
            )}
  
            {header.qid &&(
              <li>
                <span className="bp3-breadcrumb" style={{display:'flex'}}>
                  <span className='mobile_hide truncate_id' style={{ color:"rgba(0, 0, 0,0.2)"}}>
                    {(header.qid ? "id: "+header.qid : '')}
                  </span>
                  <span className="mobile_hide">
                   {header.status && ShowStatus(header.status, true)}
                  </span>
                </span>
              </li>
            )}
            
          </ul>
          
       
        
        <div>
          { children &&(
            children
          )}
  
  
          {header.action  &&(
            <LinkIcon
              type={header.action}
              icon={header.action_icon}
              text={header.action_text}
              route={header.action_route}
            />

          )}
          
        </div>
        
      </div>
    
);

export default QuestionHeader;