import React, { Component } from 'react';
import ClassNames from "classnames";
import { Link } from "react-router-dom";
import LinkIcon from "../../_utils/LinkIcon"
import {Icon} from "@blueprintjs/core"



class ResultHeader extends Component {

    constructor(){
        super();
        this.state = {
        
        }
    }
  
  componentWillMount(){
    
    this.updateState();
  }
  
  updateState(){
    this.setState(
      {
        item:[]
      }
    );
  }
  
  renderRight() {
    return (
      <LinkIcon type={this.props.header.action}
                icon={this.props.header.action_icon}
                text={this.props.header.action_text}
                route={this.props.header.action_route}
      />
  
    );
  }
  
  renderLogo(logo){
    return(
    <LinkIcon type={'entity_logo'}
              icon={logo}
              route={"/q/entity/"+this.props.header.entityId+"/info"}
              primary={true}
    />

    )
  }
  
  renderLeft(){
  
    const h = this.props.header;
    
    return(
      <ul className="bp3-breadcrumbs">
        
        <li>
          <span className="bp3-breadcrumb">
            <span style={{opacity:'0.5'}}>
              <Icon icon={h.breadcrumb ? h.breadcrumb.icon : 'globe'} iconSize={18} />
              {h.breadcrumb ? h.breadcrumb.name : ''}
            </span>
          </span>
        </li>
  
        {this.props.category &&(
        <li>
          <span className="bp3-breadcrumb">
            {this.props.category}
          </span>
        </li>
        )}
        
        {this.props.header.entityLogo &&(
          <li>
            <span className="bp3-breadcrumb">Logo:
              {this.renderLogo(this.props.header.entityLogo)}
            </span>
          </li>
        )}
  
        {this.props.header.entityId &&(
          <li>
            <Link className="bp3-breadcrumb" to={"/q/entity/"+this.props.header.entityId+"/info"}>
              <div className="u_title">{this.props.header.title}</div>
            </Link>
          </li>
        )}
  
        {!this.props.header.entityId &&(
          <li>
            <span className="bp3-breadcrumb bp3-breadcrumb-current">
              {this.props.header.title} &nbsp;
  
              <span style={{fontWeight:'300', fontSize:'0.9em', color:'#868686'}} className={ClassNames({hidden: !this.props.header.counter })}>
                ({this.props.header.counter})
              </span>
              
            </span>
            
          </li>
        )}
      </ul>
    )
  }
  
  render() {
    
      return (

      <div className="breadcrumb_header">
        
        {this.props.header.title && this.renderLeft()}
  
        {this.props.header.action && this.renderRight()}
      </div>

      );
  }
}

export default ResultHeader;