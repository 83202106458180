// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "f400-20190317011016-hostingbucket",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d20g7u8zg7bo9w.cloudfront.net",
    "aws_cloud_logic_custom": [
        {
            "name": "stripe2api",
            "endpoint": "https://8q3d8rev81.execute-api.us-west-2.amazonaws.com/Prod",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://brjqw5lnn5bgdfxqn2ogyymn24.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:85205bad-6f28-400a-b169-c83ec8a6239b",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ccJTTHvjz",
    "aws_user_pools_web_client_id": "527vi79gp9guk0ue73vuts59d9",
    "oauth": {}
};


export default awsmobile;
