import React, {Component} from "react"
import {Link} from "react-router-dom"
import {graphql, compose, withApollo} from "react-apollo"
import {Icon, Tooltip, Position} from "@blueprintjs/core"
import matchSorter from 'match-sorter'
import ReactTable from "react-table"
import gql from "graphql-tag"
import {listEntitys} from '../../../graphql/queries'
import {deleteEntity} from '../../../graphql/mutations'
import LinkIcon from "../../_utils/LinkIcon"
import ResultHeader from "../_layout/ResultHeader"


class ListChannels extends Component {
  
  state = {
    header: {
      title: 'All Jobs',
      counter: '123',
      action: 'button',
      action_text: 'Create Channel',
      action_icon: 'plus',
      action_route: '/q/settings/newchannel',
      entityLogo: '',
      entityId: ''
    },
    busy: false,
    
  }
  
  static defaultProps = {
    events: [],
    deleteEvent: () => null,
  }
  
  componentDidMount() {
    this.updateState();
  }
  
  updateState() {
    const {header} = this.state;
    header.counter = (this.props.entities ? this.props.entities.length : '');
    header.title = "All Channels ";
    
    this.setState({header});
    
  }
  
  async handleDeleteClick(item, e) {
    e.preventDefault();
    
    if (window.confirm(`Are you sure you want to delete event ${item.id}`)) {
      const {deleteEntity} = this.props;
      
      await deleteEntity(item);
    }
  }
  
  async handleAddToMyTasks(item, e) {
    e.preventDefault();
    
    if (window.confirm(`You are adding ("${item.name}") to your Task List`)) {
      
      console.log('mutation goes here');
    }
  }
  
  
  handleSync = async () => {
    const {client} = this.props;
    const query = gql(listEntitys);
    
    this.setState({busy: true});
    
    await client.query({
      query,
      fetchPolicy: 'network-only',
    });
    
    this.setState({busy: false});
  }
  
  renderTable() {
    
    const {entities} = this.props;
    
    return (
      <ReactTable
        data={entities}
        filterable
        columns={[{
          columns: [
            {
              Header: 'Name',
              id: "displayName",
              accessor: d => d.displayName,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {keys: ["displayName"]}),
              filterAll: true,
              minWidth: 300,
              Cell: row => (
                <LinkIcon type={'entity_logo'}
                          icon={row.original.logo}
                          text={row.value}
                          route={"/q/entity/" + row.original.id}
                />
              )
            },
            {
              Header: 'Score',
              accessor: 'score'
            },
            {
              Header: 'Website',
              accessor: 'website'
            },
            {
              Header: 'Locations',
              accessor: 'locations'
            },
            {
              Header: 'Actions',
              accessor: 'id',
              maxWidth: 100,
              Cell: row => (
                <div className="actions_column">
                  <Tooltip content="Ask Question" position={Position.LEFT}>
                    <Link to={"/q/entity/" + row.value + "/ask/" + row.original.name}>
                      <Icon icon="plus" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  <Tooltip content="Launch FAQ" position={Position.LEFT}>
                    <Link to={"/e/" + row.value}>
                      <Icon icon="arrow-top-right" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  
                  
                  <Tooltip content="Edit" position={Position.LEFT}>
                    <Link to={"/q/task/" + row.original.id + "/edit"}>
                      <Icon icon="edit" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  <Tooltip content="Delete" position={Position.LEFT}>
                    <button onClick={this.handleDeleteClick.bind(this, row.original)}>
                      <Icon icon="cross" iconSize={11}/>
                    </button>
                  </Tooltip>
                
                
                </div>
              )
            }
          
          ]
        }]}
        defaultPageSize={20}
        noDataText="No Channels here!"
        className=" -highlight"
      />
    )
    
  }
  
  
  render() {
    
    return (
      <div>
        <div>
          <ResultHeader header={this.state.header}/>
        
        </div>
        <div>
          
          {this.renderTable()}
          
          {/*{[].concat(events).sort((a, b) => a.when.localeCompare(b.when)).map(this.renderEvent)}*/}
        </div>
      </div>
    );
  }
  
}

export default withApollo(compose(
  graphql(
    gql(listEntitys),
    {
      options: {
        fetchPolicy: 'network-only',
        variables: {
          limit: 1000
        }
      },
      props: ({data: {listEntitys = {items: []}}}) => ({
        entities: listEntitys.items
      })
    }
  ),
  graphql(
    gql(deleteEntity),
    {
      options: {
        update: (proxy, {data: {deleteEntity}}) => {
          const query = gql(listEntitys);
          const data = proxy.readQuery({query});
          
          data.listEntitys.items = data.listEntitys.items.filter(entity => entity.id !== deleteEntity.id);
          
          proxy.writeQuery({query, data});
        }
      },
      props: (props) => ({
        deleteEntitys: (entity) => {
          return props.mutate({
            variables: {input: {id: entity.id}},
            //Not working:
            optimisticResponse: () => ({
              deleteEntitys: {
                ...entity,
                __typename: 'Task',
                comments: {__typename: 'CommentConnection', items: []}
              }
            }),
          });
        }
      })
    }
  )
)(ListChannels));
