import gql from "graphql-tag";

export default gql(`
mutation ($input: UpdateQuestionInput!){
    updateQuestion(input: $input) {
        id
        question
        author
        lang
        entity
        entityId
        count
        d1
        d2
        d3
        d4
    }
}`);
