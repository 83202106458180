const GET_DATA_SAGA = 'GET_DATA_SAGA';
const SET_DATA = 'SET_DATA';
const SET_USER = 'SET_USER';
const SET_HEADER = 'SET_HEADER';

export {  //eslint-disable-line
  GET_DATA_SAGA,
  SET_DATA,
  SET_USER,
  SET_HEADER
};
