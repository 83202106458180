import React, { Component } from 'react';
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import {Icon, Tooltip, Position} from "@blueprintjs/core";
import ClassNames from 'classnames';
import { graphql, compose, withApollo } from "react-apollo";
import queryString from "query-string"
import TaskHead from '../editor_tasks/components/TaskHead'
import QuestionHeader from "../_layout/QuestionHeader";
import gql from "graphql-tag"
import { getQuestion } from '../../../graphql/queries'


class QuestionDetail extends Component {

  constructor(){
    super();
    this.state = {
      header:{
        title: '',
        qid: '232323433535',
        breadcrumb:[
          {
            name:'',
            route:'',
            icon:'globe',
            active: false
          },{
            name:'settings',
            route:'',
            icon:''
          }
        ],
        category:{
          name:'',
          route:''
        },
        entity:{
          name:'Loading..',
          route:''
        },
        type: 'answer', //new: green | answer: blue | edit: yellow  |  review: orange  |  delete : red
        status: 1, // Need to be INT
        action: 'link',
        action_text: 'Back',
        action_icon: 'arrow-left',
        action_route: '/q/entities',
        taskId:'',
      },
      urlQuery:''
      
    }
  }
  
  componentDidMount(){
  
  }

  componentWillReceiveProps(newProps) {
    if (newProps.question && newProps.question.id) {
      this.updateState(newProps);
    }
  }
  
  updateState(props){
    
    const userName = this.props.db_user.userName;
    const { question } = props;

    // Question Id
    const params =  props.match.params;
    const { header } = this.state;
    header.qid = (params? params.questionId: '');
    header.breadcrumb[1].name = (question ? props.question.entity:' Entity');
    header.breadcrumb[1].route = (question? '/q/entity/'+props.question.entityId : '');
    header.action_route = (question? '/q/entity/'+props.question.entityId : '');
    header.taskId = props.question.taskId;
    
   
    let payload = [];

    //Unpacking the Question Answers
    if(question.answers) {
      payload = question.answers.items.map(item => {
        
        return {
          answer: item.answer,
          answerId: item.id,
          author: item.author,
          authorId: item.authorId,
          degree: item.degree,
          lang: item.lang,
          rating: item.rating
        }
      });
    }
  
    //URL Query
    const parsed = queryString.parse(this.props.location.search);

    this.setState({
      table: payload,
      user: userName,
      questionName: (question ? props.question.question : 'No question'),
      entity: (question ? props.question.entity:'No PublicView'),
      entityId: (question ? props.question.entityId:'No EntityId'),
      lang: (question ? props.question.lang:''),
      header,
      urlQuery: parsed
    });
  
  }
  
  renderHeader(){
    
    const task = this.state.header.taskId;
    
    if(this.state.urlQuery.m === 't'){
      return <TaskHead task={task} />
      
    }else{
      return <QuestionHeader header={this.state.header} />
    }
  }

  render() {
    
    console.log(this.props, this.state);

    const { table, questionName, lang } = this.state;
    const { question, location } = this.props;
    
    return (
      <div>
        
        {this.renderHeader()}
        
        <div className="workarea editor_form">
          
          <div className="result_detail">
            <div>
              <h5>Question: </h5>
              <h3>
                {questionName} ({lang})
                <Link className='bp3-button bp3-icon-edit bp3-minimal'
                      to={"/q/"+this.state.header.qid+"/edit"}
                />
              </h3>
            </div>
            
            <div>
              <Link className='bp3-button bp3-icon-add bp3-intent-success'
                    to={`/q/${this.state.header.qid}/answer/${question ? question.taskId:null}`}>
                New Answer
              </Link>
            </div>
          </div>

          <ReactTable
            data={table}
            noDataText="No Answers yet"
            columns={[{
              columns: [{
                  Header: 'Answers',
                  accessor: 'answer',
                  minWidth: 300,
                  Cell: row =>(
                    <Link to={`/q/answer/${row.original.answerId}/edit${location ? location.search :null}`}>
                      {row.value}
                    </Link>
                  )
                },
                {
                  Header: 'Author',
                  accessor: 'author',
                  width: 70,
                },
                {
                  Header: 'Degree',
                  accessor: 'degree',
                  Cell: row =>(
                   
                      <span
                        className={ClassNames('degree',
                          {degree1 : row.value === '1'},
                          {degree2 : row.value === '2'},
                          {degree3 : row.value === '3'},
                          {degree4 : row.value === '4'}
                        )} >
                      {row.value}°
                      </span>
                
                  ),
                  width: 50,
                },
                {
                  Header: 'Language',
                  accessor: 'lang',
                  maxWidth: 50,
                },
                {
                  accessor: 'actions',
                  width: 90,
                  Cell: row => (
                    <div className="actions_column">
                      <Tooltip content="Answer Details" position={Position.LEFT}>
                        <Link to={"/q/answer/"+row.original.answerId}>
                          <Icon icon="eye-open" iconSize={11}/>
                        </Link>
                      </Tooltip>
                      <Tooltip content="Edit Answer" position={Position.LEFT}>
                        <Link to={"/q/answer/"+row.original.answerId+"/edit"}>
                          <Icon icon="edit" iconSize={11}/>
                        </Link>
                      </Tooltip>
                      <Tooltip content="Delete Answer" position={Position.LEFT}>
                        <Link to={"/q/answer/"+row.original.answerId+"/delete"}>
                          <Icon icon="cross" iconSize={11}/>
                        </Link>
                      </Tooltip>
                    </div>
                  )
                }
              ]
            }]}
            defaultPageSize={10}
            className=" -highlight"
          />
        </div>
      </div>
    );
  }
}

QuestionDetail.propTypes = {
  user: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
};


export default withApollo(compose(

  graphql(
    gql(getQuestion),
    {
      options: (props) => ({
        fetchPolicy: 'cache-and-network',
        variables: {id: props.match.params.questionId, questionId: props.match.params.questionId}
      }),
      props: ({ data: { getQuestion = {}}}) => ({
         question: getQuestion
      })
    }
  ),
)(QuestionDetail));