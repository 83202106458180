import React, { Component } from "react";

class Footer extends Component {
  
  state = {
    name: "Footer",
  };
  
  render() {
    
    return (
      <div className="dx">
        <p className="dxtag">[ {this.state.name} ]</p>
        
      </div>
      
    );
  }
  
}


export default Footer;
