import React, { Component } from "react"
import { graphql, compose, withApollo } from "react-apollo"
import gql from "graphql-tag"
import ResultHeader from "../_layout/ResultHeader"
import { listTasks } from '../../../graphql/queries'
import TaskTable from '../editor_tasks/components/TaskTable'

class ListTasks extends Component {
  
  state = {
    header: {
      title: 'All Jobs',
      counter: '123',
      action: 'button',
      action_text: 'Create Task',
      action_icon: 'plus',
      action_route: '/q/newtask',
      entityLogo: '',
      entityId: ''
    },
    tasks:[],
    busy: false,
    
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.tasks) {
      this.updateState(newProps);
    }
  }
  
  updateState(props) {
    const {header} = this.state;
    const tasks = (props.tasks ? props.tasks : '')
    
    header.counter = (props.tasks ? props.tasks.length : '');
    header.title = "All Jobs";
    
    this.setState({header, tasks:tasks});
    
  }
  


  render() {
    
    return (
      <div>
        <div>
          <ResultHeader header={this.state.header}/>
        </div>
        <div>
          <TaskTable tasks={this.state.tasks} busy={this.state.busy} userId={this.props.db_user.id}> Something</TaskTable>
        </div>
      </div>
    );
  }
  
}

export default withApollo(compose(
  graphql(
    //List all tasks
    gql(listTasks),
    {
      options: {
        fetchPolicy: 'network-only',
        variables:{limit:1000}
      },
      props: ({data: {listTasks = {items: []}}}) => ({
        tasks: listTasks.items
      })
    }
  ),
)(ListTasks));
