import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { graphql, compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Row, Col } from 'react-flexbox-grid';
import { Icon} from "@blueprintjs/core";
import gql from "graphql-tag"
import { getUser } from '../../../graphql/queries'
import {renderEntitiesList} from '../../_utils/ShowEntities'
import QuestionHeader from "../_layout/QuestionHeader";


class EditorHome extends Component {
  
  constructor() {
    super();
    this.state = {
      header:{
        breadcrumb:[
          {
            icon:'globe',
          },
          {
            name:'Edit Task',
          },
          {
            name:'Profile',
            active: true
          }
        ],
        banner_color: 'neutral' //new | answer | edit | review | delete
      },
      user:''
    }
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.db_user) {
      this.updateState(newProps);
    }
  }

  updateState(props){

    if (props.db_user) {
      
      let urlUser = [];
      
      if(props.urlUser){urlUser = props.urlUser};
      
      const {header} = this.state;
      header.breadcrumb[1].name = urlUser.username;
      
      this.setState({user: urlUser});
      
    } else {
      this.setState({user: {}});
    }
  }
  
  displayFriend(name){
    return(
      <tbody>
        <tr>
          <td>
            <Link to={'/q'}>{name}</Link>
          </td>
          <td>
            <div className="user_stats" style={{fontSize:'0.8em'}}>
              <span className="text_ans"> 321 </span> |
              <span className="text_ask"> 854</span>
            </div>
          </td>
        </tr>
      </tbody>
    )
  }
  
  
  displayLabel(name){
    return(
      <span className="skill">
        {name}
      </span>
      
    )
  }
  
  render() {
    const { id, asked, answered, about, lang, entities, username } = this.state.user;
    
    return (
      <div>
        {this.state.header &&
        <QuestionHeader header={this.state.header}/>
        }
          <Row>
            <Col xs={12} md={8} >
              
              <Row>
                <Col xs={6} md={6}>
                  <div className="dashboard_card margin_right" style={{ minHeight:'180px'}}>
                    <h4>
                      <div>Questions.</div>
                      
                      <Link to={'/q/user/'+id+'/mcount'}>
                        <Icon icon="refresh" iconSize={9} />
                      </Link>
                      
                    </h4>
                    <h3 className="kpi_number">{ asked }</h3>
                    {/*<Link to={'/q'}>*/}
                      {/*<span style={{color:'green'}}>*/}
                        {/*<Icon icon="arrow-right" iconSize={9} />*/}
                        {/*27 New Questions*/}
                      {/*</span>*/}
                    {/*</Link>*/}
                    <Link to={'/q/user/' + id}>
                      <Icon icon="arrow-left" iconSize={9} />All my questions
                    </Link>
                    <Link to={'/q/create'}>
                      <Icon icon="plus" iconSize={9} />Create New
                    </Link>
                  </div>
                </Col>

                <Col xs={6} md={6} >
                  <div className="dashboard_card " style={{ minHeight:'180px'}}>
                    <h4>
                      Answers
                    </h4>
                    <h3 className="kpi_number">{answered}</h3>
                    <Link to={'/q/answers/' + id}>
                      <Icon icon="arrow-right" iconSize={9} />All my Answers
                    </Link>
                    {/*<Link to={'/q'}>*/}
                      {/*<Icon icon="search" iconSize={9} />Find More*/}
                    {/*</Link>*/}
                  </div>
                </Col>
                
              </Row>

              <Row>
                <Col xs={12} md={12} >
                  
                    {renderEntitiesList(entities)}
                </Col>
              </Row>
              
            </Col>

            <Col xs={12} md={4}>
              <div className="dashboard_card margin_left" style={{ minHeight:'600px', textAlign:'left'}}>
                
                <div>
                  <h4>
                    <div>About { username }</div>
                    
                    <Link to={'/q/user/'+id+'/edit'}>
                      <Icon icon="edit" iconSize={9} />Edit
                    </Link>
                  </h4>
                  <p>
                    { about }
                  </p>
                  <div>
                    {this.displayLabel(lang)}
                  </div>
                  <hr/>
                </div>

                {/*<div>*/}
                  {/*<h4>*/}
                    {/*<div>*/}
                      {/*<Icon icon="people" iconSize={12} />*/}
                      {/*Friends*/}
                    {/*</div>*/}
                    {/*<span>98</span>*/}
                  {/*</h4>*/}
                  {/**/}
                  {/*<table>*/}
                    {/*{this.displayFriend('John Stone')}*/}
                    {/*{this.displayFriend('Adam Sondler')}*/}
                    {/*{this.displayFriend('Mick Jaller')}*/}
                    {/*{this.displayFriend('Billy Morgan')}*/}
                  {/*</table>*/}
                  {/**/}
                  {/*<Link to={'/'} className="addmore">*/}
                      {/*<Icon icon="plus" iconSize={9} /> Invite*/}
                  {/*</Link>*/}
                
                {/*</div>*/}

              </div>
            </Col>
          </Row>
      </div>
    );
  }
}

export default withRouter(withApollo(compose(
  
  graphql(
    gql(getUser),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.userId}
      }),
      props: ({ data: { getUser = {}}}) => ({
        urlUser: getUser
      })
    }
  )
)(EditorHome)));
