import React, { Component } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';
import gql from "graphql-tag";
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getEntity } from '../../../graphql/queries'
import { deleteEntity } from '../../../graphql/mutations'
import QuestionHeader from "../_layout/QuestionHeader";


import {
  setHeader
} from '../../../_actions';

class EntityDelete extends Component {
  
  constructor(){
    super();
    this.state = {
      header:{
        title: 'Delete PublicView', //Optional
        qid: '1e99ej3827', //dynamically generated // Empty will show TBD
        action: 'link', // link | button
        action_text: 'Back', //Text inside button
        action_icon: '', //icon inside button
        action_route: '/q', //Button route
        banner_color: 'delete' //new | answer | edit | review | delete
      },
      record: {
        id: '12345'
      },
      entity: {
        name: ''
      }
    }
  }
  
  componentWillMount() {
    this.updateState();
  }

  async updateState() {
    const entityId = this.props.match.params.entityId;
    const { header } = this.state;
    header.qid = entityId;
    const { setHeaderData } = this.props;
    setHeaderData(header);

    const { entity } = this.state;
    const {client} = this.props;
    try {
      const result = await client.query({
        query: gql(getEntity),
        variables: {
          id: entityId,
        },
      });

      if ( result && result.data.getEntity) entity.name = result.data.getEntity.name;
  
    } catch (error) {
      console.log(error);
    }
    
    this.setState({ entity });
  }

  handleDelete = () => {
    const { history, deleteEntity } = this.props
    const { record } = this.state;
    const { entityId } =  this.props.match.params;
    record.id = entityId;

    deleteEntity(record);
    history.push("/q/entities");
  }

  handleCancel = () => {
    const { history } = this.props
    history.goBack();
  }

  render() {
    
    const { name } = this.state.entity;
    return (
      <div>
        <QuestionHeader header={this.state.header} />
        <div className="workarea editor_form">
          <table>
            <tbody>
              <tr>
                <td >
                  <span>
                    Name
                  </span>
                </td>
                <td>
                  { name }
                </td>
              </tr>
            </tbody>
          </table>
          <br/>
          <div className="editor_footer">
            <div>
            
            </div>
            <div>
              <button className="bp3-button" onClick={this.handleCancel}>Cancel</button>
              <button className="bp3-button bp3-intent-primary" onClick={this.handleDelete}>Delete</button>
            </div>
          </div>
        
        </div>
      </div>
    
    );
  }
  
}

EntityDelete.propTypes = {
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setHeaderData: value => dispatch(setHeader(value)),
});

const EntityDeleteConnected = connect(mapStateToProps, mapDispatchToProps)(EntityDelete);

export default withRouter(withApollo(compose(

  graphql(
    gql(deleteEntity),
    {
      props: ({ mutate }) => ({
        deleteEntity: ({ id }) => {
          mutate({
            variables: {
              input: {
                id
              }
            }
          })
        }
      })
    }
  ),
)(EntityDeleteConnected)));