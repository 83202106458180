import React from 'react';
import ClassNames from 'classnames'
import { Link } from "react-router-dom";
import { Icon, Tooltip, Position} from "@blueprintjs/core";

export function renderEntitiesList(entities){
  
  if(entities){
    const list = extractEntities(entities);
    return(
      <div className="dashboard_card" style={{ minHeight:'380px'}}>
        <h4>
          Entities
          <span>( {list.length} )</span>
        
        </h4>
        <table style={{width:'100%'}}>
          <tbody>
          {list.map(e => (
            
            displayEntity(e.label, e.logo, e.value, e.degree)
          
          ))}
          </tbody>
        </table>
      </div>
    )
  }else{
    return(
      <div className="dashboard_card" style={{ minHeight:'380px'}}>
        <h4>
          No Entities
        </h4>
      
      </div>
    )
  }
}

// Convert Entities String to an Array that can be used to build tables
// Input format:
//[(entityId+entityName+logoUrl+degree),(),(),()]
// Output format:
// [{value:"entityId", label:"entityName", logo:"logoUrl" },{},{},{}]

export function extractEntities(entities, format){
  
  //Unpacking Entities from String
  const ent = JSON.stringify(entities);
  let regex = /\(([^)]+)\)/;
  let e = ent.split(regex);
  //Cleaning up array
  let res = e.filter(function(v, i) {
    // check the index is even
    return i % 2 !== 0;
  });
  
  let userEntities =[];
  res.map(z => {
    let x = z.split('+');
    userEntities.push(x);
    return x;
  });
  
  let formattedArray = [];
  
  switch(format){
    //BY KEYS: This format is mapped by keys
    case 'keys' : formattedArray = userEntities.map(entity => ({ id: entity[0], displayName: entity[1], logo: entity[2], degree: entity[3] }));
    break;
    //BY DROPDOWNS : The default format is compatible with Dropdown Menus
    default: formattedArray = userEntities.map(entity => ({ value: entity[0], label: entity[1], logo: entity[2], degree: entity[3] }));
  }
  return formattedArray;
  
}



export function displayEntity(name,image,id,degree){
  return(
    <tr key={id}>
      <td>
        <Link to={'/q/entity/'+id+'/info'}>
          <div className="icon1" style={{ backgroundImage:`url(${image})` }}/>
          {name}
        </Link>
      </td>
      <td>
        <span className={ClassNames('degree',
          {degree1:(degree === '1')},
          {degree2:(degree === '2')},
          {degree3:(degree === '3')},
          {degree3:(degree === '4')}
        )}>{degree}°
        </span>
      
      </td>
      <td>
        <Tooltip content={'ask '+name} position={Position.RIGHT}>
          <Link to={'/q/entity/'+id+'/ask/'+name}>
            <Icon icon="plus" iconSize={15}/>
          </Link>
        </Tooltip>
      </td>
    </tr>
  
  )
}