import React, { Component } from 'react';
import Query from "../../../graphql/editor/UpdateCognitoUser";
import Query2 from "../../../graphql/editor/ListUserQuestionsCount";
import Query3 from "../../../graphql/editor/ListUserAnswersCount";
import { graphql, compose, withApollo } from 'react-apollo';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setHeader
} from '../../../_actions';

class UserCount extends Component {
  
  constructor(){
    super();
    this.state = {
      record: {
        id:'',
        cognitoId:'',
        answered:'',
        asked:''
      }
    }
  }
  
  componentWillMount(){
    this.updateState();
  }
  
  async updateState() {
    const {record} = this.state;
    record.cognitoId = this.props.match.params.userId;
    record.id = this.props.match.params.userId;
    record.asked = this.props.userQuestions.length;
    record.answered = this.props.userAnswers.length;
  
    this.setState({ record });
    console.log(this.state);
    
  }
  
  handleSave = () => {
    console.log(this.props);
    const { history, updateT01 } = this.props;
    const { record } = this.state;
    
    
    updateT01(record);
    
    history.push("/q/user/"+record.id+"/profile");
  }
  
  handleCancel = () => {
    const { history } = this.props
    history.goBack();
  }
  
  
  render() {
    
    
    console.log(this.props);
    return (
      <div>
        
        <div className="workarea editor_form">
          <table>
            <tbody>
            <tr>
              <td style={{width:'150px'}}>
                Asked
              </td>
              <td>
                {this.props.userQuestions.length}
              </td>
            </tr>
            <tr>
              <td>
                Answered
              </td>
              <td>
                {this.props.userAnswers.length}
              </td>
            </tr>
            
            
            
            </tbody>
          </table>
          <div className="editor_footer">
            <div>
            
            </div>
            <div>
              <button className="bp3-button" onClick={this.handleCancel} >Cancel</button>
              <button className="bp3-button bp3-intent-primary" onClick={this.handleSave} >Save</button>
            </div>
          </div>
        </div>
      </div>
    
    );
  }
  
}

UserCount.propTypes = {
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setHeaderData: value => dispatch(setHeader(value)),
});

const UserEditConnected = connect(mapStateToProps, mapDispatchToProps)(UserCount);

export default withApollo(compose(
  
  graphql(
    Query,
    {
      props: ({ mutate }) => ({
        updateT01: ({ cognitoId, answered, asked }) => {
          console.log({ cognitoId, answered, asked});
          mutate({
            variables: {
              input: {
                cognitoId,
                answered,
                asked
              }
            }
          })
        }
      })
    }
  ),
  graphql(
    Query2,
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.userId}
      }),
      props: ({ data: { listQuestions = { items: [] } } }) => ({
        userQuestions: listQuestions.items
      })
    }
  ),
  graphql(
    Query3,
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.userId}
      }),
      props: ({ data: { listAnswers = { items: [] } } }) => ({
        userAnswers: listAnswers.items
      })
    }
  )
)(UserEditConnected));

