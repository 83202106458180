import React, {Component} from "react"
import {Link} from "react-router-dom";
import { graphql, compose, withApollo } from 'react-apollo';
import {Popover, Icon, Position, PopoverInteractionKind} from "@blueprintjs/core";
import gql from "graphql-tag"
import { listTasks } from '../../../graphql/queries'

const userTasks = `
query listTasks($id:String!, $limit:Int){
  listTasks(filter: { assignedTo: {eq: $id}}, limit:$limit ){
    items{
      id
      name
      where
      when
      description
      entity
      bounty
      createdAt
      modifiedAt
      status
      count
      createdBy
      assignedTo
    }
  }
}`;


const userRequests = `
query listTasks($id:String!, $limit:Int){
  listTasks(filter: { createdBy: {eq: $id}}, limit:$limit ){
    items{
      id
      name
      where
      when
      description
      entity
      bounty
      createdAt
      modifiedAt
      status
      count
      createdBy
      assignedTo
    }
  }
}`;


class Sidebar extends Component {
  
  constructor() {
    super();
    this.state = {
      current_user: '',
      db_user: {
        id: ''
      },
      qcounter:'66',
      auth_user: '',
      showDetails: true
    }
  }
  
  async componentDidMount() {
    //The loggedIn user
    //let user = await Auth.currentAuthenticatedUser();
    
    //The user from URL
    this.setState({current_user: this.props.auth_user.id});
    this.updateState(this.props);
    
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps) {
      this.updateState(newProps);
    }
  }
  
  updateState(props) {
    
    let {db_user, auth_user, qcounter} = {};
    
    if (props.auth_user) {
      auth_user = props.auth_user;
    }
    
    if (props.db_user) {
      db_user = props.db_user;
    }
  
    if (props.items){
      qcounter = props.items.length;
    }
    
    this.setState({db_user, auth_user, qcounter});
    
  }
  
  handleDetailClick = () => {
    this.setState({showDetails: !this.state.showDetails});
  }
  
  extractEntities(entities) {
    //Unpacking Entities from String
    const ent = JSON.stringify(entities);
    let regex = /\(([^)]+)\)/;
    let e = ent.split(regex);
    //Cleaning up array
    let res = e.filter(function (v, i) {
      // check the index is even
      return i % 2 !== 0;
    });
    
    let userEntities = [];
    res.map(z => {
      let x = z.split('+');
      userEntities.push(x);
      return x;
    });
    
    const selected = userEntities.map(entity => ({
      value: entity[0],
      label: entity[1],
      logo: entity[2],
      degree: entity[3]
    }));
    
    return selected;
  }
  
  renderEntityMenu(entityId, entityName){
    return(
      <ul className="bp3-menu bp3-elevation-1">
        <li>
          <Link to={'/q/entity/'+entityId+'/info' } className="bp3-menu-item bp3-icon-dashboard">
            {entityName} Dashboard
          </Link>
        </li>
        <li>
          <Link to={'/q/entity/'+entityId+'/ask/'+entityName } className="bp3-menu-item bp3-icon-plus">
            New Question
          </Link>
        </li>
        <li>
          <Link to={'/q/entity/'+entityId+'/newtask' } className="bp3-menu-item bp3-icon-insert">
            New {entityName} Job
          </Link>
        </li>
        <li>
          <Link to={'/q/entity/'+entityId+'/edit' } className="bp3-menu-item bp3-icon-edit">
            Edit entity info
          </Link>
        </li>
      </ul>
    );
  }
  
  renderEntities(entities) {
    
    if (entities) {
      const list = this.extractEntities(entities);
      
      const e = list.map(e => (
        <li
          className={"darkerli " + (this.props.match.params.entityId === e.value ? 'active' : '')}
          key={e.value}>
            
              <Link to={'/q/entity/' + e.value }>
                <div className="side-icon">
                  <Popover
                    content={this.renderEntityMenu(e.value, e.label)}
                    position={Position.BOTTOM_LEFT}
                    interactionKind={PopoverInteractionKind.HOVER}
                  >
                    <div className="side-logo" style={{ backgroundImage:`url(${e.logo})` }}/>
                  </Popover>
                  {/*<span className="icon-counter discrete">17</span>*/}
                </div>
                
                {/*<Icon icon="user" iconSize={18} className="side-icon"/>*/}
                <span className="nav-text-wrapper">
                    {e.label}
                </span>
              </Link>
              
              {/*<Icon icon={this.props.match.params.entityId === e.value ?*/}
                {/*'caret-down' : 'caret-right'} iconSize={11}/>*/}
            
        </li>
      
      ));
      return e;
      
    } else {
      return (
        <div className="dashboard_card" style={{minHeight: '380px'}}>
          <h4>
            No Entities
          </h4>
        
        </div>
      )
    }
    
  }
  
  alertTrigger() {
    
    return (
      <div className='delete-button' onClick={() => {
        if (window.confirm('Text to confirm here')) window.location.reload()
      } }>RE</div>
    );
  }
  
  tasksMenu(){
  
    const allCount = parseInt(this.props.allTasks.length);
    const myCount = parseInt(this.props.myTasks.length);
    const myRequests = parseInt(this.props.myRequests.length);
    
    //const count = 0;
    
    return(
      <ul>
  
        {/*only Tasker can see*/}
        {this.props.db_user.status >= 110 ?
    
          <li>
            <Link to={"/q/usertasks/" + this.state.current_user}>
              <Icon icon="annotation" iconSize={22} className="side-icon"/>
              {myCount ?
                <span className="icon-counter">{myCount}</span>
                : null
              }
              <span className="nav-text-wrapper">
                <div className="spaced" style={{paddingRight: '20px'}}>
                  <span><strong>My Jobs</strong> &nbsp;</span>
                  {myCount ?
                    <span className="bp3-tag bp3-round icon-counter-big">
                        <span style={{color: 'red'}}>
                          {myCount}
                        </span>
                    </span>
                    : null
                  }
                </div>
              </span>
      
            </Link>
          </li>
          :null
        }
  
  
        {/*only Customer and admin can see*/}
        {this.props.db_user.status <= 105 ?
          <li>
            <Link to={"/q/userposted/" + this.state.current_user}>
              <Icon icon="share" iconSize={22} className="side-icon"/>
              {myCount ?
                <span className="icon-counter">{myRequests}</span>
                : null
              }
              <span className="nav-text-wrapper">
                <div className="spaced" style={{paddingRight: '20px'}}>
                  <span><strong>Posted Jobs</strong> &nbsp;</span>
                  {myCount ?
                    <span className="bp3-tag bp3-round icon-counter-big">
                        <span style={{color: 'red'}}>
                          {myRequests}
                        </span>
                    </span>
                    : null
                  }
                </div>
              </span>
      
            </Link>
          </li>
          :null
        }
  
  
  
        {/*only Tasker can see*/}
        {this.props.db_user.status >= 110 ?
          <li>
            <Link to={`/q/tasks` }>
              <Icon icon="duplicate" iconSize={20} className="side-icon"/>
              {allCount ?
                <span className="icon-counter discrete">
                    {allCount}
                </span>
                : null
              }
        
              <span className="nav-text-wrapper">
                <div className="spaced" style={{paddingRight: '20px'}}>
                  <span>All Jobs &nbsp;</span>
                  {allCount ?
                    <span className="bp3-tag bp3-round icon-counter-big">
                    <span>
                        {allCount}
                    </span>
                  </span>
                    : null
                  }
                </div>
              </span>
            </Link>
          </li>
          :null
        }
        
  
        {/*only Customer and admin can see*/}
        {this.props.db_user.status <= 105 ?
          <li>
            <Link to={'/q/newtask'}>
              <Icon icon="insert" iconSize={18} className="side-icon"/>
              <span className="nav-text-wrapper">Create Job</span>
            </Link>
          </li>
          :null
        }
        
      </ul>
    )
  }
  
  entityMenu(){
    
    return (
      <div>
        {/*<ul>*/}
          {/*<li className={ this.props.select === 'q' ? 'active' : ''}>*/}
            {/*<Link to={"/q/user/" + this.state.current_user}>*/}
              {/*<Icon icon="comment" iconSize={25} className="side-icon"/>*/}
            {/**/}
              {/*{this.state.qcounter > 0 &&*/}
              {/*<span className="icon-counter">{this.state.qcounter}</span>*/}
              {/*}*/}
            {/**/}
              {/*<span className="nav-text-wrapper">*/}
                          {/*<div className="spaced" style={{paddingRight: '20px'}}>*/}
                            {/*<span><strong>Questions</strong> &nbsp;</span>*/}
                            {/*<span className="bp3-tag bp3-round icon-counter-big"*/}
                            {/*><span style={{*/}
                              {/*color: 'red',*/}
                              {/*fontWeight: '800'*/}
                            {/*}}>{this.state.qcounter} </span> &nbsp; / {this.state.qcounter}</span>*/}
                          {/*</div>*/}
                        {/*</span>*/}
            {/*</Link>*/}
          {/*</li>*/}
        {/*</ul>*/}
      
        {/*<li className="darkerlishadow">*/}
        {/*<Link to={"/q/answers/"+this.state.current_user}>*/}
        {/*<Icon icon="arrow-right" iconSize={18} className="side-icon"/>*/}
        {/*<span className="nav-text-wrapper">My Answers</span>*/}
        {/*</Link>*/}
        {/*</li>*/}
      
        <ul className="darkerli_wrapper">
          {this.renderEntities(this.state.db_user.entities)}
        
          <li className="darkerli">
            <Link to={"/q/settings/" + this.state.current_user + "/channels"}>
              <Icon icon="more" iconSize={12} className="side-icon"/>
              <span className="nav-text-wrapper">See all</span>
            </Link>
          </li>
        </ul>
      </div>
    )
  }
  
  
  render() {
    
    return (
      <nav className="main-menu">
        <div>
          <div className="logo">
          
          </div>
        </div>
  
        {  this.props.db_user.status === 101 ?
              <span className="bp3-tag bp3-intent-danger" style={{margin:'0 4px'}}>Admin</span>
           : this.props.db_user.status === 110 ?
             <span className="bp3-tag bp3-intent-warning" style={{margin:'0 5px'}}>Tasker</span>
           :
             <span className="bp3-tag bp3-intent-primary" style={{margin:'0 10px'}}>User</span>
        }
        
        {this.tasksMenu()}
  
        {this.props.db_user.status <= 105 && (
          this.entityMenu()
        )}
        
        <ul className="settings">
          <li className={ this.props.select === 's' ? 'active' : ''}>
            <Link to={"/q/settings/"+this.state.current_user+"/edit"}>
              <Icon icon="cog" iconSize={18} className="side-icon"/>
              <span className="nav-text-wrapper">Settings</span>
            </Link>
          </li>
        </ul>
      </nav>
      )
    }
  
  }


export default withApollo(compose(
  graphql(
    gql(listTasks),
    {
      options: (props) => ({
        variables: {id: props.db_user.id, limit:1000}
      }),
      props: ({ data: { listTasks = { items: [] } } }) => ({
        allTasks: listTasks.items
      })
    }
  ),
  graphql(
    gql(userTasks),
    {
      options: (props) => ({
        variables: {id: props.db_user.id, limit:1000}
      }),
      props: ({ data: { listTasks = { items: [] } } }) => ({
        myTasks: listTasks.items
      })
    }
  ),
  graphql(
    gql(userRequests),
    {
      options: (props) => ({
        variables: {id: props.db_user.id, limit:1000}
      }),
      props: ({ data: { listTasks = { items: [] } } }) => ({
        myRequests: listTasks.items
      })
    }
  ),
)(Sidebar));
    

