import React, {Component} from "react"
import {Dialog} from "@blueprintjs/core"
import { Row, Col } from 'react-flexbox-grid'
import TaskModal from "./TaskModal"
import TaskHead from './TaskHead'
import "./TaskHeader.css"

// Step
const Step = props => (
  <div className={`Stepper__step ${props.indicator} ${props.step} ${ parseInt(props.indicator) === parseInt(props.step) ? 'is-active':'not-active'} ${ parseInt(props.indicator) < parseInt(props.step) ? 'is-complete':'is-notcomplete'} `} >
    <div className="Stepper__indicator">
      <span className="Stepper__info">{ parseInt(props.indicator) < parseInt(props.step) ? '✓': props.indicator }</span>
    </div>
    <div className="Stepper__label">{props.title}</div>
    <div className="Stepper__panel">{props.children}</div>
  </div>
);

// Overlay
const Stepper = props => (
  <div
    className={`Stepper ${props.isVertical ? "Stepper--vertical" : ""} ${
      props.isInline ? "Stepper--inline" : ""
      }`}
  >
    {props.children}
  </div>
);

class TaskHeader extends Component {
  
  constructor() {
    super();
    this.state = {
      modals:{
        first:false,
        second:false,
        currentTabIndex: 0
      }
    }
  }
  
  renderStepper(){
    return(
      <div>
        <Stepper isInline>
          <Step indicator="1" title="Question" step={this.props.step}>
            <div className="Content">
              <button>Prev</button>
              <button>Next</button>
            </div>
          </Step>
          <Step indicator="2" title="Answer" step={this.props.step}>
            <div className="Content">
              <button>Prev</button>
              <button>Next</button>
            </div>
          </Step>
          <Step indicator="3" title="Save" step={this.props.step}>
            <div className="Content">
              <button>Prev</button>
              <button>Next</button>
            </div>
          </Step>
        </Stepper>
      </div>
    )
  }
  
  handleToggleTaskModal = () => {
    const { modals} = this.state;
    modals.second = !modals.second;
    this.setState({ modals });
  };
  
  renderTaskModal(){
    
    return(
      <Dialog
        icon="info-sign"
        onClose={this.handleToggleTaskModal}
        title="Task Instructions"
        isOpen={this.state.modals.second}
      >
        <TaskModal task={this.props.task}/>
        
        <div className="bp3-dialog-footer">
          <button className="bp3-button bp3-intent-success bp3-fill" onClick={this.handleToggleTaskModal}>Gotcha, close this</button>
        </div>
      </Dialog>
    )
  }
  
  render() {
    return (
      <React.Fragment>
        <TaskHead task={this.props.task} />
        
        <Row center="xs" className="mobile_hide">
          <Col xs={12} sm={10} md={9} lg={8} >
            <div>
            {this.renderStepper(this.props.step)}
            </div>
            
          </Col>
          <Col xs={12} sm={1}>
            <button type="button" onClick={this.handleToggleTaskModal} class="bp3-button bp3-icon-help bp3-minimal" style={{color:'#adadad', fontSize:'0.8em'}}>Instructions</button>
            
          </Col>
        </Row>
  
        {this.renderTaskModal()}
      </React.Fragment>
    
    );
  }
  
}


export default TaskHeader;