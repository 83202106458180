import React, { Component } from "react"
import { Card, Elevation, Icon, Button } from "@blueprintjs/core";
import Select from 'react-select';
import { withApollo, compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import SettingsWrapper from "./_layout/SettingsWrapper"
import myconfig from '../../../custom-config';
import { updateUser } from '../../../graphql/mutations'
import { listStripeCustomers } from '../../../graphql/queries'


class TestPayments extends Component {
  
  constructor(){
    super();
    this.state = {
      header: {
        counter: '',
        action: 'button',
        action_text: 'Settings',
        action_icon: 'arrow-left',
        action_route: '/q/settings',
        banner_color:'expanded',
        breadcrumb:[
          {
            name:'',
            route:'',
            icon:'cog',
            active: false
          },{
            name:'Settings',
            active: true,
            size:'L'
          },{
            name:'',
            size:'L'
          }
        ],
      },
      charge: null,
      transfer: null,
      cust_id: 'Non selected'
    }
  }

  componentDidMount(){
    const { db_user } = this.props;

    this.setState({
      account_id: db_user.accountNumber,
      stripeCustomerId: db_user.stripeCustomerId
    })
  }

  createdCharge = async () => {
    const res = await fetch(`${myconfig.Stripe.apiUrl}/charges`, { // POST to our backend server with the token and charge detgails
      method: 'POST',
      body: JSON.stringify({
        customer_id: this.state.cust_id,
        charge: {
          amount: 100,
          currency: myconfig.Stripe.currency,
        },
        transfer_group: 'test payment group'
      }),
    });
    const data = await res.json();
    console.log(data);
    if (data.message && data.message.includes('success')) {
      this.setState({
        charge: data.charge
      })
    } else {
      alert('failed')
    }    
  }

  setStripeCustomer = stripeCustomerId => {
    console.log(stripeCustomerId)
    const { updateUser } = this.props

    const userId = this.props.match.params.userId ? this.props.match.params.userId : this.props.db_user.id ;
    const param = {
      id: userId,
      stripeCustomerId
    }
    updateUser(param);
  }

  // updateTask = data => {
  //
  //   console.log(data)
  //   const { updateTask } = this.props
  //   console.log(data)
  //   console.log('updateTask')
  //
  //   const taskId = this.props.taskId;
  //   const param = {
  //     id: taskId,
  //     status: 120,
  //     transaction: data.charge.id,
  //     paidBy: data.charge.customer,
  //     paidAt: data.charge.created
  //   }
  //   updateTask(param);
  // }

  releasePayment = async() => {
    const { charge } = this.state
    console.log(charge);
    const res = await fetch(`${myconfig.Stripe.apiUrl}/transfer`, { // POST to our backend server with the token and charge detgails
      method: 'POST',
      body: JSON.stringify({
        charge,
        destination: myconfig.Stripe.testPaymentAcct_ID,
        transfer_group: 'test payment group'
      }),
    });
    const data = await res.json();

    //Todo: Save info to Task.transferTo, Task.transferAt, Task.transferId
    if (data.message && data.message.includes('success')) {
      this.setState({
        transfer: data.transfer
      }, () => {
        console.log(data)  
      })
    } else {
      alert ('failed')
    }    
  }

  getSelectData = (customers) => {
    let result = []
    customers.map(customer => {
      result.push({
        value: customer.cust_id,
        label: `${customer.brand} ( •••• ${customer.last4} )`
      })
      return null;
    })
    return result
  }
  
  render() {
    
    console.log(this.props)
    const userId = this.props.match.params.userId ? this.props.match.params.userId : this.props.db_user.id ;
    const userRole = this.props.db_user ? parseInt(this.props.db_user.status) : 0;
  
  
    const { charge, transfer } = this.state
    const selectData = this.getSelectData(this.props.listStripeCustomers)
    return (
      <SettingsWrapper user={userId} userRole={userRole} header={this.state.header} activeTab="test">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>Test Payments</h2>
          <div style={{ float: 'right', marginTop: 10 }}>
            <span>Stripe Customer Id</span>
            <span className="bp3-tag">{this.state.cust_id}</span>
          </div>
        </div>

        {/* <div style={{color:'#00e0fd'}}>
          Step1: Create a test (Customer) payment for $1.00 USD <br/>
        </div> */}
        <p />


        <Card interactive={true} elevation={Elevation.TWO}>
          <h3>Step 1. Test User payment </h3>
  
          <ul>
            <li>You can test your payment option here with $1.00</li>
            {/* <li>This payment should NOT be paid to Vendor immediately.</li>
            <li>It needs to be hold until it's released (Step 2)</li> */}
          </ul>

          <Select
            defaultValue={selectData[0]}
            name="color"
            options={selectData}
            onChange={item => { this.setState({cust_id: item.value}) }}
          />

          <Button
            style={{ marginTop: 10, backgroundColor: '#137cbd'}}
            onClick={this.createdCharge}
          >
            Pay $1 
          </Button>
          {/* <PayButton amount={100} createdCharge={this.createdCharge} setStripeCustomer={this.setStripeCustomer} stripeCustomerId={this.state.stripeCustomerId} updateTask={this.updateTask}/> */}
          {/* <button >$1.00 Test Payment</button> */}
        </Card>

        {charge && <Icon icon="arrow-down" iconSize={40} />}
        
        
  
        {/* <div style={{color:'#00e0fd'}}>
          Step2: Create a button to release the payment <br/>
        </div> */}
        {
          charge &&
          <Card interactive={true} elevation={Elevation.TWO}>
            <h3>Step 2. Payment gets released </h3>
    
            <ul>
              <li>Please release your payment.</li>
              <li>Vendor will receive $0.75 (75%)</li>
              {/* <li>Vendor should receive $0.75 (75%)</li>
              <li>Stripe Receive commission</li>
              <li>F400 (the platform) keeps the rest.</li> */}
            </ul>
            
            <button onClick={this.releasePayment} >Release payment</button>
          </Card>
        }
        
        {transfer && <Icon icon="arrow-down" iconSize={40} />}
  
        
  
        {/* <div style={{color:'#00e0fd'}}>
          Step3: Confirmation <br/>
        </div> */}
        {
          transfer &&
          <Card interactive={true} elevation={Elevation.TWO}>
            <h3>Step3. Vendor Receives payment</h3>
    
            <ul>
              <li>Confirm that your payment was completed successfully</li>
            </ul>
            
          </Card>
        }
      </SettingsWrapper>
    );
  }
  
}

export default withApollo(compose(
  graphql(
    gql(listStripeCustomers),
    {
      options: (props) => ({
        variables: {
          limit: 1000,
          filter: { cognitoId: { eq: props.match.params.userId } }
        }
      }),
      props: ({ data: { listStripeCustomers = { items: [] } } }) => ({
        listStripeCustomers: listStripeCustomers.items
      })
    }
  ),
  graphql(
    gql(updateUser),
    {
      props: ({mutate}) => ({
        updateUser: ({id, stripeCustomerId}) => {
          console.log({id, stripeCustomerId});
          mutate({
            variables: {
              input: {
                id,
                stripeCustomerId
              }
            }
          })
        }
      })
    }
  )
)(TestPayments));
