import React, { Component } from 'react'
import { graphql, compose, withApollo} from 'react-apollo'
import { withRouter } from 'react-router'
import { Icon, Dialog} from "@blueprintjs/core"
import { Row, Col } from 'react-flexbox-grid'
import { Link } from "react-router-dom";
import gql from "graphql-tag"
import { createQuestion } from '../../../graphql/mutations'
import { getTask} from '../../../graphql/queries'
import TaskStepper from "../editor_tasks/components/TaskStepper"


class QuestionCreateByEntity extends Component {

  constructor() {
    super();
    this.state = {
      header: {
        title: '', //Optional
        qid: '', //dynamically generated // Empty will show TBD
        breadcrumb:[
          {
            icon:'globe',
          },
          {
            name:'loading..',
          },
          {
            name:'...',
            route:''
          },
          {
            name:'New'
          }
        ],
        status: '', // 1 | 2 | 3 | 4 | 5 | 6 (need to be INT)
        action: '', // link | button
        action_text: '', //Text inside button
        action_route: '', //Button route
        banner_color: 'new' //new | answer | edit | review | delete
      },
      record: {
        lang: 'ENG',
        status: 1,
        rated: 'G'
      },
      task:[],
      entities:'',
      selectedOption: null,
      modals:{
        first:false,
        second:false
      }
    }
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.task) {
      this.updateState(newProps);
    }
  }
  
  updateState(props) {
    
    // updating  user info
    const { record, header} = this.state;
    
    record.author= props.db_user.username;
    record.authorId = props.db_user.id;
    record.entityId = props.match.params.entityId;
    record.entity = props.match.params.entityName;
    header.breadcrumb[1].name=props.match.params.entityName;
    
    if(props.match.params.taskId) {
      const taskId = props.match.params.taskId;
  
      header['action'] = 'link';
      header.breadcrumb[2].name = 'task: '+taskId.substring(0, 7) + '...';
      header.breadcrumb[2].route = '/q/task/'+ taskId+'/progress' ;
      //header['action_icon'] = 'document';
      // header['action_text'] = 'Back to task';
      // header['action_route'] = '/q/task/' + taskId ;
      record['taskId'] = taskId;
      
      //WTF?? WHY DO I NEED TO PASS THIS WEIRD ASS FIELD THAT IS NOT EVEN MAPPED TO MY SCHEMA, OR THE WHOLE @connection wont work
      record['questionTaskId'] = taskId;
      
    }
    //Getting the entities List from GQL
    const entities = props.entities;

    this.setState({ record, header, entities, task: props.task });
  }
  
  
  handleValues = field => event => {
    const { record } = this.state;
    record[`${field}`] = event.target.value;
    this.setState({ record });
  };
  
  handleClearField = (field) => {
    const { record } = this.state;
    record[`${field}`] = '';
    this.setState({ record });
  }
  
  handleAddQuestionSign = (field) => {
    const { record } = this.state;
    record[`${field}`] = record[`${field}`]+'?';
    this.setState({ record });
  }
  
  handleAddOpenQuestionSign = (field) => {
    const { record } = this.state;
    record[`${field}`] = '¿'+record[`${field}`];
    this.setState({ record });
  }
  
  handleInsertEntityName = (field) => {
    const { record } = this.state;
    record[`${field}`] = record[`${field}`]+" "+record[`entity`]+" ";
    this.setState({ record });
  }
  
  handleCreate = () => {
    // aws
    const { history, createQuestion } = this.props
    const { record } = this.state;
    const currentDate = Date.now();
    record.createdAt = currentDate.toString();
    
    //The Mutation
    createQuestion(record);
    
    //If Question is coming from a Task, send back to task
    if(this.props.match.params.taskId){
      history.push("/q/task/"+this.props.match.params.taskId+"/progress");
    }else{
      history.push("/q/entity/"+this.props.match.params.entityId);
    }
    
  };
  
  handleCreateAndNew = () => {
    
    // aws
    const { history, createQuestion } = this.props;
    const { record } = this.state;
    const currentDate = Date.now();
    record.createdAt = currentDate.toString();
    const taskId = this.props.match.params.taskId;
    
    //The Mutation
    createQuestion(record);
    history.push("/q/entity/"+this.props.match.params.entityId+"/ask/"+this.props.match.params.entityName+(taskId ? "/"+taskId : ''));
  };
  
  handleCreateAndAnswer = async () => {
    
    const { history, createQuestion } = this.props;
    const { record } = this.state;
    const currentDate = Date.now();
    record.createdAt = currentDate.toString();
    
    //The Mutation
    const result = await createQuestion(record);
  
    if (result) {
  
      //If Question is coming from a Task, carry the Task ID
      if(this.props.match.params.taskId){
        history.push("/q/"+result.data.createQuestion.id+"/answer/"+this.props.match.params.taskId);
      }else{
        history.push("/q/"+result.data.createQuestion.id+"/answer");
      }
      
    } else {
      console.log('this is else')
    }
    
  };
  
  handleCancel = () => {
    const { history} = this.props;
    history.push("/q/entity/"+this.props.match.params.entityId);
  };
  
  handleToggleModal = () => {
    const { modals} = this.state;
    modals.first = !modals.first;
    this.setState({ modals });
  };
  
  renderModal(){
    
    return(
      <Dialog
        icon="info-sign"
        onClose={this.handleToggleModal}
        title="Rating System"
        isOpen={this.state.modals.first}
      >
        <div style={{padding:'10px 30px'}}>
          <p style={{padding:'20px 0'}}>We use MPAA rating system to define if content is appropriate for children. By default all content is rated ‘G’ General Audiences. But it can be modified as you see fit. </p>
          
          <table className="bp3-text-small">
            <tr>
              <td style={{width:'70px'}}>
                <h3>G </h3>
              </td>
              <td>
                <h5 style={{display:'inline'}}>General Audiences : </h5>
                 All ages admitted. Nothing that would offend parents for viewing by children.
              </td>
            </tr>
            <tr>
              <td>
                <h3>PG</h3>
              </td>
              <td>
                <h5 style={{display:'inline'}}>Parental Guidance Suggested : </h5>
                Some material may not be suitable for children. Parents urged to give "parental guidance". May contain some material parents might not like for their young children.
              </td>
            </tr>
            <tr>
              <td>
                <h3>PG-13</h3>
              </td>
              <td>
                <h5 style={{display:'inline'}}>Parents Strongly Cautioned : </h5>
                Some material may be inappropriate for children under 13. Parents are urged to be cautious. Some material may be inappropriate for pre-teenagers.
              </td>
            </tr>
            <tr>
              <td>
                <h3>R</h3>
              </td>
              <td>
                <h5 style={{display:'inline'}}>Restricted : </h5>
                Under 17 requires accompanying parent or adult guardian. Contains some adult material. Parents are urged to learn more about the film before taking their young children with them.
              </td>
            </tr>
            <tr>
              <td>
                <h3>NC-17</h3>
              </td>
              <td>
                <h4 style={{display:'inline'}}>Adults Only : </h4>
                No One 17 and Under Admitted. Clearly adult. Children are not admitted.
              </td>
            </tr>
           
          </table>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <button className="bp3-button bp3-intent-success" onClick={this.handleToggleModal}>Gotcha, close this</button>

            </div>
          </div>
        </div>
      </Dialog>
      
    )
  };
  
  render() {
   
    const { question, lang,rated } = this.state.record;
    
    console.log(this.props, this.state)
  
    return (
      
      <React.Fragment>
        
        {this.props.match.params.taskId &&
  
        <TaskStepper task={this.state.task} step={1}>
          <h4>{this.state.task.name}</h4>
        </TaskStepper>
        }
        
        <div className="workarea editor_form">
          
          <Row center="xs" style={{textAlign:'left'}} >
            <Col xs={12} sm={11} md={10} lg={9} >
              <span className="mandatory tag tag-alert">Write Question</span>
              <div className="editor_textarea">
                <textarea className="bp3-input" placeholder={"e.g. Who designed the logo?"} style={{ width: '100%' }} value={question} onChange={this.handleValues('question')} />
                <div>
                  <button className="bp3-button bp3-fill" type="submit" onClick={() => { this.handleClearField('question') }}><Icon icon="eraser" iconSize={13} /></button>
                  <button className="bp3-button bp3-fill" type="submit" onClick={() => { this.handleAddQuestionSign('question') }} >+?</button>
                  <button className="bp3-button bp3-fill" type="submit" onClick={() => { this.handleAddOpenQuestionSign('question') }} >+¿</button>
                  <button className="bp3-button bp3-fill" type="submit" onClick={() => { this.handleInsertEntityName('question') }} ><Icon icon="flag" iconSize={13} /></button>
                </div>
              </div>
  
              <div className="bp3-control-group" style={{marginTop:'15px'}}>
                <span className="bp3-tag bp3-minimal">Language</span>
                <div className="bp3-select">
                  <select className="" value={lang} onChange={this.handleValues('lang')}>
                    <option selected>Choose a language...</option>
                    <option value="ENG">English</option>
                    <option value="SPA">Spanish</option>
                    <option value="POR">Portuguese</option>
                    <option value="MAN">Mandarin</option>
                    <option value="GER">German</option>
                    <option value="JAP">Japanese</option>
                    <option value="FRA">France</option>
                  </select>
                </div>
              </div>
  
              <div className="bp3-control-group" style={{marginTop:'15px'}}>
                <span className="bp3-tag bp3-minimal">Rating</span>
                <div className="bp3-select">
                  <select className="" value={rated} onChange={this.handleValues('rated')}>
                    <option selected>Choose a Rate...</option>
                    <option value="G">G</option>
                    <option value="PG">PG</option>
                    <option value="PG-13">PG-13</option>
                    <option value="R">R</option>
                    <option value="NC-17">NC-17</option>
                  </select>
                </div>
                <button className="bp3-button bp3-icon-help bp3-minimal bp3-small" onClick={this.handleToggleModal} />
                {this.renderModal()}
              </div>
              
              
              <div className="editor_footer_wrapper">
                <div className="editor_footer">
                  {this.props.match.params.taskId ?
                    <Link className="bp3-button bp3-intent-danger bp3-icon-small-cross" to={"/q/task/"+this.props.match.params.taskId} >
                      <span className="mobile_hide">Cancel</span>
                    </Link>
                    :
                    <button className="bp3-button bp3-intent-warning bp3-icon-small-cross" onClick={this.handleCancel}>
                      <span className="mobile_hide">Cancel</span>
                    </button>
                  }
                  <div className="editor_footer">
                    <button className="bp3-button" onClick={this.handleCreateAndNew}>Save+New</button>
                    <br/>
                    <button className="bp3-button bp3-icon-small-tick" onClick={this.handleCreate}>Save</button>
                    <br/>
                    <button className="bp3-button bp3-intent-success bp3-icon-arrow-right" onClick={this.handleCreateAndAnswer}>Answer</button>
                  </div>
                </div>
              </div>
              
            </Col>
          </Row>
        
        </div>
      </React.Fragment>
    
    );
  }
  
}


export default withRouter(withApollo(compose(
  graphql(
    gql(getTask),
    {
      options: ({match: {params: {taskId}}}) => ({
        variables:{id:taskId},
        fetchPolicy: 'cache-and-network',
      }),
      props: ({data: {getTask: task, loading}}) => ({
        task,
        loading,
      }),
    }
  ),
graphql(
  gql(createQuestion),
    {
      props: (props) => ({
        createQuestion: ({ questionTaskId, question, author, authorId, entity, entityId, entityLogo, lang, status, rated, createdAt, taskId, accessible }) => {
          
          return props.mutate({
    
            update: (store, { data: { createQuestion } }) => {
              
              console.log('created:');
              console.log(createQuestion);
            },
            refetchQueries:[`getEntities`, `listQuestions`],
            variables: {
              input: {
                questionTaskId,
                question,
                author,
                authorId,
                entity,
                entityId,
                entityLogo,
                lang,
                status,
                rated,
                createdAt,
                taskId,
                accessible
              }
            },
            //fetchPolicy:'no-cache',
            optimisticResponse: () => ({
              createQuestion: {
                questionTaskId,
                id:'',
                qid:'qwerty',
                question,
                author,
                authorId,
                entity,
                entityId,
                entityLogo,
                lang,
                status,
                rated,
                createdAt,
                taskId,
                accessible,
                __typename:'question'
              }
            })
          })
        }
      })
    }
  )
)(QuestionCreateByEntity)));
