import React, { Component } from 'react'
import ReactTable from "react-table"
import ResultHeader from "../_layout/ResultHeader"
import { Link } from "react-router-dom"
import {Icon, Tooltip, Position, NonIdealState} from "@blueprintjs/core"
import { graphql, compose, withApollo } from "react-apollo"
import gql from "graphql-tag"
import { listQuestions } from '../../../graphql/queries'
import LinkIcon from "../../_utils/LinkIcon"
import matchSorter from 'match-sorter'

class ListUserQuestion extends Component {
  constructor(){
    super();
    
    this.state = {
      header:{
        title:'loading..',
        counter:'',
        action:'',
        action_text:'',
        action_icon:'',
        action_route:''
      },
      table:[]
      
    }
  }
  
  componentWillMount(){
    this.updateState(this.props);
  }
  
  componentWillReceiveProps(newProps){
    this.updateState(newProps);
  }
  
  updateState(props){
    //Getting all GQL payload
    const payload = props.items;
    
    //Getting Counter number
    const header = {...this.state.header};
    header.counter = payload.length;
    header.title = (props.db_user ? props.db_user.username : "User questions ");
    
    //Updating State
    this.setState({table: payload, header});
    
  }
  
  renderTable(){
    
    const table = this.state.table;
    
    return(
      <ReactTable
        data={table}
        noDataText="No Questions here!"
        filterable
        defaultFilterMethod={(filter, row) =>
        String(row[filter.id]) === filter.value}
        columns={[{
          columns: [
            {
              Header: "Question",
              id: "question",
              accessor: d => d.question,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["question"] }),
              filterAll: true,
              minWidth: 300,
              Cell: row =>(
                <div>
                  <LinkIcon type={'link'}
                            text={row.value}
                            route={"/q/"+row.original.id}
                            icon="circle-arrow-up"
                  />
                </div>
  
              )
            },
            {
              Header: 'Entity',
              id:"entity",
              accessor: d => d.entity,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["entity"] }),
              filterAll: true,
              Cell: row =>(
                <LinkIcon type={'entity_logo'}
                          icon={row.original.entityLogo}
                          text={row.value}
                          route={'/q/entity/'+row.original.entityId}
                />
              )
            },
            {
              Header: 'Answers',
              accessor: 'count',
              maxWidth: 70,
              Filter: '-',
              Cell: row =>(
                <Link to={"/q/"+ row.original.questionId }>
                  {row.value}
                </Link>
              )
            },
            {
              Header: '',
              accessor: 'author',
              Filter: '-',
              maxWidth: 75,
            },
            {
              accessor: 'id',
              maxWidth: 100,
              Filter: '-',
              Cell: row => (
                <div className="actions_column">
                  <Tooltip content="Add Answer" position={Position.LEFT}>
                    <Link to={"/q/"+row.value+"/answer"}>
                      <Icon icon="plus" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  <Tooltip content="Edit" position={Position.LEFT}>
                    <Link to={"/q/"+row.value+"/edit"}>
                      <Icon icon="edit" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  <Tooltip content="Delete" position={Position.LEFT}>
                    <Link to={"/q/"+row.value+"/delete"}>
                      <Icon icon="cross" iconSize={11} />
                    </Link>
                  </Tooltip>
                </div>
               
              )
            }
          
          ]
        }]}
        defaultPageSize={20}
        className=" -highlight"
      />
    )
    
  }
  
  renderNoResults(){
    
    const action =(
      <Link to="/q/create" className="bp3-button">
        Ask a question
      </Link>
    )
    return(
      <div style={{display:'flex', justifyContent:'center', height:'50vh'}}>
        <NonIdealState
          icon="issue"
          title="No questions here"
          description="You haven't asked any question yet"
          action={action}
        />
      </div>
    )
  }
  
  render() {
    return (
      
      <div>
        {this.state.header && <ResultHeader category="Questions" header={this.state.header} />}
        <div className="workarea">
          { !this.state.table.length ? this.renderNoResults() : this.renderTable() }
        </div>      
      </div>
    
    );
  }
}

export default withApollo(compose(
  graphql(
    gql(listQuestions),
    {
      options: (props) => ({
        //fetchPolicy: 'no-cache',
        variables: {
          //id: props.match.params.userId,
          limit:1000,
          filter:{authorId:{eq: props.match.params.userId}}
        }
      }),
      props: ({ data: { listQuestions = { items: [] } } }) => ({
        items: listQuestions.items
      })
    }
  ),
)(ListUserQuestion));
