import React, {Component} from 'react'
import {graphql, compose, withApollo} from 'react-apollo'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import {Storage} from 'aws-amplify'
import {PhotoPicker} from 'aws-amplify-react'
import {Row, Col} from 'react-flexbox-grid'
import {Dialog,Icon} from "@blueprintjs/core"
import gql from "graphql-tag"
import { getUser, listEntitys } from '../../../graphql/queries'
import { updateUser } from '../../../graphql/mutations'
import myconfig from '../../../custom-config'
import amplifyconfig from '../../../aws-exports'
import SettingsWrapper from "../Settings/_layout/SettingsWrapper"


import {
  setHeader
} from '../../../_actions';

Storage.configure({
  bucket: myconfig.Storage.bucket,
  region: myconfig.Storage.region,
  identityPoolId: amplifyconfig.aws_cognito_identity_pool_id
});

//Avatar preview style
const previewStyle = {
  width: 70,
  height: 70,
  objectFit: 'cover',
  borderRadius: '50%',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'darkgray'
};

class UserEdit extends Component {
  
  constructor() {
    super();
    this.state = {
      header: {
        counter: '',
        action: '',
        action_text: '',
        action_icon: '',
        action_route: '',
        banner_color: '',
        breadcrumb: [
          {
            name: '',
            route: '',
            icon: 'cog',
            active: false
          }, {
            name: 'Settings',
            active: true,
            size: 'L'
          }, {
            name: '',
            size: 'L'
          }
        ],
      },
      record: {
        username: 'loading..',
        email: '',
        about: '',
        avatar: '',
        status:'',
        entities: {}
      },
      entities_temp: {},
      prevSrc: null,
      file: null,
      auth_user: {
        id: ''
      },
      db_user:[],
      userRole:0,
      modals: {
        first: false,
      },
      isToggleOn: true,
    };
    
    
    this.handleClick = this.handleClick.bind(this);
  }
  
  componentWillMount() {
    this.updateState();
  }
  
  async updateState() {
    const {header} = this.state;
    
    const {setHeaderData} = this.props;
    setHeaderData(header);
    
    const {record } = this.state;
    record.id = this.props.match.params.userId;
    let prevSrc = this.state.prevSrc;
    const {client} = this.props;
    
    let entities_temp = this.state.entities_temp;
    
    try {
      const result = await client.query({
        query: gql(getUser),
        fetchPolicy: 'network-only',
        variables: {
          id: this.props.match.params.userId,
        },
      });
      
      if (result && result.data.getUser) {
        record.id = result.data.getUser.id;
        record.username = result.data.getUser.username;
        record.email = result.data.getUser.email;
        record.about = result.data.getUser.about ? result.data.getUser.about : '';
        record.avatar = result.data.getUser.avatar;
        record.status = result.data.getUser.status ? result.data.getUser.status : '105';
        record.entities = result.data.getUser.entities;
        prevSrc = result.data.getUser.avatar;
        entities_temp = result.data.getUser.entities;
        header.breadcrumb[2].name = result.data.getUser.username;
        header.breadcrumb[2].route = (result.data.getUser.id ? "/q/user/" + result.data.getUser.id + "/profile" : "/q/users");
        header.action_route = (result.data.getUser.id ? "/q/user/" + result.data.getUser.id + "/profile" : "/q/users");
        
      }
      
      if (!entities_temp) {
        entities_temp = result.data.getUser.entities;
        console.log(entities_temp);
      }
      
      
    } catch (error) {
      console.log(error);
    }
    
    this.setState({record, prevSrc, header, entities_temp: entities_temp});
  }
  
  handleValues = field => event => {
    const {record} = this.state
    record[`${field}`] = event.target.value;
    this.setState({record});
  }
  
  handleEntityChange = (selectedOption) => {
    
    console.log(selectedOption);
    const {record} = this.state;
    
    const format2save = selectedOption.map(e => ("(" + e.value + "+" + e.label + "+" + e.logo + "+3)"));
    
    record['entities'] = format2save.join();
    
    this.setState({record, entities_temp: format2save});
    
    
  };
  
  handleSave = () => {
    const {history, updateUser} = this.props
    const {record} = this.state;
    const {userId} = this.props.match.params;
    record.cognitoId = userId;
    record.id = userId;
    record.entities = this.state.entities_temp;
    console.log(record.entities);
    
    updateUser(record);
    
    const {file} = this.state;
    
    if (file) {
      Storage.put(file.name, file.file, {
        Bucket: myconfig.Storage.bucket,
        key: record.avatar,
        ACL: "public-read-write"
      })
      .then(result => {
        console.log("avatar is added into the S3 bucket correctly.");
      })
      .catch(err => {
        console.log("avatar is not added. error is ", err);
      });
    }
    console.log('FinalState:////////');
    console.log(this.state);
    history.push("/q/user/" + record.id + "/profile");
  }
  
  handleCancel = () => {
    const {history} = this.props
    history.goBack();
  }
  
  onPickInfo(data) {
    const {record} = this.state;
    record.avatar = "https://s3.amazonaws.com/" + myconfig.Storage.bucket + "/public/" + data.name;
    //const { file } = this.state;
    this.setState({record, file: data});
  }
  
  onChangeImage(data) {
    this.setState({prevSrc: data});
  }
  
  handleToggleModal = () => {
    const {modals} = this.state;
    modals.first = !modals.first;
    this.setState({modals});
  };
  
  renderModal() {
    
    return (
      <Dialog
        icon="mugshot"
        onClose={this.handleToggleModal}
        title="Your user Avatar"
        isOpen={this.state.modals.first}
      >
        <div style={{padding: '10px 30px'}}>
          <div>
            <PhotoPicker
              preview
              onPick={ data => this.onPickInfo(data)}
              onLoad={dataURL => this.onChangeImage(dataURL)}
              style={{margin: '0'}}
            />
            <button className="bp3-button bp3-large bp3-intent-success"
                    style={{float:'right'}}
                    onClick={this.handleToggleModal}>Save
            </button>
          </div>
        </div>
      </Dialog>
    
    )
  };
  
  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }
  
  renderConfirm(status) {
    return (
      <Icon icon={status ? 'tick' : 'cross'}
            style={{color: (status ? 'green' : 'red')}}
            iconSize={15}/>
    )
  }
  
  
  render() {
    
    const {
      username,
      email,
      about,
      status
    } = this.state.record;
    
    let authUserId = this.props.auth_user ? this.props.auth_user.id : 0;
    const sameUser = this.props.match.params.userId === authUserId;
    const userRole = this.props.db_user ? parseInt(this.props.db_user.status): 0;
    
    return (
      <SettingsWrapper user={this.props.match.params.userId}
                       header={this.state.header}
                       userRole={userRole}
                       activeTab="user">
        <h2>My Info</h2>
        <Row>
          <Col xs={12}>
            <div>
              <Row>
                <Col xs={12} md={8}>
                  <table>
                    <tbody>
                    <tr>
                      <td>
                        Username
                      </td>
                      <td>
                        { sameUser ?
                          
                          <input className="bp3-input" placeholder="username"
                                 style={{width: '100%'}}
                                 value={username} onChange={this.handleValues('username')}/>
                          :
                          <div>{username}</div>
                        }
                      
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Email
                      </td>
                      <td>
                        { sameUser ?
                          <input className="bp3-input" placeholder="email" style={{width: '100%'}}
                                 value={email} onChange={this.handleValues('email')}/>
                          :
                          <div>{email}</div>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        About me
                      </td>
                      <td>
                        { sameUser ?
                          <textarea className="bp3-input" style={{width: '100%'}}
                                    placeholder="A very famous mouse" value={ about }
                                    onChange={this.handleValues('about')}/>
                          :
                          <div>{about}</div>
                        }
                      </td>
                    </tr>
                    
                    </tbody>
                  </table>
                
                </Col>
                <Col xs={12} md={4}>
  
                  {this.renderModal()}
                  
                  { sameUser ?
                    <div>
                      {this.state.prevSrc ?
    
                        <div>
                          <button style={{padding:'20px', color:'#0078ff',borderColor:'#0078ff'}}
                                  onClick={this.handleToggleModal}>
                            <img src={this.state.prevSrc} style={previewStyle} alt=""/>
                            <br/><br/>
                            <p>Change Avatar</p>
                          </button>
                        </div>
                        
                        :
                        <button style={{padding:'20px', color:'#0078ff',borderColor:'#0078ff'}}
                                onClick={this.handleToggleModal}>
                          <Icon icon="plus" iconSize={30} color="#0078ff" />
                          <Icon icon="mugshot" iconSize={30} color="#0078ff" />
                          <br/><br/>
                          <p>Add Avatar</p>
                        </button>
    
                      }
                      
                    </div>
                    
                    :
                    ''
                  }
                </Col>
              </Row>
            
            </div>
          </Col>
        
        </Row>
        
        
        <Row>
          <Col xs={12}>
            <hr/>
            <div className="editor_footer">
              <div>
                { sameUser &&
                <select className="bp3-select" value={status}
                        onChange={this.handleValues('status')}>
                  <option value="">Select one</option>
                  <option value="101">Admin</option>
                  <option value="105">Regular</option>
                  <option value="110">Tasker</option>
                </select>
                }
              </div>
              
              { sameUser ?
                <div>
                  
                  <button className="bp3-button" onClick={this.handleCancel}>Cancel</button>
                  < button className="bp3-button bp3-intent-primary" onClick={this.handleSave}>
                    Save
                  </button>
                </div>
                :null
              }
            </div>
          </Col>
        </Row>
      </SettingsWrapper>
    
    );
  }
  
}

UserEdit.propTypes = {
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setHeaderData: value => dispatch(setHeader(value)),
});

const UserEditConnected = connect(mapStateToProps, mapDispatchToProps)(UserEdit);

export default withRouter(withApollo(compose(
  graphql(
    gql(updateUser),
    {
      props: ({mutate}) => ({
        updateUser: ({id, cognitoId, username, email, about, avatar, entities, status}) => {
          console.log({id, cognitoId, username, email, about, avatar, entities, status});
          mutate({
            variables: {
              input: {
                id,
                cognitoId,
                username,
                email,
                about,
                avatar,
                entities,
                status
              }
            }
          })
        }
      })
    }
  ),
  graphql(
    gql(listEntitys),
    {
      options: {
        //fetchPolicy: 'network-only',
      },
      props: ({data: {listEntitys = {items: []}}}) => ({
        entitiesList: listEntitys.items
      })
    }
  )
)(UserEditConnected)));
