import gql from "graphql-tag";

export default gql(`
query {
    listUsers(limit:1000){
        items{
            id
            username
            email
            locations
            first
            last
            asked
            answered
        }
    }
}`);
