import React from 'react';
import { ApolloProvider } from "react-apollo";
import { Auth } from 'aws-amplify';
import aws_config from "./aws-exports";
import AWSAppSyncClient, {AUTH_TYPE} from "aws-appsync";
import { StripeProvider } from 'react-stripe-elements';

import { Rehydrated} from "aws-appsync-react";
import { Provider } from 'react-redux';
import store from './store';
import Home from './Components/router';
import './App.css';


// Auth.currentCredentials()
// .then(d => console.log('data: ', d))
// .catch(e => console.log('error: ', e))

// const authConfig = {
//   type: aws_config.aws_appsync_authenticationType,
//
//   // COGNITO USER POOLS
//   jwtToken: async () => {
//     return (await Auth.currentSession()).getAccessToken().getJwtToken();
//   }
// };


// AppSync client instantiation
const client = new AWSAppSyncClient(
  {
    disableOffline: true,
    url: aws_config.aws_appsync_graphqlEndpoint,
    region: aws_config.aws_cognito_region,
    auth: {
      // IAM
      type: AUTH_TYPE.AWS_IAM,
      credentials: () => Auth.currentCredentials(),
    },
    //auth: authConfig,
    //complexObjectsCredentials: () => Auth.currentCredentials()
  },
  // {
  //   cache,
  //   link: ApolloLink.from([
  //     stateLink, /// client side state only
  //     createAppSyncLink({
  //       url: aws_config.aws_appsync_graphqlEndpoint,
  //       region: aws_config.aws_cognito_region,
  //       auth: authConfig,
  //       complexObjectsCredentials: () => Auth.currentCredentials()
  //     })
  //   ])
  // }
);

class WithProvider extends React.Component {
  
  render() {
  
    //const current = Auth.currentCredentials()
    // console.log(client);
    // console.log(Auth.currentCredentials());
   
      return (
        <ApolloProvider client={client}>
          <Provider store={store}>
            <StripeProvider apiKey="pk_test_zXwYYJoWJ3KE2mwrCBrSC3Z7">

              <Rehydrated>
                <Home authData={this.props.authData} />
              </Rehydrated>
            </StripeProvider>
          </Provider>
        </ApolloProvider>
      );
  }
}

export default WithProvider;