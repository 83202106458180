import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Row, Col } from 'react-flexbox-grid'
import EntityInfo from "./EntityInfo"
import kebabCase from "lodash/kebabCase"

class EntityHeader extends Component {
  
  
  render() {
    //document.title = this.props.header.displayName +" - FAQ";
    const slug = "/questions/"+kebabCase(this.props.header.title)+"/"+this.props.header.id;
    
    return (
      <Row center="xs">
        <Col xs={11} md={8}>
          
          <div className="entity_head">
              <EntityInfo logo={this.props.header.entityLogo} alt={this.props.header.displayName} link={slug}>
                <Link to={slug}>
                  <h3 style={{margin:'0', fontWeight:'100', fontSize: '22px'}}>
                    {this.props.header.displayName}
                  </h3>
                </Link>
                <h4 style={{opacity:'0.4',margin:'0', fontWeight:'100', fontSize:'22px'}}>Frequently Asked Questions</h4>
              </EntityInfo>
          </div>
        </Col>
      </Row>
    
    );
  }
  
}


export default EntityHeader;
