import React, {Component} from "react"
import { graphql, compose, withApollo } from "react-apollo"
import gql from "graphql-tag"
import { getTask, listQuestions } from '../../../graphql/queries'
import TaskWrapper from "./components/TaskWrapper"
//import EventComments from "./components/TaskComments"
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

class TaskDetail extends Component {
  
  constructor() {
    super();
    this.state = {
      task:{
        id:'',
        status:''
      },
    }
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.task) {
      this.updateState(newProps);
    }
  }
  
  updateState(props) {
    const {task} = this.state;
    task.id = (props.task ? props.task.id : '');
    task.status = (props.task ? props.task.status : '');
   
    this.setState({task});
    
  }
  
  
  render() {
 
    const {task, listQuestions, loading, db_user} = this.props;
    
    return (
      <div className={` ${loading ? 'loading' : ''}`}>
         {task &&
            <TaskWrapper
              task={task}
              questions={listQuestions}
              section="details"
              user={db_user}
            >
              
              {task.description ?
                <div className="task_card" style={{ alignItems:'start'}}>
                  <FroalaEditorView
                  model={task.description}
                  />
                </div>
                
                :
                <div>No Description available</div>
              
              }

              {/*TODO: FIX COMMENTS*/}
              {/*{task &&*/}
              {/**/}
              {/*<table style={{marginTop:'40px'}}>*/}
              {/*<tbody>*/}
              {/*<tr>*/}
              {/*<td>*/}
              {/*Comments*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*/!*TODO: UNTANGLE COMMENT SUBSCRIPTIONS*!/*/}
              {/*/!*AND A NESTED OBJECT*!/*/}
              {/*<EventComments eventId={task.id} comments={task.comments}/>*/}
              {/*</td>*/}
              {/*</tr>*/}
              {/*</tbody>*/}
              {/*</table>*/}

              {/*}*/}
            </TaskWrapper>
          
         }
      </div>
    );
  }
  
}


export default withApollo(compose(
  graphql(
    gql(getTask),
    {
      options: ({match: {params: {id}}}) => ({
        variables: {id, limit:1000,},
        fetchPolicy: 'cache-and-network',
      }),
      props: ({data: {getTask: task, loading}}) => ({
        task,
        loading,
      }),
    },
  ),
  graphql(
    gql(listQuestions),
    {
      options: (props) => ({
        variables: {
          limit:1000,
          filter:{taskId:{eq:props.match.params.id}}
        }
      }),
      props: ({ data: { listQuestions = { items: [] } } }) => ({
        listQuestions: listQuestions.items
      })
    }
  )
)(TaskDetail));


// const ViewTaskWithData = graphql(
//   gql(getTask),
//   {
//     options: ({match: {params: {id}}}) => ({
//       variables: {id},
//       fetchPolicy: 'cache-and-network',
//     }),
//     props: ({data: {getTask: task, loading}}) => ({
//       task,
//       loading,
//     }),
//   },
// )(TaskDetail);
//
// export default ViewTaskWithData;