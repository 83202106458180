import React from 'react';
import {Link} from "react-router-dom";

//101 = admin
//105 = user
//110 = tasker

const SettingsSidebar = ({activeTab, userId, userRole}) => (
      <div className="bp3-tabs bp3-vertical">
        <ul className="bp3-tab-list" role="tablist">
          
          <Link to={"/q/settings/" + userId + "/edit"}>
            <li className="bp3-tab" role="tab" aria-selected={activeTab === 'user'}>User Info</li>
          </Link>
          
          {userRole === 110 || userRole ===101 ?
            <Link to={"/q/settings/" + userId + "/connect"}>
              <li className="bp3-tab" role="tab" aria-selected={activeTab === 'receivable'}>Connect
                Account
              </li>
            </Link>
            :null
          }
          {userRole === 105 || userRole ===101 ?
            <Link to={"/q/settings/" + userId + "/testpayments"}>
              <li className="bp3-tab" role="tab" aria-selected={activeTab === 'test'}>Test Payments
              </li>
            </Link>
            :null
          }
          
          <Link to={"/q/settings/" + userId + "/transactions"}>
            <li className="bp3-tab" role="tab" aria-selected={activeTab === 'transactions'}>
              My Cards
            </li>
          </Link>
         
          
          {userRole === 105 || userRole ===101 ?
            <Link to={"/q/settings/" + userId + "/channels"}>
              <li className="bp3-tab" role="tab" aria-selected={activeTab === 'mychannels'}>Channels
              </li>
            </Link>
            :null
          }
        </ul>
      </div>
  
);



export default SettingsSidebar;