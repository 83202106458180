import React, { Component } from "react"
import { Route, Switch } from 'react-router-dom'
import { Grid, Row, Col } from 'react-flexbox-grid'
import {Auth} from 'aws-amplify';
import {Helmet} from "react-helmet"
import EntityIntegrations from './EntityShare'
import EntityDashboard from './EntityDashboard'
import EntityQuestions from './EntityQuestions'
import EntityNewQuestion from './EntityNewQuestion'
import EntityQuestionDetail from './EntityQuestionDetail'
import EntityAnswerDetail from './EntityAnswerDetail'
import EntityExplore from './EntityExplore'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import 'react-table/react-table.css'
import '../Editor/_layout/Answers/Answer.css'
import bg from "../_layout/assets/blueprint-7.png"


class EntityWrapper extends Component {
  
  constructor() {
    super();
    this.state = {
      showDetails: true,
      user:{
          username:''
      }
    }
  }
  
  async componentDidMount() {
    //get Logged in user
    let user = await Auth.currentAuthenticatedUser();
    this.setState({user: user});
    
  }
  
  renderHelmet(){
    
    return(
      <Helmet>
        <meta charSet="utf-8" />
        <title>F400</title>
        <meta property="og:type" content="website" />
      </Helmet>
    )
  }
  
  renderRouter() {
      
    const match = this.props.match;
    
    return (
      <Grid fluid>
        {/*{console.log(authUser)}*/}
          <Row center="xs">
              <Col xs={12} md={12}>
                
                <Switch>
                  {/*ALL ENTITIES*/}
                    <Route exact path={`${match.url}`}
                           render={routeProps => <EntityExplore {...routeProps} />}/>
                    <Route exact path={`${match.url}/:mode`}
                           render={routeProps => <EntityExplore {...routeProps} />}/>
                    
                    <Route path={`${match.url}/:slug/:entityId/analytics`}
                           render={routeProps => <EntityDashboard {...routeProps}
                                                                  id={match.params.id}/>}/>
                  
                  {/*ENTITY ASK*/}
                    <Route exact path={`${match.url}/:slug/:entityId/ask`}
                           render={routeProps => <EntityNewQuestion {...routeProps}
                                                                    id={match.params.id}/>}/>
                  
                  {/*ENTITY DETAIL*/}
                    <Route exact path={`${match.url}/:slug/:entityId`}
                           render={routeProps => <EntityQuestions {...routeProps} />}/>
                    <Route exact path={`${match.url}/:slug/:entityId/:config`}
                           render={routeProps => <EntityQuestions {...routeProps} />}/>
                    
                    
                    <Route path={`${match.url}/:slug/:entityId/i/:type`}
                           component={EntityIntegrations}/>
                  
                  {/*QUESTIONS*/}
                    <Route path={`${match.url}/:slug/:entityId/q/:questionId/:slugQuestion/:config`}
                           render={routeProps => <EntityQuestionDetail {...routeProps}
                                                                       id={match.params.id}/>}/>
                    <Route path={`${match.url}/:slug/:entityId/q/:questionId/:slugQuestion`}
                           render={routeProps => <EntityQuestionDetail {...routeProps}
                                                                       id={match.params.id}/>}/>
                  
                  {/*ANSWER*/}
                    <Route path={`${match.url}/:slug/:entityId/a/:answerId/:slugAnswer/:config`}
                           render={routeProps => <EntityAnswerDetail {...routeProps}
                                                                     id={match.params.id}/>}/>
                    <Route path={`${match.url}/:slug/:entityId/a/:answerId/:slugAnswer`}
                           render={routeProps => <EntityAnswerDetail {...routeProps}
                                                                     id={match.params.id}/>}/>
                
                
                </Switch>
                
              
              </Col>
          </Row>
      
      </Grid>
    );
  }
  
  renderLoggedOut(){
      return(
        <div className="loggedOut">
          <div  className="screen" style={{ backgroundImage:`url(${bg})` }} />
            <div className="postIt">
              <h4><span>User:</span> user1</h4>
              <h4><span>psswrd:</span> Mars123</h4>
            </div>
        </div>
      )
  }
  
  render() {
    
    return(
      <React.Fragment>
        {this.renderHelmet()}
        {/*{user.username ? this.renderRouter() : this.renderLoggedOut()}*/}
        {this.renderRouter()}
      </React.Fragment>
    );
  }
  
}


export default EntityWrapper;


