import React from 'react';
import { Link } from "react-router-dom";
import {Icon} from "@blueprintjs/core"
import { Row, Col } from 'react-flexbox-grid'
import ShowStatus from '../../../_utils/ShowStatus'


const TaskHead = ({task, children, userRole, status}) => (
  
  <Row className="task_header">
    <Col xs={7} sm={9} md={9}>
      <div className="task_name">
        <Link to={`/q/task/${task.id}`} className="truncate_short">
            Task: {task.id}
        </Link>
        <span className="assigned mobile_hide">
          {ShowStatus(status, true)}
  
          {status === '102' ?
              userRole ==='assignee' || userRole ==='owner_assignee'?
                <span> to you ({task.assignedToName})</span>
                :<span> to
                    <Link to={`/q/user/${task.assignedTo}/profile`}>
                      <span> {task.assignedToName}</span>
                    </Link>
                </span>
            :null
          }
          
        </span>
  
        {userRole === 'owner' || userRole === 'owner_assignee' ?
          <span className="assigned mobile_hide" style={{color:'red'}}>
            <Icon icon="issue" iconSize={15}/>
            <span style={{margin:'0 7px'}}>This job was created by you.</span>
          </span>
          :null
        }
        
        
      </div>
    </Col>
    <Col xs={5} sm={3} md={3} style={{textAlign:'right'}}>
      
      {children ?
        children
        :
        null
      }
    </Col>
  </Row>
);

export default TaskHead;