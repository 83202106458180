import React, { Component } from 'react'
import ReactTable from "react-table"
import { graphql, compose, withApollo } from "react-apollo"
import gql from "graphql-tag"
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Spring } from 'react-spring'
import {Helmet} from "react-helmet"
import matchSorter from 'match-sorter'
import { Row, Col } from 'react-flexbox-grid'
import { Link } from "react-router-dom"
import {Icon, Tooltip, Position, Dialog} from "@blueprintjs/core"
import { listEntitys } from '../../graphql/queries'
import LinkIcon from "../_utils/LinkIcon"
import Mosaic from "./_layout/Mosaic"
import bg from "../_layout/assets/wp_city.jpg"

import {
  setUser,
  setHeader
} from '../../_actions';

class EntityExplorer extends Component {
  constructor(){
    super();
    
    this.state = {
      header:{
        title: '',
        displayName: '',
        counter:'',
        entityLogo:'',
        wall:'',
        theme:'dark'
      },
      entities:{
      
      },
      table:[],
      modals:{
        first:false
      }
      
    }
  
    this.renderTable = this.renderTable.bind(this);
    this.renderMosaic = this.renderMosaic.bind(this);
  }
  
  componentWillMount(){
    this.updateState();
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps.list) {
      this.updateState2(newProps);
    }
  }
  
  updateState2(props){
    
    // Question result from AWS DynamoDB using GraphQL
    const { list } = props;
    
    // Entity Header Info
    const { entities } = this.state;
    entities.id = list.id;
    entities.name = list.name;
    
    
    this.setState({entities});
  }
  
  //TODO: REPLACE THIS WITH A QUERY FROM THE SCHEMA
  async updateState() {
    //Getting all GQL payload
    const { client } = this.props;
    let payload = [];
    try {
      const result = await client.query({
        query: gql`
            query {
                listEntitys(limit:1000, filter: {
                    status: {
                        contains: "201"
                    }
                }){
                    items{
                        id
                        name
                        displayName
                        website
                        logo
                        score
                        locations
                        status
                    }
                }
            }
        `
      });
      if(result && result.data.listEntitys) {
        payload = result.data.listEntitys.items;
      }
      //console.log(result);
    } catch (error) {
      console.log(error);
    }
    
    //Getting Counter number
    
    const header = {
      ...this.state.header,
      counter: payload.length
    };
    const { setHeaderData } = this.props;
    setHeaderData(header);
    
    //Updating State
    this.setState({ table: payload });
    
  }
  
  renderTable(){
    
    const table = this.state.table;
    
    return(
      <ReactTable
        data={table}
        columns={[{
          columns: [
            {
              Header: 'Name',
              accessor: 'displayName',
              minWidth: 200,
              Cell: row =>(
                <LinkIcon type={'entity_logo'}
                          icon={row.original.logo}
                          text={row.value}
                          route={"/e/"+row.original.id}
                />
              )
            },
            {
              Header: 'Actions',
              accessor: 'id',
              maxWidth: 100,
              Cell: row => (
                <div className="actions_column" style={{display:'flex', justifyContent:'space-between'}}>
                  <Tooltip content="Ask Question" position={Position.LEFT}>
                    <Link to={"/q/create"}>
                      <Icon icon="plus" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  <Tooltip content="Backend" position={Position.LEFT}>
                    <Link to={"/q/entity/"+row.value+"/profile"}>
                      <Icon icon="log-in" iconSize={11}/>
                    </Link>
                  </Tooltip>
                  
                  
                  {this.props.db_user &&(
                    
                    this.props.db_user.status === 101 &&(
                      <React.fragment>
                        <Tooltip content="Delete" position={Position.LEFT}>
                          <Link to={"/q/entity/"+row.value+"/edit"}>
                            <Icon icon="edit" iconSize={11}/>
                          </Link>
                        </Tooltip>
                        <Tooltip content="Edit" position={Position.LEFT}>
                          <Link to={"/q/entity/"+row.value+"/delete"}>
                            <Icon icon="cross" iconSize={11} />
                          </Link>
                        </Tooltip>
                      </React.fragment>
                    )
                  )}
                
                </div>
              )
            }
          
          ]
        }]}
        defaultPageSize={20}
        className=" -highlight"
      />
    )
    
  }
  
  updateInputValue(evt) {
    const allQuestions = this.state.table;
    let inputValue= evt.target.value;
    
    
    const filterResult = matchSorter(allQuestions, inputValue, {
      keys: ['displayName', {minRanking: matchSorter.rankings.EQUAL, key: 'id'}],
    });
    
    this.setState({
      filtered: filterResult,
      inputValue: inputValue
    });
  }
  
  renderMosaic(){
  
    let mapThis = [];
    if(this.state.filtered){
      mapThis = this.state.filtered
    }else(
      mapThis = this.state.table
    );
  
    return (
      <Row center="xs">
        <Col xs={11} md={8}>
          <div style={{paddingTop:'40px'}}>
            <Row>
              <Col xs={12} md={12}>
              
                <div className="bp3-input-group">
                  <span className="bp3-icon bp3-icon-search" style={{padding:"6px 6px"}} />
                  <input
                    value={this.state.inputValue}
                    onChange={evt => this.updateInputValue(evt)}
                    type="text"
                    className="bp3-inputx"
                    placeholder={"Search "}
                    style={{ padding: "20px 40px"}}
                  />
                </div>
                <div className="search_results">{mapThis.length} results.</div>
              </Col>
              <Col xs={12} md={12} style={{padding:'30px 10px'}}>
              
                <div className="entity_mosaic_grid" key="mosaic">
                  { mapThis.map(e => <Mosaic data={e} key={e.id}/>) }
                </div>
              
                {!mapThis.length && (
                
                  <Row>
                    <Col xs={12} md={6}>
                      <h4>No public FAQs with that name</h4>
                    </Col>
                    <Col xs={12} md={6}>
                      {/*<CreateEntityQuestion question={this.state.inputValue} id={9714}/>*/}
                    </Col>
                  </Row>
              
                )}
            
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    )
  }
  
  renderModal(){
    
    return(
      <Dialog
        onClose={this.handleToggleModal}
        isOpen={this.state.modals.first}
      >
        <div style={{padding:'10px 30px'}}>
          <p style={{padding:'20px 0', color:'#666'}}>
            
            Please Login or Create a new account
          </p>
          <Link className="bp3-button bp3-intent-success" to={"/join/7e9a254a-b98d-4e32-8a53-2423ab58008f/greenpeace"} >
            Go
          </Link>
          
        </div>
      </Dialog>
    
    )
  }
  
  handleToggleModal = () => {
    const { modals} = this.state;
    modals.first = !modals.first;
    this.setState({ modals });
  }
  
  
  renderHelmet(){
    return(
      <Helmet>
        <meta charSet="utf-8" />
        <title>Explore | F400</title>
        <meta name="description" content={`Explore F400 `}/>
        <meta property="og:description" content={`Explore F400`}/>
        <meta property="og:title" content={`Explore | F400`} />
        <meta property="og:type" content="website" />
      </Helmet>
    )
  }
  
  render() {
    //const bg = this.state.header.wall;
    const mode = this.props.match.params.mode;
    
    return (
  
      <div className={"theme_wrapper "+ this.state.header.theme}>
        
        {this.renderHelmet()}
        
        <div  className="entity_background" style={{ backgroundImage:`url(${bg})` }} />
        
  
        <div style={{paddingTop:'70px'}}>
          
          <h1 style={{fontWeight:'200'}}>Public FAQ</h1>
          
          Crowdsourced Frequently Asked Questions showcase
          
  
          {this.renderModal()}
  
  
          {mode === 'table' ?
            <Spring from={{opacity: 0}} to={{opacity: 1}}>
              {props =>
                <div style={props}>
                  This will fade in
                </div>
              }
            </Spring>
            :
            this.renderMosaic()
          }
          
        </div>
        
        
  
        <button className="button bp3-text-small" style={{opacity: '0.5', top:'70px', position:'relative' }} onClick={this.handleToggleModal} >
          Create your Organization Profile &nbsp;
          <Icon icon="arrow-right" iconSize={15} />
        </button>
      </div>
      
    
    );
  }
  
}

EntityExplorer.propTypes = {
  user: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  header: PropTypes.object.isRequired,
  setHeaderData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.dataReducer.user,
  header: state.dataReducer.header,
});

const mapDispatchToProps = dispatch => ({
  setUserData: value => dispatch(setUser(value)),
  setHeaderData: value => dispatch(setHeader(value)),
});

const EntityExplorerConnected = connect(mapStateToProps, mapDispatchToProps)(EntityExplorer);

export default withApollo(compose(

  graphql(
    gql(listEntitys),
    {
      options: {
        //fetchPolicy: 'network-only',
      },
      props: ({ data: { listEntities = { items: [] } } }) => ({
        listx: listEntities.items
      })
    }
  ),
)(EntityExplorerConnected));


