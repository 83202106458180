import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { graphql, compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Row, Col } from 'react-flexbox-grid';
import { Icon, Tooltip, Position} from "@blueprintjs/core";
import gql from "graphql-tag"
import { getUser } from '../../../graphql/queries'
import ShowStatus from "../../_utils/ShowStatus"
import {renderEntitiesList} from '../../_utils/ShowEntities'
import QuestionHeader from "../_layout/QuestionHeader";

const userTasks = `
query listTasks($id:String!, $limit:Int){
  listTasks(filter: { assignedTo: {eq: $id}}, limit:$limit ){
    items{
      id
      name
      where
      when
      description
      entity
      bounty
      createdAt
      modifiedAt
      status
      count
      createdBy
      assignedTo
      transaction
    }
  }
}`;

class UserTaskerHome extends Component {
  
  constructor() {
    super();
    this.state = {
      header:{
        breadcrumb:[
          {
            icon:'user',
            name:'',
            active: true
          },
          {
            name:'Profile',
          }
        ],
        banner_color: 'neutral' //new | answer | edit | review | delete
      },
      user:{},
      tasks:{
        total:'',
        wip:'',
        completed:''
      }
    }
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.db_user && newProps.tasks) {
      this.updateState(newProps);
    }
  }

  updateState(props){

    if (props.db_user) {
      
      let urlUser = [];
      
      if(props.urlUser){urlUser = props.urlUser};
      
      //updating header
      const {header} = this.state;
      
      header.breadcrumb[0].name = urlUser.username;
      
      const tasks = this.countTasks();
      
      
      this.setState({user: urlUser, tasks});
      
    }
  }
  
  countTasks = ()=>{
  
    console.log('countTask here')
    
    const {tasks} = this.state;
    console.log(tasks);
    //Updating Task info
    tasks.total = this.props.tasks.length;
  
    let wip = 0;
    let completed = 0;
  
    this.props.tasks.map(item =>{
      if(item.status >= 105){
        completed++;
        console.log('completed')
      }else{
        wip++;
        console.log('Notcompleted')
      }
    })
    
    tasks.wip = wip;
    tasks.completed = completed;
    
    return tasks;
    
  }
  
  displaySkill(name){
    return(
      <tbody>
        <tr>
          <td>
            <Link to={'/q'}>{name}</Link>
          </td>
          <td>
            <div className="user_stats" style={{fontSize:'0.8em'}}>
              <span className="text_ans"> 321 </span> |
              <span className="text_ask"> 854</span>
            </div>
          </td>
        </tr>
      </tbody>
    )
  }
  
  
  displayLabel(name){
    return(
      <span className="skill">
        {name}
      </span>
      
    )
  }
  
  renderTasks(){
    
    
    const {tasks} = this.props;
    
    if(tasks){
      
      return(
        <div className="dashboard_card margin_top">
          <h4>
            <span>
            {this.state.user.username} Jobs
            ( {tasks.length} )
            </span>
  
            <Link to={`/q/usertasks/${this.state.user.id}`}>
              See all
            </Link>
        
          </h4>
          <table style={{width:'100%'}}>
            <tbody>

            {tasks.map(item => {
              if (item.status <= 109) {
                
                return(
                  <tr key={item.id}>
                    <td>
                      <Link to={`/q/task/${item.id}`}>
                        <Icon icon="arrow-left" iconSize={13}/>
                        {item.name}
                      </Link>
                    </td>
                    <td>
                      {ShowStatus(item.status, true)}
                    </td>
                    <td>
                      <Tooltip content={'More information'} position={Position.RIGHT}>
                        <Link className="bp3-button bp3-minimal bp3-icon-plus" to={`/q/task/${item.id}`}/>
                      </Tooltip>
                    </td>
                  </tr>
                )
              }
            })
            }
            
            </tbody>
          </table>
        </div>
      )
    }else{
      return(
        <div className="dashboard_card" style={{ minHeight:'380px'}}>
          <h4>
            No Tasks
          </h4>
      
        </div>
      )
    }
  }
  
  render() {
    const { id, asked, answered, about, lang, entities, username } = this.state.user;
    
    console.log(this.props, this.state)
    return (
      <div>
        {this.state.header &&
        <QuestionHeader header={this.state.header}>
          {this.props.db_user.id === this.props.urlUser.id &&
            <a className="bp3-button">Edit my info</a>
          }
        </QuestionHeader>
        }
          <Row>
            <Col xs={12} md={8} >
              
              <Row>
  
                {this.props.tasks.length !== 0 &&
                <Col xs={6} md={6}>
                  <div className="dashboard_card margin_right" style={{ minHeight:'180px', textAlign:'left'}}>
                    <h4>
                      <div>Work in Progress</div>
                    </h4>
                    <h3 className="kpi_number">{ this.state.tasks.wip }</h3>
                    {this.props.db_user.id === this.props.urlUser.id &&
                      <Link to={`/q/usertasks/${id}`}>
                        See all WIP
                      </Link>
                    }
                  </div>
                </Col>
                }
  
                {this.props.tasks.length !== 0 &&
                <Col xs={6} md={6}>
                  <div className="dashboard_card " style={{minHeight: '180px', textAlign: 'left'}}>
                    <h4>
                      Completed jobs
                    </h4>
                    <h3 className="kpi_number">{ this.state.tasks.completed }</h3>
                    {this.props.db_user.id === this.props.urlUser.id &&
                    <Link to={'/q/tasks'}>
                      <Icon icon="search" iconSize={9}/>See all available
                    </Link>
                    }
                  </div>
                </Col>
                }
                
              </Row>

              <Row>
                <Col xs={12} md={12} >
  
                
                  {this.props.tasks.length !== 0 && this.renderTasks()}
  
                  {/*only Customer and admin can see*/}
                  {this.state.user.status <= 105 &&
                    renderEntitiesList(entities)
                  }
                  
                </Col>
              </Row>
              
            </Col>

            <Col xs={12} md={4}>
              <div className="dashboard_card margin_left" style={{ minHeight:'600px', textAlign:'left'}}>
                
                <div>
                  <h4>
                    <div>About { username }</div>
  
                    {this.props.db_user.id === this.props.urlUser.id &&
                      <Link to={'/q/user/' + id + '/edit'}>
                        <Icon icon="edit" iconSize={9}/>Edit
                      </Link>
                    }
                  </h4>
                  <p>
                    { about }
                  </p>
                  <div>
                    {this.displayLabel(lang)}
                  </div>
                  <hr/>
                </div>
  
              
                  {/*<div>*/}
                    {/*<h4>*/}
                      {/*<div>*/}
                        {/*<Icon icon="people" iconSize={12}/>*/}
                        {/*Skills*/}
                      {/*</div>*/}
                      {/*<span>98</span>*/}
                    {/*</h4>*/}
                  
                    {/*<table>*/}
                      {/*{this.displaySkill('John Stone')}*/}
                      {/*{this.displaySkill('Adam Sondler')}*/}
                      {/*{this.displaySkill('Mick Jaller')}*/}
                      {/*{this.displaySkill('Billy Morgan')}*/}
                    {/*</table>*/}
                  
                    {/*<Link to={'/'} className="addmore">*/}
                      {/*<Icon icon="plus" iconSize={9}/> Invite*/}
                    {/*</Link>*/}
                
                  {/*</div>*/}
             

              </div>
            </Col>
          </Row>
      </div>
    );
  }
}

export default withRouter(withApollo(compose(
  
  graphql(
    gql(getUser),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.userId}
      }),
      props: ({ data: { getUser = {}}}) => ({
        urlUser: getUser
      })
    }
  ),
  graphql(
    gql(userTasks),
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.userId, limit:1000}
      }),
      props: ({ data: { listTasks = { items: [] } } }) => ({
        tasks: listTasks.items
      })
    }
  )
)(UserTaskerHome)));
