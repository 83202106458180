import React, { Component } from "react"
import { Button, Card, Elevation, Icon } from "@blueprintjs/core";
import { Link } from "react-router-dom"
import { withApollo, compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import axios from 'axios'
import SettingsWrapper from "./_layout/SettingsWrapper"
import { updateUser } from '../../../graphql/mutations'
import myconfig from '../../../custom-config'

class Payments extends Component {
  
  constructor(){
    super();
    this.state = {
      header: {
        counter: '',
        action: 'button',
        action_text: 'Settings',
        action_icon: 'arrow-left',
        action_route: '/q/settings',
        banner_color:'expanded',
        breadcrumb:[
          {
            name:'',
            route:'',
            icon:'cog',
            active: false
          },{
            name:'Settings',
            active: true,
            size:'L'
          },{
            name:'',
            size:'L'
          }
        ],
      },
      step: 1,
      account_id: "xyz"
    }
  }

  componentDidMount(){
    const url_string = window.location.href
    const url = new URL(url_string)
    const code = url.searchParams.get('code');
    console.log(code)
    if(code){
      this.setState({
        step: 2,
        account_id: code
      })
    }

    const { db_user } = this.props;
    if(db_user.accountNumber){
      this.setState({
        step: 3,
        account_id: db_user.accountNumber
      })
    }
  }

  handleSaveBtn = async () => {
    console.log(this.state.account_id)

    const url =`https://cors-anywhere.herokuapp.com/${myconfig.Stripe.auth_url}` 
    const client_secret = myconfig.Stripe.stripe_secret_key
    const code = this.state.account_id
    const grant_type = myconfig.Stripe.grant_type

    const stripe_param = { client_secret, code, grant_type }

    const result = await axios.post(url, stripe_param)
    console.log(result)

    if(result.status  === 200){
      const { data } = result
      
      const { updateUser } = this.props

      const userId = this.props.match.params.userId ? this.props.match.params.userId : this.props.db_user.id ;
      const param = {
        id: userId,
        accountNumber: data.stripe_user_id
      }
      updateUser(param);
      this.setState({ step: 3 })
    }
  }

  handleRemove = () => {
    const userId = this.props.match.params.userId ? this.props.match.params.userId : this.props.db_user.id;
    const { updateUser } = this.props
    const param = {
      id: userId,
      accountNumber: null
    }
    updateUser(param);
    this.setState({
      step: 1
    })
  }


  
  render() {
    console.log(this.props, this.state)
    
    //if not provided by URL, use current user
    const userId = this.props.match.params.userId ? this.props.match.params.userId : this.props.db_user.id ;
    const scope = "read_write"
    const clientId = myconfig.Stripe.client_id
    const auth_url = `https://connect.stripe.com/express/oauth/authorize?client_id=${clientId}&scope=${scope}&state=${userId}`
    const userRole = this.props.db_user ? parseInt(this.props.db_user.status) : 0;
    
    return (
      <SettingsWrapper user={userId} header={this.state.header} userRole={userRole} activeTab="receivable">
        <h2>Connect Account</h2>
        <br/>
        <br/>
        
        {this.state.step === 1 &&
          <div>
            <div style={{color:'#ff9800'}}>
              STEP 1. Please connect your EXPRESS account to the platform.<br/>
              (You can see this step because you don't have payment account yet.)
              <p/>
            </div>
            <Card interactive={true} elevation={Elevation.TWO}>
      
              <Icon icon="cross" iconSize={30} style={{color:'red'}} />
              <h3>Your account cannot receive payments yet.</h3>
              <p>In order to receive payments you need to set up your Stripe account.</p>
              <a href={auth_url}>
                <Button>Connect to Stripe</Button>
              </a>
            </Card>
            <br/><br/><br/><br/>
          </div>
        }
        
  
        {this.state.step === 2 &&
          <div>
            <div style={{color:'#ff9800'}}>STEP 2. We will save your stripe account ID in our Database so you don't need to connect your account again in the future. <br/>
              (If you agree, please click 'SAVE'.)
              <p />
            </div>
            <Card interactive={true} elevation={Elevation.TWO}>
              <Icon icon="issue" iconSize={30} style={{color:'orange'}} />
              <h3>Your new Stripe acount ID is : { this.state.account_id }</h3>
              <p>
                Click Save to finish the Stripe Setup process.
              </p>
              <Button onClick={this.handleSaveBtn}>Save</Button>
            </Card>
            <br/><br/><br/><br/>
          </div>
        }
        {/*//STEP 3*/}
        {this.state.step === 3 &&
          <div>
            <div style={{color:'#ff9800'}}>
              STEP 3. You connected to your stripe account successfully. We stored your account ID so you don't need to do this anymore. <br/>
              (You can test your payments in the next step.)
              <p />
            </div>
            
            <Card interactive={true} elevation={Elevation.TWO}>
              <Icon icon="confirm" iconSize={30} style={{color:'green'}} />
              <h3>Your account is connected</h3>
              <p>you are ready to receive payments (Use test API)</p>
              <Link to={`/q/settings/${userId}/testpayments`}>Go to Testing Payments</Link>
              
            </Card>
            <p/>
            <Button onClick={this.handleRemove}>Remove Payment Account</Button>
          </div>
        }
      </SettingsWrapper>
    );
  }
  
}
export default withApollo(compose(
  graphql(
    gql(updateUser),
    {
      props: ({mutate}) => ({
        updateUser: ({id, accountNumber}) => {
          console.log({id, accountNumber});
          mutate({
            variables: {
              input: {
                id,
                accountNumber
              }
            }
          })
        }
      })
    }
  )
)(Payments));



//http://localhost:3000/q/settings/4620325b-e59e-4a41-8dcf-768a9e301016/transactions
//http://localhost:3000/q/settings/4620325b-e59e-4a41-8dcf-768a9e301016/transactions