import React, {Component} from "react"
import {Link} from "react-router-dom"
import {v4 as uuid} from "uuid"
import {graphql, compose, withApollo} from "react-apollo"
import { withRouter } from 'react-router'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Select from 'react-select'
import { Row, Col } from 'react-flexbox-grid'
import gql from "graphql-tag"
import isInt from 'validator/lib/isInt';
import {nearest15min} from "../../../Utils"
import DateTimePickerCustomInput from "./components/DateTimePickerCustomInput"
import QuestionHeader from "../_layout/QuestionHeader"
import { listTasks,getTask, listEntitys } from '../../../graphql/queries'
import { createTask } from '../../../graphql/mutations'
// RichTextEditor
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

class TaskCreate extends Component {
  
  static defaultProps = {
    createTask: () => null,
  }
  
  state = {
    header: {
      title: '',
      qid: '',
      breadcrumb:[
        {
          icon:'globe',
        },
        {
          name:'Tasks',
          route:'/q/tasks'
        },
        {
          name:'Create Task',
          route:'',
          icon:'',
          active:true
        }
      ],
      type: 'new', //new: green | answer: blue | edit: yellow  |  review: orange  |  delete : red
      status: 115, // Need to be INT
      action: 'link',
      action_text: 'Back to Tasks',
      action_icon: 'arrow-left',
      action_route: '/q/tasks',
      banner_color: 'new' //new | answer | edit | review | delete
      
    },task: {
      name:'',
      when: nearest15min().format(),
      where:null,
      entity:null,
      status:101,
      bounty:'0',
      description:'',
      goal:'0'
      
    },
    validation: {
      name:true,
      when:true,
      where:true,
      entity:true,
      status:true,
      bounty:true,
      description:true,
      goal:true
    },
    textEditor:''
  };
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.entities) {
      this.updateState(newProps);
    }
  }
  
  componentDidMount(){
    this.updateState(this.props);
  }
  
  
  updateState(props) {
    const {header,task} = this.state;
    
    task.entityId= props.match.params.entityId;
    task.createdBy= props.db_user.id;
    
    this.setState({header,task});
    
  }
  
  //RICH TEXT EDITOR
  handleModelChange =(text)=> {
    this.setState({
      textEditor: text
    });
  }
  
  handleChange(field, {target: {value}}) {
    const {task} = this.state;
    
    task[field] = value;
    
    this.setState({task});
  }
  
  handleIntegerChange(field, {target: {value}}) {
    const {task, validation} = this.state;
    
    task[field] = value;
    validation[field] = isInt(value);
    
    this.setState({task, validation});
  }
  
  handleDateChange(field, value) {
    this.handleChange(field, {target: {value: value.format()}});
  }
  
  handleEntityChange = (selectedOption) => {
    
    const { temp,task } = this.state;
    task['entityId'] = selectedOption.value;
    task['entity'] = selectedOption.label;
    task['entityLogo'] = selectedOption.logo;
    
    this.setState({ temp,task });
  };
  
  handleSave = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    const {createTask, history} = this.props;
    const {task, textEditor} = this.state;
    
    const now= moment().format();
    task.createdAt = now;
    task.modifiedAt = now;
    task.description = textEditor;
    
    console.log(task);
    
    //The Mutation
    const result = await createTask({...task});
    
  
    
    if(result){
      if(result.data.createTask.bounty === '0'){
        //Send user straight to task
        history.push(`/q/task/${result.data.createTask.id}`);
      }else{
        //Send user to pay
        history.push(`/q/task/${result.data.createTask.id}/payment`);
      }
    }
  
   
    
  }
  
  render() {

    const {task, validation} = this.state;
    
    const {entities} = this.props;
  
    let selectedOption = '';
    const options = entities.map(entity => {
    
      //isolating Selected Option
      if(entity.id === this.props.match.params.entityId){
        selectedOption ={value: entity.id, label: entity.name, logo: entity.logo}
      }
      //Returning all options
      return ({value: entity.id, label: entity.name, logo: entity.logo })
    
    });
    
    return (
      <div>
        <QuestionHeader header={this.state.header}/>
  
        <Row center="xs">
          <Col xs={12} md={11} lg={10}>
            <div className="workarea editor_form">
  
              <table>
                <tbody>
                  <tr>
                    <td style={{width: '150px'}}>
                      Task Title
                    </td>
                    <td>
                      <input className="bp3-input bp3-fill"
                             type="text"
                             id="name"
                             value={task.name}
                             placeholder="Task Name (e.g. Complete 50 Translations)"
                             onChange={this.handleChange.bind(this, 'name')}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Channel
                    </td>
                    <td>
                      {/*<input className="bp3-input bp3-fill"*/}
                      {/*type="text"*/}
                      {/*id="where"*/}
                      {/*value={task.where}*/}
                      {/*placeholder="EntityId"*/}
                      {/*onChange={this.handleChange.bind(this, 'where')}/>*/}
    
                      {selectedOption &&(<h4>{selectedOption.label}</h4>)}
    
                      {!selectedOption &&(
                        <Select
                          options={options}
                          onChange={this.handleEntityChange}
                        />
                      )}
  
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Bounty
                    </td>
                    <td>
  
                      <div className="bp3-control-group" style={{marginTop:'0'}}>
                        <span className="bp3-tag bp3-minimal">USD $</span>
                        <input
                          className={"bp3-input " + (!validation.bounty ? 'bp3-intent-danger' : '')}
                          id="bounty"
                          type="text"
                          value={task.bounty}
                          placeholder="in USD"
                          onChange={this.handleIntegerChange.bind(this, 'bounty')}
                        />
                      </div>
                      <div style={{color:'red'}}>{!validation.bounty && 'This needs to be just a numeric value'}</div>
                      
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Task is Due
                    </td>
                    <td>
                      <DatePicker
                        className="ui container"
                        customInput={<DateTimePickerCustomInput />}
                        id="when"
                        selected={moment(task.when)}
                        onChange={this.handleDateChange.bind(this, 'when')}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        showTimeSelect
                        timeFormat="hh:mm a"
                        timeIntervals={15}
                        dateFormat="LL LT"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      City / Location
                    </td>
                    <td>
                      <input className="bp3-input bp3-fill"
                             
                             id="where"
                             value={task.where}
                             placeholder="City (e.g. New York)"
                             onChange={this.handleChange.bind(this, 'where')}/>
                    </td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>
                      <div style={{zIndex:'0'}}>
                        <FroalaEditor
                          model={this.state.textEditor}
                          onModelChange={this.handleModelChange}
                          tag='textarea'
                          config={{
                            placeholderText: 'Add Instructions here',
                            tabIndex: 0,
                            zIndex: 0
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Goal</td>
                    <td>
                      <input className="bp3-input bp3-fill"
                             id="goal"
                             value={task.goal}
                             placeholder="The amount of questions to be completed"
                             onChange={this.handleChange.bind(this, 'goal')}/>
                    </td>
                  </tr>
                
                </tbody>
              </table>
  
              <div className="editor_footer" style={{ justifyContent:'flex-end'}}>
                <Link to="/q/tasks" className="bp3-button">Cancel</Link>
                <button className="bp3-button bp3-intent-primary" onClick={this.handleSave}>Save</button>
              </div>
              
            </div>
          </Col>
        </Row>
      </div>
    
    );
  }
  
}


export default withRouter(withApollo(compose(
  graphql(
    gql(createTask),
    {
      props: (props) => ({
        createTask: (task) => {
          
          return props.mutate({
            update: (proxy, {data: {createTask}}) => {
              console.log(createTask);
              // Update ListTasks
              const query = gql(listTasks);
              
              const data = proxy.readQuery({query});
              
              data.listTasks.items = [...data.listTasks.items.filter(e => e.id !== createTask.id), createTask];
              
              proxy.writeQuery({query, data});
              
              // Create cache entry for GetTask
              const query2 = gql(getTask);
              const variables = {id: createTask.id};
              const data2 = {getTask: {...createTask}};
              proxy.writeQuery({query: query2, variables, data: data2});
              
            },
            refetchQueries:[`listTasks`],
            variables:{input: task},
            optimisticResponse: () => ({
              createTask: {
                ...task,
                id: uuid(),
                __typename: 'Task',
                comments: {__typename: 'CommentConnection', items: []}
              }
            }),
          })
        }
      })
    }
  ),
  graphql(
    gql(listEntitys),
    {
      options: {
        //fetchPolicy: 'network-only',
      },
      props: ({ data: { listEntitys = { items: [] } } }) => ({
        entities: listEntitys.items
      })
    }
  )
)(TaskCreate)));
