import React, { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'

import {
  TwitterShareButton, TwitterIcon,
  FacebookShareButton, FacebookIcon,
  LinkedinShareButton, LinkedinIcon,
  TelegramShareButton, TelegramIcon,
  WhatsappShareButton, WhatsappIcon,
  RedditShareButton, RedditIcon
} from 'react-share';

import Tabs from './Tabs'

class Invite extends Component {
  
  constructor() {
    super();
    this.state = {
    
    }
  }
  
  renderIcon(image){
    return(
      <img style={{width:'20px', height:'auto', opacity:'1'}} src={image} alt=""/>
    );
  }
  
  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    
    e.target.focus();
    this.setState({ copySuccess: 'Copied!' });
  };
  
  renderOptions(){
  
    const entityId = this.props.record.entityId;
    const slug = this.props.slug;
    const entityDisplayName = this.props.record.displayName;
  
    
    const url = `/join/${entityId}/${slug}`;
    const title = "Check this, you can ask anything to "+entityDisplayName+" here";
    
    return(
      <Row center="xs" style={{textAlign:'inherit'}}>
        <Col xs={11} md={8}>
  
          <Tabs record={this.props.record} type={this.props.type}/>
  
          <div className="settings_header">
  
            <div className="settings_social">
              
  
              <TwitterShareButton
                url={url}
                title={title}
                style={{cursor: 'pointer'}}
                >
                <TwitterIcon
                  size={32}
                  round />
              </TwitterShareButton>
    
              <FacebookShareButton
                url={url}
                quote={entityId+" FAQ"}
                style={{cursor: 'pointer'}}
              >
                <FacebookIcon
                  size={32}
                  round />
              </FacebookShareButton>
  
              <RedditShareButton
                url={url}
                title={title}
                windowWidth={660}
                windowHeight={460}
                style={{cursor: 'pointer'}}
                >
                <RedditIcon
                  size={32}
                  round />
              </RedditShareButton>
              
    
              <LinkedinShareButton
                url={url}
                title={title}
                windowWidth={750}
                windowHeight={600}
                style={{cursor: 'pointer'}}
              >
                <LinkedinIcon
                  size={32}
                  round />
              </LinkedinShareButton>
    
    
              <WhatsappShareButton
                url={url}
                title={title}
                separator=":: "
                style={{cursor: 'pointer'}}
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
    
              <TelegramShareButton
                url={url}
                title={title}
                style={{cursor: 'pointer'}}
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
  
  
            </div>
            
          </div>
          
  
          <p>
            Share this link to invite others to join :
          </p>
          
          <form>
            <textarea
              className="embed_box bp3-fill"
              ref={(textarea) => this.textArea = textarea}
              value={url}
              readOnly={true}
            />
            <button className="button primary" onClick={this.copyToClipboard}>Copy</button>
            <span style={{margin:'0 10px'}}>{this.state.copySuccess}</span>
          </form>
          
        </Col>
        
      </Row>
    )
    
  }
  
  render(){
    return(
      this.renderOptions()
    );
  }
}

export default Invite;

