import React, {Component} from "react"
import { graphql, compose, withApollo } from "react-apollo"
import gql from "graphql-tag"
import {Link} from "react-router-dom"
import {Icon, Button} from "@blueprintjs/core"
import { Grid,Row, Col } from 'react-flexbox-grid'
import Select from 'react-select'
import moment from 'moment'
import { getTask} from '../../../graphql/queries'
import { getUser, listStripeCustomers } from '../../../graphql/queries'
import { updateTask } from "../../../graphql/mutations"
import TaskWrapper from "./components/TaskWrapper"
import myconfig from '../../../custom-config'
import powered from '../../_layout/assets/poweredstripe.gif'

class TaskPayment extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      task:{
        id:'',
        status:''
      },
      cust_id: null,
      messages:{
        owner:{
          pending:['issue','orange', 'Please complete payment', 'Your job is not public yet'],
          escrow:['tick','green', 'We received your payment', 'Waiting for a Tasker to signup to your job'],
          escrowAssigned:['tick','green', 'Your payout is ready to be releasedZ', 'We recommend to wait until job is complete'],
          transferred:['arrow-right','blue', 'Job paid', 'Bounty was transferred to tasker, but task is not complete yet'],
          completed:['thumbs-up','blue', 'Job Completed & Paid', 'Bounty was transferred to tasker'],
          cancelled:['cross','grey', 'Closed', 'This job is closed'],
          nocard:['issue','grey', 'Please provide Card Number', 'Your job was saved in the Posted Jobs section'],
          nobounty:['minus','blue', 'No bounty assigned for this task', 'Adding a monetary reward can motivate taskers']
        },
        assignee:{
          pending:['issue','gold', 'Job is not funded yet', 'We are waiting for customer payment'],
          escrow:['tick','blue', 'Payment for this task', 'will be transferred to your account once this job is complete'],
          escrowAssigned:['tick','blue', 'Payment for this taskXXX', 'will be transferred to your account once this job is completeXXX'],
          transferred:['arrow-right','blue', 'Job paid', 'Bounty was transferred to tasker, but task is not complete yet'],
          completed:['thumbs-up','green', 'Job Completed & Paid', 'Bounty was transferred to your account'],
          cancelled:['cross','grey', 'Closed', 'This job is closed'],
          nocard:['issue','grey','Payment not confirmed',"Task creator doesn't have payment Method assigned"],
          nobounty:['confirm','blue', 'No payment for this task', 'You will be credited as the author']
        },
        other:{
          pending:['issue','gold', 'Job is not funded yet', 'We are waiting for customer payment'],
          escrow:['tick','grey', 'This job is open', 'Add this to your queue before someone else does.'],
          escrowAssigned:['tick','grey', 'This job have been assigned', 'You can check other available jobs'],
          transferred:['arrow-right','blue', 'Job paid', 'Bounty was transferred to tasker, but task is not complete yet'],
          completed:['thumbs-up','blue', 'Job Completed & Paid', 'Bounty transferred to tasker'],
          cancelled:['cross','grey', 'Job Closed', 'This job is closed'],
          nocard:['issue','grey','This Job is not funded yet','No payment method defined'],
          nobounty:['confirm','blue', 'This is a not Paid Task', 'You will be credited as the author']
        },
      },
      transfer:null
    }
    
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.task && newProps.tasker) {
      this.updateState(newProps);
    }else if(newProps.listStripeCustomers){
      
      const c = newProps.listStripeCustomers;
      //Needed to populate first available card for Stripe call
      const cust_id= c[0] ? c[0].cust_id :null;
  
      this.setState({cust_id: cust_id});
      
    }
    
  }
  
  updateState(props) {
    const {task, messages} = this.state;
    task.id = (props.task ? props.task.id : '');
    task.status = (props.task ? props.task.status : '');
    
  
    
    
    //ONLY UPDATE TEXT THAT INCLUDES BOUNTY, THE REST update directly in Resppnse Object.
    messages.owner.pending[2] = `Please complete $${props.task.bounty} usd payment`;
    messages.owner.escrow[2] = `We received your $${props.task.bounty} usd payment`;
    messages.owner.escrowAssigned[2] = `$${props.task.bounty} payout to '${props.tasker.username}' is ready to be released`;
    messages.owner.completed[3] = `$${props.task.bounty} bounty was transferred to tasker`;
    messages.assignee.escrow[2] = `$${props.task.bounty} Payment`;
    messages.assignee.completed[3] = `$${props.task.bounty} payment was transferred to your account`;
    
    
    this.setState({task});
    
  }


  getSelectData = (customers) => {
    let result = []
    customers.map(customer => {
      result.push({
        value: customer.cust_id,
        label: `${customer.brand} ( •••• ${customer.last4} )`
      })
      return null
    })
    return result
  }

  createCharge = async () => {
    const res = await fetch(`${myconfig.Stripe.apiUrl}/charges`, { // POST to our backend server with the token and charge detgails
      method: 'POST',
      body: JSON.stringify({
        customer_id: this.state.cust_id,
        charge: {
          amount: this.props.task.bounty * 100,
          currency: myconfig.Stripe.currency,
        },
        transfer_group: 'test payment group'
      }),
    });
    const data = await res.json();
    const now= moment().format();
    console.log(data);
    if (data.message && data.message.includes('success')) {
      this.props.updateTask({
        id: this.props.match.params.id,
        transaction: data.charge.id,
        paidBy:data.charge.customer,
        paidAt:data.charge.created,
        paidAmount:data.charge.amount,
        
      })
    } else {

      alert('failed')
    }
  }
  
  renderStripePayment(){
    const {listStripeCustomers} = this.props;
    const selectedData = listStripeCustomers ? this.getSelectData(listStripeCustomers) : null
    
    return(
      
      <div>
        <div style={{textAlign: 'left'}}>
          <Select
            defaultValue={selectedData[0]}
            name="color"
            options={selectedData}
            onChange={item => {
              this.setState({cust_id: item.value})
            }}
          />
        </div>
        <div
          style={{display: 'flex', justifyContent: 'space-between', paddingTop: '20px'}}
        >
          <img src={powered} style={{width: '130px', opacity: '0.3'}} alt=""/>
  
          {this.state.cust_id &&
            <Button
              onClick={this.createCharge}
              className="bp3-button bp3-intent-success"
            >
              Create Charge
            </Button>
          }
        </div>
      </div>
      
    )
  }
  
  releasePayment = async() => {
    const { charge } = this.state;
    const {tasker, task} = this.props;
    
    //Info required to complete Transfer:
    //1. Transfer Recipient, tasker receiving money
    const RecipentStripeAccNumber = tasker.accountNumber;
    const RecipentCognitoId = task.assignedTo;
    //2. Related Credit Card charge
    const relatedCharge =task.transaction;
    //3. Transfer Amount
    const transferAmount =task.bounty *75;
    //4. Transfer Amount
    
    const res = await fetch(`${myconfig.Stripe.apiUrl}/transfer`, { // POST to our backend server with the token and charge detgails
      method: 'POST',
      body: JSON.stringify({
        charge:{
          id:relatedCharge,
          amount: transferAmount,
          currency: myconfig.Stripe.currency,
        },
        destination: RecipentStripeAccNumber,
        // transfer_group: transferGroup
      }),
    });
    const data = await res.json();
    const now= moment().format();
    
    //Todo: Save info to Task.transferTo, Task.transferAt, Task.transferId
    //Todo: Is Transfer Amount Necessary?
    if (data.message && data.message.includes('success')) {
      
      this.setState({
        //Not sure why this is needed anymore
        transfer: data.transfer
      }, () => {
        console.log(data)
  
        //Updating DB
        this.props.updateTask({
          id: this.props.task.id,
          transferTo:data.transfer.destination,
          transferAt:data.transfer.created,
          transferId:data.transfer.id
        })
        
      })
    } else {
      alert ('Release failed');
      console.log(data);
    }
  }
  
  renderStripePaymentRelease(){
    return(
      
      <div>
  
        {this.props.tasker &&
        <div
          style={{display: 'flex', justifyContent: 'space-between', paddingTop: '20px'}}
        >
          <img src={powered} style={{width: '130px', opacity: '0.3'}} alt=""/>
          <button
            onClick={this.releasePayment}
            className="bp3-button bp3-intent-success"
          >
            Release Payment
          </button>
        </div>
        }
      </div>
    
    )
  }
  
  
  renderPanels(){
  
    const {task, db_user, tasker, listStripeCustomers} = this.props;
    const {messages} = this.state;
    const userStripeAccounts = listStripeCustomers ? this.getSelectData(listStripeCustomers) : null
    
    //DEFINING USER ROLE ::::::::::::::::::
    let role=null;  // owner | assignee | *
    
    if(task){
      if(task.createdBy === db_user.id){
        role = 'owner';
      }else if(task.assignedTo === db_user.id){
        role = 'assignee';
      }else{ role='other'}
    }
    
    //DEFINING TASK STATUS ::::::::::::::::::
    let paymentStatus=null;
    
    if(task){
      
      if(task.status ==='105' && task.transferId){
        paymentStatus = 'completed';
      }else if(task.status ==='110'){
        paymentStatus = 'cancelled';
      }else{
        if(parseInt(task.bounty)){
          if(task.paidBy){
            //If this transaction was transfered
            if(task.transferId) {
              paymentStatus = 'transferred';
            }else{
              if(tasker){
                //Checking if tasker has Stripe account Number already
                if(tasker.accountNumber){
                  console.log(tasker)
                  paymentStatus = 'escrow';
                }else{
                  console.log(tasker)
                  paymentStatus = 'norecipent';
                }
              }
            }
            
            
          }else{
            //This is not working for 'Other' because there's no userStripeAccounts
            //userStripeAccount comes from listStripeCustomers GQL query
            //This query uses user_db.id
            //console.log(userStripeAccounts.length)
            
            //if accounts exist is because owner have Cards on file
            if(userStripeAccounts.length){
              paymentStatus= 'pending';
              //DIFFERENTIATE BETWEENOWNER AND NOT
            }else{
              if(role==='owner'){
                paymentStatus= 'nocard';
              }else{
                paymentStatus= 'pending';
              }
              
            }
          }
        }else{
          paymentStatus = 'nobounty';
        }
      }
    }
    console.log(role,' | ',paymentStatus);
    
    //DEFINING PROPER MESSAGE FOR ROLE+PAYMENTSTATUS ::::::::::::::::::
    
    let info = null;
    let cta = null;
    let helper = null;
    
    const assigned = !!this.props.tasker;
    
    if(role === 'owner'){
  
      switch(paymentStatus){
        //CONFIRMED
        case 'pending' : info= messages.owner.pending; cta = {text:'Pay now', link:'/q' }; helper= this.renderStripePayment();
          break;
          //CONFIRMED
        case 'escrow' : info= assigned ? messages.owner.escrowAssigned : messages.owner.escrow; helper= this.renderStripePaymentRelease()
          break;
        case 'transferred' : info= messages.owner.transferred; cta = {text:'Check Progress', link:`/q/task/${task.id}/progress`};
          break;
        //CONFIRMED
        case 'completed' : info= messages.owner.completed; cta = {text:'Release Payment', link:'/q/newtask' };
          break;
        //CONFIRMED
        case 'cancelled' : info= messages.owner.cancelled; cta = {text:'Create new job', link:'/q/newtask' };
          break;
        //CONFIRMED
        case 'nocard' : info= messages.owner.nocard; cta = {text:'Add Payment Method', link:`/q/settings/${db_user.id}/paymentmethods`};
          break;
          //CONFIRMED
        case 'nobounty' : info= messages.owner.nobounty; cta = {text:'Go to Task', link:`/q/task/${task.id}` };
          break;
        default: info= messages.owner.cancelled; cta = {text:'Create new job', link:'/q/newtask' };
      }
      
    }else if(role === 'assignee'){
  
      switch(paymentStatus){
        //CONFIRMED
        case 'pending' : info= messages.assignee.pending; cta = {text:'See other open jobs', link:'/q/tasks' };
          break;
          //CONFIRMED
        case 'escrow' : info= messages.assignee.escrow; cta = {text:'Task Progress', link:`/q/task/${task.id}/assign` };
          break;
        case 'transferred' : info= messages.assignee.transferred; cta = {text:'Check Progress', link:`/q/task/${task.id}/progress`};
          break;
          //CONFIRMED
        case 'completed' : info= messages.assignee.completed; cta = {text:'See other jobs', link:'/q/tasks' };
          break;
          //CONFIRMED
        case 'cancelled' : info= messages.assignee.cancelled; cta = {text:'See other jobs', link:'/q/tasks' };
          break;
          //CONFIRMED
        case 'nocard' : info= messages.assignee.nocard; cta = {text:'See other jobs', link:'/q/tasks' };
          break;
        //CONFIRMED
        case 'nobounty' : info= messages.assignee.nobounty; cta = {text:'Signup for job', link:`/q/task/${task.id}/assign` };
          break;
        default: info= messages.assignee.cancelled; cta = {text:'See other jobs', link:'/q/tasks' };
      }
      
    }else{
      
      switch(paymentStatus){
        //CONFIRMED
        case 'pending' : info= messages.other.pending; cta = {text:'See open jobs', link:`/q/tasks` };
          break;
          //CONFIRMED
        case 'escrow' : info= assigned ? messages.other.escrowAssigned : messages.other.escrow; cta = assigned ? {text:'Open Jobs', link:`/q/tasks` } : {text:'Signup for job', link:`/q/task/${task.id}/assign` };
          break;
        case 'transferred' : info= messages.other.transferred; cta = {text:'Check Progress', link:`/q/task/${task.id}/progress`};
          break;
          //CONFIRMED
        case 'completed' : info= messages.other.completed; cta = {text:'See open jobs', link:`/q/tasks` };
          break;
          //CONFIRMED
        case 'cancelled' : info= messages.other.cancelled; cta = {text:'See open jobs', link:`/q/tasks` };
          break;
          //CONFIRMED
        case 'nocard' : info= messages.other.nocard; cta = {text:'See other jobs', link:`/q/tasks` };
          break;
          //CONFIRMED
        case 'nobounty' : info= messages.other.nobounty; cta = {text:'Signup for job', link:`/q/task/${task.id}/assign` };
          break;
        default: info= messages.other.cancelled; cta = {text:'See open jobs', link:`/q/tasks` };
      }
    }
  
    return this.renderPanel(info, cta, helper);
    
  }
  
  renderPanel(i, cta, helper){
    
    return(
     
        <Row center="xs">
          <Col xs={12} md={12}>
            <div className="task_card" style={{justifyContent:'center', textAlign:'center'}}>
              <Icon icon={i[0]} iconSize={80} style={{color: i[1]}} />
              <br/>
              <br/>
              <h4 className="bp3-heading" style={{color: i[1]}}>
                {i[2]}
              </h4>
              <h5>{i[3]}</h5>
              
              {helper ?
                helper
              :
                <Link className="bp3-button" to={cta.link}>{cta.text}</Link>
              }
              
            </div>
          </Col>
        </Row>
      
    )
  }
  
  render() {
  
    const {task, db_user} = this.props;
    console.log(this.props, this.state)
    
    return (
      <div>
        {task &&
          <TaskWrapper
            section="payment"
            task={task}
            user={db_user}
          >
            {this.renderPanels()}
          </TaskWrapper>
        }
      </div>
    );
  }
  
}


export default withApollo(compose(

  graphql(
    gql(getTask),
    {
      options: ({match: {params: {id}}}) => ({
        variables: {id, limit:1000,},
        fetchPolicy: 'cache-and-network',
      }),
      props: ({data: {getTask: task, loading}}) => ({
        task,
        loading,
      }),
    },
  ),
  graphql(
    gql(getUser),
    {
      options: (props) => ({
        variables: {id: props.task ? props.task.assignedTo : '000' },
        fetchPolicy: 'cache-and-network',
      }),
      props: ({data: {getUser: tasker, loading}}) => ({
        tasker,
        loading,
      }),
    },
  ),
  graphql(
    gql(listStripeCustomers),
    {
      options: (props) => ({
        variables: {
          limit: 1000,
          filter: { cognitoId: { eq: props.db_user.id } }
        }
      }),
      props: ({ data: { listStripeCustomers = { items: [] } } }) => ({
        listStripeCustomers: listStripeCustomers.items
      })
    }
  ),
  graphql(
    gql(updateTask),
    {
      props: ({ mutate }) => ({
        updateTask: ({ id, transaction, paidBy, paidAt, paidAmount, transferTo, transferAt, transferId, status }) => {
          console.log({ id, transaction, paidBy, paidAt, paidAmount, transferTo, transferAt, transferId, status });
          mutate({
            variables: {
              input: {
                id,
                transaction,
                paidBy,
                paidAt,
                paidAmount,
                transferTo,
                transferAt,
                transferId,
                status
              }
            }
          })
        }
      })
    }
  )
)(TaskPayment));

