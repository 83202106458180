import React, { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import {Icon} from "@blueprintjs/core"
import Tabs from './Tabs'

class WebEmbed extends Component {
  
  constructor() {
    super();
    this.state = {
      copySuccess: ''
    }
  }
  
  renderIcon(image){
    return(
      <img style={{width:'20px', height:'auto', opacity:'1'}} src={image} alt=""/>
    );
  }
  
  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    
    e.target.focus();
    this.setState({ copySuccess: 'Copied!' });
  };
  
  renderOptions(){
  
    const entityId = this.props.record.entityId;
    const slug = this.props.slug;
    
    return(
      <Row center="xs" style={{textAlign:'inherit'}}>
        <Col xs={11} md={8}>
          <Tabs record={this.props.record} type={this.props.type}/>
          <div className="settings_header">
            
            <p>Embed this FAQ in your website or blog
            </p>
            <span className="mobile_hide">
              <Icon  icon="code" iconSize={35} style={{color:'#CCC', margin:'0 30px'}} />
            </span>
            
          </div>
  
          <form>
            <textarea
              className="embed_box bp3-fill"
              ref={(textarea) => this.textArea = textarea}
              value={entityId+"_"+slug}
              readOnly={true}
            />
            <button className="button primary" onClick={this.copyToClipboard}>Copy</button>
            <span style={{margin:'0 10px'}}>{this.state.copySuccess}</span>
          </form>
      
          
        </Col>
      </Row>
    )
    
  }
  
  render(){
    return(
      this.renderOptions()
    );
  }
}

export default WebEmbed;

