import gql from "graphql-tag";

export default gql(`
query listQuestions($id:String!){
  listQuestions(limit:1000, filter: { authorId: {eq: $id}}){
    items{
      id
    }
  }
}`);
