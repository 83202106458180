import React from "react"
import { Row, Col } from 'react-flexbox-grid'
import TaskInfo from './TaskInfo'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';


const TaskModal =({task})=>{
  
  const renderInfo =()=> {
    return (
      <TaskInfo task={task}/>
    );
  }
    
  return (
    <div>
      
      <Row center="xs">
        <Col xs={11} sm={11} md={10} lg={10} >
          <div className="workarea editor_form" style={{ textAlign:'left', padding:'20px 0'}}>
            
            {renderInfo()}
            
            <hr/>
            <FroalaEditorView
              model={task.description}
            />
          
          </div>
        </Col>
      </Row>
    </div>
  
  );
  
  
}


export default TaskModal;