import React, {Component, Fragment} from "react"
import { graphql, compose, withApollo } from 'react-apollo';
import {Link} from "react-router-dom";
import moment from 'moment';
import { withRouter } from 'react-router';
import { Row, Col } from 'react-flexbox-grid'
import gql from "graphql-tag"
import {Icon, Dialog} from "@blueprintjs/core"
import { getTask, listEntitys } from '../../../graphql/queries'
import TaskModal from "./components/TaskModal"
import { updateTask } from '../../../graphql/mutations'
import QuestionHeader from "../_layout/QuestionHeader"
import {nearest15min} from "../../../Utils";
import ShowStatus from "../../_utils/ShowStatus"


class TaskAssign extends Component {
  
  constructor() {
    super();
    this.state = {
      header: {
        title: '',
        qid: '',
        type: 'answer', //new: green | answer: blue | edit: yellow  |  review: orange  |  delete : red
        //status: 1, // Need to be INT
        action: 'link', // link | button
        action_text: 'Back to Tasks', //Text inside button
        action_icon: 'arrow-left', //icon inside button
        action_route: '/q/tasks', //Button route
        banner_color: 'new' //new | answer | edit | review | delete
      },
      task: {
        id:'',
        name: '',
        when: nearest15min().format(),
        where: '',
        description: '',
        entity:'',
        entityId:'eid',
        entityLogo:'logo',
        bounty:'',
        createdAt:'',
        modifiedAt:'',
        count:'',
        createdBy:'',
        completedBy:'',
        status:'102',
        assignedTo:'',
        assignedToName:null
      },
      modals:{
        first:false,
        second:false
      }
      
    }
  }
  
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.task) {
      this.updateState(newProps);
    }
  }
  
  updateState(props) {
    const {header,task} = this.state;
    
    header.status = props.task.status;
    
    task.id= props.task.id;
    task.name= props.task.name;
    task.when= props.task.when;
    task.where= props.task.where;
    task.description= props.task.description;
    task.entity= props.task.entity;
    task.entityId= props.task.entityId;
    task.entityLogo= props.task.entityLogo;
    task.bounty= props.task.bounty;
    task.assignedTo= props.db_user.id;
    task.assignedToName= props.db_user.username;
    task.status= props.task.status;
    
    this.setState({header,task});
    
  }
  
  handleEntityChange = (selectedOption) => {
    
    const { task } = this.state;
    task['entityId'] = selectedOption.value;
    task['entity'] = selectedOption.label;
    task['entityLogo'] = selectedOption.logo;
    
    this.setState({ task });
    console.log(`Option selected:`, selectedOption);
  };
  
  handleValues = field => event => {
    const { task } = this.state
    task[`${field}`] = event.target.value;
    this.setState({ task });
  }
  
  handleDateChange(field, value) {
    const { task } = this.state
    task[`${field}`] = value;
    this.setState({ task });
  }
  
  handleSave = () => {
    const { history, updateTask } = this.props;
    const { task } = this.state;
  
    task.status= '102';
    
    task.modifiedAt = moment().format();
    
    console.log('handleSave');
    updateTask(task);
    
    history.push("/q/tasks/");
  }
  
  renderWhere(){
    
    const where = this.state.task.where;
    let text = null;
    
    if(where) {
      text = <Fragment>that I'm located in <strong>{where}</strong> to complete this task, </Fragment>;
    }
    
    return text;
    
  }
  
  handleToggleModal = () => {
    const { modals} = this.state;
    modals.first = !modals.first;
    this.setState({ modals });
  };
  
  renderModal(){
    
    return(
      <Dialog
        icon="info-sign"
        onClose={this.handleToggleModal}
        title="Task Instructions"
        isOpen={this.state.modals.first}
      >
        <TaskModal task={this.props.task}/>
    
        <div className="bp3-dialog-footer">
          <button className="bp3-button bp3-intent-success bp3-fill" onClick={this.handleToggleModal}>Gotcha, close this</button>
        </div>
      </Dialog>
    
    )
  };
  
  
  render() {
    const {loading, task} = this.props;
    console.log(this.props);
  
    const {
      id,
      name,
      when,
      bounty
    } = this.state.task;
    
    return (
      <div className={`someclasshere ${loading ? 'loading' : ''}`}>
        
        <Row center="xs">
          <Col xs={10} >
             <div className="workarea editor_form" style={{flexFlow: 'column', justifyContent:'center', display:'flex'}}>
               <table>
                 <tbody>
                 <tr>
                   <td>
                   
                   </td>
                   <td>
                     <h2 style={{color:'#CCC'}}>I confirm that: </h2>
                   </td>
                 </tr>
                 <tr>
                   <td>
                   </td>
                   <td>
                     {/*I, (<span className='icon1' style={{ backgroundImage:`url(${this.props.db_user.avatar})`, margin:'0 5px', width:'20px', height:'20px' }}/>{this.props.db_user.first} {this.props.db_user.last})*/}
                      All the
                     <a onClick={this.handleToggleModal} style={{fontWeight:'600'}}> Instructions </a>
                     {this.renderModal()}
                     
                     related to: <strong>"{name}"</strong> are clear,
                     {task &&
                       task.where &&
                        this.renderWhere()
                     }
                      and that in order to receive the payment of <strong>${bounty} USD</strong> all the items need to be marked as "<strong>{ShowStatus(105, true)}</strong>"
                     before: <strong>{moment(when).format('LL')}</strong>
                   </td>
                 </tr>
                 <tr>
                   <td>
                     Assignee:
                   </td>
                   <td>
                     {this.props.db_user.avatar &&
                      <div className='icon1' style={{ backgroundImage:`url(${this.props.db_user.avatar})`, float:'left', margin:'0 5px', width:'50px', height:'50px' }}/>
                     }
  
                     {this.props.db_user.first ?
                      `${this.props.db_user.first} ${this.props.db_user.last}`
                      :
                       this.props.db_user.username
                     }
                     
                   </td>
                 </tr>
                 <tr>
                   <td>
                     Payment:
                   </td>
                   <td>
                     {this.props.db_user.accountNumber ?
                       <div style={{color:'green'}}>
                         <Icon icon={'tick'} iconSize={25}/>
  
                         Your account is Ready to receive payments
                       </div>
                       :
                       <div>Not Defined yet <a href="/">(Add payment info)</a></div>
                     }
                     
                   </td>
                 </tr>
    
                 </tbody>
               </table>
  
               <div className="editor_footer" style={{ justifyContent:'flex-end'}}>
                 <Link to="/q/tasks" className="bp3-button">Cancel</Link>
                 <button className="bp3-button bp3-intent-success" onClick={this.handleSave} >Ok, Everything looks good</button>
               </div>
               
              </div>
          </Col>
        </Row>
      </div>
    );
  }
  
}


export default withRouter(withApollo(compose(
  
  graphql(
    gql(getTask),
    {
      options: ({match: {params: {id}}}) => ({
        variables: {id},
        fetchPolicy: 'cache-and-network',
      }),
      props: ({data: {getTask: task, loading}}) => ({
        task,
        loading,
      }),
    },
  ),
  graphql(
    gql(listEntitys),
    {
      options: {
        //fetchPolicy: 'network-only',
      },
      props: ({ data: { listEntitys = { items: [] } } }) => ({
        entities: listEntitys.items
      })
    }
  ),
  graphql(
    gql(updateTask),
    {
      props: ({ mutate }) => ({
        updateTask: ({ id, name, when, where, description, entity, bounty, entityId, entityLogo, assignedTo, assignedToName, status }) => {
          console.log(id, name, when, where, description, entity, bounty,  entityId, entityLogo, assignedTo, assignedToName, status);
          mutate({
            variables: {
              input: {
                id,
                name,
                when,
                where,
                bounty,
                description,
                entity,
                entityId,
                entityLogo,
                assignedTo,
                assignedToName,
                status
              }
            }
          })
        }
      })
    }
  ),
)(TaskAssign)));

