import {
  GET_DATA_SAGA,
  SET_DATA,
  SET_USER,
  SET_HEADER,
} from '../_constants';

export function setUser(user) {
  return {
    type: SET_USER,
    payload: user
  };
}

export function setHeader(header) {
  return {
    type: SET_HEADER,
    payload: header
  }
}

export function setData(data) {
  return {
    type: SET_DATA,
    payload: data,
  };
}

//Sagas
export function getDataSaga() {
  return {
    type: GET_DATA_SAGA,
  };
}
