import React, { Component } from 'react';
import QuestionHeader from "../_layout/QuestionHeader";
import { graphql, compose, withApollo } from "react-apollo";
import gql from "graphql-tag";
import Query from "../../../graphql/editor/GetAnswer";

class AnswerDetail extends Component {
  
  constructor(){
    super();
    this.state = {
      header:{
        qid: '..', //dynamically generated
        breadcrumb:[
          {
            name:'Answer',
            route:'',
            icon:''
          }
        ],
        status: 1, // 1 | 2 | 3 | 4 | 5 | 6 (need to be INT)
        action: 'link', // link | button
        action_text: 'Go to question', //Text inside button
        action_icon: 'arrow-left', //icon inside button
        action_route: '/q/', //Button route
        banner_color: '' //new | answer | edit | review | delete
      },
      answer: {
        questionId: '12345',
        question: 'No Question',
        answer: '',
        author: '',
        entity: 'No PublicView'
      }
    }
  }
  
  componentWillReceiveProps(newProps) {
    if(newProps && newProps.answer) {
      this.updateState(newProps)
    }
  }
 
  async updateState(props){
    const { header } = this.state;
    header.qid = props.match.params.answerId;
    header.action_route = "/q/"+ props.answer.questionId;
    
    const {answer} = this.state;
    answer.questionId = props.answer.questionId;
    answer.answer = props.answer.answer;
    answer.author = props.answer.author;

    let result = {}
    const {client} = props;
    try {
      result = await client.query({
        query: gql`
          query getQuestion($id: ID!){
            getQuestion(id: $id) {
              question
              author
              lang
              entity
            }
          }
        `,
        variables: {
          id: props.answer.questionId,
        },
      });
    } catch (error) {
      console.log(error);
    }
    
    if ( result && result.data.getQuestion) {
      answer.question = result.data.getQuestion.question;
      answer.entity =  result.data.getQuestion.entity;
    }
    this.setState({header, answer});
  }

  handleBack = () => {
    const { history } = this.props
    history.goBack();
  }

  render() {

    const { question, answer, author, entity } = this.state.answer;
    return (
      <div>
        <QuestionHeader header={this.state.header} />
        
          <div className="workarea editor_form">
            <table>
              <tbody>
                <tr>
                  <td >
                    Question
                  </td>
                  <td>
                    <p>{question}</p>
                  </td>
                </tr>
                <tr>
                  <td >
                    Entity
                  </td>
                  <td>
                    <p>{ entity }</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    Answer
                  </td>
                  <td>
                    <h3> { answer } </h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    Credit
                  </td>
                  <td>
                    <p>{ author }</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
            <div className="editor_footer">
              <div>
                <button className="bp3-button bp3-intent-primary" onClick={this.handleBack}>Go Back</button>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withApollo(compose(

  graphql(
    Query,
    {
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {id: props.match.params.answerId}
      }),
      props: ({ data: { getAnswer = {}}}) => ({
        answer: getAnswer
      })
    }
  ),
)(AnswerDetail));